import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const DonutGraphLegend = ({ data }) => {
  return (
    <Box display="flex" justifyContent="center" flexDirection="">
      <Box my={1} display="flex" flexWrap="wrap">
        {data?.map((d) => {
          return (
            <Box display="flex" alignItems="center" mr={1}>
              <Box
                style={{
                  backgroundColor: d.color,
                  width: 8,
                  height: 8,
                  marginRight: '5px',
                }}
              />
              <FdTypography variant="captiontext2">{d.label}</FdTypography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

DonutGraphLegend.propTypes = {
  data: PropTypes.isRequired,
};

export default DonutGraphLegend;

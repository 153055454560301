import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { format } from 'date-fns';
import { Box, Divider } from '@mui/material';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import Progress from './Progress';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import { initCaps, numberToRoman } from '../../shared/utils/stringUtils';
import TrophyBadge from './images/tiers/TrophyBadge';
import BadgeContainer from './BadgeContainer';

const TooltipLabelValue = ({ label, value }) => (
  <Box className="flex flex-col gap-y-2">
    <Box>
      <FdTypography variant="captiontext1" style={{ fontWeight: 700 }}>
        {label}
      </FdTypography>
      <FdTypography variant="captiontext1">{value}</FdTypography>
    </Box>
  </Box>
);

TooltipLabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const SectionModalContent = ({ section }) => {
  const { specialty } = section?.currentBadgeLevel || {};
  const badgeLevels = section?.badgeLevels || [];
  const scoredLevels = badgeLevels?.filter((bl) => bl?.badgeAcquiredOn) || [];
  const scoredLevel = _.max(scoredLevels?.map((bl) => bl?.level)) || 0;
  const scoredLevelEnd =
    scoredLevels?.find((sl) => sl?.level === scoredLevel)?.endDisplay || 0;
  const isPointProwess = section?.specialty === 'points';

  return (
    <Box>
      <FdTypography variant="captiontext1" color="secondary">
        {`${section?.name} trophies showcase one's ability to earn points in
        competitions and assessments across any Professional Specialty. Higher
        trophy tiers are achieved as users score more points and reach point
        targets.`}
      </FdTypography>
      <Box my={2}>
        <FdTypography variant="captiontext1" color="secondary">
          Hover over each of the trophy tier images below for more information
          on each trophy tier&apos;s unlock method.
        </FdTypography>
      </Box>
      <Box my={3}>
        <Divider />
      </Box>
      <Box>
        <Box className="flex gap-x-6 my-5">
          <Box
            className="flex items-center justify-center"
            style={{ width: '35%' }}
          >
            <BadgeContainer size="large">
              <TrophyBadge
                type={section?.specialty}
                tier={scoredLevel}
                status={isPointProwess ? 'unlocked' : 'ghosted'}
              />
            </BadgeContainer>
          </Box>
          <Box className="flex flex-col" style={{ width: '65%' }}>
            <FdTypography variant="captiontext1">Current Tier:</FdTypography>
            <FdTypography variant="subtitle1">
              {scoredLevel > 0 ? `Tier ${numberToRoman(scoredLevel)}` : 'None'}
            </FdTypography>
            <Box className="flex flex-col my-2 gap-y-3">
              <Box>
                <FdTypography variant="captiontext2">
                  Unlock Method:
                </FdTypography>
                {scoredLevelEnd > 0 ? (
                  <FdTypography variant="captiontext1" color="secondary">
                    {isPointProwess
                      ? `Earn ${scoredLevelEnd} points in total across any Professional Specialties.`
                      : `Earn ${scoredLevelEnd} points in ${specialty} + Demonstrate ${scoredLevel} x ${initCaps(
                          section?.name,
                        )}
                  skills at Proficiency Level ${scoredLevel} or above.`}
                  </FdTypography>
                ) : (
                  <FdTypography variant="captiontext1" color="secondary">
                    N/A
                  </FdTypography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="flex items-center justify-around w-full mb-3">
          {getArrayByLength(5, (__, i) => i + 1).map((item) => {
            const badgeLevel = badgeLevels?.find((bl) => bl?.level === item);
            const unlockedDate = badgeLevel?.badgeAcquiredOn
              ? format(new Date(badgeLevel?.badgeAcquiredOn), 'dd-MM-yyyy')
              : '';
            return (
              <>
                <FdTooltip
                  title={
                    <Box className="flex flex-col gap-y-4">
                      <FdTypography
                        variant="captiontext1"
                        style={{ fontWeight: 700 }}
                      >
                        {`${section?.name} - Tier ${numberToRoman(item)}`}
                      </FdTypography>
                      <Box className="flex flex-col gap-y-2">
                        <TooltipLabelValue
                          label="Status:"
                          value={
                            unlockedDate
                              ? `Unlocked (${unlockedDate})`
                              : 'Locked'
                          }
                        />
                        <TooltipLabelValue
                          label="Unlock Method:"
                          value={`Earn ${badgeLevel?.endDisplay} points in total across any Professional
                      Specialties.`}
                        />
                      </Box>
                    </Box>
                  }
                >
                  <span>
                    <Box className="flex flex-col items-center justify-center mt-4">
                      <Box className="flex flex-col my-2 items-center justify-center">
                        <FdTypography variant="captiontext2" color="secondary">
                          Tier
                        </FdTypography>
                        <FdTypography variant="h4">
                          {numberToRoman(item)}
                        </FdTypography>
                      </Box>
                      <Box
                        key={item}
                        className="flex items-center justify-center my-4"
                      >
                        <BadgeContainer size="60px">
                          <TrophyBadge
                            type={section?.specialty}
                            tier={item}
                            status={unlockedDate ? 'unlocked' : 'locked'}
                          />
                        </BadgeContainer>
                      </Box>
                      <Box className="flex flex-col my-3 items-center justify-center">
                        <FdTypography
                          variant="captiontext2"
                          color={unlockedDate ? 'primary' : 'secondary'}
                        >
                          {unlockedDate ? 'Unlocked' : 'Locked'}
                        </FdTypography>
                        <FdTypography variant="captiontext2" color="secondary">
                          {unlockedDate || 'N/A'}
                        </FdTypography>
                      </Box>
                    </Box>
                  </span>
                </FdTooltip>
                {item < 5 && (
                  <Box className="flex items-center">
                    <Divider className="w-5" />
                  </Box>
                )}
              </>
            );
          })}
        </Box>
      </Box>
      <Box mb={2} mt={3}>
        <Divider />
      </Box>
      <Progress tile={section} showSkillList={!isPointProwess} isModal />
    </Box>
  );
};

SectionModalContent.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    specialty: PropTypes.string,
    tier: PropTypes.number,
    currentBadgeLevel: PropTypes.shape({}),
    pointsScored: PropTypes.number,
    badgeLevels: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default SectionModalContent;

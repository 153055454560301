import React from 'react';
import * as singleSpa from 'single-spa';
import { Box, Divider, ThemeProvider } from '@mui/material';
import PropTypes from 'prop-types';
import {
  FdTypography,
  FdCard,
  FdButton,
  useSnapshot,
  globalStore,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { TROPHY_TILES_BANNER } from '../../constants';
import TrophyBadge from './images/tiers/TrophyBadge';
import BadgeContainer from './BadgeContainer';
import TrophyRoomCardContent from './TrophyRoomCardContent';

const TrophyRoomCardParticipant = ({ theme }) => {
  const globalSnap = useSnapshot(globalStore);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <FdCard
          variant="outlined"
          media={
            <Box
              className="flex items-center justify-center "
              sx={{
                height: '120px',
                background:
                  theme?.palette?.type === 'dark'
                    ? 'conic-gradient(from 90deg at 50% 50%, #170073 0deg, #050505 225.29deg, #170073 360deg)'
                    : 'linear-gradient(89.99deg, #B6B0FF 1.46%, #D9D6FE 49.01%, #F0F8FF 99.99%)',
              }}
            >
              <Box className="flex items-center justify-center gap-x-10">
                {TROPHY_TILES_BANNER?.map((_type) => (
                  <BadgeContainer size="small">
                    <TrophyBadge type={_type} tier={5} status="unlocked" />
                  </BadgeContainer>
                ))}
              </Box>
            </Box>
          }
        >
          <Box className="flex justify-between mb-4">
            <FdPageHeading type="TROPHY" variant="h4">
              Trophy Room
            </FdPageHeading>
            <FdButton
              size="small"
              onClick={() =>
                singleSpa.navigateToUrl('/competitions/trophy-room')
              }
            >
              View
            </FdButton>
          </Box>
          <Box minHeight="36px">
            <FdTypography variant="captiontext1">
              Your Trophy Room displays the trophies you have obtained through
              various activities on the platform. Your five most recently
              obtained trophies are displayed below. Click &apos;View&apos; to
              navigate to your expanded Trophy Room.
            </FdTypography>
          </Box>
          <Box my={1}>
            <Divider />
          </Box>
          <Box mt={3}>
            <TrophyRoomCardContent userId={globalSnap?.userId} />
          </Box>
        </FdCard>
      </Box>
    </ThemeProvider>
  );
};

TrophyRoomCardParticipant.propTypes = {
  theme: PropTypes.objectOf(
    PropTypes.shape({ palette: PropTypes.shape({ type: PropTypes.string }) }),
  ).isRequired,
};

export default TrophyRoomCardParticipant;

import React from 'react';
import { Link, Box } from '@mui/material';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { FdTypography, HELPDESK_EMAIL } from '@fifthdomain/fe-shared';

const Error = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Box mt={20}>
        <WarningTwoToneIcon
          style={{
            fontSize: 68,
            color: 'rgb(255 142 55)',
          }}
        />
      </Box>
      <Box m={3}>
        <FdTypography variant="h3">Ooops! Something went wrong.</FdTypography>
      </Box>
      <Box m={3}>
        <FdTypography variant="body1">
          Please raise a support ticket or contact us at
          <Link href={`mailto:${HELPDESK_EMAIL}`}>{` ${HELPDESK_EMAIL}`}</Link>
        </FdTypography>
      </Box>
    </Box>
  );
};

export default Error;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import {
  FdTable,
  FdChip,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import Summary from '../Assessment/Summary';
import { listTeamsByEventId } from '../../graphql/queries';
import {
  manageTeamEvents,
  addUserToTeam,
  createTeam,
} from '../../graphql/mutations';
import { TEAM_MEMBER_TYPES } from '../../constants';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { getParticipantStatus } from '../../shared/utils/getParticipantStatus';
import AddTeams from './AddTeams';
import InviteTeams from './InviteTeams';
import ViewTeam from './ViewTeam';

const TeamTab = ({
  assessmentId,
  status,
  statusColor,
  maxTeamSize,
  loading,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const [createModal, setCreateModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [removeTeam, setRemoveTeam] = useState(undefined);
  const [viewTeam, setViewTeam] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    data: teamsInCompetitionData,
    loading: teamsInCompetitionDataLoading,
    refetch: refetchListTeamsByEventId,
  } = useQueryRecursive(gql(listTeamsByEventId), {
    variables: {
      eventId: assessmentId,
      filter: {
        status: {
          ne: 'REMOVED',
        },
      },
    },
  });

  const [manageTeamEventsData, { loading: manageTeamEventsLoading }] =
    useMutation(gql(manageTeamEvents), {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    });

  const [createTeamMutation, { loading: createTeamLoading }] = useMutation(
    gql(createTeam),
  );
  const [addUserToTeamMutation, { loading: addUserToTeamLoading }] =
    useMutation(gql(addUserToTeam));

  const teamsInCompetition =
    teamsInCompetitionData?.listTeamsByEventId?.items || [];

  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const actions = [
    {
      label: 'View',
      onClick: (row) => {
        setViewTeam(row);
      },
      show: () => true,
    },
    {
      show: () => false, // dummy entry to display drop down
    },
    {
      label: 'Remove Team',
      onClick: ({ teamId }) => {
        setRemoveTeam(teamId);
      },
      show: () => status !== 'Ended' && hasManagePermission,
    },
  ];
  const columns = [
    { field: 'name', width: 350, headerName: 'Team Name' },
    { field: 'memberCount', width: 350, headerName: 'Member Count' },
    {
      field: 'status',
      headerName: 'Team Status',
      width: 140,
      valueGetter: (params) => params?.row?.status,
      renderCell: (params) => (
        <FdChip
          color={getParticipantStatusColor(params?.row?.status || '')}
          size="small"
          label={getParticipantStatus(params?.row?.status || '')}
        />
      ),
    },
  ];

  const teamsToDisplay =
    teamsInCompetitionData?.listTeamsByEventId?.items?.map((item) => ({
      ...item,
      id: item?.id,
      name: item?.team?.name,
      memberCount: item?.team?.members?.items?.length,
      status: item?.status,
    })) || [];

  return (
    <Box>
      <Summary
        loading={loading}
        data={[
          {
            value: (
              <Box mb={1}>
                <FdChip color={statusColor} size="small" label={status} />
              </Box>
            ),
            description: 'Competition Status',
          },
          {
            value: <Box mb={1}>{teamsToDisplay?.length}</Box>,
            description: 'Teams',
          },
          {
            value: <Box mb={1}>{maxTeamSize}</Box>,
            description: 'Max Team Size',
          },
        ]}
        titleVariant="subtitle1"
        subtitleVariant="subtitle2"
      />
      <Box mt={2} mb={2} height="631px" width="100%" data-cy="teams-table">
        <FdTable
          toolbarSettings={{
            title: 'Teams',
            headerActions:
              status !== 'Ended' && hasManagePermission
                ? [
                    {
                      label: 'invite existing Team(s)',
                      onClick: () => {
                        setInviteModal(true);
                      },
                    },
                    {
                      label: 'Create + invite new Team(s)',
                      onClick: () => {
                        setCreateModal(true);
                      },
                    },
                  ]
                : [],
            filterButton: true,
            searchBox: true,
          }}
          actions={actions}
          rows={teamsToDisplay}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={10}
          loading={teamsInCompetitionDataLoading}
          gridId="competitions-admin-participants"
        />
      </Box>

      <AddTeams
        maxTeamSize={maxTeamSize}
        openModal={createModal}
        disableConfirm={
          createTeamLoading || addUserToTeamLoading || manageTeamEventsLoading
        }
        onConfirm={async (teamData) => {
          await Promise.all(
            teamData?.map((team) => {
              return createTeamMutation({
                variables: {
                  input: {
                    creatorId: globalSnap.userId,
                    name: team?.teamName,
                    private: globalSnap?.isAAFCOrg,
                  },
                },
                onCompleted: (_data) => {
                  const teamId = _data?.createTeam?.id;
                  addUserToTeamMutation({
                    variables: {
                      role: Object.keys(TEAM_MEMBER_TYPES)[1],
                      action: 'ADD',
                      participantEventType: 'COMPETITION',
                      teamId,
                      emails: team?.teamMembers,
                      origin: 'VIEW_EVENT',
                    },
                    onCompleted: () => {
                      manageTeamEventsData({
                        variables: {
                          action: 'ADD',
                          participantEventType: 'COMPETITION',
                          teams: [teamId],
                          eventId: assessmentId,
                        },
                        onCompleted: () => {
                          refetchListTeamsByEventId();
                          setCreateModal(false);
                          successToastMessage(
                            'Success! team(s) created and invited.',
                          );
                        },
                      });
                    },
                  });
                },
                onError: ({ graphQLErrors }) => {
                  setErrorMessage(graphQLErrors[0]?.message);
                },
              });
            }),
          );
        }}
        setOpenModal={setCreateModal}
        existingTeamData={teamsToDisplay}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <InviteTeams
        assessmentId={assessmentId}
        teamSize={maxTeamSize}
        teamsInCompetition={teamsInCompetition}
        openModal={inviteModal}
        onConfirm={() => {
          // this could be used to refresh the teams query
          refetchListTeamsByEventId();
        }}
        setOpenModal={setInviteModal}
        onDismiss={() => {
          warningToastMessage('No changes were saved');
        }}
      />

      <ViewTeam
        viewTeam={viewTeam}
        setViewTeam={setViewTeam}
        existingTeamData={teamsToDisplay}
        onConfirm={() => {
          // this could be used to refresh the teams query
          refetchListTeamsByEventId();
        }}
      />
      <FdModal
        size="sm"
        title="Remove this team from the competition?"
        description="Are you sure that you want to remove this team from the competition? They will no longer have access to the competition if you proceed with this action."
        dismiss={
          manageTeamEventsLoading ? 'Loading' : 'remove team from competition'
        }
        confirm="cancel"
        disableDismiss={manageTeamEventsLoading}
        open={removeTeam}
        onDismiss={async () => {
          await manageTeamEventsData({
            variables: {
              action: 'REMOVE',
              participantEventType: 'COMPETITION',
              teams: [removeTeam],
              eventId: assessmentId,
            },
          });
          refetchListTeamsByEventId();
          setRemoveTeam(false);
          successToastMessage('Team removed from competition!');
        }}
        onConfirm={() => {
          setRemoveTeam(false);
          warningToastMessage('Team not removed.');
        }}
        data-cy="delete-team-modal"
      />
    </Box>
  );
};

TeamTab.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusColor: PropTypes.string.isRequired,
  maxTeamSize: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TeamTab;

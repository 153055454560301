import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { FdTable, FdTypography } from '@fifthdomain/fe-shared';
import TaskInfoCell from '../Assessment/TaskInfoCell';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';

const TasksAnalyticsTable = ({ data, loading }) => {
  const { green, red } = colors;

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Challenge Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'status',
      width: 150,
      headerName: 'Status',
      renderCell: (params) => {
        return (
          <Box className="flex flex-col items-center">
            {params?.value === 'Solved' ? (
              <CheckCircleIcon style={{ color: green[700] }} />
            ) : (
              <ErrorIcon style={{ color: red[300] }} />
            )}
            <FdTypography color="secondary" variant="captiontext2">
              {params?.value}
            </FdTypography>
          </Box>
        );
      },
    },
    {
      field: 'attempts',
      width: 150,
      headerName: 'Attempts',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Attempts"
          tooltipText="This column displays the total number of attempts the assigned user has made on each challenge within this training activity."
        />
      ),
      valueGetter: (params) => params?.value,
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="This column lists all skills mapped to each challenge, including both those required and not required for the assigned user's current work role."
        />
      ),
      valueGetter: (params) =>
        params?.value?.length > 0
          ? [...params?.value].map((t) => t?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        return getCommaSeparatedPlusSuffix(
          params?.row?.skills?.map((skill) => skill?.name),
        );
      },
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
  ];

  return (
    <Box>
      <Box mt={1} mb={2} height="610px" width="100%">
        <FdTable
          loading={loading}
          defaultMuiToolbarSettings={{
            showMuiDefaultToolbar: true,
            columnsButton: false,
            filterButton: true,
            densityButton: true,
          }}
          rowHeight={60}
          toolbarSettings={{
            title: 'Challenge Analytics',
            filterOptions: [],
            searchBox: true,
            filterButton: true,
          }}
          disableSelectionOnClick
          actions={[]}
          rows={data}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={10}
          gridId="training-challenges-analytics"
        />
      </Box>
    </Box>
  );
};

TasksAnalyticsTable.defaultProps = {
  loading: false,
};

TasksAnalyticsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,

  loading: PropTypes.bool,
};

export default TasksAnalyticsTable;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MemoryIcon from '@mui/icons-material/Memory';
import CodeIcon from '@mui/icons-material/Code';
import RouterIcon from '@mui/icons-material/Router';
import BuildIcon from '@mui/icons-material/Build';
import { SPECIALTY_COLOR } from '../../constants';

const useStyles = makeStyles()(() => ({
  avatar: {
    color: '#FFFFFF',
    height: '2rem',
    width: '2rem',
    borderRadius: '4px',
    padding: '5px',
  },
}));

const SpecialtyIcon = ({ specialty }) => {
  const { classes } = useStyles();
  const SPECIALTY_ICON = {
    Intelligence: MemoryIcon,
    Protection: LockOpenIcon,
    Detection: LocationOnIcon,
    Investigation: CodeIcon,
    Penetration: RouterIcon,
    Engineering: BuildIcon,
  };
  const color = SPECIALTY_COLOR[specialty] || '#78909C';
  const Icon = SPECIALTY_ICON[specialty] || LocationOnIcon;

  return (
    <Icon
      className={classes.avatar}
      style={{
        backgroundColor: color,
        fontSize: 38,
      }}
    />
  );
};

SpecialtyIcon.propTypes = {
  specialty: PropTypes.string.isRequired,
};

export default SpecialtyIcon;

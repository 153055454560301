import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdCortex, cortexSort, getCortexLevels } from '@fifthdomain/fe-shared';
import SkillsDemonstrated from './SkillsDemonstrated';
import useGetSkills from '../../../hooks/useGetSkills';
import { ErrorIcon, TickIcon } from '../../Training/icons';
import { PROFICIENCY_LEVELS } from '../../../constants';

const defaultBorderWidth = '1px';
const targetBorder = 'rgba(0, 151, 167, 1)';
const targetBorderWidth = '2px';
const fillerBorder = 'rgba(77, 208, 225, 1)';
const cellGreyedOutColor = '#EAEEF4';

const CyberBrain = ({
  loading,
  cortexData,
  teamBased,
  toggleSelection,
  isParticipant,
  topSpecialty,
  top3Skills,
  overlayWorkrole,
}) => {
  const { skills, skillsLoading } = useGetSkills();
  const isSolvesSelected = toggleSelection === 'solves';
  const cortexLoading = skillsLoading || loading;
  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();
  const data = cortexData?.map((cd) => cd?.dataSorted).flat();
  const yLabelText = isSolvesSelected
    ? 'Total Solved Challenges: '
    : 'Total Unsolved Challenges: ';
  const yLastSolveText = `Last ${isSolvesSelected ? 'Solve' : 'Attempt'}: `;
  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        const skillData = data?.find((d) => d?.id === skill?.alias) || {};
        const skillLevelTarget = skillData?.proficiencyLevelsRequired;
        const maxSkillCompleted = _.maxBy(
          skillData?.data?.filter((i) => i?.yLevelPoints > 0),
          'x',
        )?.x;
        const isSkillSolved = maxSkillCompleted >= skillLevelTarget;

        return {
          id: skill?.alias,
          idWidth: '30px',
          idDisplay: (
            <Box className="flex items-center">
              {skillLevelTarget ? (
                <strong>{skill?.alias}</strong>
              ) : (
                skill?.alias
              )}
            </Box>
          ),
          data: [
            ...(levels?.map((l) => {
              const skillLevelData = skillData?.data?.find((d) => d?.x === l);
              const {
                y: yValue,
                yLabel,
                yLabelName,
                yLabelLastSolve,
                yLevelPoints,
              } = skillLevelData || {};
              const isTarget = l === skillLevelTarget;
              const isFiller = skillLevelTarget && l < skillLevelTarget;

              return {
                x: l,
                yAlias: skill?.alias,
                yValue,
                yValueDisplay: yLevelPoints,
                yHideValue: !yLabel,
                ...(!skillLevelTarget &&
                  overlayWorkrole && {
                    yBackgroundColor: cellGreyedOutColor,
                  }),
                yBorderColor: isTarget
                  ? targetBorder
                  : isFiller
                    ? fillerBorder
                    : // show greyed out cell border if not target
                      !skillLevelTarget &&
                      overlayWorkrole &&
                      cellGreyedOutColor,
                yBorderWidth: isTarget ? targetBorderWidth : defaultBorderWidth,
                yToolTip: (
                  <Box className="flex flex-col my-1">
                    <Box>
                      <strong>Skill: </strong>
                      {yLabelName}
                    </Box>
                    <Box>
                      <strong>Proficiency: </strong>
                      {`Level ${l} (${PROFICIENCY_LEVELS[l]})`}
                    </Box>
                    <Box>
                      <strong>{yLabelText}</strong>
                      {yLabel || 0}
                    </Box>
                    <Box>
                      <strong>{yLastSolveText}</strong>
                      {yLabelLastSolve}
                    </Box>
                  </Box>
                ),
              };
            }) || []),
          ],
          isSkillSolved,
          skillLevelTarget,
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];
  const rowHeaders = specialtiesFormatted
    .map((s) =>
      s?.dataSorted?.map((i) => ({
        id: i?.id, // id is skill alias
        headerContent: (
          <Box className="flex items-center justify-center gap-x-2" width={20}>
            {i?.skillLevelTarget &&
              (i?.isSkillSolved ? <TickIcon /> : <ErrorIcon />)}
          </Box>
        ),
      })),
    )
    .flat();

  return (
    <Box id="cyber-brain" className="flex flex-col items-center">
      <FdCortex
        specialties={specialtiesFormatted}
        rowHeaders={rowHeaders}
        loading={cortexLoading}
        enableAnimation={false}
      />
      {isParticipant && (
        <SkillsDemonstrated
          isSolvesSelected={isSolvesSelected}
          teamBased={teamBased}
          topSpecialty={topSpecialty}
          top3Skills={top3Skills}
        />
      )}
    </Box>
  );
};

CyberBrain.defaultProps = {
  toggleSelection: undefined,
  topSpecialty: undefined,
  top3Skills: [],
};

CyberBrain.propTypes = {
  loading: PropTypes.bool.isRequired,
  teamBased: PropTypes.bool.isRequired,
  isParticipant: PropTypes.bool.isRequired,
  toggleSelection: PropTypes.string,
  topSpecialty: PropTypes.string,
  top3Skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  cortexData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  overlayWorkrole: PropTypes.bool.isRequired,
};

export default CyberBrain;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdChip, FdTable } from '@fifthdomain/fe-shared';
import { SPECIALTY_COLOR } from '../../constants';
import ChallengeInfoCell from './ChallengeInfoCell';

const ChallengeListTable = ({ rows }) => {
  const columns = [
    {
      field: 'name',
      width: 480,
      headerName: 'Challenges',
      valueGetter: (params) => params.value,
      renderCell: (params) => <ChallengeInfoCell values={params.row} />,
    },
    {
      field: 'specialties',
      width: 150,
      headerName: 'Professional Specialties',
      renderCell: (params) => (
        <FdChip
          rounded={false}
          style={{ backgroundColor: SPECIALTY_COLOR[params?.value] || '' }}
          size="small"
          label={params?.value}
        />
      ),
    },
  ];
  return (
    <Box height="490px">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows || []}
        columns={columns}
        tablePageSize={5}
        gridId="challenge-list"
      />
    </Box>
  );
};

ChallengeListTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

ChallengeListTable.defaultProps = {
  rows: [],
};

export default ChallengeListTable;

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  FdAccordion,
  FdTypography,
  FdButton,
  FdTextField,
  FdModal,
  FdMultiChipTextField,
  FdAlert,
} from '@fifthdomain/fe-shared';
import {
  useFieldArray,
  Controller,
  useWatch,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FdBoxButton from './FdBoxButton';
import { warningToastMessage } from '../../shared/utils/toast';

const AddTeams = ({
  maxTeamSize,
  openModal,
  onConfirm,
  setOpenModal,
  existingTeamData,
  disableConfirm,
  errorMessage,
  setErrorMessage,
}) => {
  const initialValues = {
    teamName: '',
    teamMembers: [],
    addTeam: [],
  };

  const validationSchema = Yup.object().shape({
    addTeam: Yup.array()
      .of(
        Yup.object().shape({
          teamName: Yup.string()
            .required('Please enter a team name.')
            .max(25, 'Team name must be 25 characters or less.')
            .noWhitespace('Please enter a team name.')
            .test(
              'unique-test',
              'Team name already taken. Please choose a different name.',
              (value) =>
                !existingTeamData?.map((item) => item?.name)?.includes(value),
            ),
          teamMembers: Yup.array()
            .of(Yup.string().email('Please enter a valid email'))
            .min(1, 'Enter at least one email address.')
            .max(maxTeamSize, 'You cannot exceed the max team size.')
            .required('Enter at least one email address.')
            .test('unique-emails', function (value) {
              const { path, createError } = this;
              const duplicateEmails = existingTeamData
                ?.map((item) =>
                  item?.team?.members?.items?.map((i) => i?.email),
                )
                ?.flat()
                ?.filter((element) => value?.includes(element));
              if (duplicateEmails?.length > 0)
                return createError({
                  path,
                  message: `${duplicateEmails} already a part of another team for this competition`,
                });
              return true;
            }),
        }),
      )
      .unique(
        'teamName',
        'Team name already taken. Please choose a different name.',
      )
      .test('unique-values', function (value) {
        const { path, createError } = this;
        const emails = value
          ?.map((item) => item?.teamMembers)
          ?.flat()
          .filter((notUndefined) => notUndefined !== undefined);
        const duplicateEmails = emails?.filter(
          (item, index) => index !== emails?.indexOf(item),
        );

        if (duplicateEmails?.length > 0)
          return createError({
            path: `${path}.${value?.length - 1}.${'teamMembers'}`,
            message: `${duplicateEmails} already a part of another team for this competition`,
          });
        return true;
      })
      .min(1, 'Please create at least one team to continue.'),
  });

  const [cancelTeam, setCancelTeam] = useState(false);
  const {
    control,
    getValues,
    trigger,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    fields: addTeamFields,
    remove: addTeamRemove,
    append: addTeamAppend,
  } = useFieldArray({
    control,
    name: 'addTeam',
  });

  // reset form on invocation
  useEffect(() => reset(), [reset, openModal]);

  const watchTeam = useWatch({
    control,
    name: 'addTeam',
  });

  return (
    <FormProvider>
      <form>
        <FdModal
          size="lg"
          title={
            <Box>
              <FdTypography variant="h3">
                Create and invite team(s)
              </FdTypography>
              <FdTypography variant="body2" color="secondary">
                Create and invite teams that participants can join and edit.
                Once teams are created and invitations sent, you won&apos;t be
                able to manage them further, including adding or removing
                members or editing details.
              </FdTypography>
            </Box>
          }
          dismiss="CANCEL"
          disableConfirm={disableConfirm || errors?.addTeam}
          confirm="create and invite team(s)"
          open={openModal}
          onDismiss={() => {
            setCancelTeam(true);
            setValue('addTeam', []);
            setErrorMessage('');
          }}
          onConfirm={async () => {
            const result = await trigger();
            if (result) {
              onConfirm(getValues()?.addTeam);
            }
          }}
          data-cy="add-teams-modal"
        >
          <Box>
            <Box mb={1}>
              <FdTypography variant="subtitle2">
                {`Max Team Size : ${maxTeamSize}`}
              </FdTypography>
            </Box>
            <FdAlert
              variant="info"
              message={
                <Box>
                  <FdTypography variant="subtitle1">
                    You can only create and invite teams which do not exceed the
                    set max team size limit.
                  </FdTypography>
                  <FdTypography variant="body2">
                    Teams exceeding the max team size limit will not be able to
                    be formed. If required, you can adjust the max team size set
                    for this competition via the &apos;Details&apos; tab.
                  </FdTypography>
                </Box>
              }
            />

            {errors?.addTeam?.message && (
              <Box my={3}>
                <FdAlert variant="error" message={errors?.addTeam?.message} />
              </Box>
            )}

            <Box my={3}>
              {addTeamFields.map((item, index) => {
                const memberCount =
                  watchTeam?.[index]?.teamMembers?.length > maxTeamSize
                    ? { value: 'Exceeded the max team size', color: 'error' }
                    : watchTeam?.[index]?.teamMembers?.length > 0
                      ? { value: watchTeam?.[index]?.teamMembers?.length }
                      : { value: 'Not Specified' };
                return (
                  <Box my={2} key={item.id}>
                    <FdAccordion
                      open
                      startAdornment
                      showToggleButton={false}
                      summary={() => (
                        <Box style={{ width: '100%' }}>
                          <Box display="flex">
                            <Box sx={{ flexGrow: 1 }}>
                              <FdTypography variant="h3">
                                {watchTeam?.[index]?.teamName || 'Team'}
                              </FdTypography>
                            </Box>
                            <Box display="flex" mt={0.5} mr={1}>
                              <FdTypography variant="body2">
                                Member Count :
                              </FdTypography>
                              <FdTypography
                                variant="body2"
                                color={memberCount?.color || 'secondary'}
                              >
                                {memberCount?.value}
                              </FdTypography>
                            </Box>
                            <FdButton
                              variant="secondary"
                              size="small"
                              onClick={() => {
                                addTeamRemove(index);
                              }}
                            >
                              DELETE
                            </FdButton>
                          </Box>
                        </Box>
                      )}
                      content={() => (
                        <Box display="flex" flexDirection="column">
                          <Controller
                            control={control}
                            name={`addTeam[${index}].teamName`}
                            render={({
                              field: { ref, ...rest },
                              fieldState: { error },
                            }) => (
                              <Box mt={1} mb={2}>
                                <FdTextField
                                  id={`addTeam[${index}].teamName`}
                                  label="Team Name"
                                  required
                                  fullWidth
                                  error={error || !!errorMessage}
                                  helperText={
                                    errorMessage ||
                                    (error
                                      ? error.message
                                      : 'Enter a name for this team that is viewable by participants.')
                                  }
                                  {...rest}
                                  inputRef={ref}
                                  onFocus={() => {
                                    clearErrors();
                                    if (errorMessage) {
                                      setErrorMessage('');
                                    }
                                  }}
                                />
                              </Box>
                            )}
                          />
                          <Box mt={0} mb={3}>
                            <Controller
                              control={control}
                              name={`addTeam[${index}].teamMembers`}
                              render={({
                                field: { ref, ...rest },
                                fieldState: { error },
                              }) => (
                                <Box mt={1} mb={2}>
                                  <FdMultiChipTextField
                                    id={`addTeam[${index}].teamMembers`}
                                    fullWidth
                                    multiline
                                    label={
                                      <>
                                        <FdTypography variant="subtitle1">
                                          Add Team Members
                                        </FdTypography>
                                        <Box mt={1}>
                                          <FdTypography variant="subtitle2">
                                            Copy and paste email address(es)
                                          </FdTypography>
                                        </Box>
                                      </>
                                    }
                                    required
                                    error={error}
                                    helperText={
                                      error
                                        ? error?.message || error?.[0]?.message
                                        : 'Add at least one registered Event User to this team to complete team creation. Paste their email address into this box or use the search bar below.'
                                    }
                                    {...rest}
                                    inputRef={ref}
                                    values={rest?.value}
                                    setValues={(value) => {
                                      setValue(
                                        `addTeam[${index}].teamMembers`,
                                        value,
                                        { shouldValidate: true },
                                      );
                                    }}
                                  />
                                </Box>
                              )}
                            />
                          </Box>
                        </Box>
                      )}
                    />
                  </Box>
                );
              })}
              <FdBoxButton
                onClick={async (e) => {
                  e.preventDefault();
                  addTeamAppend({});
                }}
              >
                Add team
              </FdBoxButton>
            </Box>
          </Box>
        </FdModal>

        <FdModal
          size="sm"
          title="Cancel team(s) creation?"
          description="Are you sure you want to cancel team creation? No teams will be created, and no invitations will be sent."
          dismiss="yes, cancel"
          confirm="no, stay"
          open={cancelTeam}
          onDismiss={() => {
            reset();
            setCancelTeam(false);
            setOpenModal(false);
            warningToastMessage('No team(s) created or invited.');
          }}
          onConfirm={() => {
            setCancelTeam(false);
          }}
          data-cy="delete-team-modal"
        />
      </form>
    </FormProvider>
  );
};

AddTeams.propTypes = {
  maxTeamSize: PropTypes.number.isRequired,
  openModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  setOpenModal: PropTypes.func.isRequired,
  disableConfirm: PropTypes.bool,
  existingTeamData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      team: PropTypes.shape({
        name: PropTypes.string,
        members: PropTypes.shape({
          items: PropTypes.arrayOf(PropTypes.objectOf({})),
        }),
      }),
    }),
  ),
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
};

AddTeams.defaultProps = {
  onConfirm: () => {},
  disableConfirm: false,
  existingTeamData: [],
  errorMessage: '',
  setErrorMessage: () => {},
};

export default AddTeams;

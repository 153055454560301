import { fetchAuthSession } from 'aws-amplify/auth';

export const getToken = async () => {
  try {
    const jwtToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    return jwtToken;
  } catch (e) {
    return '';
  }
};

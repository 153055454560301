import { getUrl } from 'aws-amplify/storage';

export const getRandomImage = (images) =>
  images[Math.floor(Math.random() * images.length)];

export const getImageUrlFromS3 = async (key) => {
  try {
    const imageData = await getUrl({
      path: `public/${key}`,
    });
    return imageData.url.toString();
  } catch (error) {
    return error.message;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const BoxChip = ({ bgColor, text, ...props }) => (
  <Box
    className="flex items-center justify-center rounded px-2"
    style={{ backgroundColor: bgColor, height: '24px' }}
    {...props}
  >
    <FdTypography
      variant="captiontext1"
      color="black"
      style={{ fontWeight: 500 }}
    >
      {text}
    </FdTypography>
  </Box>
);

BoxChip.propTypes = {
  bgColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default BoxChip;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import StarsIcon from '@mui/icons-material/Stars';
import {
  FdTypography,
  FdCard,
  FdIconWithTooltip,
} from '@fifthdomain/fe-shared';
import CertificateImage from '../../shared/images/certificate.svg';
import { getValueByKey } from '../../shared/utils/objectUtils';
import { ACCOMPLISHMENT_TYPES } from '../../constants';

const UserSelfReported = ({ accomplishmentData }) => {
  const _accomplishments =
    accomplishmentData?.listaccomplishmentByUserId?.items?.map((item) => ({
      ...item,
      accId: item?.id,
      accName: item?.name,
      type: getValueByKey(ACCOMPLISHMENT_TYPES, item?.type),
      startYear: item?.startYear || '',
      endYear: item?.endYear || '',
      yearAwarded: item?.yearAwarded || '',
      currentYear: item?.endYear === String(new Date().getFullYear()),
    })) || [];

  return (
    <FdCard
      variant="outlined"
      heading={
        <Box className="flex">
          <FdTypography variant="h3">
            Self-Reported Accomplishments
          </FdTypography>
          <FdIconWithTooltip title="This section contains all the self-reported accomplishments that this user has included in their profile. Note that the contents of this section are self-reported only and have not been independently verified." />
        </Box>
      }
    >
      <Box minHeight="190px">
        <Box display="flex">
          <Box pr={2}>
            <SchoolIcon />
          </Box>
          <Box>
            <FdTypography variant="subtitle1">Education/Courses</FdTypography>
            {_accomplishments?.filter(
              (aType) => aType?.type === ACCOMPLISHMENT_TYPES.EDUCATION,
            )?.length > 0 ? (
              _accomplishments
                ?.filter(
                  (aType) => aType?.type === ACCOMPLISHMENT_TYPES.EDUCATION,
                )
                ?.map((a) => (
                  <Box mt={1} key={a?.id} display="flex">
                    <FdTypography color="secondary" variant="body2">
                      {`${a?.providerName} | ${a?.accName}`}
                    </FdTypography>
                    <FdTypography color="secondary" variant="body2">
                      {`${a?.startYear} - ${a?.endYear}`}
                    </FdTypography>
                  </Box>
                ))
            ) : (
              <FdTypography color="secondary" variant="body2">
                None Added
              </FdTypography>
            )}
          </Box>
        </Box>
        <Box display="flex" my={3}>
          <Box pr={2} pt={0.5}>
            <img src={CertificateImage} alt="no-content-data" />
          </Box>
          <Box>
            <FdTypography variant="subtitle1">Certifications</FdTypography>
            {_accomplishments?.filter(
              (aType) => aType?.type === ACCOMPLISHMENT_TYPES.CERTIFICATIONS,
            )?.length > 0 ? (
              _accomplishments
                ?.filter(
                  (aType) =>
                    aType?.type === ACCOMPLISHMENT_TYPES.CERTIFICATIONS,
                )
                ?.map((a) => (
                  <Box mt={1} key={a?.id} display="flex">
                    <FdTypography color="secondary" variant="body2">
                      {`${a?.providerName} | ${a?.accName}`}
                    </FdTypography>
                    <FdTypography color="secondary" variant="body2">
                      {`${a?.yearAwarded}`}
                    </FdTypography>
                  </Box>
                ))
            ) : (
              <FdTypography color="secondary" variant="body2">
                None Added
              </FdTypography>
            )}
          </Box>
        </Box>
        <Box display="flex">
          <Box pr={2}>
            <StarsIcon />
          </Box>
          <Box>
            <FdTypography variant="subtitle1">Other</FdTypography>
            {_accomplishments?.filter(
              (aType) => aType?.type === ACCOMPLISHMENT_TYPES.OTHER,
            )?.length > 0 ? (
              _accomplishments
                ?.filter((aType) => aType?.type === ACCOMPLISHMENT_TYPES.OTHER)
                ?.map((a) => (
                  <Box mt={1} key={a?.id} display="flex">
                    <FdTypography color="secondary" variant="body2">
                      {`${a?.accName}`}
                    </FdTypography>
                    <FdTypography color="secondary" variant="body2">
                      {`${a?.startYear} - ${a?.endYear}`}
                    </FdTypography>
                  </Box>
                ))
            ) : (
              <FdTypography color="secondary" variant="body2">
                None Added
              </FdTypography>
            )}
          </Box>
        </Box>
      </Box>
    </FdCard>
  );
};

UserSelfReported.propTypes = {
  accomplishmentData: PropTypes.shape({
    listaccomplishmentByUserId: PropTypes.shape({
      items: PropTypes.arrayOf({
        id: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default UserSelfReported;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdSkeleton,
  FdCard,
  useSnapshot,
  globalStore,
  FdTooltip,
  FdTypography,
} from '@fifthdomain/fe-shared';
import TeamMembers from './Management/TeamMembers';
import InviteTeamMembers from './Management/InviteTeamMembers';
import TeamDetails from './Management/TeamDetails';
import { getMemberMapping } from './Management/utils';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Team/viewTeam';
import { TEAM_ROLES } from '../../constants';
import TeamDelete from './Management/TeamDelete';

const CardHeading = ({ heading, tooltipText, subHeading }) => (
  <Box>
    <Box className="flex">
      <FdTypography variant="h4">{heading}</FdTypography>
      {tooltipText && (
        <FdTooltip title={tooltipText}>
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      )}
    </Box>
    <FdTypography
      variant="captiontext1"
      color="secondary"
      style={{ marginTop: '0.5rem' }}
    >
      {subHeading}
    </FdTypography>
  </Box>
);

CardHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

const TeamManagement = ({ team, loading, refetchTeam, role }) => {
  const globalSnap = useSnapshot(globalStore);
  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset } = reactHookFormMethods;

  useEffect(() => {
    reset({
      id: team?.id,
      name: team?.name,
      avatar: team?.key
        ? new File([''], team?.key.match(/[^/]+$/)[0], { type: 'image/png' })
        : undefined,
      key: team?.key,
      members: getMemberMapping(
        [...(team?.members?.items || [])],
        globalSnap.userAlias,
      ),
      emails: [],
      isPrivate: !!team?.private,
      events: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, reset, globalSnap.userEmail]);
  // view only mode if not manager
  const viewOnly = role !== TEAM_ROLES.TEAM_MANAGER;

  return (
    <FdSkeleton loading={loading} height={620}>
      <FormProvider {...reactHookFormMethods}>
        <Box className="flex flex-col gap-y-2">
          <FdCard
            variant="outlined"
            heading={
              <CardHeading
                heading="Current Team Members"
                subHeading="A current list of team members is listed below. You can view the registration status and role type of each team member, and perform actions based on your role type."
                tooltipText="A team consists of two role types: Team Managers (who can edit and manage the team) and General Members (who can view the team). Teams can have any number of Team Managers."
              />
            }
          >
            <TeamMembers
              viewOnly={viewOnly}
              onLeave={() => null}
              isPrivate={Boolean(team?.private)}
            />
          </FdCard>
          {!viewOnly && !team?.private && (
            <FdCard
              variant="outlined"
              heading={
                <CardHeading
                  heading="Invite New Team Members"
                  subHeading="Invite new members to your team by typing or pasting email addresses into the box below and clicking the Invite button."
                  tooltipText="Invited users will receive an email to log in/register to the FifthDomain platform. When they log in, they will be able to view your team on their My Teams page."
                />
              }
            >
              <InviteTeamMembers loading={loading} refetchTeam={refetchTeam} />
            </FdCard>
          )}
          <FdCard
            variant="outlined"
            heading={
              <CardHeading
                heading="Team Details"
                subHeading="View your team's key details below. Team Managers within this team can edit and manage these details."
              />
            }
          >
            <TeamDetails
              viewOnly={viewOnly}
              team={team}
              refetchTeam={refetchTeam}
            />
          </FdCard>
          {!viewOnly && !team?.private && (
            <FdCard
              variant="outlined"
              heading={
                <CardHeading
                  heading="Delete Team"
                  subHeading="If this team and its data are no longer required by team members, you may wish to delete this team, which can be done by clicking the 'Delete Team' button below."
                />
              }
            >
              <TeamDelete team={team} />
            </FdCard>
          )}
        </Box>
      </FormProvider>
    </FdSkeleton>
  );
};

TeamManagement.propTypes = {
  loading: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    key: PropTypes.string,
    members: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    private: PropTypes.bool,
  }).isRequired,
  refetchTeam: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default TeamManagement;

import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdTypography } from '@fifthdomain/fe-shared';
import { Summary } from '../Assessment';
import LabelWithTooltip from '../Insights/LabelWithTooltip';

const EventTagSummary = ({
  teamBased,
  loading,
  invitedCount,
  eventTagData,
  eventTagsByAssessmentId,
}) => {
  const taggedTeamOrUser = _.uniqBy(
    eventTagsByAssessmentId,
    teamBased ? 'teamEventId' : 'userId',
  );
  const summaryData = [
    {
      value: (
        <FdTypography variant="h4" style={{ fontWeight: 500 }}>
          {eventTagData?.length || 0}
        </FdTypography>
      ),
      description: 'Event Tags',
    },
    {
      value: (
        <FdTypography variant="h4" style={{ fontWeight: 500 }}>
          {invitedCount || 0}
        </FdTypography>
      ),
      description: `Total ${teamBased ? 'Teams' : 'Participants'}`,
    },
    {
      value: (
        <FdTypography variant="h4" style={{ fontWeight: 500 }}>
          {taggedTeamOrUser?.length || 0}
        </FdTypography>
      ),
      description: (
        <LabelWithTooltip
          label={`Tagged ${teamBased ? 'Teams' : 'Participants'}`}
          tooltipText={`Tagged ${teamBased ? 'teams' : 'participants'} are ${
            teamBased ? 'teams' : 'participants'
          } who have applied one or more event tags to themselves for this event.`}
        />
      ),
    },
    {
      value: (
        <FdTypography variant="h4" style={{ fontWeight: 500 }}>
          {invitedCount - taggedTeamOrUser?.length || 0}
        </FdTypography>
      ),
      description: (
        <LabelWithTooltip
          label={`Untagged ${teamBased ? 'Teams' : 'Participants'}`}
          tooltipText={`Untagged ${teamBased ? 'teams' : 'participants'} are ${
            teamBased ? 'teams' : 'participants'
          } who have not applied any event tags to themselves for this event.`}
        />
      ),
    },
  ];

  return (
    <Box width="100%">
      <Summary
        data={summaryData}
        subtitleVariant="subtitle2"
        loading={loading}
      />
    </Box>
  );
};

EventTagSummary.propTypes = {
  eventTagData: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
  }),
  eventTagsByAssessmentId: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
  }),
  teamBased: PropTypes.bool,
  invitedCount: PropTypes.number,
  loading: PropTypes.bool,
};

EventTagSummary.defaultProps = {
  eventTagData: [],
  eventTagsByAssessmentId: [],
  loading: false,
  teamBased: false,
  invitedCount: 0,
};

export default EventTagSummary;

import React from 'react';
import { Box } from '@mui/material';
import { FdTypography, FdCard } from '@fifthdomain/fe-shared';
import BlueFillerImage from './images/BlueFillerIAniImage';

const ComingSoon = () => {
  return (
    <FdCard variant="outlined">
      <Box className="flex flex-col items-center gap-y-3 mt-3">
        <Box my={3} style={{ width: '160px' }}>
          <BlueFillerImage />
        </Box>
        <FdTypography variant="h4">Coming Soon</FdTypography>
        <FdTypography variant="captiontext1">
          Our team is working on adding new badges you can obtain.
        </FdTypography>
        <FdTypography variant="captiontext1">Check back later!</FdTypography>
      </Box>
    </FdCard>
  );
};

export default ComingSoon;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  FdTypography,
  FdCard,
  FdIconWithTooltip,
  FdTextField,
} from '@fifthdomain/fe-shared';
import * as singleSpa from 'single-spa';
import EventCard from './EventCard';
import ExternalEvents from './ExternalEvents';
import NoDataImage from '../../shared/images/no-content-data.svg';

const AssignedEvents = ({ data, externalData, affiliated }) => {
  const [searchText, setSearchText] = useState('');

  const onEnterClick = ({ eventId, contentType }) => {
    const url =
      contentType === 'competition'
        ? `/competitions/view/${eventId}?tabindex=4`
        : contentType === 'assessment'
          ? `/assessor/view/${eventId}?tabindex=1`
          : contentType === 'training'
            ? `/competitions/training/edit/${eventId}`
            : `/labs/courses/view-admin/${eventId}?tabindex=0`;

    return singleSpa.navigateToUrl(url);
  };

  const filterBySearch =
    searchText?.length > 0
      ? data?.filter((d) =>
          d?.title?.toLowerCase()?.includes(searchText?.toLowerCase()),
        )
      : data;
  return (
    <FdCard
      variant="outlined"
      heading={
        <Box className="flex">
          <FdTypography variant="h3">Assigned Events</FdTypography>
          <FdIconWithTooltip
            title={`
          This section displays all competitions, assessments, and courses that
          ${
            affiliated
              ? ' have been assigned to this user by your organisation. You can also see the number of externally hosted events this user has been assigned to.'
              : ' your organisation has assigned to this user. Any events assigned by external organisations are not displayed.'
          }
          
          `}
          />
        </Box>
      }
    >
      {data?.length === 0 && externalData?.length === 0 ? (
        <Box
          height="500px"
          className="flex flex-col justify-center items-center w-full"
        >
          <Box>
            <img src={NoDataImage} alt="no-content-data" />
          </Box>
        </Box>
      ) : (
        <>
          <FdTextField
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            endAdornment={<SearchIcon />}
            fullWidth
          />
          <Box mt={3} maxHeight={700} style={{ overflowY: 'auto' }}>
            {filterBySearch?.map((cd) => (
              <EventCard content={cd} onEnterClick={onEnterClick} />
            ))}
            {externalData?.length > 0 && (
              <ExternalEvents
                externalData={externalData?.length}
                affiliated={affiliated}
              />
            )}
          </Box>
        </>
      )}
    </FdCard>
  );
};

AssignedEvents.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  externalData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  affiliated: PropTypes.bool,
};

AssignedEvents.defaultProps = {
  externalData: [],
  affiliated: false,
};

export default AssignedEvents;

import { gql, useMutation } from '@apollo/client';
import * as singleSpa from "single-spa";
import { stopAssessmentForUser } from '../graphql/mutations';

const useStopAssessment = (type) =>
  useMutation(gql(stopAssessmentForUser), {
    onCompleted: (_data) => {
      singleSpa.navigateToUrl(
        `/competitions/${
          type === 'TRAINING' ? 'training' : 'competition'
        }-complete/${_data?.stopAssessmentForUser}`,
      );
    },
    onError: () => singleSpa.navigateToUrl('/competitions/error'),
  });

export default useStopAssessment;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox } from '@mui/material';
import { FdCard, FdTypography, getAvatarColor } from '@fifthdomain/fe-shared';
import LineNivo from '../Charts/LineNivo';
import { getRankingNormalizedRows } from './overviewUtils';
import { filterBySkill } from './utils';
import CompAvgImage from '../../resources/images/insights/comp-avg.svg';
import TeamAvgImage from '../../resources/images/insights/team-avg.svg';
import { sortObjectArrayByField } from '../../shared/utils/objectUtils';
import ProfileHyperlink from './ProfileHyperlink';

const PerformanceSkills = ({
  allSkills,
  performanceData,
  contestantSelected,
}) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const navyBlueColor = 'rgba(29, 57, 110, 1)';
  const {
    participantsFinished,
    completedTasks,
    allTaskAttempts,
    totalAssessmentPoints,
    maxDurationInMins,
    timeSpentDataForAssessment,
    totalPointsScoredInAssessment,
    teamBased,
    participantsFinishedAssessment,
    teamGroups,
    tasksOpened,
    tasks,
    selectedUserIds,
  } = performanceData || {};

  useEffect(() => {
    const members = teamBased
      ? teamGroups
          ?.find((t) => t?.teamId === contestantSelected?.id)
          ?.team?.members?.items?.filter((m) => m?.userId !== 'UNDEFINED')
          ?.map((tm) => ({
            userId: tm?.userId,
            alias: tm?.User?.alias,
            color: getAvatarColor(tm?.User?.alias),
            selected: false,
          }))
          ?.sort(sortObjectArrayByField('alias'))
      : [];
    setTeamMembers(members);
  }, [teamBased, teamGroups, contestantSelected]);

  const rankingNormalizedRows = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: teamBased,
    _teams: participantsFinishedAssessment,
    _teamGroups: teamGroups,
    _tasksOpened: tasksOpened,
  });

  const chartCohortRow = {
    id: 'Competition Average',
    userId: 0,
    data: allSkills?.map((s) => {
      const skillTasks = filterBySkill(tasks?.items, s.skillName);
      const skillTotalAssessmentPoints = skillTasks?.reduce(
        (acc, i) => acc + i.task?.recommendedPoints,
        0,
      );

      const normalizedRows = getRankingNormalizedRows({
        _participantsFinished: participantsFinished,
        _completedTasks: filterBySkill(completedTasks, s.skillName),
        _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
        _totalAssessmentPoints: skillTotalAssessmentPoints,
        _maxDurationInMins: maxDurationInMins,
        _timeSpentData: timeSpentDataForAssessment,
        _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
        _teamBased: teamBased,
        _teams: participantsFinishedAssessment,
        _teamGroups: teamGroups,
        _tasksOpened: filterBySkill(tasksOpened, s.skillName),
      });

      return {
        x: s.skillAlias,
        y:
          rankingNormalizedRows?.length > 0
            ? normalizedRows?.reduce((acc, i) => acc + i?.overallScore, 0) /
              rankingNormalizedRows?.length
            : 0,
      };
    }),
  };

  const chartData = rankingNormalizedRows
    ?.filter((c) =>
      teamBased
        ? c.id === contestantSelected?.id
        : selectedUserIds.includes(c.id),
    )
    .map((c) => {
      return {
        id: teamBased ? 'Team Average' : c.name,
        userId: c.id,
        data: allSkills?.map((s) => {
          const skillTasks = filterBySkill(tasks?.items, s.skillName);
          const skillTotalAssessmentPoints = skillTasks?.reduce(
            (acc, i) => acc + i.task?.recommendedPoints,
            0,
          );

          const normalizedRows = getRankingNormalizedRows({
            _participantsFinished: participantsFinished,
            _completedTasks: filterBySkill(completedTasks, s.skillName),
            _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
            _totalAssessmentPoints: skillTotalAssessmentPoints,
            _maxDurationInMins: maxDurationInMins,
            _timeSpentData: timeSpentDataForAssessment,
            _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
            _teamBased: teamBased,
            _teams: participantsFinishedAssessment,
            _teamGroups: teamGroups,
            _tasksOpened: filterBySkill(tasksOpened, s.skillName),
          });

          return {
            x: s.skillAlias,
            y: normalizedRows?.find((nr) => nr?.id === c.id)?.overallScore || 0,
          };
        }),
        rank: c.rank,
      };
    });

  const teamMembersRankingNormalizedRows = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: false,
    _teams: participantsFinishedAssessment,
    _teamGroups: teamGroups,
    _tasksOpened: tasksOpened,
  });

  const selectedTeamMemberIds = teamMembers
    ?.filter((tm) => tm?.selected)
    ?.map((m) => m?.userId);
  const chartDataTeamMembers = teamBased
    ? teamMembersRankingNormalizedRows
        ?.filter((c) => selectedTeamMemberIds?.includes(c.id))
        .map((c) => {
          return {
            id: teamMembers?.find((tm) => tm?.userId === c.id)?.alias,
            userId: c.id,
            data: allSkills?.map((s) => {
              const skillTasks = filterBySkill(tasks?.items, s.skillName);
              const skillTotalAssessmentPoints = skillTasks?.reduce(
                (acc, i) => acc + i.task?.recommendedPoints,
                0,
              );

              const normalizedRows = getRankingNormalizedRows({
                _participantsFinished: participantsFinished,
                _completedTasks: filterBySkill(completedTasks, s.skillName),
                _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
                _totalAssessmentPoints: skillTotalAssessmentPoints,
                _maxDurationInMins: maxDurationInMins,
                _timeSpentData: timeSpentDataForAssessment,
                _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
                _teamBased: false,
                _teams: participantsFinishedAssessment,
                _teamGroups: teamGroups,
                _tasksOpened: filterBySkill(tasksOpened, s.skillName),
              });

              return {
                x: s.skillAlias,
                y:
                  normalizedRows?.find((nr) => nr?.id === c.id)?.overallScore ||
                  0,
              };
            }),
            rank: c.rank,
          };
        })
        ?.sort(sortObjectArrayByField('id'))
    : [];

  const chartDataRows = [chartCohortRow, ...chartData, ...chartDataTeamMembers];

  const getSkillName = (alias) =>
    allSkills?.find((s) => s.skillAlias === alias)?.skillName || '';

  const performanceMessage = teamBased
    ? 'The coloured lines represent the candidates selected to be compared. Hover over the vertices to see their skill score. The skill score is a function of the successful solves in that skill as well as the speed and efficiency.'
    : 'The skill score is a function of the successful solves in that skill as well as the speed and efficiency';

  return (
    <Box mt={2}>
      <FdCard heading="Performance in skills" variant="outlined">
        <FdTypography
          variant="captiontext1"
          color="secondary"
          style={{ marginTop: '-20px' }}
        >
          {`The blue line shows their score in a particular skill, and the black
          line shows the average performance of the cohort. Hover over the line
          to see the performance in that skill compared to the cohort average.
          The performance is based on the challenges successfully solved for
          that skill as well as their efficiency and speed in solving those
          tasks. ${performanceMessage}`}
        </FdTypography>
        <Box display="flex" height="380px" alignItems="center">
          <Box height="271px" width={teamBased ? '80%' : '100%'}>
            <LineNivo
              data={chartDataRows}
              colors={[
                '#757575',
                navyBlueColor,
                ...teamMembers
                  ?.filter((tm) => selectedTeamMemberIds?.includes(tm?.userId))
                  ?.sort(sortObjectArrayByField('alias'))
                  ?.map((tm) => tm?.color),
              ]}
              showDashedForIds={[
                {
                  id: 'Competition Average',
                  color: '#757575',
                },
                {
                  id: 'Team Average',
                  color: navyBlueColor,
                },
              ]}
              tooltip={(d) => {
                const { serieId, data: _data } = d.point;
                const skillName = getSkillName(_data?.x);
                return (
                  <Box
                    p={1}
                    style={{
                      backgroundColor: '#6E6E6E',
                      borderRadius: '4px',
                    }}
                  >
                    <FdTypography
                      variant="captiontext1"
                      style={{ color: 'white' }}
                    >
                      {serieId}
                    </FdTypography>
                    <Box mt={0.5}>
                      <FdTypography
                        variant="captiontext2"
                        style={{ color: 'white' }}
                      >
                        {`${skillName}(${_data?.x}): ${Math.round(
                          _data?.yFormatted,
                        )}`}
                      </FdTypography>
                    </Box>
                  </Box>
                );
              }}
            />
          </Box>
          {teamBased && (
            <Box
              display="flex"
              width="20%"
              alignItems="flex-start"
              height="100%"
              mt={6}
            >
              <Box>
                <FdTypography variant="captiontext1">
                  Click the participant whose performance you want to see.
                </FdTypography>
                <Box mt={1}>
                  <Box display="flex">
                    <img
                      src={TeamAvgImage}
                      alt="team-avg"
                      className="pl-1 mr-4 opacity-80"
                    />
                    <FdTypography variant="body2">Team Cumulative</FdTypography>
                  </Box>
                  <Box display="flex" mt={1}>
                    <img
                      src={CompAvgImage}
                      alt="competition-avg"
                      className="pl-1 mr-4 opacity-80"
                    />
                    <FdTypography variant="body2">
                      Competition Average
                    </FdTypography>
                  </Box>
                  <Box mt={1} style={{ height: '281px', overflowY: 'auto' }}>
                    {teamMembers?.map((cd) => (
                      <Box display="flex" alignItems="center" key={cd?.userId}>
                        <Checkbox
                          style={{ padding: '6px 6px 6px 0' }}
                          value={cd?.selected}
                          onChange={(v) =>
                            setTeamMembers((currentValues) =>
                              currentValues?.map((item) =>
                                item.userId === cd?.userId
                                  ? { ...item, selected: v.target.checked }
                                  : item,
                              ),
                            )
                          }
                        />
                        <Box className="flex w-5 h-5 mr-1 items-center">
                          <Box
                            className="rounded-full w-4 h-4"
                            style={{
                              backgroundColor: cd?.color,
                            }}
                          />
                        </Box>
                        <ProfileHyperlink userId={cd?.userId}>
                          <FdTypography variant="body2">
                            {cd?.alias}
                          </FdTypography>
                        </ProfileHyperlink>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </FdCard>
    </Box>
  );
};

PerformanceSkills.defaultProps = {
  contestantSelected: undefined,
};

PerformanceSkills.propTypes = {
  allSkills: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  performanceData: PropTypes.shape({
    participantsFinished: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalAssessmentPoints: PropTypes.number.isRequired,
    maxDurationInMins: PropTypes.number.isRequired,
    timeSpentDataForAssessment: PropTypes.number.isRequired,
    totalPointsScoredInAssessment: PropTypes.number.isRequired,
    teamBased: PropTypes.bool.isRequired,
    participantsFinishedAssessment: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) }),
    selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  contestantSelected: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default PerformanceSkills;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import {
  FdTypography,
  FdButton,
  FdAvatar,
  FdSkeleton,
  getAvatarColor,
  getAvatarName,
} from '@fifthdomain/fe-shared';

const UserRole = ({
  loading,
  name,
  userRole,
  coverageInPercentage,
  viewProfile,
  onClickDetails,
  onClickViewProfile,
}) => {
  const theme = useTheme();

  return (
    <Box className="flex items-center gap-x-4 my-2">
      <FdSkeleton height="30px" width="800px" loading={loading}>
        <Box className="flex items-center gap-x-2">
          <FdAvatar
            color={getAvatarColor(name)}
            size="small"
            variant="circular"
            content={getAvatarName(name) || ''}
          />
          <FdTypography variant="body1" style={{ fontWeight: 500 }}>
            {name}
          </FdTypography>
        </Box>
        {userRole && (
          <Box
            height="28px"
            className="flex items-center justify-center rounded px-3"
            style={{
              backgroundColor:
                theme?.palette?.type === 'dark'
                  ? 'rgb(32, 34, 143)'
                  : 'rgba(204, 213, 222, 1)',
            }}
          >
            <FdTypography variant="captiontext1" style={{ fontWeight: 500 }}>
              {userRole}
            </FdTypography>
          </Box>
        )}
        {Number(coverageInPercentage) >= 0 && (
          <>
            <FdTypography variant="body2" color="secondary">
              {`Current Work Role Coverage: ${coverageInPercentage}%`}
            </FdTypography>
            <FdButton variant="secondary" size="small" onClick={onClickDetails}>
              See Details
            </FdButton>
          </>
        )}
        {viewProfile && (
          <FdButton variant="primary" size="small" onClick={onClickViewProfile}>
            View Profile
          </FdButton>
        )}
      </FdSkeleton>
    </Box>
  );
};

UserRole.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  coverageInPercentage: PropTypes.string,
  viewProfile: PropTypes.bool,
  onClickDetails: PropTypes.func,
  onClickViewProfile: PropTypes.func,
};

UserRole.defaultProps = {
  coverageInPercentage: undefined,
  viewProfile: false,
  onClickDetails: () => null,
  onClickViewProfile: () => null,
};

export default UserRole;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {
  FdTable,
  FdTypography,
  FdButton,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';
import TaskInfoCell from '../Assessment/TaskInfoCell';
import TeamAvatar from '../Insights/TeamAvatar';

const ChallengeAnalyticsTable = ({
  data,
  loading,
  onLeaderBoardSelection,
  teamBased,
  levelBased,
}) => {
  const { green, red } = colors;
  const theme = useTheme();

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Challenge Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'status',
      width: 150,
      headerName: 'Status',
      renderCell: (params) => {
        return (
          <Box>
            {params?.value === 'Solved' ? (
              <CheckCircleIcon style={{ color: green[700] }} />
            ) : (
              <ErrorIcon style={{ color: red[300] }} />
            )}
            <FdTypography color="secondary" variant="captiontext2">
              {params?.value}
            </FdTypography>
          </Box>
        );
      },
    },
    { field: 'points', width: 150, headerName: 'Points' },
    ...(levelBased
      ? [
          {
            field: 'level',
            flex: 1,
            headerName: 'Level',
            valueGetter: (params) => `Level ${params?.value}`,
          },
        ]
      : []),
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional Specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
      renderCell: (params) => <FdHighlightChip text={params?.value} />,
    },
    {
      field: 'solvesBy',
      width: 160,
      headerName: 'Solves by',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Solves by"
          tooltipText={`This column shows the number of ${
            teamBased ? 'teams' : 'participants'
          } that have solved this challenge.`}
        />
      ),
      renderCell: (params) => {
        return (params?.value === 0 && '-') || params?.value;
      },
    },
    {
      field: 'attemptsBy',
      headerName: 'Attempts by',
      width: 160,
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Attempts by"
          tooltipText={`This column shows the number of ${
            teamBased ? 'teams' : 'participants'
          } that have attempted this challenge.`}
        />
      ),
      renderCell: (params) => {
        return (params?.value === 0 && '-') || params?.value;
      },
    },
    {
      field: 'firstBloodBy',
      headerName: 'First Solve by',
      flex: 1,
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="First Solve by"
          tooltipText={`This column shows the name of the  ${
            teamBased ? 'teams' : 'participants'
          } who first solved this challenge.`}
        />
      ),
      valueGetter: (params) => {
        const firstBloodBy = params?.row?.firstBloodBy;
        return firstBloodBy && (firstBloodBy?.alias || firstBloodBy?.name);
      },
      renderCell: (params) => {
        return (
          params?.value && (
            <>
              {teamBased && (
                <TeamAvatar
                  size="small"
                  team={{
                    key: params?.row?.firstBloodBy?.key,
                    name: params?.value,
                  }}
                />
              )}
              <FdButton
                variant="tertiary"
                size="small"
                style={{
                  textDecoration: 'underline',
                  color: theme?.palette?.type === 'dark' ? '#fff' : '#000',
                }}
                onClick={() => {
                  onLeaderBoardSelection(params?.row?.firstBloodBy?.id);
                }}
              >
                {params?.value}
              </FdButton>
            </>
          )
        );
      },
    },
  ];

  return (
    <Box mb={2} height="720px" width="100%">
      <FdTable
        toolbarSettings={{
          title: 'Challenge Analytics',
          searchBox: true,
        }}
        selection={false}
        rows={data}
        columns={columns}
        tablePageSize={10}
        loading={loading}
        gridId="competitions-challenge-analytics-table"
      />
    </Box>
  );
};

ChallengeAnalyticsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  onLeaderBoardSelection: PropTypes.func.isRequired,
  teamBased: PropTypes.bool.isRequired,
  levelBased: PropTypes.bool.isRequired,
};

export default ChallengeAnalyticsTable;

import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  FdTypography,
  FdMarkdownRender,
  FdLoadingSpinner,
  globalStore,
  useSnapshot,
  FdBreadcrumbs,
} from '@fifthdomain/fe-shared';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import { invalidateAssessmentsHomePageDataQuery } from '../queries/invalidateQueries';

const TrainingComplete = () => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: globalSnap?.userId });

  // exclude finished and removed status
  const assessmentDataFiltered =
    assessmentTasksData.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  useEffect(() => {
    invalidateAssessmentsHomePageDataQuery();
  }, []);

  if (assessmentTasksLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <Box>
      <FdBreadcrumbs
        linkChain={[{ url: '/landing/landing-homepage', name: 'Home' }]}
        linkComponent={RouterLink}
        renderAsButton
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box id="complete-image" mt={10}>
          <svg
            width="264"
            height="226"
            viewBox="0 0 264 226"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2973_26109)">
              <path
                d="M167.242 92.8417C169.497 90.5865 173.153 90.5865 175.408 92.8417C177.663 95.0968 177.663 98.7532 175.408 101.008L110.832 165.585C107.708 168.709 102.642 168.709 99.5182 165.585L76.5917 142.658C74.3365 140.403 74.3365 136.747 76.5917 134.492C78.8468 132.236 82.5032 132.236 84.7583 134.492L99.5182 149.251C102.642 152.376 107.708 152.376 110.832 149.251L167.242 92.8417ZM176.982 77.9069C173.858 74.7827 168.792 74.7827 165.668 77.9069L110.832 132.743C107.708 135.867 102.642 135.867 99.5182 132.743L86.3319 119.557C83.2077 116.433 78.1423 116.433 75.0181 119.557L61.6569 132.918C58.5327 136.042 58.5327 141.108 61.6569 144.232L99.5181 182.093C102.642 185.217 107.708 185.217 110.832 182.093L190.343 102.582C193.467 99.4577 193.467 94.3923 190.343 91.2681L176.982 77.9069Z"
                fill="#6E84FF"
              />
            </g>
            <path
              d="M9.55879 172.17C10.8219 173.406 12.8107 173.46 14.1545 172.331C15.1489 171.471 16.009 170.961 16.4927 171.176C17.8365 171.713 16.4927 173.944 15.6864 175.261C15.0146 176.39 8 185.716 8 192.219C8 195.66 9.29003 198.508 11.6013 200.228C13.617 201.733 16.2777 202.19 18.6965 201.465C21.5722 200.632 23.9373 197.702 26.9205 194.02C30.1725 190.016 34.5263 184.775 37.8858 184.775C42.2665 184.775 42.3203 187.489 42.6159 189.586C32.4569 191.306 28.1568 199.449 28.1568 204.018C28.1568 208.587 32.0269 212.323 36.7839 212.323C41.1646 212.323 48.3135 208.748 49.3886 195.928H52.6405C54.495 195.928 56 194.423 56 192.569C56 190.714 54.495 189.209 52.6405 189.209H49.3617C48.9586 184.775 46.4322 177.922 38.5308 177.922C32.4838 177.922 27.2968 183.055 25.2542 185.554C23.6954 187.516 19.7178 192.219 19.0997 192.865C18.4278 193.671 17.2721 195.122 16.1165 195.122C14.9071 195.122 14.1814 192.891 15.1489 189.962C16.0896 187.032 18.9115 182.275 20.1209 180.502C22.2172 177.438 23.6148 175.342 23.6148 171.686C23.6148 165.854 19.2072 164 16.869 164C13.9395 164 11.3863 165.693 9.61254 167.279C8.18813 168.569 8.18813 170.826 9.55879 172.17ZM36.8645 205.792C36.0314 205.792 34.8757 205.093 34.8757 203.857C34.8757 202.244 36.8376 197.944 42.589 196.439C41.7828 203.669 38.7458 205.792 36.8645 205.792Z"
              fill="#81D4FA"
            />
            <path
              d="M241.592 34.0276L211.302 41.2256C208.924 41.7908 207.462 44.1939 208.028 46.5721L215.242 76.8351C215.807 79.2133 218.194 80.7019 220.572 80.1367L242.192 74.9989L252.082 58.9442L246.944 37.324C246.379 34.9458 243.97 33.4624 241.592 34.0276ZM220.379 50.4892L237.675 46.379C238.864 46.0964 240.068 46.8381 240.351 48.0272C240.633 49.2163 239.892 50.4204 238.703 50.703L221.407 54.8132C220.217 55.0958 219.013 54.3541 218.731 53.165C218.448 51.9759 219.19 50.7718 220.379 50.4892ZM229.948 61.92L223.462 63.4613C222.273 63.7439 221.068 63.0022 220.786 61.8131C220.503 60.624 221.245 59.4198 222.434 59.1373L228.92 57.5959C230.109 57.3134 231.313 58.0551 231.596 59.2442C231.878 60.4333 231.137 61.6374 229.948 61.92ZM239.26 72.2697L236.948 62.5406C236.665 61.3515 237.407 60.1474 238.596 59.8648L248.325 57.5528L239.26 72.2697Z"
              fill="#BA68C8"
            />
            <path
              d="M82.9252 42.0752L64.7752 23.9252L66.0502 22.6502C67.2002 21.5002 68.6252 20.9377 70.3252 20.9627C72.0252 20.9877 73.4502 21.5752 74.6002 22.7252L84.2752 32.4002C85.4252 33.5502 86.0002 34.9627 86.0002 36.6377C86.0002 38.3127 85.4252 39.7252 84.2752 40.8752L82.9252 42.0752ZM45.9502 79.2002C44.8002 80.3502 43.3877 80.9252 41.7127 80.9252C40.0377 80.9252 38.6252 80.3502 37.4752 79.2002L27.8002 69.5252C26.6502 68.3752 26.0752 66.9627 26.0752 65.2877C26.0752 63.6127 26.6502 62.2002 27.8002 61.0502L29.0002 59.8502L47.1502 78.0002L45.9502 79.2002ZM56.8252 77.1002C56.2252 77.7002 55.5252 78.0002 54.7252 78.0002C53.9252 78.0002 53.2252 77.7002 52.6252 77.1002L29.9002 54.3752C29.3002 53.7752 29.0002 53.0752 29.0002 52.2752C29.0002 51.4752 29.3002 50.7752 29.9002 50.1752L34.1752 45.8252C34.7752 45.2252 35.4877 44.9252 36.3127 44.9252C37.1377 44.9252 37.8502 45.2252 38.4502 45.8252L43.1752 50.5502L55.6252 38.1002L50.9002 33.3752C50.3002 32.7752 50.0002 32.0752 50.0002 31.2752C50.0002 30.4752 50.3002 29.7752 50.9002 29.1752L55.1752 24.8252C55.7752 24.2252 56.4877 23.9252 57.3127 23.9252C58.1377 23.9252 58.8502 24.2252 59.4502 24.8252L82.1752 47.5502C82.7752 48.1502 83.0752 48.8627 83.0752 49.6877C83.0752 50.5127 82.7752 51.2252 82.1752 51.8252L77.8252 56.1002C77.2252 56.7002 76.5252 57.0002 75.7252 57.0002C74.9252 57.0002 74.2252 56.7002 73.6252 56.1002L68.9002 51.3752L56.4502 63.8252L61.1752 68.5502C61.7752 69.1502 62.0752 69.8627 62.0752 70.6877C62.0752 71.5127 61.7752 72.2252 61.1752 72.8252L56.8252 77.1002Z"
              fill="#00BCD4"
            />
            <path
              d="M213.344 172.401L208.128 172.392L201.987 162.704L206.568 159.246C207.48 158.575 208.539 158.182 209.744 158.067C210.949 157.953 212.103 158.16 213.205 158.69L217.087 160.554L224.88 144.328C225.248 143.562 225.832 143.052 226.632 142.799C227.433 142.545 228.24 142.614 229.055 143.005L244.009 150.187C244.536 150.441 244.84 150.85 244.922 151.417C245.004 151.983 244.817 152.434 244.361 152.769L241.416 154.849C241.212 155.029 241.066 155.208 240.98 155.389C240.893 155.569 240.844 155.795 240.831 156.066L241.049 159.665C241.072 160.231 240.837 160.659 240.344 160.949C239.851 161.239 239.341 161.258 238.813 161.004L226.735 155.204L222.839 163.316L226.434 165.043C227.536 165.572 228.413 166.326 229.063 167.304C229.714 168.283 230.087 169.349 230.181 170.504L230.425 176.445L219.2 177.71L215.932 173.644C215.657 173.235 215.28 172.915 214.8 172.685C214.321 172.455 213.836 172.36 213.344 172.401ZM178.218 180.325L197.061 166.328L203.766 176.87C204.067 177.348 204.459 177.73 204.94 178.017C205.422 178.303 205.956 178.449 206.544 178.454L212.008 178.499L215.459 182.736C215.83 183.191 216.272 183.528 216.786 183.748C217.301 183.967 217.838 184.045 218.399 183.981L230.642 182.623L231.634 205.98C231.681 207.111 231.223 207.973 230.26 208.565C229.298 209.156 228.265 209.187 227.163 208.658L178.923 185.489C177.82 184.96 177.199 184.134 177.059 183.013C176.919 181.892 177.305 180.996 178.218 180.325Z"
              fill="#F48FB1"
            />
            <defs>
              <clipPath id="clip0_2973_26109">
                <rect
                  width="140"
                  height="140"
                  fill="white"
                  transform="translate(56 60)"
                />
              </clipPath>
            </defs>
          </svg>
        </Box>
        <Box m={2}>
          <FdTypography variant="h2">Training Activity Complete</FdTypography>
        </Box>
        <FdMarkdownRender
          mt={2}
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="684px"
          markdown={assessmentDataFiltered?.assessment?.postMessage}
        />
      </Box>
    </Box>
  );
};

export default TrainingComplete;

import Level1InProgress from './Level1.png';
import Level2InProgress from './Level2.png';
import Level3InProgress from './Level3.png';
import Level4InProgress from './Level4.png';
import Level5InProgress from './Level5.png';
import Level6InProgress from './Level6.png';
import Level7InProgress from './Level7.png';
import Level8InProgress from './Level8.png';
import Level9InProgress from './Level9.png';
import Level10InProgress from './Level10.png';

export {
  Level1InProgress,
  Level2InProgress,
  Level3InProgress,
  Level4InProgress,
  Level5InProgress,
  Level6InProgress,
  Level7InProgress,
  Level8InProgress,
  Level9InProgress,
  Level10InProgress,
};

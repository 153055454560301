import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { isValid as isValidDate } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Box, FormControlLabel, Switch } from '@mui/material';
import {
  FdTypography,
  FdButton,
  FdTextField,
  FdChip,
  FdIconWithTooltip,
  FdCard,
  FdDateTimePicker,
  FdAlert,
  FdMarkdownEditor,
  FdMarkdownRender,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';
import { updateAssessment } from '../../graphql/mutations';

const EditContainer = ({
  title,
  labelOnly,
  children,
  hideEdit,
  field,
  onCancel,
  onSave,
  error,
  disabled,
}) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <FdCard variant="outlined">
      <Box className="flex items-center justify-between mb-2">
        <Box className="flex items-center">
          <FdTypography variant="h3">{title}</FdTypography>
        </Box>
        {editMode ? (
          <Box className="flex space-x-2">
            <FdButton
              variant="secondary"
              onClick={() => {
                setEditMode(false);
                onCancel(field);
                warningToastMessage('Training activity not updated');
              }}
            >
              Cancel
            </FdButton>
            <FdButton
              variant="primary"
              onClick={() => {
                if (!error) {
                  setEditMode(false);
                  onSave(field);
                }
              }}
              disabled={disabled}
            >
              Save
            </FdButton>
          </Box>
        ) : (
          !hideEdit && (
            <FdButton
              variant="primary"
              onClick={() => {
                setEditMode(true);
              }}
            >
              Edit
            </FdButton>
          )
        )}
      </Box>
      {editMode ? children : labelOnly}
    </FdCard>
  );
};

EditContainer.propTypes = {
  title: PropTypes.string.isRequired,
  labelOnly: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  hideEdit: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const Details = ({ loading, trainingStatus }) => {
  const { trainingId } = useParams();
  const { control, resetField, trigger, getValues } = useFormContext();

  const [updateAssessmentMutation, { loading: updateLoading }] = useMutation(
    gql(updateAssessment),
  );

  const onCancelField = (field) => resetField(field);
  const onSaveField = async (field) => {
    const valid = await trigger([field]);
    if (valid) {
      const value = getValues(field);
      // update relevant field
      updateAssessmentMutation({
        variables: {
          input: {
            id: trainingId,
            [field]: value,
          },
        },
        onCompleted: () => {
          successToastMessage('Success! Training activity updated!');
        },
      });
    }
  };
  const editHidden = trainingStatus === 'Completed';

  return (
    <Box>
      <FdSkeleton loading={loading} height="1742px">
        <Box>
          <Controller
            control={control}
            name="name"
            render={({
              field: { ref, value, ...rest },
              fieldState: { error },
            }) => (
              <Box mb={2}>
                <EditContainer
                  title="Details"
                  labelOnly={
                    <Box className="flex flex-col gap-y-6 mt-5">
                      <Box>
                        <FdTypography variant="subtitle1">
                          Training Activity Name
                        </FdTypography>
                        <FdTypography variant="body1">{value}</FdTypography>
                      </Box>
                      <Box>
                        <FdTypography variant="subtitle1">
                          Training Activity Type
                        </FdTypography>
                        <Box className="flex items-center gap-x-2">
                          <FdTypography variant="body1">
                            Individual-Based
                          </FdTypography>
                          <FdIconWithTooltip title="All training activities are custom made to fit one user based on the required skills they are yet to fulfil in their assigned work role." />
                        </Box>
                      </Box>
                    </Box>
                  }
                  field="name"
                  onCancel={onCancelField}
                  onSave={onSaveField}
                  error={error}
                  disabled={updateLoading}
                  hideEdit={editHidden}
                >
                  <FdTextField
                    id="name"
                    required
                    fullWidth
                    error={error}
                    placeholder="Training Activity - <Work Role Name> - <User Name> - #No."
                    helperText={
                      error
                        ? error.message
                        : 'This training activity will appear under its designated name in your library. This name will be displayed to the assigned user.'
                    }
                    value={value}
                    {...rest}
                    inputRef={ref}
                  />
                  <Box mt={4}>
                    <FdTypography variant="subtitle1">
                      Training Activity Type
                    </FdTypography>
                    <Box className="flex items-center gap-x-2">
                      <FdTypography variant="body1">
                        Individual-Based
                      </FdTypography>
                      <FdIconWithTooltip title="All training activities are custom made to fit one user based on the required skills they are yet to fulfil in their assigned work role." />
                    </Box>
                  </Box>
                </EditContainer>
              </Box>
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="endDateTime"
            render={({
              field: { ref, value, ...rest },
              fieldState: { error },
            }) => (
              <Box>
                <EditContainer
                  title="Availability"
                  labelOnly={
                    <Box className="flex flex-col gap-y-6 mt-5">
                      <Box>
                        <FdTypography variant="subtitle1">
                          Training Activity Start
                        </FdTypography>
                        <Box className="flex items-center gap-x-2">
                          <FdTypography variant="body1" color="secondary">
                            {getDateTimeZoneFormatted(
                              getValues('startDateTime'),
                            )}
                          </FdTypography>
                          <FdIconWithTooltip title="Training activities become available to their assigned user as soon as they are created. The assigned user can attempt the training activity until it ends." />
                        </Box>
                      </Box>
                      <Box>
                        <FdTypography variant="subtitle1">
                          Training Activity End
                        </FdTypography>
                        <Box className="flex items-center gap-x-2">
                          <FdTypography variant="body1" color="secondary">
                            {getDateTimeZoneFormatted(value)}
                          </FdTypography>
                        </Box>
                      </Box>
                    </Box>
                  }
                  field="endDateTime"
                  onCancel={onCancelField}
                  onSave={onSaveField}
                  error={error}
                  disabled={updateLoading}
                  hideEdit={editHidden}
                >
                  <Box mt={4}>
                    <FdTypography variant="subtitle1">
                      Training Activity Start
                    </FdTypography>
                    <Box className="flex items-center gap-x-2">
                      <FdTypography variant="body1">
                        {getDateTimeZoneFormatted(getValues('startDateTime'))}
                      </FdTypography>
                      <FdIconWithTooltip title="Training activities become available to their assigned user as soon as they are created. The assigned user can attempt the training activity until it ends." />
                    </Box>
                  </Box>
                  <Box mt={4}>
                    <FdDateTimePicker
                      label="Training Activity End"
                      value={value}
                      disablePast
                      minDateMessage="Training Activity End date cannot be in the past"
                      helperText={
                        rest.value && !isValidDate(rest.value)
                          ? 'Training Activity End date and time must be entered in the format “DD/MM/YYYY HH:MM”'
                          : (error && error.message) ||
                            'The assigned user will not be able to attempt the training activity after this date.'
                      }
                      error={error}
                      {...rest}
                      inputRef={ref}
                      style={{ '&:p': { width: 'max-content', marginLeft: 0 } }}
                    />
                  </Box>
                  <Box mt={4} mb={2}>
                    <FdAlert
                      variant="info"
                      message="Training activity cannot be restarted once they have ended. Please ensure the end date is set appropriately far in the future."
                    />
                  </Box>
                </EditContainer>
              </Box>
            )}
          />
        </Box>
        <Box mt={2}>
          <FdCard variant="outlined">
            <Box className="flex flex-col gap-y-6 mt-2">
              <Box>
                <FdTypography variant="subtitle1">
                  VPN Configuration
                </FdTypography>
                <Box className="flex flex-col gap-y-3 mt-4">
                  <FdChip
                    label="VPN Enabled"
                    style={{ width: 'fit-content' }}
                  />
                  <FdTypography variant="captiontext1" color="secondary">
                    When enabled, the assigned user will have access to a VPN
                    configuration file, allowing them to connect securely to
                    your training activity environment.
                  </FdTypography>
                  <FdAlert
                    variant="info"
                    message="VPN configuration is enabled by default for all training activities."
                  />
                </Box>
              </Box>
              <Box>
                <FdTypography variant="subtitle1">Jump Box</FdTypography>
                <FormControlLabel
                  control={
                    <Switch
                      id="jumpBoxReadOnly"
                      checked={getValues('jumpBox')}
                      data-cy="jumpBox"
                      disabled
                    />
                  }
                  label="Enable Jump Box"
                />
                <FdTypography variant="captiontext1" color="secondary">
                  When enabled, the assigned user will be able to connect to a
                  Jump Box which allows them to access lab resources securely.
                </FdTypography>
                <Box mt={2}>
                  <FdAlert
                    variant="info"
                    message="Jump Box enablement settings cannot be changed once a training activity is created. Please confirm your desired settings before clicking ‘Create Training Activity’. If enabled, participants will have access to a Jump Box for the entire duration of the event."
                  />
                </Box>
              </Box>
            </Box>
          </FdCard>
        </Box>
        <Box mt={2}>
          <Controller
            control={control}
            name="preMessage"
            render={({
              field: { ref, value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <EditContainer
                title="Training Activity Instructions"
                labelOnly={
                  value ? (
                    <Box style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      <FdMarkdownRender markdown={value} />
                    </Box>
                  ) : (
                    <FdTypography variant="body1">None provided</FdTypography>
                  )
                }
                field="preMessage"
                onCancel={onCancelField}
                onSave={onSaveField}
                error={error}
                disabled={updateLoading}
                hideEdit={editHidden}
              >
                <Box mb={2}>
                  <FdTypography variant="body1">
                    The following message will be displayed to the assigned user
                    before and during this training activity.
                  </FdTypography>
                  <Box mt={2}>
                    <FdMarkdownEditor
                      id="preMessage"
                      name="preMessage"
                      markdown={value}
                      setMarkdown={onChange}
                      label="Message"
                      required
                      error={error}
                      errorText={error && error.message}
                      ref={ref}
                      {...rest}
                    />
                  </Box>
                </Box>
              </EditContainer>
            )}
          />
        </Box>
        <Box mt={2}>
          <Controller
            control={control}
            name="postMessage"
            render={({
              field: { ref, value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <EditContainer
                title="Training Activity Completion Message"
                labelOnly={
                  value ? (
                    <Box style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      <FdMarkdownRender markdown={value} />
                    </Box>
                  ) : (
                    <FdTypography variant="body1">None provided</FdTypography>
                  )
                }
                field="postMessage"
                onCancel={onCancelField}
                onSave={onSaveField}
                error={error}
                disabled={updateLoading}
                hideEdit={editHidden}
              >
                <Box mb={2}>
                  <FdTypography variant="body1">
                    The following message will be displayed to the assigned user
                    after they complete this training activity.
                  </FdTypography>
                  <Box mt={2}>
                    <FdMarkdownEditor
                      id="preMessage"
                      name="preMessage"
                      markdown={value}
                      setMarkdown={onChange}
                      label="Message"
                      required
                      error={error}
                      errorText={error && error.message}
                      ref={ref}
                      {...rest}
                    />
                  </Box>
                </Box>
              </EditContainer>
            )}
          />
        </Box>
      </FdSkeleton>
    </Box>
  );
};

Details.propTypes = {
  loading: PropTypes.bool.isRequired,
  trainingStatus: PropTypes.string.isRequired,
};

export default Details;

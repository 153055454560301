import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdHighlightChip } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()(() => ({
  cellHeading: {
    lineHeight: '28px',
  },
}));

const ChallengeInfoCell = ({ values }) => {
  const { classes } = useStyles();
  const { difficulty, name, estimatedSolveTime } = values;

  return (
    <Box>
      <Box className={classes.cellHeading}>{name}</Box>
      <Box display="flex">
        <FdHighlightChip text={difficulty} condensed />
        <FdHighlightChip
          text={`Est. Time : ${estimatedSolveTime}min`}
          condensed
        />
      </Box>
    </Box>
  );
};

ChallengeInfoCell.propTypes = {
  values: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default ChallengeInfoCell;

/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { FdTypography, FdTab } from '@fifthdomain/fe-shared';
import { CHAMPION_LEVELS } from '../../constants';
import TrophyBadge from './images/tiers/TrophyBadge';
import BlueFillerImage from './images/BlueFillerIAniImage';
import { addOrdinalSuffix } from '../../shared/utils/stringUtils';
import BadgeContainer from './BadgeContainer';

const TooltipLabelValue = ({ label, value }) => (
  <Box className="flex flex-col gap-y-2">
    <Box>
      <FdTypography variant="captiontext1" style={{ fontWeight: 700 }}>
        {label}
      </FdTypography>
      <FdTypography variant="captiontext1">{value}</FdTypography>
    </Box>
  </Box>
);

TooltipLabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const TabPage = ({ tier, badgeLevels }) => {
  const tabLabel = {
    1: 'first',
    2: 'second',
    3: 'third',
  }[tier?.value];
  const badge = badgeLevels?.find((bl) => bl?.level === tier?.value) || {};
  const championEvents = badge?.championEvents || [];
  const eventsCount = championEvents?.length || 0;
  const status = eventsCount > 0 ? 'unlocked' : 'locked';
  const teamCompetitionsCount =
    championEvents?.filter((c) => c?.assessment?.teamBased)?.length || 0;
  const individualCompetitionsCount = eventsCount - teamCompetitionsCount;

  return (
    <Box>
      <Box className="grid grid-cols-3 gap-x-6 my-5">
        <Box className="flex items-center justify-center">
          <BadgeContainer size="128px">
            <TrophyBadge
              type="competition_champion"
              tier={tier?.value}
              status={status}
            />
          </BadgeContainer>
        </Box>
        <Box className="col-span-2">
          <Box className="flex flex-col">
            <FdTypography variant="subtitle1">{tier?.name}</FdTypography>
            <FdTypography variant="captiontext2" color="secondary">
              {`${addOrdinalSuffix(tier?.value)} Place`}
            </FdTypography>
            <Box className="flex flex-col my-2">
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="captiontext2">
                  Latest Count:
                </FdTypography>
                <FdTypography variant="subtitle1">{`${eventsCount}x`}</FdTypography>
              </Box>
              <FdTypography variant="captiontext2">
                (Individual Competitions:{' '}
                <strong>{individualCompetitionsCount}</strong>, Team
                Competitions: <strong>{teamCompetitionsCount}</strong>)
              </FdTypography>
              <Box mt={2}>
                <FdTypography variant="captiontext2">
                  Unlock Method:
                </FdTypography>
                <FdTypography variant="captiontext1" color="secondary">
                  {`Earn ${tabLabel} place in an individual competition or be a member of the first-place team in a team competition.`}
                </FdTypography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box my={2}>
        <FdTypography variant="subtitle2">{`Awarded ${tier?.name} Trophies:`}</FdTypography>
        {status === 'locked' ? (
          <Box className="flex flex-col items-center justify-center gap-y-4 mt-6">
            <Box mt={2} style={{ width: '100px' }}>
              <BlueFillerImage />
            </Box>
            <Box className="text-center">
              <FdTypography variant="captiontext2">
                No relevant trophies have been obtained.
                <br />
                Come back later!
              </FdTypography>
            </Box>
          </Box>
        ) : (
          <Box mt={3} style={{ maxHeight: '165px', overflowY: 'auto' }}>
            {championEvents?.map((event, index) => (
              <Box className="flex items-center gap-x-2" key={index}>
                <Box style={{ marginTop: '10px' }}>
                  <BadgeContainer height="72px">
                    <TrophyBadge
                      type="competition_champion"
                      tier={tier?.value}
                      status="unlocked"
                    />
                  </BadgeContainer>
                </Box>
                <Box className="flex flex-col gap-x-2">
                  <Box className="flex items-center justify-center gap-x-2">
                    <FdTypography variant="body2">
                      {`${tier?.name} #${index + 1}:`}
                    </FdTypography>
                    <FdTypography variant="subtitle2">
                      {event?.assessment?.name}
                    </FdTypography>
                  </Box>
                  <Box className="flex items-center gap-x-2" height="15px">
                    <FdTypography variant="captiontext2" color="secondary">
                      {`Hosted by ${event?.assessment?.creator?.org?.name}`}
                    </FdTypography>
                    {event?.assessment?.teamBased && (
                      <>
                        <Box height="12px">
                          <Divider orientation="vertical" />
                        </Box>
                        <FdTypography variant="captiontext2" color="secondary">
                          Team Based
                        </FdTypography>
                      </>
                    )}
                  </Box>
                  <Box className="flex items-center gap-x-2">
                    <FdTypography variant="captiontext2">
                      Award Date:
                    </FdTypography>
                    <FdTypography variant="captiontext2" color="secondary">
                      {format(new Date(event?.createdAt), 'dd-MM-yyyy')}
                    </FdTypography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

TabPage.propTypes = {
  tier: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  badgeLevels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const SectionModalContentWithTabs = ({ section }) => {
  return (
    <Box>
      <FdTypography variant="captiontext1" color="secondary">
        {`${section?.name} trophies award those who achieve the first, second, and third places in competitions. Top-scoring individuals and members of top-scoring teams receive Gold, Silver and Bronze trophies accordingly.`}
      </FdTypography>

      <FdTab
        label={CHAMPION_LEVELS?.map((index) => ({
          label: index?.name,
          data: <TabPage tier={index} badgeLevels={section?.badgeLevels} />,
        }))}
        variant="fullWidth"
      />
    </Box>
  );
};

SectionModalContentWithTabs.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string,
    badgeLevels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default SectionModalContentWithTabs;

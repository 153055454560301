import { gql } from '@apollo/client';
import { useQueryRecursive } from '@fifthdomain/fe-shared';
import { listSkills } from '../graphql/queries';

const useGetSkills = () => {
  const { data: listSkillsData, loading: skillsLoading } = useQueryRecursive(
    gql(listSkills),
    {
      staleTime: { hours: 24 },
    },
  );
  const skills = listSkillsData?.listSkills?.items || [];

  return {
    skills,
    skillsLoading,
  };
};

export default useGetSkills;

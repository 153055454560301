import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const Tile = ({ heading, subHeading, description }) => (
  <Box className="flex flex-col items-center text-center">
    <FdTypography variant="h3">{heading}</FdTypography>
    {subHeading && (
      <Box my={2}>
        <FdTypography variant="body1" style={{ fontWeight: 500 }}>
          {subHeading}
        </FdTypography>
      </Box>
    )}
    <FdTypography variant="captiontext1" color="secondary">
      {description}
    </FdTypography>
  </Box>
);

Tile.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  description: PropTypes.string.isRequired,
};

Tile.defaultProps = {
  subHeading: undefined,
};

export default Tile;

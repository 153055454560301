import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, IconButton, useTheme, Checkbox } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdCard,
  FdTypography,
  FdTooltip,
  getAvatarColor,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import RadarNivo from '../Charts/RadarNivo';
import NoDataPresentSvg from '../../shared/images/no-data-present.svg';
import { getRankingNormalizedRows, getRankingTableRows } from './overviewUtils';
import { sortObjectArrayByField } from '../../shared/utils/objectUtils';
import { DonutStacked } from '../Charts';
import TeamAvatar from './TeamAvatar';
import ProfileHyperlink from './ProfileHyperlink';

const performanceMessage = {
  individual:
    "The Performance Triangle visually captures the participant's performance, integrating Success, Efficiency, and Speed metrics. Blue triangle corresponds to the participant, while the grey line signifies the competition average. For metric definitions, simply click on the information tags below.",
  team: "The Performance Triangle visually captures the team's performance, integrating Success, Efficiency, and Speed metrics. Each colored triangle corresponds to a team member, while the navy blue and grey triangle show team average and competition average respectively. Using checkboxes, select the team member(s) whose performance you want to see. For metric definitions, simply click on the information tags below.",
};

const LabelWithTooltip = ({ title, tooltipText, ...props }) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <FdTypography variant="captiontext1" color="secondary">
        {title}
      </FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

LabelWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
};

const NoDataToPresent = () => (
  <Box className="flex flex-col items-center justify-center" height="330px">
    <img src={NoDataPresentSvg} alt="no data present" />
    <FdTypography variant="subtitle1">No data to present</FdTypography>
  </Box>
);

const HighlightedLabel = ({ value }) => (
  <Box
    className="flex items-center justify-center mr-2"
    style={{
      backgroundColor: 'rgb(51, 51, 255)',
      height: '28px',
      borderRadius: '4px',
    }}
  >
    <FdTypography
      variant="subtitle1"
      style={{ color: '#fff', padding: '0 0.8rem' }}
    >
      {value}
    </FdTypography>
  </Box>
);
HighlightedLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

const LabelValue = ({ label, value }) => (
  <Box className="flex items-center justify-between w-4/5 ml-11 mt-1">
    <FdTypography variant="body2">{label}</FdTypography>
    <FdTypography variant="subtitle1">{value}</FdTypography>
  </Box>
);
LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const OverallPerformance = ({
  performanceData,
  contestantSelected,
  teamPoints,
}) => {
  const theme = useTheme();
  const [teamMembers, setTeamMembers] = useState([]);
  const isDarkTheme = theme?.palette?.type === 'dark';
  const navyBlueColor = 'rgba(29, 57, 110, 1)';
  const {
    participantsFinished,
    completedTasks,
    allTaskAttempts,
    totalAssessmentPoints,
    maxDurationInMins,
    timeSpentDataForAssessment,
    totalPointsScoredInAssessment,
    teamBased,
    participantsFinishedAssessment,
    teamGroups,
    tasksOpened,
    individualAttempts,
  } = performanceData || {};

  useEffect(() => {
    const members = teamBased
      ? teamGroups
          ?.find((t) => t?.teamId === contestantSelected?.id)
          ?.team?.members?.items?.filter((m) => m?.userId !== 'UNDEFINED')
          ?.map((tm) => ({
            userId: tm?.userId,
            alias: tm?.User?.alias,
            color: getAvatarColor(tm?.User?.alias),
            selected: false,
          }))
          ?.sort(sortObjectArrayByField('alias'))
      : [];
    setTeamMembers(members);
  }, [teamBased, teamGroups, contestantSelected]);

  const teamOrParticipant = teamBased ? 'Team' : 'Participant';

  const rankingNormalizedRows = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: teamBased,
    _teams: participantsFinishedAssessment,
    _teamGroups: teamGroups,
    _tasksOpened: tasksOpened,
  });

  const { rankingTableRows } = getRankingTableRows({
    _participantsFinished: participantsFinished,
    _rankingNormalizedRows: rankingNormalizedRows,
  });

  const getCohortAvgValue = (_field) =>
    rankingNormalizedRows?.length > 0
      ? Math.round(
          rankingNormalizedRows?.reduce((acc, i) => acc + i[_field], 0) /
            rankingNormalizedRows?.length,
          2,
        )
      : 0;

  const participantNormalizedValues = rankingTableRows?.find(
    (p) => p?.id === contestantSelected?.id,
  );

  const { normalizedSuccess, normalizedEfficiency, normalizedSpeed } =
    participantNormalizedValues ?? {};

  const rankingNormalizedRowTeamMembers = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: false,
    _teams: participantsFinishedAssessment,
    _teamGroups: teamGroups,
    _tasksOpened: tasksOpened,
  });

  const teamMembersSelected =
    teamMembers
      ?.filter((tm) => tm?.selected)
      ?.sort(sortObjectArrayByField('alias')) || [];

  const getMembersNormalizedValues = (_topic) =>
    teamMembersSelected
      ?.map((tm) => ({
        [`${tm?.alias} Score`]: rankingNormalizedRowTeamMembers?.find(
          (p) => p?.id === tm?.userId,
        )?.[_topic],
      }))
      ?.reduce((r, o) => ({ ...r, ...o }), {}); // convert array to object

  const radarChartData = [
    {
      label: 'Success',
      baseLine: 100,
      [`${teamOrParticipant} Score`]: normalizedSuccess,
      'Competition Average': getCohortAvgValue('normalizedSuccess'),
      ...getMembersNormalizedValues('normalizedSuccess'),
    },
    {
      label: 'Speed',
      baseLine: 100,
      [`${teamOrParticipant} Score`]: normalizedSpeed,
      'Competition Average': getCohortAvgValue('normalizedSpeed'),
      ...getMembersNormalizedValues('normalizedSpeed'),
    },
    {
      label: 'Efficiency',
      baseLine: 100,
      [`${teamOrParticipant} Score`]: normalizedEfficiency,
      'Competition Average': getCohortAvgValue('normalizedEfficiency'),
      ...getMembersNormalizedValues('normalizedEfficiency'),
    },
  ];
  const noDataToPresent = Boolean(!participantNormalizedValues);
  const selectedSliceData = teamMembers?.find((tm) => tm?.active);
  const selectedSliceDetails = rankingNormalizedRowTeamMembers?.find(
    (r) => r?.id === selectedSliceData?.userId,
  );
  const selectedFlagsData = individualAttempts?.find(
    (i) => i?.userId === selectedSliceData?.userId,
  );

  return (
    <Box className="flex">
      <Box mt={1} mb={1} width={teamBased ? '75%' : '100%'}>
        <FdCard
          variant="outlined"
          heading={`${teamOrParticipant}'s overall performance`}
        >
          <Box display="flex">
            <Box>
              <FdTypography
                variant="body2"
                color="secondary"
                style={{ marginTop: '-18px' }}
              >
                {teamBased
                  ? performanceMessage.team
                  : performanceMessage.individual}
              </FdTypography>
              <Box className="flex justify-between mt-1">
                <Box className="flex items-center">
                  <HighlightedLabel
                    value={participantNormalizedValues?.overallScore || 0}
                  />
                  <FdTypography variant="captiontext1">{`${teamOrParticipant} competition score`}</FdTypography>
                  <FdTooltip
                    title={
                      <Box>
                        Competition score is a measurement of Success
                        (challenges successfully solved), Efficiency (scoring
                        points with least number of attempts), and Speed
                        (quickness in completing the competition).
                        <br />
                        The most important metric that determines this score is
                        Success, but Speed and Efficiency are also given
                        weightage.
                      </Box>
                    }
                  >
                    <IconButton size="small" style={{ marginLeft: '5px' }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </FdTooltip>
                </Box>
                <Box className="flex">
                  <LabelWithTooltip
                    title="Success"
                    tooltipText={`Success is measured by the ${teamOrParticipant}'s capability to capture flags. It factors in the points scored for the successfully completed challenges.`}
                    mr={3}
                  />
                  <LabelWithTooltip
                    title="Efficiency"
                    tooltipText={`Efficiency measures the ${teamOrParticipant}'s ability to score points while making the least number of attempts for challenges.`}
                    mr={3}
                  />
                  <LabelWithTooltip
                    title="Speed"
                    tooltipText={`Speed is determined by the ${teamOrParticipant}'s capability to complete the competition in the quickest possible time.`}
                  />
                </Box>
              </Box>
              {noDataToPresent ? (
                <NoDataToPresent />
              ) : (
                <Box className="flex">
                  <Box height="350px" width={teamBased ? '75%' : '87%'} my={3}>
                    <RadarNivo
                      data={radarChartData}
                      keys={[
                        'baseLine',
                        `${teamOrParticipant} Score`,
                        'Competition Average',
                        ...teamMembersSelected?.map(
                          (tm) => `${tm?.alias} Score`,
                        ),
                      ]}
                      indexBy="label"
                      colors={[
                        'transparent',
                        'transparent',
                        'transparent',
                        ...teamMembersSelected?.map(() => 'transparent'),
                      ]}
                      gridLabel={(props) => {
                        // eslint-disable-next-line react/prop-types
                        const { x, y, id } = props;
                        const anchor =
                          x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
                        const lineData = radarChartData?.find(
                          (d) => d.label === id,
                        );
                        return (
                          <g
                            transform={`translate(${
                              x + (id === 'Success' ? 30 : 0)
                            }, ${y})`}
                          >
                            <text
                              alignmentBaseline="middle"
                              textAnchor={id === 'Success' ? 'start' : anchor}
                              fontSize={14}
                              fontFamily="Montserrat, sans-serif"
                              fontWeight={700}
                              opacity={0.7}
                              fill={isDarkTheme ? '#fff' : '#000'}
                            >
                              {id}
                              <tspan
                                fontSize={14}
                                fontWeight={400}
                                x={0}
                                dy={25}
                              >
                                {`${teamOrParticipant} Score ${Math.round(
                                  lineData[`${teamOrParticipant} Score`],
                                )}`}
                              </tspan>
                              <tspan
                                fontSize={14}
                                fontWeight={400}
                                x={0}
                                dy={20}
                                opacity={0.7}
                              >
                                {`Competition Average ${Math.round(
                                  lineData['Competition Average'],
                                )}`}
                              </tspan>
                            </text>
                          </g>
                        );
                      }}
                      borderColor={(v) =>
                        v.key === 'Competition Average'
                          ? '#424242'
                          : v.key === 'baseLine'
                            ? 'rgba(0, 0, 0, 0.12)'
                            : teamMembersSelected.find(
                                (tm) => v.key === `${tm?.alias} Score`,
                              )?.color || navyBlueColor
                      }
                      sliceTooltip={(t) => {
                        const toolTipValues = t?.data
                          ?.filter((d) => d?.id !== 'baseLine')
                          ?.map((d) => {
                            const isTeamScore = d?.id === 'Team Score';
                            const isCompetitionAverage =
                              d?.id === 'Competition Average';
                            const idWithoutScore =
                              d?.id !== 'Team Score'
                                ? d?.id?.replace(' Score', '')
                                : d?.id;

                            return {
                              id: idWithoutScore,
                              value: Math.round(d?.value),
                              ...(isTeamScore
                                ? { slno: 1, color: navyBlueColor }
                                : isCompetitionAverage
                                  ? { slno: 2, color: '#424242' }
                                  : {
                                      slno: 3,
                                      color: getAvatarColor(idWithoutScore),
                                    }),
                            };
                          });

                        const tooltipValuesDisplay = _.orderBy(
                          toolTipValues,
                          ['slno', 'id'],
                          ['asc', 'asc'],
                        );
                        return (
                          <div
                            style={{
                              background: theme?.palette?.background?.default,
                              borderRadius: '2px',
                              padding: '5px 9px',
                              boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                            }}
                          >
                            <Box>
                              <FdTypography variant="subtitle2">
                                {t?.index}
                              </FdTypography>
                              {tooltipValuesDisplay?.map((d) => (
                                <Box className="flex items-center">
                                  <Box
                                    className="w-2 h-2 mr-2"
                                    style={{
                                      backgroundColor: d?.color,
                                    }}
                                  />
                                  <FdTypography variant="body1">{`${d?.id}: ${d?.value}`}</FdTypography>
                                </Box>
                              ))}
                            </Box>
                          </div>
                        );
                      }}
                      isInteractive
                    />
                  </Box>
                  <Box mt={3}>
                    <Box className="flex items-center">
                      <Box
                        height="6px"
                        width="35px"
                        style={{
                          backgroundColor: navyBlueColor,
                          marginRight: '0.8rem',
                          opacity: 0.8,
                        }}
                      />
                      <FdTypography variant="body2">
                        {`${teamBased ? 'Team' : 'Participant'} Average`}
                      </FdTypography>
                    </Box>
                    <Box className="flex items-center mt-1">
                      <Box
                        height="6px"
                        width="35px"
                        style={{
                          backgroundColor: '#424242',
                          marginRight: '0.8rem',
                          opacity: 0.8,
                        }}
                      />
                      <FdTypography variant="body2">
                        Competition Average
                      </FdTypography>
                    </Box>
                    {teamBased && (
                      <Box
                        mt={1}
                        style={{ height: '281px', overflowY: 'auto' }}
                      >
                        {teamMembers?.map((cd) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            key={cd?.userId}
                          >
                            <Checkbox
                              style={{ padding: '6px 6px 6px 0' }}
                              value={cd?.selected}
                              onChange={(v) =>
                                setTeamMembers((currentValues) =>
                                  currentValues?.map((item) =>
                                    item.userId === cd?.userId
                                      ? { ...item, selected: v.target.checked }
                                      : item,
                                  ),
                                )
                              }
                            />
                            <Box className="flex w-5 h-5 mr-1 items-center">
                              <Box
                                className="rounded-full w-4 h-4"
                                style={{
                                  backgroundColor: cd?.color,
                                }}
                              />
                            </Box>
                            <ProfileHyperlink userId={cd?.userId}>
                              <FdTypography variant="body2">
                                {cd?.alias}
                              </FdTypography>
                            </ProfileHyperlink>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </FdCard>
      </Box>
      {teamBased && (
        <Box ml={2} mt={1} mb={1} width="37%">
          <FdCard
            variant="outlined"
            heading="Team members's points contribution"
          >
            <Box height="494px">
              <FdTypography
                variant="captiontext1"
                style={{ marginTop: '-20px' }}
                color="secondary"
              >
                Click on the donut segments representing each team member to see
                further details about points contribution for that member.
              </FdTypography>
              <Box className="flex items-center mt-2">
                <HighlightedLabel value={teamPoints || 0} />
                <FdTypography variant="captiontext1">
                  Total teams points
                </FdTypography>
              </Box>
              <Box
                className="flex items-center justify-center py-3"
                height="280px"
              >
                <FdDelayed triggerField={teamMembers} delay={0}>
                  <DonutStacked
                    data={teamMembers?.map((tm) => ({
                      id: tm?.alias,
                      userId: tm?.userId,
                      label: tm?.alias,
                      value:
                        rankingNormalizedRowTeamMembers?.find(
                          (r) => r?.id === tm?.userId,
                        )?.totalPoints || 0,
                      active: tm?.active,
                    }))}
                    colors={teamMembers?.map((tm) => tm?.color)}
                    onClick={(v) => {
                      setTeamMembers((currentValues) =>
                        currentValues?.map((item) => ({
                          ...item,
                          active: item.userId === v?.data?.userId,
                        })),
                      );
                    }}
                    tooltip={(v) => (
                      <FdTooltip
                        style={{
                          backgroundColor: 'rgba(110, 110, 110, 1)',
                          color: '#fff',
                          padding: '0.5rem 0.8rem',
                          borderRadius: '4px',
                        }}
                      >
                        <span>{v?.datum?.label}</span>
                      </FdTooltip>
                    )}
                    innerRadius={0.8}
                    borderColor={(v) => v.data.active && 'rgba(65, 64, 64, 1)'}
                    borderWidth={4}
                    margin={{ top: 5, right: 0, bottom: 5, left: 0 }}
                  />
                </FdDelayed>
              </Box>
              {!selectedSliceData && (
                <FdTypography
                  variant="captiontext1"
                  color="secondary"
                  style={{ marginTop: '5rem' }}
                >
                  Each segment represents the contribution of a player. Click on
                  the individual segment to know the details such as points,
                  success, flags and first solve for that player.
                </FdTypography>
              )}
              {selectedSliceData && (
                <Box mt={2}>
                  <Box className="flex items-center">
                    <TeamAvatar
                      size="small"
                      team={{ name: selectedSliceData?.alias }}
                    />
                    <FdTypography
                      variant="subtitle1"
                      style={{ marginLeft: '0.5rem' }}
                    >
                      {selectedSliceData?.alias}
                    </FdTypography>
                  </Box>
                  <Box>
                    <LabelValue
                      label="Points Scored"
                      value={selectedSliceDetails?.totalPoints}
                    />
                    <LabelValue
                      label="Success Score"
                      value={Math.round(
                        selectedSliceDetails?.normalizedSuccess,
                      )}
                    />
                    <LabelValue
                      label="Flags"
                      value={selectedFlagsData?.flags || '-'}
                    />
                    <LabelValue
                      label="First Solves"
                      value={selectedFlagsData?.firstBloods || '-'}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </FdCard>
        </Box>
      )}
    </Box>
  );
};

OverallPerformance.propTypes = {
  performanceData: PropTypes.shape({
    completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalAssessmentPoints: PropTypes.number.isRequired,
    maxDurationInMins: PropTypes.number.isRequired,
    timeSpentDataForAssessment: PropTypes.number.isRequired,
    totalPointsScoredInAssessment: PropTypes.number.isRequired,
    teamBased: PropTypes.bool.isRequired,
    participantsFinishedAssessment: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) }),
    selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  contestantSelected: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  teamPoints: PropTypes.number.isRequired,
};

export default OverallPerformance;

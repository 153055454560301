import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const LevelNumberIcon = ({ level, completed }) => {
  return (
    <Box
      className="flex items-center justify-center h-10 w-10"
      style={{
        borderRadius: '8px',
        backgroundColor: completed
          ? 'rgba(40, 149, 123, 1)'
          : 'rgba(76, 90, 255, 1)',
        color: '#fff',
      }}
    >
      <FdTypography variant="body1" style={{ fontFamily: 'Raitor' }}>
        {`0${level}`.slice(-2)}
      </FdTypography>
    </Box>
  );
};

LevelNumberIcon.propTypes = {
  level: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
};

export default LevelNumberIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdCard, FdMarkdownRender, FdTypography } from '@fifthdomain/fe-shared';

const AssessmentRules = ({ userFullName, preMessage, videoUrl }) => (
  <Box my={2} pb={4}>
    <FdCard heading={`Welcome ${userFullName}!`} variant="outlined">
      <Box>
        <FdMarkdownRender markdown={preMessage} />
      </Box>
      {videoUrl && (
        <Box mt={3}>
          <FdTypography variant="h4">Instructional Video</FdTypography>
          <div
            style={{ maxWidth: '80%', marginTop: '16px' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: videoUrl,
            }}
          />
          <script src="https://player.vimeo.com/api/player.js" />
        </Box>
      )}
    </FdCard>
  </Box>
);

AssessmentRules.propTypes = {
  userFullName: PropTypes.string.isRequired,
  preMessage: PropTypes.string.isRequired,
  videoUrl: PropTypes.string,
  assessmentData: PropTypes.shape({}).isRequired,
};
AssessmentRules.defaultProps = {
  videoUrl: undefined,
};

export default AssessmentRules;

export const AssessmentVPNRules = ({ preMessage }) => (
  <Box>
    <FdCard variant="outlined">
      <Box>
        <FdMarkdownRender markdown={preMessage} />
      </Box>
    </FdCard>
  </Box>
);

AssessmentVPNRules.propTypes = {
  preMessage: PropTypes.string.isRequired,
};

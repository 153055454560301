import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, CircularProgress, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  FdTable,
  FdTypography,
  FdSelect,
  FdButton,
  FdChip,
  FdModal,
  FdTooltip,
  FdChips,
  useSnapshot,
  globalStore,
  Authorization,
  FdAlert,
  warningToastMessage,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import TaskInfoCell from './TaskInfoCell';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import {
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';

const getTableData = (tData, _tasks) =>
  tData?.map((d) => ({
    ...d,
    level: _tasks.find((t) => t.taskId === d.id)?.level || null,
  })) || [];

const TasksTable = ({
  data,
  editTasks,
  setEditTasksCallBack,
  viewTaskActions,
  viewAssessment,
  showEditButton,
  listHintRevealsData,
  revealHintsCallBack,
  competitionStatus,
  onRefreshTasks,
  isRefreshLoading,
  onEditMode,
  loading,
}) => {
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [revealAllHints, setRevealAllHints] = useState([]);
  const [selectPlaceholder, setSelectPlaceholder] = useState('ASSIGN LEVEL');
  const { control, setValue, getValues } = useFormContext();
  const { multiLevel, levels } = getValues();

  const { fields: tasks } = useFieldArray({
    control,
    name: 'tasks',
  });
  const globalSnap = useSnapshot(globalStore);
  const isAdminUser = globalSnap.userType === 'ADMIN';
  const tableData = useMemo(() => getTableData(data, tasks), [data, tasks]);
  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'creator',
      headerName: 'Creator/ Provider',
      flex: 1,
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle2">Creator/ Provider</FdTypography>
            <FdTooltip
              title={
                <Box>
                  Creator of the challenge is the manager/organisation which
                  created that challenge. Creator information appears for
                  challenges that are owned by your organisation.
                  <br />
                  <br />
                  Provider name appears for challenges that have been leased
                  from, (or provided by) other organisation.
                </Box>
              }
            >
              <IconButton size="small" style={{ marginLeft: '10px' }}>
                <InfoOutlinedIcon />
              </IconButton>
            </FdTooltip>
          </Box>
        );
      },
      valueGetter: (params) => {
        const rowVal = params?.row;
        if (rowVal?.owned) {
          return rowVal?.creatorOrgId === rowVal?.ownerOrgId
            ? `Creator: ${rowVal.creator}`
            : `Creator: ${rowVal?.creatorOrg}`;
        }
        return `Provider: ${rowVal?.ownerOrg}`;
      },
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
      renderCell: (params) => <FdHighlightChip text={params?.value} />,
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      valueGetter: (params) =>
        params?.value?.length > 0
          ? [...(params?.value || [])].map((t) => t?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        return getCommaSeparatedPlusSuffix(
          params?.row?.skills?.map((skill) => skill?.name),
        );
      },
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => upperCaseFirstLetter(params?.value),
    },
    {
      field: 'difficultyLabel',
      flex: 1,
      headerName: 'Proficiency',
    },
    {
      field: 'estimatedSolveTime',
      flex: 1,
      headerName: 'Est. Time to Solve (mins)',
      type: 'number',
    },
    ...(globalSnap.orgPricingTier === 'STARTER'
      ? []
      : [
          {
            field: 'tags',
            flex: 1,
            headerName: 'Assigned Tags',
            valueGetter: (params) =>
              params.row?.tags?.length > 0
                ? [...(params.row?.tags || [])]
                    .map((t) => t.Tag?.name)
                    ?.join(' ')
                : '',
            renderCell: (params) => {
              const _data = [...(params.row?.tags || [])]
                ?.sort(sortByDateField('updatedAt', 'desc'))
                .map((t) => ({
                  label: t.Tag?.name,
                  color: getTagColor(t.Tag?.color),
                }));
              return _data?.length > 0 ? (
                <FdChips
                  data={_data}
                  numberOfChipsDisplayed={1}
                  caption="More"
                />
              ) : (
                <Box width="100%" textAlign="center">
                  -
                </Box>
              );
            },
          },
        ]),
    {
      field: 'recommendedPoints',
      width: 100,
      headerName: 'Points',
      type: 'number',
    },
    {
      field: 'level',
      flex: 1,
      headerAlign: 'center',
      headerName: 'Level',
      type: 'string',
      filterable: multiLevel,
      align: 'center',
      valueFormatter: (params) =>
        !params?.value ? 'No level selected' : `Level ${params?.value}`,
      valueGetter: (params) =>
        !params?.value ? 'No level selected' : params?.value,
    },
    {
      field: 'hintsReleased',
      flex: 1,
      headerName: 'Number of Hints released',
    },

    ...(hasManagePermission
      ? [
          {
            field: 'hintsRevealAll',
            filterable: false,
            width: 200,
            headerName: ' ',
            valueGetter: (row) => row?.value,
            renderCell: (row) => {
              const rowAction =
                row?.value?.length > 0 ? (
                  <FdButton
                    variant="secondary"
                    onClick={() => {
                      setRevealAllHints(row?.value);
                    }}
                  >
                    reveal all hints
                  </FdButton>
                ) : row?.value?.length === 0 ? (
                  <FdChip
                    color="default"
                    size="small"
                    label="All Hints Revealed"
                  />
                ) : (
                  row?.value
                );

              return rowAction;
            },
          },
        ]
      : []),

    {
      field: 'technologyTags',
      flex: 1,
      headerName: 'Technologies',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Technologies"
          tooltipText="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
    {
      field: 'techniqueTags',
      flex: 1,
      headerName: 'Techniques',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Techniques"
          tooltipText="Techniques refer to the specific methods or strategies required to resolve a challenge."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
  ];

  const editTask = viewAssessment || !editTasks ? editTasks : true;

  const assignSelectPlaceHolder = (_selectedTasks) => {
    const selectedLevels = [
      ...new Set(
        tableData
          .filter((td) => _selectedTasks.includes(td.id))
          .map((td) => (!td.level ? 'ASSIGN LEVEL' : `Level ${td.level}`)),
      ),
    ];
    let placeHolder;
    switch (selectedLevels.length) {
      case 0:
        placeHolder = 'ASSIGN LEVEL';
        break;
      case 1:
        placeHolder = selectedLevels?.[0];
        break;
      default:
        placeHolder = 'MIXED';
        break;
    }
    setSelectPlaceholder(placeHolder);
  };

  return (
    <Box mt={2} mb={2} height={editTask ? '560px' : '610px'} width="100%">
      <FdTable
        loading={loading}
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          columnsButton: true,
          filterButton: true,
          densityButton: true,
        }}
        rowHeight={60}
        columnVisibilityModel={{
          level: multiLevel,
          hintsReleased: !editTasks,
          hintsRevealAll: !editTasks && competitionStatus !== 'Ended',
          difficulty: false,
          estimatedSolveTime: false,
          technologyTags: false,
          techniqueTags: false,
          creator: false,
          skills: false,
        }}
        slotProps={{
          columnsPanel: {
            getTogglableColumns: () => [
              'creator',
              'specialtyName',
              'skills',
              'type',
              'tags',
              'recommendedPoints',
              ...(multiLevel ? ['level'] : []),
              ...(!editTasks ? ['hintsReleased'] : []),
              'technologyTags',
              'techniqueTags',
            ],
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
        }}
        toolbarSettings={{
          title: (
            <Box>
              <FdTypography variant="h3">Challenges</FdTypography>
              <FdAlert
                style={{ marginTop: '4px' }}
                variant="info"
                message={
                  <FdTypography variant="body2">
                    All labs in lab-based challenges have a duration of 2 hours.
                    Participants can extend their lab instances as many times as
                    they require, resetting their lab timers to 2 hours and
                    retaining progress made. Participants can also reset their
                    labs as needed, which will deploy a new lab instance and
                    erase all progress.
                  </FdTypography>
                }
              />
            </Box>
          ),
          headerElements: multiLevel && (
            <Box>
              <FdTypography>{`Number of levels: ${levels}`}</FdTypography>
            </Box>
          ),
          headerActions:
            showEditButton && !editTasks
              ? [
                  {
                    label: 'EDIT',
                    onClick: () => {
                      setEditTasksCallBack(true);
                    },
                  },
                ]
              : [],
          headerCustomElements: [
            // show for multi-level for edit and new
            ...((viewAssessment && editTasks && multiLevel) ||
            (!viewAssessment && multiLevel)
              ? [
                  {
                    CustomElement: () => (
                      <FdSelect
                        label=""
                        options={[
                          'Remove/No level',
                          ...getArrayByLength(
                            getValues('levels'),
                            (_, i) => `Level ${i + 1}`,
                          ),
                        ]}
                        defaultValue=""
                        width="160px"
                        placeholder={selectPlaceholder}
                        customPlaceHolder
                        onChange={(_value) => {
                          setSelectPlaceholder('ASSIGN LEVEL');
                          if (!selectedTasks.length) {
                            setSelectedTasks([]);
                            warningToastMessage(
                              'No challenges assigned to Level',
                            );
                            return;
                          }
                          const level =
                            Number(_value.replace('Level', '').trim()) || 0;
                          const isDelete = level === 0;
                          // delete
                          if (isDelete) {
                            const _tasks = tasks?.filter(
                              (t) => !selectedTasks.includes(t.taskId),
                            );
                            setValue('tasks', _tasks);
                          } else {
                            // update and delete
                            const _updateTasks = tasks?.map((t) => ({
                              ...t,
                              level: selectedTasks.includes(t.taskId)
                                ? level
                                : t.level,
                            }));
                            const _insertTasks = selectedTasks
                              .filter(
                                (s) => !tasks.map((t) => t.taskId).includes(s),
                              )
                              .map((t) => ({
                                taskId: t,
                                level,
                              }));
                            setValue('tasks', [
                              ..._updateTasks,
                              ..._insertTasks,
                            ]);
                          }
                          setSelectedTasks([]);
                        }}
                      />
                    ),
                  },
                ]
              : []),
            {
              CustomElement: () =>
                (isAdminUser && showEditButton) || // edit mode
                (isAdminUser && !onEditMode) ? ( // new mode
                  <FdButton
                    startIcon={
                      isRefreshLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <RefreshIcon />
                      )
                    }
                    disabled={isRefreshLoading}
                    onClick={onRefreshTasks}
                  >
                    Refresh Challenges
                  </FdButton>
                ) : (
                  <></>
                ),
              styleClass: {},
            },
          ],
          filterOptions: [],
          searchBox: true,
          filterButton: editTask,
        }}
        selectionModel={multiLevel ? selectedTasks : tasks.map((t) => t.taskId)}
        onSelectionModelChange={(_values) => {
          if (_values?.length > 0) {
            if (multiLevel) {
              setSelectedTasks(_values);
            } else {
              setValue(
                'tasks',
                _values.map((v) => ({ taskId: v })),
              );
            }
            assignSelectPlaceHolder(_values);
          }
        }}
        selection={editTask}
        disableSelectionOnClick
        actions={viewTaskActions}
        rows={
          tableData?.map((d) => {
            const _totalHints = d?.hints?.items;
            const _hintRevealed =
              listHintRevealsData?.listHintReveals?.items?.filter((item) =>
                item?.taskAssessmentId?.includes(d?.taskAssessmentId),
              );
            const _remainToRevealHints = _totalHints
              ?.filter(
                ({ id }) =>
                  !_hintRevealed?.map((item) => item?.hintId).includes(id),
              )
              ?.map((item) => ({
                hintId: item?.id,
                taskAssessmentId: d?.taskAssessmentId,
                challengeName: d?.name,
              }));
            return {
              ...d,
              difficulty: getDifficultyLevel(d.difficulty),
              hintsReleased: _totalHints?.length
                ? `${_hintRevealed?.length}(out of ${_totalHints?.length})`
                : '-',
              hintsRevealAll: _totalHints?.length ? _remainToRevealHints : null,
            };
          }) || []
        }
        columns={columns}
        pagination
        visibleSelection
        rowsPerPageOptions={[5, 10, 20]}
        tablePageSize={10}
        gridId="competitions-admin-challenges"
      />

      <FdModal
        size="xs"
        title="Reveal all Hints?"
        description={
          <Box>
            <FdTypography variant="body1">
              Once revealed, the hints can’t be hidden again.
            </FdTypography>
            <FdTypography variant="body1">
              Are you sure you want to reveal all the hints for this challenge?
            </FdTypography>
          </Box>
        }
        dismiss="Cancel"
        confirm="Reveal"
        open={revealAllHints?.length}
        onDismiss={() => {
          setRevealAllHints([]);
        }}
        onConfirm={() => {
          revealHintsCallBack(revealAllHints);
          setRevealAllHints([]);
        }}
      />
    </Box>
  );
};

TasksTable.defaultProps = {
  editTasks: true,
  setEditTasksCallBack: () => {},
  viewAssessment: false,
  viewTaskActions: [],
  showEditButton: undefined,
  listHintRevealsData: {},
  revealHintsCallBack: () => {},
  competitionStatus: null,
  onRefreshTasks: () => null,
  isRefreshLoading: false,
  onEditMode: false,
  loading: false,
};

TasksTable.propTypes = {
  competitionStatus: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,
  editTasks: PropTypes.bool,
  setEditTasksCallBack: PropTypes.func,
  viewAssessment: PropTypes.bool,
  viewTaskActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  revealHintsCallBack: PropTypes.func,
  showEditButton: PropTypes.bool,
  listHintRevealsData: PropTypes.shape({
    listHintReveals: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          hintId: PropTypes.string,
          taskAssessmentId: PropTypes.string,
        }),
      ),
    }),
  }),
  onRefreshTasks: PropTypes.func,
  isRefreshLoading: PropTypes.bool,
  onEditMode: PropTypes.bool,
  loading: PropTypes.bool,
};

export default TasksTable;

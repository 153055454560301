import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { differenceInMinutes } from 'date-fns';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTypography,
  FdAlert,
  useQueryRecursive,
  FdButton,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import NoParticipantSelectedSvg from '../shared/images/no-participant.svg';
import InsightsSummary from '../components/Insights/InsightsSummary';
import {
  getSystemTime,
  listUserAssessmentsByTeamId,
  listUserAssessmentsByUserId,
} from '../graphql/queries';
import { getCompletedStatus } from '../components/Insights/utils';
import OverallPerformance from '../components/Insights/OverallPerformance';
import { sortObjectArrayByField } from '../shared/utils/objectUtils';
import PerformanceSkills from '../components/Insights/PerformanceSkills';
import ChallengesApproach from '../components/Insights/ChallengesApproach';
import { getTasksAttemptsByAssessmentId } from '../queries/customQueries';
import TeamProgressGraphs from '../components/Insights/TeamProgressGraphs';
import { scrollToTop } from '../shared/utils/scroll';

const Insights = ({
  scoreboardData,
  assessmentData,
  loading,
  timeSpentDataForAssessment,
  tasksOpened,
  attemptsData,
  attemptsLoading,
  teamsAssessmentData,
  usersData,
  selectionFromLeaderBoard,
  refreshViewAssessmentQueries,
  assessmentStatus,
}) => {
  const [contestantSelected, setContestantSelected] = useState(undefined);
  const {
    id: assessmentId,
    teamBased,
    teams,
    startDateTime,
    endDateTime,
    multiLevel,
    levels,
    hours,
    minutes,
    tasks: nonLevelTasks,
  } = assessmentData?.getAssessment || {
    id: undefined,
    startDateTime: undefined,
    endDateTime: undefined,
    teamBased: false,
    teams: [],
    multiLevel: false,
    levels: [],
  };
  const tasks = multiLevel
    ? { items: levels?.items?.map((l) => l.tasks?.items)?.flat() }
    : nonLevelTasks || [];

  const { data: serverTime } = useQuery(gql(getSystemTime), {
    fetchPolicy: 'network-only',
  });
  const {
    data: userAssessmentsUserData,
    loading: userAssessmentsUserLoading,
    refetch: refetchListUserAssessmentsByUserId,
  } = useQueryRecursive(gql(listUserAssessmentsByUserId), {
    variables: {
      userId: contestantSelected?.id,
      userAssessmentAssessmentId: { eq: assessmentId },
      limit: 1000,
    },
    skip: !contestantSelected || teamBased,
    staleTime: { minutes: 5 },
  });
  const {
    data: userAssessmentsTeamData,
    loading: userAssessmentsTeamLoading,
    refetch: refetchListUserAssessmentsByTeamId,
  } = useQueryRecursive(gql(listUserAssessmentsByTeamId), {
    variables: {
      teamId: contestantSelected?.id,
      filter: {
        userAssessmentAssessmentId: { eq: assessmentId },
      },
      limit: 1000,
    },
    skip: !contestantSelected || !teamBased,
    staleTime: { minutes: 5 },
  });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsAssessmentData,
    loading: tasksAttemptsAssessmentDataLoading,
    refetch: refetchGetTasksAttemptsByAssessmentId,
  } = useQueryRecursive(gql(getTasksAttemptsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
    skip: !assessmentId,
    staleTime: { minutes: 10 },
  });

  const refreshQueries = () => {
    refreshViewAssessmentQueries();
    refetchListUserAssessmentsByUserId();
    refetchListUserAssessmentsByTeamId();
    refetchGetTasksAttemptsByAssessmentId();
    scrollToTop();
  };

  const userAssessmentsData = teamBased
    ? userAssessmentsTeamData?.listUserAssessmentsByTeamId?.items[0]
    : userAssessmentsUserData?.listUserAssessmentsByUserId?.items[0] || {};
  const userAssessmentStatus = userAssessmentsData?.status;

  const participantStatus = userAssessmentStatus
    ? ['STARTED', 'NOT_STARTED'].includes(userAssessmentStatus) && endDateTime
      ? getCompletedStatus(
          userAssessmentStatus,
          endDateTime,
          'competition',
          serverTime,
        )
      : userAssessmentStatus === 'FINISHED'
        ? 'ENDED'
        : userAssessmentStatus
    : '';

  const allAttempts = scoreboardData?.filter((a) => a?.points > 0) || [];
  const teamOrParticipant = teamBased ? 'Team' : 'Participant';
  const teamIds =
    teams?.items
      ?.filter((t) => t?.status !== 'REMOVED')
      ?.map((t) => t.teamId) || [];

  const allAttemptsTeamsOrParticipants =
    (teamBased
      ? allAttempts
          ?.filter((t) => teamIds.includes(t.teamId))
          ?.map((a) => ({
            ...a,
            userId: a.teamId,
            participant: a.team?.name,
            teamKey: a.team?.key,
            memberCount: a.team?.members?.items?.length || 0,
          }))
      : allAttempts?.map((a) => ({
          ...a,
          participant: a.user?.alias || '',
        }))) || [];
  const allContestants = allAttemptsTeamsOrParticipants
    ?.map((c) => ({
      id: c?.userId,
      name: c?.participant,
      teamKey: c?.teamKey,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const noDataToPresent = allContestants?.length === 0;
  // define rank based on most points
  const rankedRows =
    _.orderBy(
      allAttemptsTeamsOrParticipants,
      ['points', 'firstBloods', 'successRate'],
      ['desc', 'desc', 'desc'],
    )?.map((a, i) => ({ ...a, rank: a.points > 0 ? i + 1 : '-' })) || [];

  // filter for selected contestant
  const selectedContestantData = contestantSelected
    ? rankedRows?.find((a) => a?.userId === contestantSelected?.id)
    : {};
  const scoreboardSummaryData = {
    selectedContestantData,
    participantStatus,
    totalFlags: multiLevel
      ? levels?.items
          ?.map((l) =>
            l.tasks?.items?.map((_task) => ({
              ..._task.task,
            })),
          )
          .flat()?.length
      : tasks?.items?.map((_task) => ({
          ..._task.task,
        }))?.length || 0,
  };

  // team based
  const teamGroups = teamsAssessmentData?.listTeamsByEventId?.items || [];
  const participantsFinished = usersData?.listUsersByAssessmentId?.items || [];

  const allTaskAttempts =
    attemptsData?.listTaskAttemptAggregatesByAssessmentId?.items || [];
  const completedTasks = allTaskAttempts?.filter(
    (a) => a.status === 'COMPLETED',
  );

  const maxDurationInMins =
    !hours && !minutes
      ? differenceInMinutes(new Date(endDateTime), new Date(startDateTime))
      : hours * 60 + minutes;
  const totalPointsScoredInAssessment = completedTasks?.reduce(
    (acc, i) => acc + (i?.points || 0),
    0,
  );
  const totalAssessmentPoints = tasks?.items?.reduce(
    (acc, i) => acc + (i.task?.recommendedPoints || 0),
    0,
  );

  // if team based then populate with teams instead
  const participantsFinishedAssessment = teamBased
    ? teamGroups
        ?.map((t) => ({ userId: t?.teamId, userName: t?.team?.name }))
        .sort(sortObjectArrayByField('userName'))
    : _.flatMap(
        _.groupBy(
          participantsFinished?.map((u) => u?.user) || [],
          'name', // check for duplicates in name
        ),
        (users) =>
          users?.length === 1
            ? [{ userId: users[0]?.id, userName: users[0]?.name }]
            : users.map((user, index) => ({
                userId: user?.id,
                userName: `${user?.name} [${index + 1}]`,
              })),
      ).sort(sortObjectArrayByField('userName'));

  const selectedUserIds = teamBased
    ? teamGroups
        ?.find((tg) => tg.teamId === contestantSelected?.id)
        ?.team?.members?.items?.map((u) => u.userId)
    : [
        participantsFinishedAssessment?.find(
          (u) => u.userId === contestantSelected?.id,
        )?.userId,
      ] || [];

  const userSubmittedData = participantsFinished?.filter(
    (sd) =>
      selectedUserIds?.includes(sd.userId) &&
      ['STARTED', 'FINISHED']?.includes(sd?.status) &&
      sd?.startedOn,
  )[0];

  // all skills in the assessment
  const allSkills = _.chain(
    tasks?.items
      ?.map(({ task }) =>
        task?.skills?.items?.map(({ skill }) => ({
          skillId: skill?.id,
          skillName: skill?.name,
          skillAlias: skill?.alias,
        })),
      )
      ?.flat()
      ?.filter((a) => a?.skillId),
  )
    .uniqBy('skillId')
    .sortBy('skillName')
    ?.map((nc) => {
      const tasksCount =
        tasks?.items?.filter((t) =>
          t.task?.skills?.items?.some(({ skill }) => skill?.id === nc?.skillId),
        )?.length || 0;
      return {
        ...nc,
        caption: `${tasksCount} tasks`,
        tasksCount,
      };
    })
    .value();

  const performanceData = {
    participantsFinished,
    completedTasks,
    allTaskAttempts,
    totalAssessmentPoints,
    maxDurationInMins,
    timeSpentDataForAssessment,
    totalPointsScoredInAssessment,
    teamBased,
    participantsFinishedAssessment,
    teamGroups,
    tasksOpened,
    tasks,
    selectedUserIds,
    individualAttempts: allAttempts,
  };

  // attempts detail based on selected user/team
  const taskAttemptsDetail =
    tasksAttemptsAssessmentData?.listTaskAttemptsByAssessmentId?.items?.filter(
      (ta) => selectedUserIds?.includes(ta.userId),
    ) || [];

  const approachData = {
    startedOn: userSubmittedData?.createdAt,
    eventStartDate: startDateTime,
    eventEndDate: endDateTime,
    allTaskAttempts,
    selectedUserIds,
    taskAttemptsDetail,
    timeSpentDataForAssessment,
    tasks,
    tasksOpened,
    completedTasks,
  };
  const insightsReloading =
    userAssessmentsUserLoading ||
    userAssessmentsTeamLoading ||
    tasksAttemptsAssessmentDataLoading;

  return (
    <Box data-cy="insights-page">
      <FdAlert
        variant="info"
        customIcon={<InfoOutlinedIcon />}
        message={
          <Box className="flex items-center justify-between w-full">
            Data on this page may be delayed by a few minutes from real-time
            updates. For the most current information, please refresh the page
            to fetch the latest data.
            <FdButton
              size="small"
              startIcon={
                <RefreshIcon
                  className={
                    loading || insightsReloading
                      ? 'animate-spin'
                      : 'animate-none'
                  }
                />
              }
              onClick={refreshQueries}
              className="focus:outline-none"
            >
              Refresh this page
            </FdButton>
          </Box>
        }
      />
      {!noDataToPresent && (
        <Box>
          <InsightsSummary
            allContestants={allContestants}
            contestantSelected={contestantSelected}
            setContestantSelected={setContestantSelected}
            loading={loading}
            statusLoading={
              userAssessmentsTeamLoading || userAssessmentsUserLoading
            }
            teamBased={teamBased}
            scoreboardSummaryData={scoreboardSummaryData}
            selectionFromLeaderBoard={selectionFromLeaderBoard}
          />
          {contestantSelected && (
            <Box>
              <FdSkeleton loading={attemptsLoading} height={380}>
                <OverallPerformance
                  performanceData={performanceData}
                  contestantSelected={contestantSelected}
                  teamPoints={selectedContestantData?.points}
                />
              </FdSkeleton>
              <Box>
                <FdSkeleton loading={attemptsLoading} height={380}>
                  <TeamProgressGraphs
                    teamBased={teamBased}
                    attemptsData={allTaskAttempts}
                    tasksData={tasks}
                    selectedUserIds={selectedUserIds}
                    teamGroups={teamGroups}
                    contestantSelected={contestantSelected}
                  />
                </FdSkeleton>
                <FdSkeleton loading={attemptsLoading} height={634}>
                  <PerformanceSkills
                    teamBased={teamBased}
                    performanceData={performanceData}
                    allSkills={allSkills}
                    contestantSelected={contestantSelected}
                  />
                </FdSkeleton>
                <FdSkeleton loading={attemptsLoading} height={634}>
                  <ChallengesApproach
                    teamBased={teamBased}
                    approachData={approachData}
                    contestantSelected={contestantSelected}
                    loading={tasksAttemptsAssessmentDataLoading}
                    assessmentStatus={assessmentStatus}
                  />
                </FdSkeleton>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {!contestantSelected && (
        <Box style={{ maxHeight: '740px', overflow: 'hidden' }}>
          <Box
            className="flex flex-col items-center content-center justify-center"
            style={{ height: '80vh' }}
          >
            <img
              src={NoParticipantSelectedSvg}
              alt="no-participant-selected"
              style={{ height: '150px' }}
            />
            <FdTypography variant="h3" data-cy="insights-message">
              {' '}
              {noDataToPresent
                ? 'Nothing to show'
                : `No ${teamOrParticipant} Selected`}
            </FdTypography>
            <FdTypography
              variant="body1"
              color="secondary"
              data-cy="insights-no-attempts"
            >
              <i>
                {noDataToPresent
                  ? `You can view insights once a ${teamOrParticipant} who has attempted a task completes the Assessment `
                  : `Select a ${teamOrParticipant} at the top right of this page to view their Insights`}
              </i>
            </FdTypography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

Insights.defaultProps = {
  assessmentData: undefined,
  selectionFromLeaderBoard: undefined,
};

Insights.propTypes = {
  assessmentData: PropTypes.shape({ getAssessment: PropTypes.shape({}) }),
  scoreboardData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  timeSpentDataForAssessment: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  attemptsData: PropTypes.shape({
    listTaskAttemptAggregatesByAssessmentId: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  attemptsLoading: PropTypes.bool.isRequired,
  teamsAssessmentData: PropTypes.shape({
    listTeamsByEventId: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  usersData: PropTypes.shape({
    listUsersByAssessmentId: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  selectionFromLeaderBoard: PropTypes.string,
  refreshViewAssessmentQueries: PropTypes.func.isRequired,
  assessmentStatus: PropTypes.string.isRequired,
};

export default Insights;

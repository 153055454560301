import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FdTypography } from '@fifthdomain/fe-shared';
import LevelIndicator from './LevelIndicator';
import LevelIcon from './LevelIcon';
import LevelNumberIcon from './LevelNumberIcon';
import ChallengeFinderIcon from './ChallengeFinder/ChallengeFinderIcon';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
  },
  listItem: {
    background: theme.palette.background.paper,
    marginBottom: '16px',
    height: '70px',
    minWidth: '171px',
    border:
      theme.palette.type === 'dark'
        ? `1px solid ${theme.palette.table.border}`
        : 'none',
    borderRadius: '8px',
  },
  listItemText: {
    wordBreak: 'break-all',
  },
  listIcon: {
    minWidth: '38px',
    paddingLeft: 0,
    marginLeft: 0,
  },
  highlighted: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    paddingLeft: '12px',
  },
  circularShadow: {
    marginLeft: '6px',
    marginRight: '6px',
    position: 'relative',
    display: 'inline-flex',
    color: theme.palette.type === 'light' ? '#CCD5DE' : '#90caf9',
  },
  circularSuccess: {
    color: theme.palette.type === 'light' ? '#4caf50' : '#90caf9',
  },
  successIcon: {
    color: theme.palette.type === 'light' ? '#4caf50' : '#90caf9',
    fontSize: '48px !important',
    paddingLeft: '2px',
  },
}));

const IconWithProgress = ({ percentageComplete }) => {
  const { classes } = useStyles();
  return percentageComplete === 100 ? (
    <Box pr="0.1rem">
      <CheckCircleOutlineIcon className={classes.successIcon} />
    </Box>
  ) : (
    <Box>
      <Box className={classes.circularShadow}>
        <CircularProgress
          variant="determinate"
          value={100}
          color="inherit"
          thickness={5}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            variant="determinate"
            value={percentageComplete}
            className={classes.circularSuccess}
            thickness={5}
          />
        </Box>
      </Box>
    </Box>
  );
};

IconWithProgress.propTypes = {
  percentageComplete: PropTypes.number.isRequired,
};

const LevelSelector = ({ levels, onSelect, customIcons }) => {
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const ListIcon = ({ percentageComplete, level, selected }) =>
    customIcons ? (
      <LevelIcon
        level={level}
        completed={percentageComplete === 100}
        selected={selected}
      />
    ) : (
      <LevelNumberIcon level={level} completed={percentageComplete === 100} />
    );
  ListIcon.propTypes = {
    percentageComplete: PropTypes.number.isRequired,
    level: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
  };

  return (
    <Box height="702px" style={{ overflowY: 'auto' }} ref={containerRef}>
      <List component="nav" disablePadding>
        {levels.map((l) => (
          <ListItem
            className={`${classes.listItem} ${
              l.selected && classes.highlighted
            }`}
            onClick={() => onSelect(l.level)}
            key={shortid.generate()}
          >
            <ListItemIcon className={classes.listIcon}>
              <ListIcon
                level={l.level}
                percentageComplete={l.percentageComplete}
                selected={l.selected}
              />
            </ListItemIcon>
            <ListItemText>
              <LevelIndicator level={l} containerWidth={width} />
            </ListItemText>
            {l?.filteredChallenges !== '' && (
              <Box
                className="flex items-center gap-x-1 rounded-2xl px-1 h-5"
                style={{
                  backgroundColor:
                    l?.filteredChallenges === 0
                      ? theme?.fdProColors?.alert?.defaultDark
                      : theme?.palette?.autoComplete?.chipBackground,
                  color: theme?.palette?.autoComplete?.chipColor,
                }}
              >
                <ChallengeFinderIcon className="w-3 h-3" />
                <FdTypography
                  variant="subtitle2"
                  style={{
                    fontWeight: 500,
                    color: theme?.palette?.autoComplete?.chipColor,
                  }}
                >
                  {l?.filteredChallenges}
                </FdTypography>
              </Box>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

LevelSelector.defaultProps = {
  customIcons: false,
};

LevelSelector.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.number,
      percentageComplete: PropTypes.number,
      selected: false,
    }),
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  customIcons: PropTypes.bool,
};

export default LevelSelector;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { getUrl, uploadData } from 'aws-amplify/storage';
import { gql, useLazyQuery } from '@apollo/client';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  FdCard,
  FdTypography,
  FdButton,
  FdExternalLink,
} from '@fifthdomain/fe-shared';
import { downloadVPNConfigureFile } from '../../../graphql/queries';
import { successToastMessage } from '../../../shared/utils/toast';

const VPNConfiguration = ({ orgId, userAssessmentId, assessmentId }) => {
  const [isVPNdownload, setIsVPNdownload] = useState(false);
  const [isVPNCopying, setIsVPNCopying] = useState(false);
  const [downloadVPNConfig] = useLazyQuery(gql(downloadVPNConfigureFile));

  return (
    <FdCard variant="outlined" className="w-full">
      <FdTypography variant="subtitle1">VPN Configuration</FdTypography>
      <Box className="flex items-center my-3 gap-x-2">
        <FdButton
          startIcon={<DownloadIcon />}
          disabled={!!isVPNdownload}
          onClick={async () => {
            setIsVPNdownload(true);
            downloadVPNConfig({
              variables: {
                userAssessmentId,
                assessmentId,
                orgId,
              },
              onCompleted: (data) => {
                const text = atob(data.downloadVPNConfigureFile);
                const blob = new Blob([text], {
                  type: 'text/plain',
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `user-config-${assessmentId}.ovpn`;
                a.click();
                URL.revokeObjectURL(url);
                setIsVPNdownload(false);
              },
            });
          }}
        >
          {isVPNdownload ? 'Waiting...' : 'Download VPN Configuration File'}
        </FdButton>
        <FdTypography variant="body1">or</FdTypography>
        <FdButton
          startIcon={<ContentCopyIcon />}
          disabled={!!isVPNCopying}
          onClick={async () => {
            setIsVPNCopying(true);
            const key = `user-config-${assessmentId}-fd.ovpn`;
            const currentDate = new Date();
            const expiryDate = new Date(currentDate);
            expiryDate.setHours(currentDate.getHours() + 24);

            try {
              await getUrl({
                path: ({ identityId }) => `private/${identityId}/${key}`,
                options: {
                  cacheControl: 'no-cache',
                  validateObjectExistence: true,
                },
              });

              const userConfigLink = await getUrl({
                path: ({ identityId }) => `private/${identityId}/${key}`,
                options: {
                  cacheControl: 'no-cache',
                },
              });

              navigator.clipboard.writeText(userConfigLink.url).then(() => {
                successToastMessage(
                  'VPN Configuration link copied successfully.',
                );
              });

              setIsVPNCopying(false);
            } catch (error) {
              downloadVPNConfig({
                variables: {
                  userAssessmentId,
                  assessmentId,
                  orgId,
                },
                onCompleted: async (data) => {
                  const text = atob(data.downloadVPNConfigureFile);

                  const expiryDate48 = new Date(currentDate);
                  expiryDate48.setHours(currentDate.getHours() + 48);

                  uploadData({
                    path: ({ identityId }) => `private/${identityId}/${key}`,
                    data: text,
                    options: {
                      cacheControl: 'no-cache',
                      expires: expiryDate48,
                    },
                  });

                  const userConfigLink = await getUrl({
                    path: ({ identityId }) => `private/${identityId}/${key}`,
                    options: {
                      cacheControl: 'no-cache',
                    },
                  });

                  navigator.clipboard.writeText(userConfigLink.url).then(() => {
                    successToastMessage(
                      'VPN Configuration link copied successfully.',
                    );
                  });

                  setIsVPNCopying(false);
                },
              });
            }
          }}
        >
          {isVPNCopying ? 'Waiting...' : 'Copy VPN Configuration Link'}
        </FdButton>
      </Box>
      <FdTypography variant="body2">
        You must use the provided VPN file on your workstation or jump box to
        securely connect to the lab virtual machines(VMs) in each challenge. You
        can either download the VPN configuration file directly or copy the
        download URL to into the jumpbox or elsewhere.
        <FdExternalLink
          href="https://au.intercom.help/fifth-domain/en/articles/2396-using-vpn-in-a-competition"
          noUnderline
        >
          Click here
        </FdExternalLink>
        to learn more about using VPN configuration file.
      </FdTypography>
    </FdCard>
  );
};

VPNConfiguration.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  userAssessmentId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default VPNConfiguration;

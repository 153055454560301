import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdSelect } from '@fifthdomain/fe-shared';
import { TASK_ACTIVITY } from '../../../constants';

const ActivitySelector = ({ activity, onActivityChange }) => (
  <Box mt={1} mb={2}>
    <FdSelect
      id="taskActivity"
      defaultSelected={activity}
      options={TASK_ACTIVITY.map((t) => t.desc)}
      onChange={onActivityChange}
      placeholder="Select Activity"
      data-cy="task-activity"
    />
  </Box>
);

ActivitySelector.propTypes = {
  activity: PropTypes.string.isRequired,
  onActivityChange: PropTypes.func.isRequired,
};

export default ActivitySelector;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdCard, FdTypography, FdAlert } from '@fifthdomain/fe-shared';
import ProficiencyApproachTable from './ProficiencyApproachTable';

const ChallengesApproach = ({
  teamBased,
  approachData,
  loading,
  assessmentStatus,
}) => {
  const {
    allTaskAttempts,
    selectedUserIds,
    taskAttemptsDetail,
    startedOn,
    tasksOpened,
    tasks,
    completedTasks,
    eventStartDate,
    eventEndDate,
  } = approachData;

  const allTaskAttemptsUserWise = allTaskAttempts?.filter((at) =>
    selectedUserIds?.includes(at.userId),
  );

  return (
    <Box mt={2}>
      <FdCard heading="Challenges Approach" variant="outlined">
        <Box>
          <FdTypography variant="subtitle2" style={{ marginTop: '-20px' }}>
            {`How did the ${
              teamBased ? 'teams' : 'participants'
            } plan their time?`}
          </FdTypography>
          <FdTypography variant="captiontext1" color="secondary">
            {teamBased
              ? "This visualisation offers valuable insights into the team's journey, shedding light on their endeavor by showcasing a chronological display of when they viewed a challenge, when they entered it, when and how many times they attempted it and when they solved it. This captures the team's allocation of time across each attempted challenge, providing a nuanced perspective on their approach."
              : "This visualisation offers valuable insights into the Participant's journey, shedding light on their endeavor by showcasing a chronological display of when they viewed a challenge, when they entered it, when and how many times they attempted it and when they solved it. This captures the participant's allocation of time across each attempted challenge, providing a nuanced perspective on their approach."}
          </FdTypography>
        </Box>
        <FdAlert
          customIcon={<InfoOutlinedIcon />}
          variant="info"
          message={
            <FdTypography variant="body2">
              Click &apos;View&apos; to see challenge summary and other details.
            </FdTypography>
          }
          className="my-4"
        />
        <ProficiencyApproachTable
          taskAttempts={allTaskAttemptsUserWise}
          taskAttemptsDetail={taskAttemptsDetail}
          startDateTime={startedOn}
          loading={loading}
          tasks={tasks}
          completedTasks={completedTasks}
          selectedUserIds={selectedUserIds}
          teamBased={teamBased}
          tasksOpened={tasksOpened}
          assessmentStatus={assessmentStatus}
          eventStartDate={eventStartDate}
          eventEndDate={eventEndDate}
        />
      </FdCard>
    </Box>
  );
};

ChallengesApproach.propTypes = {
  teamBased: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  approachData: PropTypes.shape({
    allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})),
    selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})),
    taskAttemptsDetail: PropTypes.arrayOf(PropTypes.shape({})),
    startedOn: PropTypes.string,
    timeSpentDataForAssessment: PropTypes.string,
    tasksOpened: PropTypes.arrayOf(PropTypes.shape({})),
    tasks: PropTypes.arrayOf(PropTypes.shape({})),
    completedTasks: PropTypes.arrayOf(PropTypes.shape({})),
    eventStartDate: PropTypes.string,
    eventEndDate: PropTypes.string,
  }).isRequired,
  assessmentStatus: PropTypes.string.isRequired,
};

export default ChallengesApproach;

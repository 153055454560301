import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { FdTypography } from '@fifthdomain/fe-shared';

const ProgressBar = withStyles(LinearProgress, () => ({
  root: {
    height: 8,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
}));

const ProgressBarBlue = withStyles(ProgressBar, () => ({
  colorPrimary: {
    backgroundColor: '#ACBED3',
  },
  bar: {
    backgroundColor: '#6C99FF',
  },
}));

const ProgressBarGreen = withStyles(ProgressBar, () => ({
  colorPrimary: {
    backgroundColor: '#B7CCB8',
  },
  bar: {
    backgroundColor: '#2E7D32',
  },
}));

const ProgressBarPurple = withStyles(ProgressBar, () => ({
  colorPrimary: {
    backgroundColor: '#D9B3CF',
  },
  bar: {
    backgroundColor: '#B61D8D',
  },
}));

const ProgressBarWithTitle = ({ color, value, caption, title }) => {
  let ProgressBarType;
  switch (color) {
    case 'blue':
      ProgressBarType = ProgressBarBlue;
      break;
    case 'green':
      ProgressBarType = ProgressBarGreen;
      break;
    case 'purple':
      ProgressBarType = ProgressBarPurple;
      break;
    default:
      ProgressBarType = ProgressBarBlue;
      break;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        {title && (
          <FdTypography variant="subtitle2" color="secondary">
            {title}
          </FdTypography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Box width="128px" mr={1}>
          <ProgressBarType variant="determinate" value={value} />
        </Box>
        <Box width="35px" ml={1}>
          <FdTypography variant="captiontext1" color="textSecondary">
            {caption}
          </FdTypography>
        </Box>
      </Box>
    </Box>
  );
};

ProgressBarWithTitle.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  caption: PropTypes.string.isRequired,
  title: PropTypes.string,
};

ProgressBarWithTitle.defaultProps = {
  color: 'blue',
  title: '',
};

export default ProgressBarWithTitle;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const FdTextView = ({ label, value, hideOnEmpty, ...props }) => {
  const hide = hideOnEmpty && !value;
  return (
    !hide && (
      <Box my={1} {...props}>
        <FdTypography variant="subtitle1">{label}</FdTypography>
        <FdTypography variant="body1" color="secondary">
          {value}
        </FdTypography>
      </Box>
    )
  );
};

FdTextView.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  hideOnEmpty: PropTypes.bool,
};

FdTextView.defaultProps = {
  label: '',
  value: '',
  hideOnEmpty: false,
};

export default FdTextView;

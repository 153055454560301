import React from 'react';
import { Box } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { FdAccordion, FdTypography, FdButton } from '@fifthdomain/fe-shared';

const BrowseCatalog = () => {
  return (
    <Box mt={2}>
      <FdAccordion
        variant="info"
        startAdornmentIcon={() => <ShoppingCartIcon />}
        summary={() => (
          <FdTypography variant="subtitle1">
            Need more challenges for this competition?
          </FdTypography>
        )}
        endAdornment
        showToggleButton={false}
        content={() => (
          <Box>
            <FdTypography variant="body2">
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                Explore the FifthDomain Challenges Store! Click the “Browse
                Store” button below to access more challenges to add to your
                organisation’s library (this will open a new browser tab). Once
                you have leased new challenges, click the “Refresh Challenges”
                button in the Challenges table to update it with any new leases.
              </span>
            </FdTypography>
            <Box mt={2}>
              <FdButton
                onClick={() =>
                  window.open(
                    `${window.location.origin}/org/content-lease-catalogue`,
                    '_blank',
                  )
                }
              >
                Browse Store
              </FdButton>
            </Box>
          </Box>
        )}
        open
      />
    </Box>
  );
};

export default BrowseCatalog;

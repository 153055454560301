import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import { addOrdinalSuffix } from '../../shared/utils/stringUtils';
import { CHAMPION_LEVELS } from '../../constants';
import TrophyBadge from './images/tiers/TrophyBadge';
import BadgeContainer from './BadgeContainer';

const LabelValue = ({ label, value }) => (
  <Box className="flex gap-x-2">
    <FdTypography variant="captiontext2" color="secondary">
      {label}
    </FdTypography>
    <FdTypography
      variant="captiontext2"
      color="secondary"
      style={{ fontWeight: 600 }}
    >
      {value}
    </FdTypography>
  </Box>
);

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const TrophyVerticalTile = ({ tile }) => {
  const badgeLevels = tile?.badgeLevels;

  return (
    <Box className="flex items-center my-2" style={{ minHeight: '343px' }}>
      <Box className="flex flex-col items-center justify-around w-full gap-y-4">
        {CHAMPION_LEVELS.map((item) => {
          const badge =
            badgeLevels?.find((bl) => bl?.level === item?.value) || {};
          const totalEventsCount = badge?.championEvents?.length || 0;
          const teamCompetitionsCount =
            badge?.championEvents?.filter((e) => e?.assessment?.teamBased)
              ?.length || 0;
          const individualCompetitionsCount =
            totalEventsCount - teamCompetitionsCount;
          const status = totalEventsCount > 0 ? 'unlocked' : 'locked';

          return (
            <Box className="flex items-center justify-center gap-x-6">
              <Box key={item} className="flex items-center justify-center">
                <BadgeContainer size="60px">
                  <TrophyBadge
                    type={tile?.specialty}
                    tier={item}
                    status={status}
                  />
                </BadgeContainer>
              </Box>
              <Box className="flex flex-col my-3">
                <FdTypography variant="subtitle1" style={{ width: '70px' }}>
                  {item.name}
                </FdTypography>
                <FdTypography variant="captiontext2" color="secondary">
                  {`${addOrdinalSuffix(item.value)} Place`}
                </FdTypography>
              </Box>
              <Box className="flex flex-col gap-y-1">
                <LabelValue
                  label="Individual Competitions:"
                  value={individualCompetitionsCount}
                />
                <LabelValue
                  label="Team Competitions:"
                  value={teamCompetitionsCount}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

TrophyVerticalTile.propTypes = {
  tile: PropTypes.shape({
    specialty: PropTypes.string,
    badgeLevels: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  section: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default TrophyVerticalTile;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdExternalLink } from '@fifthdomain/fe-shared';
import useGetProfileLink from '../../hooks/useGetProfileLink';

const ProfileHyperlink = ({ userId, children, isParticipant }) => {
  const profileLink = useGetProfileLink(userId);

  return (
    <Box display="flex" mr={2}>
      {profileLink ? (
        <FdExternalLink href={!isParticipant && profileLink}>
          {children}
        </FdExternalLink>
      ) : (
        children
      )}
    </Box>
  );
};

ProfileHyperlink.propTypes = {
  userId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isParticipant: PropTypes.bool,
};

ProfileHyperlink.defaultProps = {
  isParticipant: false,
};

export default ProfileHyperlink;

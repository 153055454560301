import { useMutation } from '@fifthdomain/fe-shared';
import { addAIChatMessage } from '../shared/utils/chatUtils';

const useGetAIChatAddMessage = ({ onUpdate }) => {
  const addMessageMutation = useMutation({
    mutationFn: ({ message, userAssessmentId, taskId, images }) =>
      addAIChatMessage({
        message,
        userAssessmentId,
        taskId,
        images,
        onMessageChunk: (updatedMessage) => {
          onUpdate({
            ...updatedMessage,
            content: [...updatedMessage.content],
          });
        },
      }),
  });

  return {
    ...addMessageMutation,
  };
};

export default useGetAIChatAddMessage;

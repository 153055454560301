/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCourseUsers = /* GraphQL */ `
  query ListCourseUser(
    $filter: ModelCourseUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courseId
        messages(filter: { seen: { eq: FALSE } }) {
          items {
            id
            message
            userCourseId
            sender {
              id
              type
            }
            createdAt
          }
        }
        modulePartProgresses {
          items {
            id
            status
          }
        }
        course {
          orgId
          user {
            id
            org {
              name
            }
          }
          name
          description
          status
          availability
          categoryId
          category {
            name
          }
          image {
            bucket
            region
            key
          }
          available
          ownerId
          courseModules {
            items {
              id
              name
              description
              orderNumber
              parts {
                items {
                  id
                  name
                  description
                  type
                  file {
                    key
                    region
                    bucket
                  }
                  labId
                  lab {
                    id
                    name
                    description
                    provider
                    status
                    userId
                    public
                    shared
                    payload
                    orgId
                    createdAt
                    updatedAt
                  }
                  quiz {
                    name
                    duration
                  }
                  duration
                  expiry
                  inactivityExpiry
                  initialLabCount
                  minLabCount
                }
              }
            }
            nextToken
          }
          courseUsers {
            items {
              id
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
        userId
        orgId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        courseProgress
        id
        createdAt
        updatedAt
        courseCourseUsersId
      }
      nextToken
    }
  }
`;

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      preMessage
      postMessage
      createdBy
      participantEventType
      createdAt
      updatedAt
      status
      announcements {
        items {
          id
          userId
          user {
            id
            alias
          }
          messages {
            content
            type
          }
          likesCount
          reactions {
            items {
              id
              userId
              reactionType
            }
          }
          createdAt
          updatedAt
        }
      }
      chatForums {
        items {
          id
          userId
          user {
            id
            alias
          }
          role
          messages {
            content
            type
          }
          createdAt
          updatedAt
        }
      }
      teamBased
      teams {
        items {
          team {
            name
            key
            members {
              items {
                userId
              }
            }
          }
        }
      }
    }
  }
`;

export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      assessment {
        id
        orgId
        name
        teamBased
        startDateTime
        endDateTime
        enableVPN
        preMessage
        postMessage
        participantEventType
        description
      }
      userAssessmentAssessmentId
      groupId
      teamId
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          name
          orgId
          alias
        }
        assessment {
          id
          orgId
          creator {
            org {
              name
            }
          }
          teamBased
          startDateTime
          endDateTime
          participantEventType
          tasks {
            items {
              id
              task {
                id
                recommendedPoints
                attempts(filter: { success: { eq: true } }) {
                  items {
                    id
                    success
                    assessmentId
                    taskAttemptUserAssessmentId
                    taskAttemptTaskId
                    task {
                      id
                      recommendedPoints
                    }
                  }
                }
              }
            }
          }
        }
        userAssessmentAssessmentId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listLabInstancesByModulePartId = /* GraphQL */ `
  query ListLabInstancesByModulePartId(
    $modulePartId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstancesByModulePartId(
      modulePartId: $modulePartId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labPrototypeId
        status
        modulePartId
        userId
        userAssessmentId
        expiry
        deletedAt
        createdAt
        updatedAt
        modulePartLabInstancesId
        vms {
          items {
            id
            name
            labPrototypeVmId
            vpn
            vdi
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          externalId
          category
          difficulty
          recommendedPoints
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
          user {
            name
            id
          }
          assessment {
            name
            hours
            minutes
            tasks {
              items {
                taskId
              }
            }
          }
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        createdAt
        updatedAt
        taskAttemptTaskId
      }
      nextToken
    }
  }
`;
export const getVmConsole = /* GraphQL */ `
  query GetVmConsole($vmId: ID!, $type: INSTANCE_TYPE!) {
    getVmConsole(vmId: $vmId, type: $type) {
      url
      __typename
    }
  }
`;
export const getLabTime = /* GraphQL */ `
  query GetLabTime($labInstanceId: ID!) {
    getLabTime(labInstanceId: $labInstanceId) {
      timeInLab
      timeRemaining
      __typename
    }
  }
`;
export const getLabPrototype = /* GraphQL */ `
  query GetLabPrototype($id: ID!) {
    getLabPrototype(id: $id) {
      id
      name
      provider
      status
      userId
      public
      shared
      payload
      orgId
      createdAt
      updatedAt
    }
  }
`;
export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          teamId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;

export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          orgId
          alias
          verifiedOnboarding
          status
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAssessmentsByOrg = /* GraphQL */ `
  query ListAssessmentsByOrg(
    $orgId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        status
        hours
        minutes
        preMessage
        postMessage
        createdBy
        participantEventType
        users {
          items {
            id
            userId
            userAssessmentAssessmentId
            groupId
            teamId
            status
            startedOn
            finishedOn
            createdAt
            updatedAt
            user {
              id
              email
              type
            }
          }
          nextToken
        }
        availabilityType
        creator {
          id
          name
          orgId
          alias
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          orgId
          alias
          status
        }
        assessment {
          id
          orgId
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
          specialThemes {
            items {
              themeId
              theme {
                name
              }
            }
          }
        }
        verifiedOnboarding
        status
        permissions
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        orgId
        alias
        org {
          id
          name
        }
        verifiedOnboarding
        status
        permissions
      }
      nextToken
    }
  }
`;
export const listOnboardingsByUserId = /* GraphQL */ `
  query ListOnboardingsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          orgId
          alias
          verifiedOnboarding
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByAssessmentId = /* GraphQL */ `
  query ListInvitedUsersByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskActivities = /* GraphQL */ `
  query ListTaskActivities(
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activity
        userId
        assessmentId
        taskId
      }
      nextToken
    }
  }
`;
export const listTaskActivitiesByTeamId = /* GraphQL */ `
  query ListTaskActivitiesByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivitiesByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activity
        userId
        assessmentId
        taskId
        groupId
        teamId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupMembersByGroupId = /* GraphQL */ `
  query ListGroupMembersByGroupId($groupId: ID!, $orgId: ID) {
    listGroupMembersByGroupId(groupId: $groupId, orgId: $orgId) {
      id
      orgId
      alias
    }
  }
`;

export const listTasksByOrgId = /* GraphQL */ `
  query ListTasksByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        labId
        lab {
          id
          name
          vms {
            items {
              id
            }
          }
          createdAt
          updatedAt
        }
        description
        summary
        type
        labId
        lab {
          name
        }
        externalId
        orgId
        org {
          id
          name
        }
        category
        specialtyId
        specialty {
          name
          skills {
            items {
              id
              name
            }
          }
        }
        skills {
          items {
            skillId
            skill {
              id
              name
              alias
            }
            techniqueTags {
              items {
                techniqueTag {
                  name
                }
              }
            }
          }
        }
        technologyTags {
          items {
            technologyTag {
              name
            }
          }
        }
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          items {
            area {
              areaName
            }
          }
        }
        answer
        files {
          items {
            id
            name
            type
            url
            size
          }
        }
        hints {
          items {
            id
            taskId
            text
          }
        }
        tags(filter: { orgId: { eq: $orgId } }) {
          items {
            Tag {
              color
              id
              name
              orgId
            }
            updatedAt
          }
        }
        userId
        user {
          id
          name
          alias
          org {
            id
            name
          }
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          duration
          expiry
          inactivityExpiry
          initialLabCount
          minLabCount
          labId
          lab {
            name
            status
          }
          url
          orderNumber
          status
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskOrgs = /* GraphQL */ `
  query ListTaskOrgs($limit: Int, $nextToken: String, $orgId: ID) {
    listTaskOrgs(
      filter: { orgId: { eq: $orgId } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        orgId
        task {
          id
          name
          description
          summary
          type
          externalId
          labId
          lab {
            id
            name
            vms {
              items {
                id
              }
            }
            createdAt
            updatedAt
          }
          orgId
          org {
            id
            name
          }
          category
          specialtyId
          specialty {
            name
            skills {
              items {
                id
                name
              }
            }
          }
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
              techniqueTags {
                items {
                  techniqueTag {
                    name
                  }
                }
              }
            }
          }
          technologyTags {
            items {
              technologyTag {
                name
              }
            }
          }
          difficulty
          competencies {
            items {
              area {
                areaName
              }
            }
          }
          hints {
            items {
              id
              taskId
              text
            }
          }
          tags(filter: { orgId: { eq: $orgId } }) {
            items {
              Tag {
                color
                id
                name
                orgId
              }
              updatedAt
            }
          }
          files {
            items {
              id
              name
              type
              url
              size
            }
          }
          recommendedPoints
          estimatedSolveTime
          answer
          userId
          user {
            id
            name
            org {
              id
              name
            }
          }
          modulePartId
          modulePart {
            name
            labId
            expiry
            lab {
              name
              status
            }
          }
          status
          createdAt
          updatedAt
        }
        org {
          id
          name
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProductsByOrgId = /* GraphQL */ `
  query ListProductsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        product {
          id
          name
        }
      }
    }
  }
`;

export const listScoreboards = /* GraphQL */ `
  query ListScoreboards(
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          alias
        }
        teamId
        groupId
        group {
          name
        }
        flags
        firstBloods
        points
        successRate
        assessmentId
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAffliationsByUserId = /* GraphQL */ `
  query ListAffliationsByUserId(
    $userId: ID!
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByUserId(
      userId: $userId
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        status
        createdAt
        updatedAt
        customerWorkroleID
        customerWorkrole {
          name
        }
      }
      nextToken
    }
  }
`;
export const listFdSkillsByCustomerWorkRoleId = /* GraphQL */ `
  query ListFdSkillsByCustomerWorkRoleId(
    $filter: ModelCustomerSkillFdSkillFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $customerWorkroleId: ID!
  ) {
    listFdSkillsByCustomerWorkRoleId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      customerWorkroleId: $customerWorkroleId
    ) {
      items {
        id
        skillId
        skill {
          id
          name
          alias
        }
        customerWorkroleId
      }
      nextToken
    }
  }
`;
export const listTaskAttemptAggregates = /* GraphQL */ `
  query ListTaskAttemptAggregates(
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        teamId
        assessmentId
        taskId
        levels
        status
        points
        userAssessmentId
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScoreboard = /* GraphQL */ `
  query GetScoreboard($id: ID!) {
    getScoreboard(id: $id) {
      id
      userId
      user {
        id
        email
        name
        orgId
        alias
        org {
          id
          name
          maxInvitees
          remainingInvitees
        }
        theme
        createdAt
        updatedAt
      }
      teamId
      groupId
      flags
      firstBloods
      points
      successRate
      assessmentId
      assessment {
        id
        orgId
        name
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        multiLevel
        createdBy
        participantEventType
        creator {
          id
          email
          name
          orgId
          alias
          theme
        }
        hideScoreBoard
        showPostAssessmentReflection
        status
      }
      createdAt
      updatedAt
    }
  }
`;

export const getLabInstance = /* GraphQL */ `
  query GetLabInstance($id: ID!) {
    getLabInstance(id: $id) {
      id
      labPrototypeId
      status
      modulePartId
      userId
      userAssessmentId
      expiry
      deletedAt
      createdAt
      updatedAt
      modulePartLabInstancesId
    }
  }
`;
export const listJumpboxInstancesByUserAssessmentId = /* GraphQL */ `
  query ListJumpboxInstancesByUserAssessmentId(
    $userAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJumpboxInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJumpboxInstancesByUserAssessmentId(
      userAssessmentId: $userAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jumpboxPrototypeId
        status
        userId
        userAssessmentId
        failureReason
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const queryTemplatesByStatus = /* GraphQL */ `
  query QueryTemplatesByStatus(
    $status: TEMPLATE_STATUS!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTemplatesByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        tasks {
          items {
            taskId
            task {
              id
              name
              type
              description
              summary
              externalId
              orgId
              org {
                name
              }
              category
              solution
              answer
              difficulty
              specialtyId
              specialty {
                id
                name
                description
              }
              skills {
                items {
                  id
                  skillId
                  skill {
                    id
                    name
                  }
                  techniqueTags {
                    items {
                      techniqueTag {
                        name
                      }
                    }
                  }
                }
                nextToken
              }
              technologyTags {
                items {
                  technologyTagID
                  technologyTag {
                    id
                    name
                  }
                }
              }
              attempts(filter: { success: { eq: true } }) {
                items {
                  id
                }
              }
              competencies {
                items {
                  workRole {
                    area {
                      areaName
                    }
                  }
                }
              }
              hints {
                items {
                  id
                  taskId
                  text
                }
              }
              tags {
                items {
                  Tag {
                    color
                    id
                    name
                    orgId
                  }
                  updatedAt
                }
              }
              files {
                items {
                  id
                  name
                  type
                  url
                  size
                }
              }
              userId
              user {
                id
                name
                type
                orgId
              }
              recommendedPoints
              estimatedSolveTime
              answer
              userId
              modulePartId
              labId
              lab {
                id
                name
                vms {
                  items {
                    id
                  }
                }
                createdAt
                updatedAt
              }
              status
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskAttemptsByAssessmentId = /* GraphQL */ `
  query ListTaskAttemptsByAssessmentId(
    $assessmentId: ID!
    $taskAttemptTaskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptsByAssessmentId(
      assessmentId: $assessmentId
      taskAttemptTaskId: $taskAttemptTaskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        teamId
        task {
          id
          name
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          status
          createdAt
          updatedAt
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
              }
            }
          }
        }
        taskAttemptTaskId
        userAssessment {
          id
          userId
          user {
            alias
            id
            name
          }
          userAssessmentAssessmentId
          teamId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        levelId
        level {
          id
          assessmentId
          name
          levelNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listScoreboardsByAssessmentId = /* GraphQL */ `
  query ListScoreboardsByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboardsByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          alias
        }
        teamId
        team {
          name
          key
          members {
            items {
              id
            }
          }
        }
        groupId
        flags
        firstBloods
        points
        successRate
        assessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskAttemptAggregatesByAssessmentId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByAssessmentId(
    $assessmentId: ID!
    $taskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByAssessmentId(
      assessmentId: $assessmentId
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        taskId
        task {
          id
          name
          difficulty
          recommendedPoints
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
                alias
              }
            }
          }
        }
        userAssessmentId
        userAssessment {
          userId
        }
        status
        points
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskAttemptAggregatesByStatus = /* GraphQL */ `
  query ListTaskAttemptAggregatesByStatus(
    $status: TASK_ATTEMPT_STATUS!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByStatus(
      status: $status
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        teamId
        assessmentId
        taskId
        levels
        status
        points
        userAssessmentId
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listScoreboardsByUserId = /* GraphQL */ `
  query ListScoreboardsByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboardsByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          alias
        }
        teamId
        groupId
        group {
          id
          name
        }
        flags
        firstBloods
        points
        successRate
        assessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listScoreboardsByTeamId = /* GraphQL */ `
  query ListScoreboardsByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboardsByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          alias
        }
        teamId
        team {
          id
          name
        }
        flags
        firstBloods
        points
        successRate
        assessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const downloadVPNConfigureFile = /* GraphQL */ `
  query DownloadVPNConfigureFile(
    $userAssessmentId: ID!
    $assessmentId: ID!
    $orgId: ID!
  ) {
    downloadVPNConfigureFile(
      userAssessmentId: $userAssessmentId
      assessmentId: $assessmentId
      orgId: $orgId
    )
  }
`;
export const listVPNVMsByLabInstanceId = /* GraphQL */ `
  query ListVPNVMsByLabInstanceId(
    $labInstanceId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVPNVMFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVPNVMsByLabInstanceId(
      labInstanceId: $labInstanceId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        labInstanceVmId
        externalIp
        labInstanceId
        assessmentId
        userAssessmentId
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        private
        members {
          items {
            id
            role
            userId
            email
            User {
              name
              alias
            }
            createdAt
          }
        }
        creator {
          orgId
        }
      }
      nextToken
    }
  }
`;
export const listTeamsByEventId = /* GraphQL */ `
  query ListTeamsByEventId(
    $filter: ModelTeamEventFilterInput
    $limit: Int
    $nextToken: String
    $eventId: ID!
    $teamId: ModelIDKeyConditionInput
  ) {
    listTeamsByEventId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      eventId: $eventId
      teamId: $teamId
    ) {
      items {
        id
        teamId
        team {
          id
          name
          members {
            items {
              id
              role
              userId
              email
              User {
                alias
              }
            }
          }
        }
        event {
          endDateTime
        }
        status
        finishedOn
        startedOn
      }
      nextToken
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      key
      members {
        items {
          id
          role
          userId
          User {
            id
            name
            alias
          }
          createdAt
        }
      }
    }
  }
`;
export const listUserAssessmentsByUserId = /* GraphQL */ `
  query ListUserAssessmentsByUserId(
    $userId: ID!
    $userAssessmentAssessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentsByUserId(
      userId: $userId
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userAssessmentAssessmentId
        groupId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserAssessmentsByTeamId = /* GraphQL */ `
  query ListUserAssessmentsByTeamId(
    $teamId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessmentsByTeamId(
      teamId: $teamId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userAssessmentAssessmentId
        groupId
        teamId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskOpenedsByAssessmentId = /* GraphQL */ `
  query ListTaskOpenedsByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpenedsByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        task {
          id
          name
          orgId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          userId
          modulePartId
          labId
          status
          type
          public
          specialtyId
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
                alias
              }
            }
          }
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        assessmentId
        assessment {
          id
          orgId
          teamBased
          startDateTime
          endDateTime
          multiLevel
          createdBy
          participantEventType
          hideScoreBoard
          status
          availabilityType
          enableJumpbox
          modulePartId
        }
        groupId
        levelId
        level {
          id
          assessmentId
          name
          levelNumber
        }
        startedSolving
        startedSolvingAt
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;

export const listTaskAttemptAggregatesByUserId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        assessmentId
        status
        attempts
        points
        task {
          id
          specialtyId
          specialty {
            name
          }
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskAttemptAggregatesByTeamId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        status
        attempts
        userId
        points
        task {
          id
          specialtyId
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        specialtyId
        specialty {
          name
        }
      }
      nextToken
    }
  }
`;
export const listUserScoreboardsByTeamId = /* GraphQL */ `
  query ListUserScoreboardsByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboardsByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserScoreboardsByAssessmentId = /* GraphQL */ `
  query ListUserScoreboardsByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboardsByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserScoreboardsByUserId = /* GraphQL */ `
  query ListUserScoreboardsByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboardsByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserScoreboards = /* GraphQL */ `
  query ListUserScoreboards(
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
        assessment {
          orgId
        }
      }
      nextToken
    }
  }
`;
export const listTaskOpenedsByUserAssessmentId = /* GraphQL */ `
  query ListTaskOpenedsByUserAssessmentId(
    $taskOpenedUserAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpenedsByAssessmentId(
      assessmentId: $taskOpenedUserAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        userId
        task {
          id
          name
          specialty {
            name
          }
          skills {
            items {
              skill {
                id
                name
              }
            }
          }
        }
        taskOpenedUserAssessmentId
        assessmentId
        levelId
        startedSolving
        startedSolvingAt
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;

export const listChallengesRatingsByUserAssessmentId = /* GraphQL */ `
  query ListChallengesRatingsByUserAssessmentId(
    $userAssessmentId: ID!
    $taskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengesRatingsByUserAssessmentId(
      userAssessmentId: $userAssessmentId
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        assessmentId
        userAssessmentId
        taskId
        ratings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTagsByAssessmentId = /* GraphQL */ `
  query ListTagsByAssessmentId(
    $assessmentId: ID!
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagsByAssessmentId(
      assessmentId: $assessmentId
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        assessmentId
        color
        createdAt
        description
        orgId
        updatedAt
        userId
      }
      nextToken
    }
  }
`;

export const listUserEventTagsByUserAssessmentId = /* GraphQL */ `
  query ListUserEventTagsByUserAssessmentId(
    $userAssessmentId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserEventTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEventTagsByUserAssessmentId(
      userAssessmentId: $userAssessmentId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentId
        userAssessmentId
        userId
        eventTagId
        createdAt
        eventTag {
          id
          name
          color
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTeamEventTagsByTeamEventId = /* GraphQL */ `
  query ListTeamEventTagsByTeamEventId(
    $teamEventId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamEventTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamEventTagsByTeamEventId(
      teamEventId: $teamEventId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentId
        teamEventId
        applied
        eventTagId
        createdAt
        eventTag {
          id
          name
          color
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserEventTagsByAssessmentId = /* GraphQL */ `
  query ListUserEventTagsByAssessmentId(
    $assessmentId: ID!
    $filter: ModelUserEventTagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $userAssessmentId: ModelIDKeyConditionInput
  ) {
    listUserEventTagsByAssessmentId(
      assessmentId: $assessmentId
      userAssessmentId: $userAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentId
        eventTag {
          id
          name
          color
        }
        eventTagId
        createdAt
        updatedAt
        userAssessmentId
        userId
      }
      nextToken
    }
  }
`;

export const listTeamEventTagsByAssessmentId = /* GraphQL */ `
  query ListTeamEventTagsByAssessmentId(
    $assessmentId: ID!
    $filter: ModelTeamEventTagFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeamEventTagsByAssessmentId(
      assessmentId: $assessmentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        applied
        assessmentId
        teamEventId
        eventTagId
        createdAt
        eventTag {
          id
          name
          color
        }
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTeamEvents = /* GraphQL */ `
  query listTeamEvents(
    $filter: ModelTeamEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const listaccomplishmentByUserId = /* GraphQL */ `
  query ListaccomplishmentByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAccomplishmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listaccomplishmentByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        name
        providerName
        startYear
        endYear
        yearAwarded
        userId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAnnouncementReactionsByUserId = /* GraphQL */ `
  query ListAnnouncementReactionsByUserId(
    $userId: ID!
    $announcementId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnnouncementReactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncementReactionsByUserId(
      userId: $userId
      announcementId: $announcementId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        announcementId
        reactionType
      }
      nextToken
    }
  }
`;
export const listChatForumsByAssessmentId = /* GraphQL */ `
  query ListChatForumsByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatForumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatForumsByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          alias
        }
        role
        messages {
          content
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listChallengeChatsByAssessmentId = /* GraphQL */ `
  query ListChallengeChatsByAssessmentId(
    $assessmentId: ID!
    $challengeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallengeChatsByAssessmentId(
      assessmentId: $assessmentId
      challengeId: $challengeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentId
        userId
        teamId
        challengeId
        createdAt
        updatedAt
        messages {
          content
          type
        }
        user {
          id
          alias
        }
      }
      nextToken
    }
  }
`;
export const listUserMetricsByUserId = /* GraphQL */ `
  query ListUserMetricsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMetricsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        successScore
        lastSuccessScore
        speed
        lastSpeed
        efficiency
        lastEfficiency
        score
        lastScore
      }
      nextToken
    }
  }
`;
export const listSquadMembersByUserId = /* GraphQL */ `
  query ListSquadMembersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSquadMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSquadMembersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        squadId
        userId
        role
        createdAt
        updatedAt
        squad {
          name
          members {
            items {
              userId
              user {
                id
                name
              }
              role
              status
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listUserMetricsByOrgId = /* GraphQL */ `
  query ListUserMetricsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMetricsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        successScore
        lastSuccessScore
        speed
        lastSpeed
        efficiency
        lastEfficiency
        score
        lastScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listBadges = /* GraphQL */ `
  query ListBadges(
    $filter: ModelBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        level
        target
        specialtyId
        badgeType
        totalSkillCoverage
        noOfSkillCoverageToAcquireBadge
        createdAt
        updatedAt
        specialty {
          name
        }
      }
      nextToken
    }
  }
`;
export const listBadgeByUserId = /* GraphQL */ `
  query ListBadgeByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserBadgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBadgeByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessment {
          name
        }
        assessmentId
        userId
        badgeId
        points
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserThreadsByUserAssessmentId = /* GraphQL */ `
  query ListUserThreadsByUserAssessmentId(
    $userAssessmentId: ID!
    $taskId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserThreadsByUserAssessmentId(
      userAssessmentId: $userAssessmentId
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userAssessmentId
        assessmentId
        assistantId
        threadId
        taskId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUsersDataByOrgId = /* GraphQL */ `
  query ListUsersDataByOrgId($orgId: ID!) {
    listUsersDataByOrgId(orgId: $orgId) {
      id
      email
      name
    }
  }
`;
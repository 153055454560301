import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, IconButton, colors } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded';
import {
  FdTypography,
  FdCard,
  getAvatarName,
  getAvatarColor,
  FdAvatar,
  FdChip,
} from '@fifthdomain/fe-shared';
import SquadTag from './SquadTag';

const UserSummary = ({ userData, affiliated }) => {
  const { green, blue } = colors;
  const AffiliationIcon = affiliated ? LinkRoundedIcon : LinkOffRoundedIcon;
  const affiliationLabel = affiliated ? 'Affiliated' : 'Non-Affiliated';

  return (
    <FdCard variant="outlined">
      <Box className="flex justify-center items-center gap-x-4">
        <Box className="flex items-center gap-x-2">
          <FdAvatar
            color={getAvatarColor(userData?.name)}
            size="small"
            variant="circular"
            content={getAvatarName(userData?.name) || ''}
          />
          <FdTypography variant="h3">{userData?.name}</FdTypography>
        </Box>

        <Divider orientation="vertical" flexItem />
        <FdTypography variant="body2" color="secondary">
          {userData?.alias}
        </FdTypography>

        <Divider orientation="vertical" flexItem />
        <Box className="flex items-center">
          <FdTypography variant="body2" color="secondary">
            {userData?.email}
          </FdTypography>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(userData?.email);
            }}
            style={{ height: '15px' }}
          >
            <ContentCopyIcon />
          </IconButton>
        </Box>

        <Divider orientation="vertical" flexItem />
        <Box>
          <FdChip
            style={{
              backgroundColor: affiliated ? green[100] : blue[100],
            }}
            size="small"
            label={
              <Box className="flex items-center gap-x-2">
                <AffiliationIcon />
                {affiliationLabel}
              </Box>
            }
          />
        </Box>
        <SquadTag userId={userData?.id} />
      </Box>
    </FdCard>
  );
};

UserSummary.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    alias: PropTypes.string,
  }).isRequired,
  affiliated: PropTypes.bool,
};

UserSummary.defaultProps = {
  affiliated: false,
};

export default UserSummary;

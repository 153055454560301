import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Card,
  CardContent,
  Badge,
  useTheme,
} from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  FdTypography,
  FdButton,
  FdChip,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { productStyle } from '../../shared/utils/layout';
import { getParticipantStatus } from '../../shared/utils/getParticipantStatus';
import CardProgressBar from '../Participant/CardProgressBar';

const VerticalDivider = () => (
  <Box py={1} height={68}>
    <Divider variant="vertical" />
  </Box>
);

const EventCard = ({ content, onEnterClick }) => {
  const theme = useTheme();

  return (
    <Box mb={2}>
      <Card variant="outlined" style={{ borderRadius: '8px', padding: 0 }}>
        <CardContent
          style={{
            padding: 0,
            height: '68px',
          }}
        >
          <Box className="flex items-center" style={{ height: '100%' }}>
            <Box
              className="flex items-center w-full justify-around"
              height={68}
            >
              <Box display="flex">
                <Box ml={1}>{content.icon}</Box>
                <Box className="flex flex-col justify-around ml-3">
                  <Box width={287}>
                    <FdTooltip
                      title={content.title?.length > 40 ? content.title : ''}
                    >
                      <span>
                        <FdTypography
                          variant="body2"
                          fontWeight="medium"
                          className="overflow-hidden whitespace-nowrap overflow-ellipsis mb-1"
                        >
                          {content.title}
                        </FdTypography>
                      </span>
                    </FdTooltip>
                  </Box>
                  <Box className="flex items-center">
                    <Box width={126}>
                      <FdTypography
                        variant="captiontext2"
                        className="overflow-hidden whitespace-nowrap overflow-ellipsis"
                      >
                        {`${content?.organizer?.toUpperCase()}`}
                      </FdTypography>
                    </Box>
                    {content.contentType === 'course' &&
                      content.courseModulesCount > 0 && (
                        <Box
                          display="inline-block"
                          ml={1}
                          style={{ ...productStyle('COURSE'), padding: '4px' }}
                        >
                          <FdTypography variant="captiontext2">
                            {`${content.courseModulesCount} modules`}
                          </FdTypography>
                        </Box>
                      )}
                    {content.contentType === 'assessment' && (
                      <Box display="flex" ml={1}>
                        <span
                          style={{
                            ...productStyle('ASSESSMENT', theme),
                            padding: '4px',
                            marginRight: '8px',
                          }}
                        >
                          <FdTypography variant="captiontext2">
                            {`${content.type} Assessment`}
                          </FdTypography>
                        </span>
                        <span
                          style={{
                            ...productStyle('ASSESSMENT', theme),
                            padding: '4px',
                          }}
                        >
                          <FdTypography variant="captiontext2">
                            {`Duration: ${content.duration}`}
                          </FdTypography>
                        </span>
                      </Box>
                    )}
                    {content.contentType === 'competition' && (
                      <Box display="flex" ml={1}>
                        <span
                          style={{
                            ...productStyle('COMPETITION', theme),
                            padding: '4px',
                          }}
                        >
                          <FdTypography variant="captiontext2">
                            {`${content.type} Competition`}
                          </FdTypography>
                        </span>
                      </Box>
                    )}
                    {content.contentType === 'training' && (
                      <Box className="flex gap-x-1">
                        <Box display="flex">
                          <span
                            style={{
                              ...productStyle('TRAINING', theme),
                              padding: '4px',
                              marginRight: '8px',
                            }}
                          >
                            <FdTypography variant="captiontext2">
                              Training
                            </FdTypography>
                          </span>
                        </Box>
                        <Box display="flex">
                          <span
                            style={{
                              ...productStyle('TRAINING', theme),
                              padding: '4px',
                              marginRight: '8px',
                            }}
                          >
                            <FdTypography variant="captiontext2">
                              {`${content?.tasksCount} Challenges`}
                            </FdTypography>
                          </span>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                {content.contentType === 'course' &&
                  content.unReadMessageCount > 0 && (
                    <Badge
                      badgeContent={content.unReadMessageCount}
                      color="error"
                    >
                      <ChatBubbleOutlineIcon />
                    </Badge>
                  )}
              </Box>
              <VerticalDivider />
              <Box width="15%" mx={1}>
                <CardProgressBar content={content} />
              </Box>
              <VerticalDivider />
              <Box width="20%">
                <Box
                  mx={1}
                  className="flex flex-col items-center justify-center"
                >
                  {content.timestamp && (
                    <FdTypography
                      variant="captiontext2"
                      style={{ marginTop: '8px' }}
                    >
                      {content.timestamp}
                    </FdTypography>
                  )}
                  <FdChip
                    color={getParticipantStatusColor(
                      content?.status?.toUpperCase(),
                    )}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                    size="small"
                    label={
                      content?.preComputedStatus
                        ? content?.status
                        : getParticipantStatus(content?.status)
                    }
                  />
                </Box>
              </Box>
              <VerticalDivider />
              <Box width={80} mr={1}>
                <Box className="flex items-center justify-center w-full">
                  <FdButton
                    variant="primary"
                    onClick={() => onEnterClick(content)}
                  >
                    View
                  </FdButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

EventCard.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.shape({}),
    image: PropTypes.string,
    iconColor: PropTypes.string,
    contentType: PropTypes.string,
    organizer: PropTypes.string,
    courseModulesCount: PropTypes.number,
    type: PropTypes.string,
    duration: PropTypes.string,
    unReadMessageCount: PropTypes.number,
    status: PropTypes.string,
    timestamp: PropTypes.string,
    preComputedStatus: PropTypes.bool,
    tasksCount: PropTypes.number,
  }).isRequired,
  onEnterClick: PropTypes.func.isRequired,
};

export default EventCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as InProgress from '../../resources/images/level-icons-black/in-progress';
import * as Completed from '../../resources/images/level-icons-black/completed';
import LevelNumberIcon from './LevelNumberIcon';

const useStyles = makeStyles()(() => ({
  iconImage: {
    position: 'absolute',
    top: '-5px',
    width: '147px',
    height: '68px',
  },
  iconImageBlack: {
    top: '-38px',
    width: '140px',
    height: '53px',
    objectFit: 'contain',
  },
  iconSolved: {
    opacity: 0.4,
  },
  nonSelected: {
    left: '-41px',
  },
  selected: {
    left: '-45px',
  },
}));

const LevelIcon = ({ level, completed, selected }) => {
  const { classes } = useStyles();
  const LevelComponent = completed
    ? Completed[`Level${level}Completed`]
    : InProgress[`Level${level}InProgress`];

  return (
    <Box width="45px" height="56px" className="content-center">
      {LevelComponent ? (
        <img
          src={LevelComponent}
          className={`${classes.iconImageBlack} ${selected ? classes.selected : classes.nonSelected}`}
          alt={level}
        />
      ) : (
        <LevelNumberIcon level={level} completed={completed} />
      )}
    </Box>
  );
};

LevelIcon.propTypes = {
  level: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default React.memo(LevelIcon);

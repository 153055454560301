import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
import { useTheme } from '@mui/material';

const DonutStacked = ({ data, onHover, colors, onLeave, ...props }) => {
  const theme = useTheme();
  const customTheme = {
    ...theme.nivoGraph,
    tooltip: {
      container: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  };

  return (
    <ResponsivePie
      theme={customTheme}
      data={data}
      colors={colors}
      margin={0}
      innerRadius={0.7}
      enableArcLabels={false}
      enableArcLinkLabels={false}
      tooltip={() => <></>}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      padAngle={1}
      {...props}
    />
  );
};

DonutStacked.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  colors: PropTypes.arrayOf(PropTypes.string),
  onHover: PropTypes.func,
  onLeave: PropTypes.func,
};

DonutStacked.defaultProps = {
  data: [],
  colors: [],
  onHover: () => null,
  onLeave: () => null,
};

export default DonutStacked;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.typography.primary,
  },
}));

const LegendTooltip = ({ data, totalTasks }) => {
  const { classes } = useStyles();
  const { label, value } = data;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={2}
      mb={1}
    >
      <Box
        display="flex"
        alignItems="center"
        style={{
          borderRadius: 4,
        }}
        px={3}
        className={classes.tooltip}
      >
        <Box display="flex" flexDirection="column" p={1}>
          <Box>
            <FdTypography variant="subtitle1">{label}</FdTypography>
          </Box>
          <Box mt={0.2} display="flex" alignItems="center">
            <FdTypography variant="subtitle2" style={{ marginRight: '0.5rem' }}>
              {`${value}  `}
            </FdTypography>
            <FdTypography variant="captiontext2">
              no of challenge(s)
            </FdTypography>
          </Box>
          <Box mt={0.2} display="flex" alignItems="center">
            <FdTypography variant="subtitle2" style={{ marginRight: '0.5rem' }}>
              {`${Math.round((value / totalTasks) * 100)}% `}
            </FdTypography>
            <FdTypography variant="captiontext2">
              of all challenge(s)
            </FdTypography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LegendTooltip.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  totalTasks: PropTypes.number.isRequired,
};

export default LegendTooltip;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { FdTypography, useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import LabelWithTooltip from '../LabelWithTooltip';
import {
  ErrorIcon,
  TargetProficiency,
  TickIcon,
  DemonstratedIcon,
  NotDemonstratedTraining,
} from '../../Training/icons';

const LegendElement = ({ Icon, label }) => (
  <Box className="flex items-center justify-center gap-x-1.5">
    {Icon}
    <FdTypography variant="captiontext2">{label}</FdTypography>
  </Box>
);

LegendElement.propTypes = {
  Icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
};

const LabelContainer = ({ children }) => (
  <Box className="flex flex-col items-center pb-2 my-1 bg-gray-50 rounded-lg w-4/5">
    {children}
  </Box>
);

LabelContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const OverlayAssignedWR = ({ overlayWorkrole, setOverlayWorkRole }) => {
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);

  return (
    <Box className="flex justify-center items-center w-full">
      <Box className="flex flex-col items-center w-full">
        {hasSkillTarget && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={overlayWorkrole}
                  onChange={(e) => {
                    setOverlayWorkRole(e?.target?.checked);
                  }}
                />
              }
              label={
                <FdTypography variant="captiontext2" color="secondary">
                  Overlay Assigned Work Role
                </FdTypography>
              }
            />
          </FormGroup>
        )}
        {hasSkillTarget && (
          <LabelContainer>
            <LabelWithTooltip
              variant="captiontext2"
              label="Skills Fulfilment Icons Key"
              tooltipText="Skills fulfilment icons are visible when you toggle on the overlay of a user’s assigned work role."
            />
            <Box className="flex gap-x-3 mt-1">
              <LegendElement
                Icon={TickIcon}
                label="Fulfilled Skill (Current)"
              />
              <LegendElement
                Icon={ErrorIcon}
                label="Unfulfilled Skill (Current)"
              />
            </Box>
          </LabelContainer>
        )}
        <LabelContainer>
          <LabelWithTooltip
            variant="captiontext2"
            label="Skill-Proficiency Square Key"
            tooltipText="Skill-Proficiency square borders are visible when you toggle on the overlay of a user’s assigned work role."
          />
          <Box className="flex gap-x-3 mt-1">
            {hasSkillTarget && (
              <LegendElement
                Icon={TargetProficiency}
                label="Target Proficiency"
              />
            )}
            <Box mx={1.5}>
              <LegendElement
                Icon={<DemonstratedIcon showTarget={hasSkillTarget} />}
                label="Demonstrated"
              />
            </Box>
            <LegendElement
              Icon={<NotDemonstratedTraining showTarget={hasSkillTarget} />}
              label="Not Demonstrated"
            />
          </Box>
        </LabelContainer>
      </Box>
    </Box>
  );
};

OverlayAssignedWR.propTypes = {
  overlayWorkrole: PropTypes.bool.isRequired,
  setOverlayWorkRole: PropTypes.func.isRequired,
};

export default OverlayAssignedWR;

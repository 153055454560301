import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdCard,
  FdChip,
  FdSkeleton,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import UserRole from './UserRole';
import TileCard from './TileCard';
import TasksAnalyticsTable from './TasksAnalyticsTable';
import {
  getDateTimeZoneFormatted,
  sortByDateField,
} from '../../shared/utils/dateUtils';
import FdSystemDateCountdown from '../FdSystemDateCountdown';

import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import { listTaskAttemptAggregatesByAssessmentId } from '../../graphql/queries';

const Overview = ({
  loading,
  trainingUser,
  tasks,
  trainingId,
  trainingStatus,
}) => {
  const { getValues } = useFormContext();
  const { startDateTime, endDateTime } = getValues();

  const { data: listTaskAttemptsData, loading: listTaskAttemptsLoading } =
    useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
      variables: {
        assessmentId: trainingId,
        limit: 1000,
      },
    });

  const allAttempts =
    listTaskAttemptsData?.listTaskAttemptAggregatesByAssessmentId?.items || [];

  const attemptedData = (_taskId) => {
    const allTaskAttempted = allAttempts?.filter(
      (task) => task?.taskId === _taskId,
    );
    const attempts = allTaskAttempted?.length;
    const completed =
      allTaskAttempted
        ?.filter((a) => a.status === 'COMPLETED')
        .sort(sortByDateField('updatedAt')) || [];

    return { attempts, solves: completed?.length };
  };

  const challengeAnalyticsRows = tasks?.map((item) => {
    const { attempts, solves } = attemptedData(item?.task?.id);
    return {
      id: item?.taskId,
      name: item?.task?.name,
      difficultyLabel: getDifficultyLabel(item?.task?.difficulty),
      points: item?.task?.recommendedPoints,
      specialtyName: item?.task?.specialty?.name,
      attempts,
      skills: item?.task?.skills?.items.map((s) => s.skill),
      status: solves === 0 ? 'Unsolved' : 'Solved',
      d: item?.task?.difficulty,
    };
  });
  const challengesCount = challengeAnalyticsRows?.length;
  const challengesSolvedCount =
    challengeAnalyticsRows?.filter((c) => c.status === 'Solved').length || 0;
  const successRateInPercentage = allAttempts?.length
    ? Math.round((challengesSolvedCount / allAttempts.length) * 100)
    : 0;

  return (
    <Box>
      <FdSkeleton loading={loading} height="97px">
        <FdCard variant="outlined">
          <Box className="grid grid-cols-3 divide-x-2">
            <Box className="flex flex-col items-center">
              <FdTypography variant="subtitle1">
                {getDateTimeZoneFormatted(startDateTime)}
              </FdTypography>
              <FdTypography variant="subtitle2" color="secondary">
                Training Activity Start
              </FdTypography>
            </Box>
            <Box className="flex flex-col items-center">
              <FdTypography variant="subtitle1">
                {getDateTimeZoneFormatted(endDateTime)}
              </FdTypography>
              <FdTypography variant="subtitle2" color="secondary">
                Training Activity End
              </FdTypography>
            </Box>
            <Box className="flex flex-col items-center">
              <FdTypography variant="subtitle1">
                {endDateTime && (
                  <FdSystemDateCountdown
                    endDateTime={endDateTime}
                    daysInHours
                  />
                )}
              </FdTypography>
              <FdTypography variant="subtitle2" color="secondary">
                Time Remaining
              </FdTypography>
            </Box>
          </Box>
        </FdCard>
      </FdSkeleton>
      <FdSkeleton loading={loading} height="131px" className="mt-4">
        <TileCard
          data={[
            {
              content: (
                <UserRole name={trainingUser?.user?.name} loading={loading} />
              ),
              subtitle: 'Assigned User',
            },
            {
              content: (
                <FdChip
                  label={trainingStatus}
                  color={getParticipantStatusColor(
                    trainingStatus?.toUpperCase() || '',
                  )}
                />
              ),
              subtitle: 'Assigned User Status',
            },
            {
              content: (
                <FdTypography variant="h3" color="secondary">
                  {`${challengesSolvedCount}/${challengesCount}`}
                </FdTypography>
              ),
              subtitle: 'Challenges Solved',
            },
            {
              content: (
                <FdTypography variant="h3" color="secondary">
                  {`${successRateInPercentage}%`}
                </FdTypography>
              ),
              subtitle: 'Success Rate',
            },
          ]}
        />
      </FdSkeleton>
      <TasksAnalyticsTable
        data={challengeAnalyticsRows}
        loading={listTaskAttemptsLoading}
      />
    </Box>
  );
};

Overview.propTypes = {
  loading: PropTypes.bool.isRequired,
  trainingUser: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  trainingId: PropTypes.string.isRequired,
  trainingStatus: PropTypes.string.isRequired,
};

export default Overview;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const LevelIndicator = ({
  level,
  fontVariant = 'captiontext1',
  containerWidth,
}) => {
  const levelName =
    containerWidth > 212 ? level?.levelName : level?.shortLevelName;
  const levelNameLines = levelName?.split('\n') || [];

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      {fontVariant === 'captiontext1' && (
        <FdTypography variant="captiontext2" color="secondary">
          {level?.levelTitle}
        </FdTypography>
      )}
      <FdTypography variant={fontVariant} className="text-center">
        {levelNameLines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < levelNameLines.length - 1 && <br />}
          </React.Fragment>
        ))}
      </FdTypography>
    </Box>
  );
};

LevelIndicator.propTypes = {
  level: PropTypes.shape({
    levelTitle: PropTypes.string,
    levelName: PropTypes.string,
    shortLevelName: PropTypes.string,
  }).isRequired,
  fontVariant: PropTypes.string.isRequired,
  containerWidth: PropTypes.number.isRequired,
};

export default LevelIndicator;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const SectionHeading = ({ name, type, showTypeChip }) => {
  return (
    <Box className="flex items-center gap-x-3">
      <FdTypography variant="h4">{name}</FdTypography>
      {showTypeChip && (
        <Chip
          label={type}
          size="small"
          style={{
            backgroundColor: 'rgba(134, 168, 255, 1)',
            fontWeight: '500',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        />
      )}
    </Box>
  );
};

SectionHeading.defaultProps = {
  showTypeChip: true,
};

SectionHeading.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  showTypeChip: PropTypes.bool,
};

export default SectionHeading;

import { isFuture } from 'date-fns';
import Yup from '../Common';

export const initialValues = {
  name: '',
  startDateTime: null,
  endDateTime: null,
  jumpBox: false,
  tasks: [],
  preMessage: `This training activity includes challenges selected specifically for you based on the skills you need to demonstrate at the target proficiency levels required for your current work role. 

  You can attempt these challenges at your own pace, over multiple sessions, until the training activity ends.
  
  **Training Activity Rules**

  During and after the training activity, you must NOT:
  
  - Attack the training activity infrastructure
  - Share challenge materials with others
  
  **Accessing Technical Support**
  
  You can access the FifthDomain Help Centre at any time: [Fifth Domain Help Center](url)
  
  If you need to chat with our Support team, use the Support chat box at the bottom right corner of the Help Centre page. We are available from 9:00AM to 5:00PM, Monday to Friday, AEDT/AEST time (excluding public holidays).

  You can also access the Support chat box from within the FifthDomain platform.`,
  postMessage: `Congratulations on completing the challenges in this training activity! 

 You can view all the skills you have demonstrated in your Cyber Skills Cortex. Your performance data (i.e. Success, Efficiency, and Speed scores) will be visible in your Performance Triangle.
  
 Those with 'Manage Users' or 'View Insights' permissions in your organisation, as well as Squad Managers (if you are part of a squad), will be able to see your skill and performance data within your squad’s profile.`,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a training activity name')
    .max(150, 'Training activity name must be 150 characters or less')
    .noWhitespace('Enter a valid training activity name'),
  startDateTime: Yup.date(),
  endDateTime: Yup.date()
    .required('Please enter an Training Activity End date and time')
    .typeError('Please enter an Training Activity End date and time')
    .test(
      'is-future',
      'Training Activity End date and time cannot be in the past',
      (value) => {
        return isFuture(new Date(value));
      },
    ),
  jumpBox: Yup.boolean(),
  tasks: Yup.array()
    .of(Yup.object().shape({ taskId: Yup.string() }))
    .min(1),
  preMessage: Yup.string(),
  postMessage: Yup.string(),
});

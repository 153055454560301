import React from 'react';
import { Route } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { useAuthSession } from '@fifthdomain/fe-shared';
import { getUserById } from '../graphql/queries';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const { data, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
  });

  if (userLoading) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        // all other valid auth routes
        if (user && data) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AuthRoute;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTable,
  FdTypography,
  FdTooltip,
  FdChips,
  useSnapshot,
  globalStore,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import TaskInfoCell from '../Assessment/TaskInfoCell';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';
import {
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';
import ViewTaskDrawer from '../Assessment/ViewTaskDrawer';

const TasksTable = ({ data, loading }) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [activeTask, setActiveTask] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);

  const { fields: tasks } = useFieldArray({
    control,
    name: 'tasks',
  });
  const globalSnap = useSnapshot(globalStore);
  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = data?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'creator',
      headerName: 'Creator/ Provider',
      flex: 1,
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle2">Creator/ Provider</FdTypography>
            <FdTooltip
              title={
                <Box>
                  Creator of the challenge is the manager/organisation which
                  created that challenge. Creator information appears for
                  challenges that are owned by your organisation.
                  <br />
                  <br />
                  Provider name appears for challenges that have been leased
                  from, (or provided by) other organisation.
                </Box>
              }
            >
              <IconButton size="small" style={{ marginLeft: '10px' }}>
                <InfoOutlinedIcon />
              </IconButton>
            </FdTooltip>
          </Box>
        );
      },
      valueGetter: (params) => {
        const rowVal = params?.row;
        if (rowVal?.owned) {
          return rowVal?.creatorOrgId === rowVal?.ownerOrgId
            ? `Creator: ${rowVal.creator}`
            : `Creator: ${rowVal?.creatorOrg}`;
        }
        return `Provider: ${rowVal?.ownerOrg}`;
      },
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      valueGetter: (params) =>
        params?.value?.length > 0
          ? [...params?.value].map((t) => t?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        return getCommaSeparatedPlusSuffix(
          params?.row?.skills?.map((skill) => skill?.name),
        );
      },
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => upperCaseFirstLetter(params?.value),
    },
    ...(globalSnap.orgPricingTier === 'STARTER'
      ? []
      : [
          {
            field: 'tags',
            flex: 1,
            headerName: 'Assigned Tags',
            valueGetter: (params) =>
              params.row?.tags?.length > 0
                ? [...params.row?.tags].map((t) => t.Tag?.name)?.join(' ')
                : '',
            renderCell: (params) => {
              const _data = [...params.row?.tags]
                ?.sort(sortByDateField('updatedAt', 'desc'))
                .map((t) => ({
                  label: t.Tag?.name,
                  color: getTagColor(t.Tag?.color),
                }));
              return _data?.length > 0 ? (
                <FdChips
                  data={_data}
                  numberOfChipsDisplayed={1}
                  caption="More"
                />
              ) : (
                <Box width="100%" textAlign="center">
                  -
                </Box>
              );
            },
          },
        ]),
  ];

  return (
    <Box>
      <Box mt={2} mb={2} height="610px" width="100%">
        {errors?.tasks && (
          <Box my={1}>
            <FdAlert
              variant="error"
              message="You must select at least one challenge before you can generate a training activity."
            />
          </Box>
        )}
        <FdTable
          loading={loading}
          defaultMuiToolbarSettings={{
            showMuiDefaultToolbar: true,
            columnsButton: true,
            filterButton: true,
            densityButton: true,
          }}
          rowHeight={60}
          slotProps={{
            columnsPanel: {
              getTogglableColumns: () => [
                'creator',
                'specialtyName',
                'skills',
                'type',
                'tags',
              ],
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
          toolbarSettings={{
            title: '',
            filterOptions: [],
            searchBox: true,
            filterButton: true,
          }}
          selectionModel={tasks.map((t) => t.taskId)}
          onSelectionModelChange={(_values) => {
            setValue(
              'tasks',
              _values.map((v) => ({ taskId: v })),
              { shouldDirty: true },
            );
            trigger('tasks');
          }}
          selection
          disableSelectionOnClick
          actions={viewTaskActions}
          rows={data}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={10}
          gridId="training-challenges"
        />
      </Box>
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'competitions']}
        />
      )}
    </Box>
  );
};

TasksTable.defaultProps = {
  loading: false,
};

TasksTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,
  loading: PropTypes.bool,
};

export default TasksTable;

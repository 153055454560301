import Color from 'color';

/* 
  Usage:-
  - add space for drawer - width in pixels
  setAppMarginRightByIds(['topnav'], rightHandSideDrawerWidth = '400px');

  - remove space for drawer - width in pixels
  setAppMarginRightByIds(['topnav'], rightHandSideDrawerWidth = '0');
*/
const setAppMarginRightByIds = (ids, width) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const id in ids) {
    if (document.getElementById(ids[id])) {
      document.getElementById(ids[id]).style.marginRight = width;
    }
  }
  if (document.getElementsByTagName('header')[0]) {
    document.getElementsByTagName('header')[0].style.paddingRight = width;
  }
};

export default setAppMarginRightByIds;

export const productStyle = (_product, theme, typeStyle) => {
  return {
    padding: typeStyle === 'chip' ? '2px 4px 0 4px' : '12px 4px',
    borderRadius: '4px',
    maxHeight: '48px',
    color: '#000000',
    fontWeight: 500,
    backgroundColor:
      _product === 'ASSESSMENT'
        ? theme?.fdProColors?.event?.assessments
        : _product === 'COMPETITION'
          ? theme?.fdProColors?.event?.competitions
          : _product === 'COURSE'
            ? theme?.fdProColors?.event?.courses
            : _product === 'TRAINING'
              ? theme?.fdProColors?.event?.trainings
              : theme?.fdProColors?.event?.archive,
  };
};

export const getLabelColor = (color = '#FFF') => {
  return Color(color).isLight() ? 'black' : 'white';
};

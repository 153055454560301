import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import Chart from 'react-apexcharts';
import getGraphTheme from '../../shared/utils/graphUtil';

const Radar = ({ data, categories, chartId }) => {
  const theme = useTheme();
  const graphTheme = getGraphTheme(theme);
  const options = {
    series: data,
    options: {
      chart: {
        id: chartId,
        height: 450,
        type: 'radar',
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories,
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
      },
      legend: {
        offsetY: -30,
        formatter: (seriesName) => [seriesName, '%'],
      },
      tooltip: {
        y: {
          formatter: (value) => `${value}%`,
        },
      },
      theme: {
        mode: graphTheme,
      },
    },
  };

  return (
    <Box>
      <Chart
        options={options.options}
        series={options.series}
        type="radar"
        height="450"
      />
    </Box>
  );
};

Radar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ),
    }),
  ),
  categories: PropTypes.arrayOf(PropTypes.string),
  chartId: PropTypes.string,
};

Radar.defaultProps = {
  data: [],
  categories: [],
  chartId: 'radar-chart',
};

export default Radar;

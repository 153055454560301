import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const DonutGraphLegend = ({ data }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {data?.map((d) => (
        <Box mb={0.5}>
          <Box display="flex" alignItems="center">
            <Box
              style={{
                backgroundColor: d?.color,
                width: 8,
                height: 8,
                marginRight: '5px',
              }}
            />
            <FdTypography variant="captiontext2">{`${d?.label} Challenges`}</FdTypography>
          </Box>
          <Box>
            <FdTypography variant="subtitle2">{`${d?.solved}/${d?.total}`}</FdTypography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

DonutGraphLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default DonutGraphLegend;

import React, { useState } from 'react';
import { gql } from '@apollo/client';
import _ from 'lodash';
import {
  addWeeks,
  differenceInCalendarWeeks,
  isWithinInterval,
} from 'date-fns';
import { Box, Divider } from '@mui/material';
import {
  BasePage,
  FdCard,
  FdTypography,
  FdIconWithTooltip,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdSkeleton,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import TimePeriodFilter from '../components/Participant/SkillsOverview/TimePeriodFilter';
import { getArrayByLength } from '../shared/utils/objectUtils';
import { SKILLS_PERFORMANCE_TIMELINE_START_DATE } from '../constants';
import PerformanceTriangle from '../components/Team/Dashboard/PerformanceTriangle';
import { listUserScoreboardsByUserId } from '../graphql/queries';
import CyberSkillCortex from '../components/Insights/CyberSkillCortex';

const getAverage = (objectArray, columnsToAverage) => {
  const average = _.reduce(
    objectArray,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(average, (value, key) => {
    average[key] = parseFloat((value / objectArray?.length).toFixed(2));
  });

  return average;
};

const SkillsOverview = () => {
  const [triangleValueRange, setTriangleValueRange] = useState([0, 100]);
  const globalSnap = useSnapshot(globalStore);

  const { data: userScoreboardsData, loading: userScoreboardsLoading } =
    useQueryRecursive(gql(listUserScoreboardsByUserId), {
      variables: {
        limit: 1000,
        userId: globalSnap?.userId,
      },
    });

  const onTriangleValueRangeChange = (__, newValue) =>
    setTriangleValueRange(newValue);

  const noOfWeeks = differenceInCalendarWeeks(
    new Date(),
    SKILLS_PERFORMANCE_TIMELINE_START_DATE,
  );

  const slice = 100 / (noOfWeeks > 0 ? noOfWeeks : 1);
  const markers = getArrayByLength(Number(noOfWeeks + 1), (__, i) => i + 1);
  const marks =
    markers?.map((m, i) => {
      const last = noOfWeeks + 1 === i + 1;
      return {
        value: last ? 100 : i * slice,
        date: addWeeks(SKILLS_PERFORMANCE_TIMELINE_START_DATE, i),
        first: i === 0,
        last,
      };
    }) || [];

  const getTimeLineDate = (_value) =>
    (_value === 100
      ? new Date()
      : marks?.find((m) => m?.value === _value)?.date) || new Date();

  const userScoreboards =
    userScoreboardsData?.listUserScoreboardsByUserId?.items?.filter((s) =>
      isWithinInterval(new Date(s.updatedAt), {
        start: getTimeLineDate(triangleValueRange[0]),
        end: getTimeLineDate(triangleValueRange[1]),
      }),
    ) || [];

  const columnsToAverage = ['successScore', 'speed', 'efficiency', 'score'];
  const memberAverage = getAverage(userScoreboards, columnsToAverage);

  const performanceData = {
    team: {
      score: Math.round(memberAverage?.score) || 0,
    },
    participant: {
      success: memberAverage?.successScore || 0,
      efficiency: memberAverage?.efficiency || 0,
      speed: memberAverage?.speed || 0,
    },
    teamBased: false,
  };

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'My Skills', type: 'SKILL' }} />
      <BasePage data-cy="skills-overview-page">
        <Box>
          <FdCard variant="outlined">
            <FdPageHeading type="SKILL" variant="h4">
              Skills Overview
            </FdPageHeading>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '0.5rem' }}
            >
              Welcome to your Skills Overview page! Here, you can explore your
              performance and the skills you have demonstrated in the events you
              have participated in on the platform.
            </FdTypography>
          </FdCard>
          <Box className="flex flex-col gap-y-2 mt-4">
            <FdCard variant="outlined">
              <Box className="flex">
                <FdTypography variant="h4">Cyber Skills Cortex</FdTypography>
                <FdIconWithTooltip title="Your Cyber Skills Cortex accumulates the skills you have demonstrated in all of your assigned events on the platform." />
              </Box>
              <FdTypography
                variant="captiontext1"
                color="secondary"
                style={{ marginTop: '0.5rem' }}
              >
                The Cyber Skills Cortex displays demonstrated cyber skills as
                per the Dreyfus proficiency levels (1-5). Mapped to one of six
                FifthDomain Professional Specialties, each skill darkens in
                corresponding squares from the centre of the Cortex as
                one&apos;s demonstration of proficiency in that skill increases.
              </FdTypography>
              <Box mt={1} mb={6}>
                <Divider />
              </Box>
              <CyberSkillCortex
                marks={marks}
                _userId={globalSnap?.userId}
                isParticipant
              />
            </FdCard>

            <FdCard variant="outlined">
              <Box className="flex">
                <FdTypography variant="h4">Performance Triangle</FdTypography>
                <FdIconWithTooltip title="Your Performance Triangle accumulates your performance in all assigned events on the platform." />
              </Box>
              <FdTypography
                variant="captiontext1"
                color="secondary"
                style={{ marginTop: '0.5rem', marginBottom: '2.5rem' }}
              >
                The Performance Triangle visually displays a participant&apos;s
                Success, Efficiency, and Speed scores on the platform. The blue
                triangle below shows your Success, Efficiency, and Speed scores.
              </FdTypography>
              <Box my={3}>
                <Divider />
              </Box>
              <TimePeriodFilter
                marks={marks}
                step={null}
                valueRange={triangleValueRange}
                onValueChange={onTriangleValueRangeChange}
                description="The slider below enables you to filter your view to a specific time period in the past. The leftmost position represents week 1 of October 2023 (the earliest view available), and the rightmost indicates the current week. Drag the slider to explore your performance metrics from October 2023 to the selected week."
              />
              <Box mt={1} mb={6}>
                <Divider />
              </Box>
              <FdSkeleton loading={userScoreboardsLoading} height={448}>
                <PerformanceTriangle performanceData={performanceData} cohort />
              </FdSkeleton>
            </FdCard>
          </Box>
        </Box>
      </BasePage>
    </Box>
  );
};

export default SkillsOverview;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  FdTab,
  FdTextField,
  FdAlert,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import EventCard from './EventCard';
import {
  getSystemTime,
  listScoreboardsByTeamId,
  listUserAssessmentsByUserId,
} from '../../../graphql/queries';
import {
  getCompetitionStatus,
  getTimeStampText,
} from '../../../shared/utils/getParticipantStatus';

const getStatusType = (_status) => {
  switch (_status) {
    case 'STARTED':
    case 'NOT_COMPLETED':
      return 'In Progress';
    case 'NOT_STARTED':
    case 'INVITED':
      return 'Upcoming';
    case 'FINISHED':
    case 'ENDED':
      return 'Completed';
    default:
      return '';
  }
};

const getEventsByType = (_events, _type) =>
  _events?.filter((e) => getStatusType(e?.status) === _type) || [];

const Competitions = ({ data, type, loading }) => {
  const [searchText, setSearchText] = useState('');
  const filteredData = getEventsByType(data, type);
  const filterBySearch =
    searchText?.length > 0
      ? filteredData?.filter((d) =>
          d?.name.toLowerCase()?.includes(searchText?.toLowerCase()),
        )
      : filteredData;

  if (filteredData?.length === 0) {
    return <FdAlert message={`No ${type} competitions`} variant="warning" />;
  }

  return (
    <FdSkeleton loading={loading} height={500}>
      <FdTextField
        placeholder="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        endAdornment={<SearchIcon />}
        fullWidth
      />
      <Box mt={3} maxHeight={1200} style={{ overflowY: 'auto' }}>
        {filterBySearch?.map((cd) => (
          <EventCard event={cd} />
        ))}
        {filterBySearch?.length === 0 && (
          <FdAlert
            message="No competitions matching this search criteria"
            variant="warning"
          />
        )}
      </Box>
    </FdSkeleton>
  );
};

Competitions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.oneOf(['in-progress', 'upcoming', 'completed']).isRequired,
  loading: PropTypes.bool.isRequired,
};

const TeamCompetitions = ({ team }) => {
  const globalSnap = useSnapshot(globalStore);
  const { data: serverTime } = useQuery(gql(getSystemTime), {
    fetchPolicy: 'network-only',
  });
  const { data: assessmentsData, loading: assessmentsLoading } =
    useQueryRecursive(gql(listUserAssessmentsByUserId), {
      variables: {
        userId: globalSnap.userId,
      },
      staleTime: { seconds: 0 },
    });
  const userAssessments =
    assessmentsData?.listUserAssessmentsByUserId?.items || [];

  const { data: scoreboardData, loading: scoreboardLoading } =
    useQueryRecursive(gql(listScoreboardsByTeamId), {
      variables: {
        teamId: team?.id,
      },
      staleTime: { seconds: 0 },
    });
  const scoreboardResults =
    scoreboardData?.listScoreboardsByTeamId?.items || [];

  const events =
    team?.events?.items
      ?.filter((e) => e?.event?.participantEventType === 'COMPETITION')
      .map((e) => {
        const pointsAvailable = [
          ...(e?.event?.multiLevel
            ? e?.event?.levels?.items
                ?.map((l) =>
                  l.tasks?.items?.map((_task) => ({
                    ..._task.task,
                  })),
                )
                .flat()
            : e?.event?.tasks?.items?.map((_task) => ({
                ..._task.task,
              }))),
        ]?.reduce((acc, i) => acc + i?.recommendedPoints, 0);
        const pointsScored =
          scoreboardResults?.find((s) => s?.assessmentId === e?.eventId)
            ?.points || 0;
        const pointsText = `${pointsScored}/${pointsAvailable} Points`;
        const pointsAvg =
          pointsAvailable > 0 ? (pointsScored / pointsAvailable) * 100 : 0;

        // get Competition status based on the dates
        const status = getCompetitionStatus(
          e?.event?.startDateTime,
          e?.event?.endDateTime,
          serverTime?.getSystemTime,
        );
        return {
          id: e?.eventId,
          name: e?.event?.name,
          status,
          timeStamp: getTimeStampText(
            e?.event?.startDateTime,
            e?.event?.endDateTime,
            e?.event?.availabilityType,
            status,
          ),
          userAssessmentId: userAssessments?.find(
            (ua) => ua.userAssessmentAssessmentId === e?.eventId,
          )?.id,
          pointsText,
          pointsAvg,
        };
      }) || [];

  return (
    <Box>
      <FdTab
        label={[
          {
            label: `In Progress (${
              getEventsByType(events, 'In Progress')?.length
            })`,
            index: 0,
            data: (
              <Competitions
                data={events}
                type="In Progress"
                loading={assessmentsLoading || scoreboardLoading}
              />
            ),
          },
          {
            label: `Upcoming (${getEventsByType(events, 'Upcoming')?.length})`,
            index: 1,
            data: (
              <Competitions
                data={events}
                type="Upcoming"
                loading={assessmentsLoading || scoreboardLoading}
              />
            ),
          },
          {
            label: `Completed (${
              getEventsByType(events, 'Completed')?.length
            })`,
            index: 1,
            data: (
              <Competitions
                data={events}
                type="Completed"
                loading={assessmentsLoading || scoreboardLoading}
              />
            ),
          },
        ]}
        style={{ marginTop: '-10px' }}
      />
    </Box>
  );
};

TeamCompetitions.propTypes = {
  team: PropTypes.shape({
    events: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    id: PropTypes.string,
  }).isRequired,
};

export default TeamCompetitions;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdTooltip, FdTypography } from '@fifthdomain/fe-shared';

const LabelWithTooltip = ({ label, tooltipText, variant }) => {
  return (
    <Box className="flex items-center">
      <FdTypography variant={variant} color="secondary">
        {label}
      </FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small">
          <InfoOutlinedIcon fontSize="small" />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

LabelWithTooltip.defaultProps = {
  variant: 'subtitle2',
};

export default LabelWithTooltip;

import { uploadData } from 'aws-amplify/storage';
import shortid from 'shortid';
import { amplifyConfig } from '@fifthdomain/fe-shared';

const getContentType = (fileType) => {
  switch (fileType) {
    case '.md':
      return 'text/markdown';
    case '.tar.gz':
      return 'application/gzip';
    case 'application/x-yaml':
      return 'application/yaml';
    default:
      return 'application/gzip';
  }
};

export const uploadFileToS3 = ({ key, file, progressCallback = () => {} }) => {
  // Set default value for progressCallback as an empty function
  return uploadData({
    path: `public/${key}`,
    data: file,
    options: {
      onProgress: progressCallback,
    },
  });
};

const fileUploadAWS = async ({ file, fileType }, setUploadPercentage) => {
  const fileName = file.name.slice(0, file.name.lastIndexOf('.'));
  const fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);
  const uniqueFileName = `${fileName}-${shortid.generate()}.${fileExtension}`;
  await uploadData({
    path: `public/${uniqueFileName}`,
    data: file,
    options: {
      contentType: getContentType(fileType),
      onProgress: ({ transferredBytes, totalBytes }) => {
        const percentage =
          Math.round((transferredBytes / totalBytes) * 100) / 100;
        setUploadPercentage?.(percentage);
      },
    },
  }).result;

  return {
    name: uniqueFileName,
    type: file.type,
    size: file.size,
    url: URL.createObjectURL(file),
    bucket: amplifyConfig?.aws_user_files_s3_bucket,
    region: amplifyConfig?.aws_user_files_s3_bucket_region,
  };
};

export default fileUploadAWS;

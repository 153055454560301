import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import {
  FdChatStream,
  globalStore,
  useSnapshot,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import {
  createChallengeChat,
  deleteChallengeChat,
  updateChallengeChat,
} from '../../graphql/mutations';
import { uploadFileToS3 } from '../../shared/utils/fileUploadAWS';
import { loadMessagesWithImages } from './utils';
import { invalidateChallengeChat } from '../../queries/invalidateQueries';

const ChallengeChat = ({
  challengeId,
  assessmentId,
  team,
  chatMessages,
  loading,
}) => {
  const [imageKey, setImageKey] = useState(undefined);
  const [imageFile, setImageFile] = useState(undefined);
  const [chatMessagesWithImages, setChatMessagesWithImages] = useState([]);
  const [keyIds, setKeyIds] = useState([]);
  const globalSnap = useSnapshot(globalStore);
  const { id: teamId, members } = team;
  const userList =
    members?.items
      ?.filter((m) => m?.userId !== globalSnap?.userId && m?.User?.alias)
      ?.map((m) => ({ id: m?.userId, alias: m?.User?.alias })) || [];

  useEffect(() => {
    loadMessagesWithImages(chatMessages, globalSnap?.userId)
      .then((chatMessagesDisplay) => {
        setChatMessagesWithImages(chatMessagesDisplay);
      })
      .catch(() => {
        setChatMessagesWithImages([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  const uploadToS3 = (_imageKey, _imageFile) => {
    const pImageKey = imageKey || _imageKey;
    const pImageFile = imageFile || _imageFile;
    if (pImageKey) {
      uploadFileToS3({
        key: pImageKey,
        file: pImageFile,
        progressCallback: (progress) => {
          // reset imageFile on completion
          if (progress?.loaded === progress?.total) {
            setImageFile(undefined);
          }
        },
      });
    }
  };

  const [createChallengeMessageMutation] = useMutation(
    gql(createChallengeChat),
  );

  const [updateChallengeMessageMutation] = useMutation(
    gql(updateChallengeChat),
    {
      onCompleted: () => {
        // if image present then upload to S3
        uploadToS3();
        // invalidate queries to force refetch
        invalidateChallengeChat();
      },
    },
  );

  const [deleteChallengeMessageMutation] = useMutation(
    gql(deleteChallengeChat),
    {
      onCompleted: (_data) => {
        successToastMessage('Success! Message deleted.');
        // invalidate queries to force refetch
        invalidateChallengeChat();
      },
    },
  );

  const getImageKey = (image) => {
    const imageKeyGenerated = image
      ? `chat-images/${shortid.generate()}`
      : undefined;
    if (imageKeyGenerated) {
      setImageKey(imageKeyGenerated);
      setImageFile(image);
    }
    return imageKeyGenerated;
  };

  const getMessagePayload = (message, imageKeyGenerated, image) => {
    return {
      messages: [
        {
          content: message,
          type: 'TEXT',
        },
        ...(image
          ? [
              {
                content: imageKeyGenerated,
                type: 'IMAGE',
              },
            ]
          : []),
      ],
    };
  };
  const getMessageId = (_keyId) =>
    keyIds?.find((i) => i?.keyId === _keyId)?.messageId;

  return (
    <Box>
      <FdSkeleton loading={loading} height={window.innerHeight}>
        <FdChatStream
          allMessages={chatMessagesWithImages}
          onAddMessage={(message, image, keyId) => {
            const imageKeyGenerated = getImageKey(image);
            // create message
            createChallengeMessageMutation({
              variables: {
                input: {
                  assessmentId,
                  challengeId,
                  teamId,
                  ...getMessagePayload(message, imageKeyGenerated, image),
                  userId: globalSnap?.userId,
                },
              },
              onCompleted: (_data) => {
                const id = _data?.createChallengeChat?.id;
                setKeyIds([...keyIds, { keyId, messageId: id }]);
                // if image present then upload to S3
                uploadToS3(imageKeyGenerated, image);
                // invalidate queries to force refetch
                invalidateChallengeChat();
              },
            });
          }}
          onUpdateMessage={({ messageId, message, image, keyId }) => {
            const imageKeyGenerated = getImageKey(image);
            // update message
            updateChallengeMessageMutation({
              variables: {
                input: {
                  id: messageId || getMessageId(keyId),
                  challengeId,
                  teamId,
                  ...getMessagePayload(message, imageKeyGenerated, image),
                  userId: globalSnap?.userId,
                },
              },
            });
          }}
          onDeleteMessage={(messageId, keyId) =>
            deleteChallengeMessageMutation({
              variables: {
                input: {
                  id: messageId || getMessageId(keyId),
                },
              },
            })
          }
          onCancelDeleteMessage={() =>
            warningToastMessage('Message deletion cancelled')
          }
          currentRole="PARTICIPANT"
          userList={userList}
          hideReactions
          size="small"
          placeholder="Message Team Chat"
          captionText="Only members of your team can see messages in your Team Chat."
          shortMode
          warningToastMessage={warningToastMessage}
          messageCharLimit={1000}
        />
      </FdSkeleton>
    </Box>
  );
};

ChallengeChat.defaultProps = {
  team: {},
  loading: false,
};

ChallengeChat.propTypes = {
  challengeId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
    members: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          userId: PropTypes.string,
          User: PropTypes.shape({
            alias: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  chatMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
};

export default ChallengeChat;

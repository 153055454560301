import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import _ from 'lodash';
import {
  BasePage,
  useQueryRecursive,
  FdTypography,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  listScoreboardsByAssessmentId,
  listTaskOpenedsByUserAssessmentId,
} from '../graphql/queries';
import { getParticipantStatus } from '../shared/utils/getParticipantStatus';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import ProficiencyApproachTable from '../components/Insights/ProficiencyApproachTable';
import {
  getTasksAttemptsByAssessmentId,
  listUsersByAssessmentId,
  listTaskAttemptAggregatesByAssessmentId,
} from '../queries/customQueries';

const AssessmentScoreboardDetail = () => {
  const { assessmentId, id, _userId } = useParams();

  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: _userId || id });

  const { data: listUsersData } = useQueryRecursive(
    gql(listUsersByAssessmentId),
    {
      variables: {
        userAssessmentAssessmentId: assessmentId,
        limit: 500,
      },
      onCompleted: () => {
        // set left margin to zero as this page does not show sidebar
        document.getElementById('competitions').style.marginLeft = 0;
      },
    },
  );

  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData?.listUserAssessments?.items.find(
      (ad) => ad.assessment?.id === assessmentId,
    );

  const listAssessmentUsersData =
    listUsersData?.listUsersByAssessmentId?.items || [];
  const userAssessments = listAssessmentUsersData?.filter((ua) =>
    assessmentDataFiltered?.assessment?.teamBased
      ? ua?.teamId === id
      : ua?.userId === id,
  );
  const userAssessmentStarted = userAssessments?.filter(
    (sd) => ['STARTED', 'FINISHED'].includes(sd?.status) && sd?.startedOn,
  )[0];
  const {
    status: assessmentStatus,
    startDateTime: eventStartDate,
    endDateTime: eventEndDate,
  } = assessmentDataFiltered?.assessment || {};

  const {
    data: listTaskAttemptAggregatesData,
    loading: listTaskAttemptAggregatesLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 1000,
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
  });

  const { data: tasksOpenedData, loading: tasksOpenedLoading } =
    useQueryRecursive(gql(listTaskOpenedsByUserAssessmentId), {
      variables: {
        taskOpenedUserAssessmentId:
          assessmentDataFiltered?.userAssessmentAssessmentId,
        limit: 1000,
      },
      skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    });

  const tasksOpened =
    tasksOpenedData?.listTaskOpenedsByAssessmentId?.items?.map((a) => ({
      ...a,
      userId: a.teamId ? a.teamId : a.userId,
      taskId: a.taskOpenedTaskId,
    })) || [];

  // get all task attempts for the assessment
  const {
    data: getTasksAttemptsByUserAssessmentIdData,
    loading: getTasksAttemptsByUserAssessmentIdLoading,
  } = useQueryRecursive(gql(getTasksAttemptsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 1000,
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    staleTime: { minutes: 10 },
  });

  const taskAttemptsDetail =
    getTasksAttemptsByUserAssessmentIdData?.listTaskAttemptsByAssessmentId?.items?.map(
      (a) => ({
        ...a,
        userId: a.teamId ? a.teamId : a.userId,
      }),
    ) || [];

  const allTaskAttemptsUserWise =
    listTaskAttemptAggregatesData?.listTaskAttemptAggregatesByAssessmentId?.items
      ?.map((a) => ({
        ...a,
        teamId: a.teamId || a.userId,
        success: a.status === 'COMPLETED',
      }))
      ?.filter((at) => id === at?.teamId);
  const completedTasks = allTaskAttemptsUserWise?.filter(
    (a) => a.status === 'COMPLETED',
  );

  const { data: listTaskAttemptsData, loading: listTaskAttemptsLoading } =
    useQueryRecursive(gql(listScoreboardsByAssessmentId), {
      variables: {
        assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
        limit: 2000,
      },
      skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
      staleTime: { minutes: 0 },
    });

  // all attempts
  const allAttempts =
    listTaskAttemptsData?.listScoreboardsByAssessmentId?.items?.filter(
      (a) => a?.points > 0,
    ) || [];

  const teamBased = assessmentDataFiltered?.assessment?.teamBased;
  const teamIds =
    assessmentDataFiltered?.assessment?.teams?.items
      ?.filter((t) => t?.status !== 'REMOVED')
      ?.map((t) => t.teamId) || [];
  const allAttemptsTeamsOrParticipants =
    (teamBased
      ? allAttempts
          .filter((t) => teamIds.includes(t.teamId))
          .map((a) => ({
            ...a,
            userId: a.teamId,
            participant: a.team?.name,
          }))
      : allAttempts
          .filter((t) => id?.includes(t.userId))
          .map((a) => ({
            ...a,
            participant: a.user?.alias || '',
          }))) || [];

  const rank =
    _.orderBy(
      allAttempts,
      ['points', 'firstBloods', 'successRate'],
      ['desc', 'desc', 'desc'],
    )
      ?.map((a, i) => ({ ...a, rank: a.points > 0 ? i + 1 : '-' }))
      .filter((t) =>
        teamBased ? id?.includes(t.teamId) : id?.includes(t.userId),
      )?.[0]?.rank || '-';

  const selectedTeamOrCandidate = allAttemptsTeamsOrParticipants?.find((a) =>
    teamBased ? a?.teamId === id : a?.userId === id,
  );

  const tasksCompleted = selectedTeamOrCandidate?.flags || 0;
  const firstBloods = selectedTeamOrCandidate?.firstBloods || 0;
  const totalPoints = selectedTeamOrCandidate?.points || 0;
  const totalTasks =
    assessmentDataFiltered?.assessment?.tasks?.items?.length ||
    assessmentDataFiltered?.assessment?.levels?.items
      ?.map((l) =>
        l.tasks?.items?.map((_task) => ({
          ..._task.task,
        })),
      )
      .flat()?.length;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: assessmentDataFiltered?.assessment?.name,
            path: `/competitions/competition-tasks/${assessmentId}`,
            type: 'COMPETITION',
          },
        ]}
        page={{ name: 'Scoreboard' }}
      />
      <BasePage
        heading={
          teamBased
            ? selectedTeamOrCandidate?.team?.name
            : selectedTeamOrCandidate?.participant
        }
        data-cy="scoreboard-detail-page"
      >
        <Box mt={2} mb={3}>
          <FdSkeleton
            loading={assessmentTasksLoading}
            height="115px"
            width="100%"
          >
            <AssessmentSummary
              assessment={{
                rank,
                tasksCompleted,
                firstBloods,
                totalPoints: totalPoints || 0,
                teamBased,
                teamId: assessmentDataFiltered?.teamId,
                eventStatus: assessmentDataFiltered?.status
                  ? getParticipantStatus(assessmentDataFiltered?.status)
                  : '',
                totalTasks: totalTasks || 0,
              }}
              scoreBoardDetail
            />
          </FdSkeleton>
        </Box>
        <FdTypography variant="h4">Competition Approach</FdTypography>
        <Box>
          <FdTypography mt={0.5} color="secondary" variant="captiontext1">
            {teamBased
              ? `This visualisation maps the team's journey, showing when they viewed,
          started, attempted, and solved challenges, revealing their time
          allocation and competition approach. These actions are depicted using
          unique icons, and you can find their explanations in the legend below.
          In instances where actions follow immediately after one another, you
          may see only one icon representing the most recent action.`
              : 'View details about this participant’s approach in the competition below.'}
          </FdTypography>
        </Box>
        <Box mt={3}>
          <ProficiencyApproachTable
            taskAttempts={allTaskAttemptsUserWise}
            taskAttemptsDetail={
              taskAttemptsDetail?.filter((t) => t?.userId === id) || []
            }
            startDateTime={userAssessmentStarted?.createdAt}
            loading={assessmentTasksLoading}
            tableLoading={
              tasksOpenedLoading ||
              listTaskAttemptsLoading ||
              listTaskAttemptAggregatesLoading ||
              getTasksAttemptsByUserAssessmentIdLoading
            }
            tasks={assessmentDataFiltered?.assessment?.tasks || []}
            completedTasks={completedTasks}
            selectedUserIds={id}
            teamBased={teamBased}
            tasksOpened={tasksOpened}
            assessmentStatus={assessmentStatus}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            isParticipant
          />
        </Box>
      </BasePage>
    </Box>
  );
};

export default AssessmentScoreboardDetail;

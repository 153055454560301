import React, { useState } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import WarningIcon from '@mui/icons-material/Warning';
import _ from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import {
  useParams,
  useLocation,
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMonths, differenceInMinutes } from 'date-fns';
import {
  ReactQueryClient,
  BasePage,
  FdTab,
  FdButton,
  FdModal,
  FdTypography as Typography,
  FdChip,
  FdTable,
  FdSkeleton,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  Authorization,
  useRecentLinks,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { useQuery, gql, useMutation } from '@apollo/client';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import {
  addUsers,
  removeUserFromAssessment,
  removeInvitedUser,
  updateAssessment,
} from '../graphql/mutations';
import { InviteUsers, Summary, Scoreboard } from '../components/Assessment';
import {
  getTasksByAssessmentId as getAssessment,
  listCourseUsersByOrgId,
  listAssessmentsByOrg,
} from '../queries/customQueries';
import { getAssessmentStatus, getTotal } from '../shared/utils/taskUtils';
import {
  listUsersByAssessmentId,
  listInvitedUsersByAssessmentId,
  listUsersByOrgId,
  getSystemTime,
  listScoreboardsByAssessmentId,
  listTaskAttemptAggregatesByStatus,
  listTaskOpenedsByAssessmentId,
  listTaskAttemptAggregatesByAssessmentId,
  listTeamsByEventId,
  listUserScoreboardsByAssessmentId,
} from '../graphql/queries';
import Overview from './Overview';
import {
  getParticipantStatusColor,
  getAssessmentStatusColor,
} from '../shared/utils/getStatusColor';
import { getParticipantStatus } from '../shared/utils/getParticipantStatus';
import {
  formatMinutes,
  getDateTimeZoneFormatted,
} from '../shared/utils/dateUtils';
import { AVAILABILITY_TYPES, ASSESSMENT_TYPES } from '../constants';
import TeamTab from '../components/Teams/TeamTab';
import {
  validationSchema,
  initialValues,
} from '../validation-schemas/Competitions';
import { invalidateAdminHomePageDataQuery } from '../queries/invalidateQueries';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';
import { getDifficultyLabel } from '../shared/utils/difficultyMapping';
import ViewDetails from '../components/View/ViewDetails';
import ViewChallenges from '../components/View/ViewChallenges';
import Insights from './Insights';
import ListEventTags from '../components/EventTag/ListEventTags';
import AdminAnnouncements from '../components/Assessment/AdminAnnouncements';
import AdminChatForum from '../components/Assessment/AdminChatForum';

const useStyles = makeStyles()({
  buttonStart: {
    backgroundColor: '#1976D2',
    '&:focus': {
      backgroundColor: '#1976D2 !important',
    },
  },
});

const ViewAssessment = () => {
  const { assessmentId } = useParams();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { classes } = useStyles();
  const globalSnap = useSnapshot(globalStore);
  const tabindex = new URLSearchParams(search).get('tabindex') || 3; // defaults to details tab
  const [editTasks, setEditTasks] = useState(false);
  const [inviteUsers, setInviteUsers] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [deleteParticipant, setDeleteParticipant] = useState(false);
  const [showStartEndCompetition, setShowStartEndCompetition] = useState({
    start: false,
    end: false,
  });
  const [deletedParticipantId, setDeletedParticipantId] = useState('');
  const [deletedInvitedParticipantId, setDeletedInvitedParticipantId] =
    useState('');
  const [custIndex, setCustIndex] = useState(tabindex);
  const [executionQueue, setExecutionQueue] = useState([]);
  const [selectionFromLeaderBoard, setSelectionFromLeaderBoard] =
    useState(undefined);
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });

  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { watch, getValues, reset } = reactHookFormMethods;
  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };

  const {
    data: serverTime,
    loading: serverTimeLoading,
    refetch: refetchServerTime,
  } = useQuery(gql(getSystemTime), {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (_data) => {
      for (let i = 0; i < executionQueue.length; i += 1) {
        const fn = executionQueue[i];
        fn(_data);
      }
      setExecutionQueue([]);
    },
  });

  const [updateAssessmentMutation, { loading: updateLoading }] = useMutation(
    gql(updateAssessment),
  );

  const { data: listTaskAttemptsData, loading: listTaskAttemptsLoading } =
    useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
      variables: {
        assessmentId,
        limit: 1000,
      },
    });

  const {
    data: listInvitedUsersByAssessmentIdData,
    loading: listInvitedUsersByAssessmentIdLoading,
    refetch: refetchListInvitedUsersByAssessmentId,
  } = useQueryRecursive(gql(listInvitedUsersByAssessmentId), {
    variables: {
      assessmentId,
      limit: 500,
    },
  });

  const {
    data: assessmentData,
    loading: assessmentDataLoading,
    refetch: refetchAssessmentData,
  } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentId,
      hasPermission: hasManagePermission,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const assessmentEndDateTime = data.getAssessment?.endDateTime;
      const defaultLabExpiryDateTime = assessmentEndDateTime
        ? new Date(assessmentEndDateTime)
        : addMonths(new Date(), 2);
      const multiLevelLabUsage = data.getAssessment?.multiLevel
        ? data?.getAssessment?.levels?.items
            ?.map((l) =>
              l?.tasks?.items?.filter(
                (assessmentTask) => assessmentTask.task.type === 'LAB',
              ),
            )
            .flat()
        : data.getAssessment.tasks.items?.filter(
            (assessmentTask) => assessmentTask.task.type === 'LAB',
          );

      reset({
        maxTeamSize: data.getAssessment?.maxTeamSize,
        assessmentType: data.getAssessment?.teamBased
          ? { id: 'assessmentType', value: ASSESSMENT_TYPES.TEAM_BASED }
          : {
              id: 'assessmentType',
              value: ASSESSMENT_TYPES.INDIVIDUAL_BASED,
            },
        guided: data.getAssessment?.guided || false,
        multiLevel: data.getAssessment?.multiLevel || false,
        jumpbox: data.getAssessment?.enableJumpbox === 'TRUE',
        enableVPN: data.getAssessment?.enableVPN === 'TRUE',
        scoreboardVisibility: !data?.getAssessment?.hideScoreBoard,
        levels: !data.getAssessment?.multiLevel
          ? null
          : data.getAssessment?.level,
        name: data.getAssessment?.name,
        description: data.getAssessment?.description,
        videoUrl: data.getAssessment?.videoUrl,
        availabilityType:
          data.getAssessment?.availabilityType === 'MANUAL'
            ? { id: 'availabilityType', value: AVAILABILITY_TYPES.MANUAL }
            : { id: 'availabilityType', value: AVAILABILITY_TYPES.AUTOMATIC },
        startDateTime: data.getAssessment?.startDateTime
          ? new Date(data.getAssessment?.startDateTime)
          : null,
        endDateTime: data.getAssessment?.endDateTime
          ? new Date(data.getAssessment?.endDateTime)
          : null,
        preMessage: data.getAssessment?.preMessage,
        postMessage: data.getAssessment?.postMessage,
        story: data.getAssessment?.story,
        tasks: data.getAssessment?.multiLevel
          ? data.getAssessment?.levels?.items
              ?.map((l) =>
                l.tasks.items?.map((t) => ({
                  level: l.levelNumber,
                  taskId: t.taskId,
                  taskAssessmentId: t.id,
                  task: t,
                })),
              )
              .flat() || []
          : data.getAssessment?.tasks.items?.map((task) => ({
              taskId: task.taskId,
              taskAssessmentId: task.id,
            })) || [],
        labControls: multiLevelLabUsage.reduce((acc, curr) => {
          const {
            duration,
            expiry,
            inactivityExpiry,
            initialLabCount,
            minLabCount,
          } = curr?.modulePart || {
            duration: 120,
            expiry: defaultLabExpiryDateTime,
            inactivityExpiry: 14,
            initialLabCount: 0,
            minLabCount: 0,
          };
          return {
            ...acc,
            [curr.taskId]: {
              labControlDefined: true,
              labDuration: duration,
              labExpiryDateTime: expiry,
              labAbandonment: inactivityExpiry,
              initialLabPool: initialLabCount,
              minimumLabPool: minLabCount,
            },
          };
        }, {}),
        taskLabs: multiLevelLabUsage.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.taskId]: curr.modulePartId,
          };
        }, {}),
      });
      // add recent link
      addRecentLink({
        id: assessmentId,
        name: data.getAssessment?.name,
        type: 'COMPETITION',
        url: pathname + search,
        role: 'MANAGE',
      });
    },
  });

  const { data: listUsersData, refetch: refetchListUsersByAssessmentId } =
    useQueryRecursive(gql(listUsersByAssessmentId), {
      variables: {
        userAssessmentAssessmentId: assessmentId,
        limit: 500,
      },
    });

  const {
    data: listTaskAttemptsScoreboardData,
    loading: listTaskAttemptsScoreboardLoading,
    refetch: refetchListTaskAttemptsScoreboardData,
  } = useQueryRecursive(gql(listScoreboardsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 2000,
    },
    skip: !assessmentId,
  });

  const {
    data: listUserScoreboardData,
    loading: listUserScoreboardDataLoading,
    refetch: refetchUserScoreboardData,
  } = useQueryRecursive(gql(listUserScoreboardsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 2000,
    },
    skip: !assessmentId,
  });

  const {
    data: listTaskAttemptAggregatesData,
    loading: listTaskAttemptAggregatesLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByStatus), {
    variables: {
      status: 'COMPLETED',
      assessmentId: { eq: assessmentId },
      limit: 2000,
    },
    skip: !assessmentId,
  });

  const { data: listOrgUsersData, loading: listOrgUsersLoading } =
    useQueryRecursive(gql(listUsersByOrgId), {
      variables: {
        orgId: globalSnap?.orgId,
      },
      staleTime: { hours: 24 },
    });

  const {
    data: listAllFinishedAssessmentsData,
    loading: listAllFinishedAssessmentsLoading,
  } = useQueryRecursive(gql(listAssessmentsByOrg), {
    variables: {
      orgId: globalSnap.orgId,
      limit: 500,
    },
  });
  const {
    data: listCourseUsersByOrgIdData,
    loading: listCourseUsersByOrgIdLoading,
  } = useQueryRecursive(gql(listCourseUsersByOrgId), {
    variables: {
      orgId: globalSnap?.orgId,
    },
  });

  const {
    data: teamsAssessmentData,
    loading: teamsAssessmentDataLoading,
    refetch: refetchListTeamsByEventId,
  } = useQueryRecursive(gql(listTeamsByEventId), {
    variables: {
      eventId: assessmentId,
    },
  });

  const {
    data: tasksOpenedData,
    loading: tasksOpenedLoading,
    refetch: refetchTasksOpened,
  } = useQueryRecursive(gql(listTaskOpenedsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
  });

  const {
    data: listTaskAttemptsAggregateData,
    loading: listTaskAttemptsAggregateDataLoading,
    refetch: refetchListTaskAttemptsAggregate,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
  });

  const hideModalAndRefetch = () => {
    setInviteUsers(false);
    setRouteIndex(3);
    refetchListInvitedUsersByAssessmentId();
    refetchListUsersByAssessmentId();
  };

  const [addUsersMutation, { loading: createParticipantLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: () => {
        hideModalAndRefetch();
      },
      onError: () => {
        hideModalAndRefetch();
      },
    },
  );

  const [removeParticipantMutation, { loading: removeParticipantLoading }] =
    useMutation(gql(removeUserFromAssessment), {
      variables: {
        userAssessmentId: deletedParticipantId,
      },
      onCompleted: (_data) => {
        refetchListUsersByAssessmentId();
        setDeleteParticipant(false);
      },
    });

  const [removeInvitedUserMutation, { loading: removeInvitedUserLoading }] =
    useMutation(gql(removeInvitedUser), {
      variables: {
        inviteToken: deletedInvitedParticipantId,
      },
      onCompleted: (_data) => {
        refetchListInvitedUsersByAssessmentId();
        setRouteIndex(3);
        setDeleteParticipant(false);
      },
    });

  // update participant data with attempts
  const allAttemptFiltered = (userAssessmentId) => {
    const attempts =
      listTaskAttemptsData?.listTaskAttemptAggregatesByAssessmentId?.items?.filter(
        (listTaskAttemptTask) =>
          listTaskAttemptTask?.userAssessmentId === userAssessmentId,
      );
    return attempts;
  };

  const listCourseUsersByOrgIdItems =
    listCourseUsersByOrgIdData?.listCourseUsersByOrgId?.items || [];

  const coursesAsFinishedAssessments = listCourseUsersByOrgIdItems.reduce(
    (acc, cur) => {
      const parentRecord = acc.find((record) => record.id === cur.courseId);
      if (parentRecord) {
        parentRecord.users.items.push({
          id: cur.user?.id,
          user: { email: cur.user?.email, type: cur.user?.type },
        });
      } else {
        acc.push({
          id: cur.courseId,
          name: cur.course?.name || '-',
          participantEventType: 'COURSE',
          status: 'FINISHED',
          users: {
            items: [
              {
                id: cur.user?.id,
                user: { email: cur.user?.email, type: cur.user?.type },
              },
            ],
          },
        });
      }
      return acc;
    },
    [],
  );

  const listOrgUsers = listOrgUsersData?.listUsersByOrgId?.items || [];
  const allFinishedAssessments = [
    ...(listAllFinishedAssessmentsData?.listAssessmentsByOrg?.items || []),
    ...(coursesAsFinishedAssessments || []),
  ];

  const isEventGuided = getValues('guided');
  const tasksOpened =
    tasksOpenedData?.listTaskOpenedsByAssessmentId?.items || [];

  const allAttempts =
    listTaskAttemptsData?.listTaskAttemptAggregatesByAssessmentId?.items || [];

  const invitedParticipant =
    listInvitedUsersByAssessmentIdData?.listInvitedUsersByAssessmentId?.items
      .filter((invitedUser) => !invitedUser.accepted)
      .map((participant) => ({
        id: participant.id,
        attempted: '-',
        name: '-',
        started: '-',
        taskSolved: '-',
        points: '-',
        participantType: participant.participantType
          ? upperCaseFirstLetter(participant.participantType)
          : '',
        email: participant.email,
        status: 'INVITED',
      }));

  const { teamBased, startDateTime, endDateTime, teams } =
    assessmentData?.getAssessment || {
      teamBased: false,
      startDateTime: undefined,
      endDateTime: undefined,
      teams: [],
    };

  const status = getAssessmentStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );
  const isCompetitionEnded = status === 'Ended';

  const refreshViewAssessmentQueries = () => {
    refetchAssessmentData();
    refetchListTaskAttemptsScoreboardData();
    refetchUserScoreboardData();
    refetchListTeamsByEventId();
    refetchTasksOpened();
    refetchListTaskAttemptsAggregate();
    refetchListUsersByAssessmentId();
  };

  const statusColor = getAssessmentStatusColor(status);

  const participantRows = listUsersData?.listUsersByAssessmentId?.items?.map(
    (userAssessment) => {
      const getStatus = (userStatus, assessmentStatus) => {
        if (userStatus === 'NOT_STARTED' && assessmentStatus === 'Ended')
          return 'NOT COMPLETED';
        if (userStatus === 'NOT_STARTED' && assessmentStatus !== 'Ended')
          return 'NOT STARTED';
        return userStatus;
      };

      return {
        id: userAssessment.id,
        userId: userAssessment?.user?.id,
        name: userAssessment.user?.name,
        alias: userAssessment.user?.alias,
        email: userAssessment.user?.email,
        participantType: upperCaseFirstLetter(
          userAssessment.user?.participantType,
        ),
        status: getStatus(userAssessment.status, status),

        started: userAssessment.startedOn
          ? getDateTimeZoneFormatted(userAssessment.startedOn, true)
          : '-',
        startedOn: userAssessment.startedOn,
        finishedOn:
          userAssessment.finishedOn || userAssessment?.assessment?.endDateTime,
      };
    },
  );

  const timeSpentDataForAssessment = teamBased
    ? teamsAssessmentData?.listTeamsByEventId?.items.map((ga) => ({
        userId: ga?.teamId,
        duration: ga.startedOn
          ? differenceInMinutes(
              new Date(ga.finishedOn || ga?.event?.endDateTime || new Date()),
              new Date(ga.startedOn),
            )
          : 0,
      }))
    : participantRows?.map((p) => ({
        userId: p.userId,
        duration: differenceInMinutes(
          new Date(p.finishedOn || new Date()),
          new Date(p.startedOn),
        ),
      })) || [];

  const filteredParticipantRows = participantRows?.map((participantRow) => {
    if (listTaskAttemptsData) {
      const attempts = allAttemptFiltered(participantRow.id);

      const tasksAttemptSuccesstasksAttemptSuccess = attempts?.filter(
        (attempt) => attempt.status === 'COMPLETED',
      );

      const taskSolved = tasksAttemptSuccesstasksAttemptSuccess?.length;

      // getTotal adds up the total of array of object
      const points =
        getTotal(tasksAttemptSuccesstasksAttemptSuccess, 'points') || 0;
      return {
        ...participantRow,
        duration:
          participantRow?.duration !== 0 && participantRow.status === 'FINISHED'
            ? formatMinutes(participantRow.duration)
            : '-',
        taskSolved,
        points,
        attempted: attempts?.length !== 0,
      };
    }
    return participantRow;
  });

  const assessmentTasks = assessmentData?.getAssessment?.multiLevel
    ? assessmentData?.getAssessment?.levels?.items
        ?.map((l) =>
          l.tasks.items?.map((t) => ({
            ...t.task,
            level: l.levelNumber,
            taskId: t.taskId,
            id: t.taskId,
            taskAssessmentId: t.id,
          })),
        )
        .flat() || []
    : assessmentData?.getAssessment?.tasks?.items?.map((t) => ({
        ...t.task,
        taskId: t.taskId,
        taskAssessmentId: t.id,
      })) || [];

  const labControls = watch('labControls');
  const getEditButtonStatus = () => {
    if (status === 'Ended' || !hasManagePermission) return false;

    if (['Not Started', 'In Progress'].includes(status)) return true;

    const hasParticipantStartedOrFinished = participantRows?.some(
      (participant) =>
        participant.status === 'STARTED' || participant.status === 'FINISHED',
    );

    if (status === 'In Progress' && !hasParticipantStartedOrFinished)
      return true;

    return false;
  };
  const showEditButton = getEditButtonStatus();

  const columns = [
    { field: 'name', flex: 1, headerName: 'Name' },
    { field: 'alias', flex: 1, headerName: 'Alias' },
    { field: 'email', flex: 1, headerName: 'Email' },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      valueGetter: (params) => getParticipantStatus(params?.value),
      renderCell: (params) => (
        <FdChip
          color={getParticipantStatusColor(params?.row?.status)}
          size="small"
          label={getParticipantStatus(params?.row?.status)}
        />
      ),
    },
    { field: 'started', flex: 1, headerName: 'Started' },
    { field: 'taskSolved', flex: 1, headerName: 'Challenge Solved' },
    // We don't use participant type atm, which means this column is always empty
    // { field: 'participantType', flex: 1, headerName: 'Type' },
    { field: 'points', flex: 1, headerName: 'Points' },
  ];
  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;
        return (
          hasManagePermission &&
          ['NOT STARTED', 'INVITED']?.includes(rowData?.status) && (
            <FdButton
              variant="tertiary"
              size="small"
              onClick={() => {
                if (rowData.status === 'INVITED') {
                  setDeleteParticipant(true);
                  setDeletedInvitedParticipantId(rowData.id);
                }
                if (rowData.status === 'NOT STARTED') {
                  setDeleteParticipant(true);
                  setDeletedParticipantId(rowData.id);
                }
              }}
            >
              DELETE
            </FdButton>
          )
        );
      },
    },
  ];

  const participantInvitedCount =
    listInvitedUsersByAssessmentIdData?.listInvitedUsersByAssessmentId?.items?.filter(
      (participant) => !participant.accepted,
    ).length;

  const participantNotRemovedCount =
    listUsersData?.listUsersByAssessmentId?.items?.filter(
      (userAssessment) => userAssessment.status !== 'REMOVED',
    ).length;

  const invitedCount = participantInvitedCount + participantNotRemovedCount;

  const participants = () => (
    <Box>
      <Summary
        loading={assessmentDataLoading}
        data={[
          {
            value: (
              <Box mb={1}>
                <FdChip color={statusColor} size="small" label={status} />
              </Box>
            ),
            description: 'Competition Status',
          },
          ...(status === 'In Progress' && endDateTime
            ? [
                {
                  value: (
                    <Box mb={1}>
                      <FdSystemDateCountdown endDateTime={endDateTime} />
                    </Box>
                  ),
                  description: 'Time remaining',
                },
              ]
            : []),
          {
            value: <Box mb={1}>{invitedCount}</Box>,
            description: 'Participants Invited',
          },
        ]}
        titleVariant="subtitle1"
        subtitleVariant="subtitle2"
      />
      <Box mt={2} mb={2} height="631px" width="100%">
        <FdSkeleton loading={assessmentDataLoading} height="631px">
          <FdTable
            toolbarSettings={{
              title: 'Participants',
              headerActions:
                hasManagePermission && !isCompetitionEnded
                  ? [
                      {
                        label: 'Invite',
                        onClick: () => {
                          ReactQueryClient.removeQueries(['ListUsersByOrgId']);
                          setInviteUsers(true);
                        },
                      },
                    ]
                  : [],
              filterButton: true,
              searchBox: true,
            }}
            actions={actions}
            rows={[
              ...(filteredParticipantRows?.filter(
                (participant) => participant?.status !== 'REMOVED',
              ) || []),
              ...(invitedParticipant || []),
            ]}
            columns={columns}
            pagination
            visibleSelection
            rowsPerPageOptions={[5, 10, 20]}
            tablePageSize={10}
            loading={listInvitedUsersByAssessmentIdLoading}
            gridId="competitions-view-invited-participants"
          />
        </FdSkeleton>
        {inviteUsers && (
          <InviteUsers
            title="Invite Participants"
            users={inviteUsers}
            usersCallBack={async (newUserEmails, showToast = true) => {
              if (!newUserEmails) {
                setInviteUsers(false);
                if (showToast) {
                  warningToastMessage('User(s) not invited');
                }
                return;
              }
              const existingUsers = [
                ...(filteredParticipantRows || []),
                ...(invitedParticipant || []),
              ];
              const existingEmails = existingUsers?.map((eu) =>
                eu?.email?.toLowerCase(),
              );
              const usersToAdd = [
                ...new Set(
                  newUserEmails.filter(
                    (nu) => !existingEmails.includes(nu.toLowerCase()),
                  ),
                ),
              ];
              if (usersToAdd.length === 0) {
                warningToastMessage('No new users to invite');
                return;
              }
              // add users individually
              await Promise.all(
                usersToAdd?.map((user) =>
                  addUsersMutation({
                    variables: {
                      emails: [user],
                      orgId: globalSnap?.orgId,
                      type: 'PARTICIPANT',
                      assessmentId,
                      participantEventType: 'COMPETITION',
                    },
                  }),
                ),
              );
              successToastMessage('Success! Invite(s) sent');
            }}
            loading={createParticipantLoading}
            orgUsersLoading={listOrgUsersLoading}
            titleFor="Participants"
            listOrgUsers={listOrgUsers}
            listAllFinishedAssessments={allFinishedAssessments}
            assessmentId={assessmentId}
            finishedAssessmentsLoading={
              listAllFinishedAssessmentsLoading || listCourseUsersByOrgIdLoading
            }
          />
        )}
      </Box>
    </Box>
  );

  const labBasedSelectedTasks = assessmentTasks
    ?.filter((taskItem) => taskItem?.type === 'LAB')
    ?.map((labBasedTask) => ({
      ...labBasedTask,
      difficulty: getDifficultyLabel(labBasedTask.difficulty),
      specialtyName: labBasedTask?.specialty?.name,
      skills: labBasedTask?.skills?.items.map((s) => s.skill?.name),
      status:
        labControls?.[labBasedTask.id] &&
        labControls?.[labBasedTask.id]?.labControlDefined
          ? 'Defined'
          : 'Undefined',
    }));

  const closeStartEndCompetitionModal = () =>
    setShowStartEndCompetition({
      start: false,
      end: false,
    });
  const teamBasedText = teamBased ? 'teams' : 'the participants';
  const startOrEndCompetition = (_data) => {
    const params = showStartEndCompetition.start
      ? {
          startDateTime: new Date(_data?.getSystemTime).toISOString(),
          status: 'STARTED',
        }
      : {
          endDateTime: new Date(_data?.getSystemTime).toISOString(),
          status: 'FINISHED',
        };

    updateAssessmentMutation({
      variables: {
        input: {
          id: assessmentId,
          ...params,
        },
      },
      onCompleted: () => {
        invalidateAdminHomePageDataQuery({ orgId: globalSnap?.orgId });
        closeStartEndCompetitionModal();
        refetchAssessmentData();
        refetchServerTime();
        setRouteIndex(1);
        successToastMessage('Competition updated');
      },
    });
  };

  const hideUsageControl =
    globalSnap.orgPricingTier !== 'CUSTOM' ||
    labBasedSelectedTasks?.length === 0 ||
    !hasManagePermission ||
    isCompetitionEnded;

  const announcementsTabIndex = hideUsageControl ? 7 : 8;
  const chatForumTabIndex = hideUsageControl ? 8 : 9;
  const userScoreboardsData =
    listUserScoreboardData?.listUserScoreboardsByAssessmentId?.items || [];
  const getTeamAvg = (teamId) => {
    const teamScoreboard =
      userScoreboardsData.filter((u) => u?.teamId === teamId) || [];
    return {
      speed: _.round(_.meanBy(teamScoreboard, 'speed'), 2),
      efficiency: _.round(_.meanBy(teamScoreboard, 'efficiency'), 2),
    };
  };
  const scoreboardAllAttempts =
    listTaskAttemptsScoreboardData?.listScoreboardsByAssessmentId?.items
      ?.filter((a) => a?.points > 0)
      ?.map((a) => {
        const userScoreboard = teamBased
          ? getTeamAvg(a?.teamId)
          : userScoreboardsData.find((u) => u?.userId === a?.userId) || {};
        return {
          ...a,
          speed: userScoreboard?.speed || 0,
          efficiency: userScoreboard?.efficiency || 0,
        };
      }) || [];
  const compName = getValues('name');

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: compName, type: 'COMPETITION' }} />
      <BasePage
        heading={
          <FdSkeleton loading={assessmentDataLoading}>{compName}</FdSkeleton>
        }
        headingAdornment={
          hasManagePermission && (
            <FdSkeleton loading={assessmentDataLoading}>
              {(!startDateTime && !endDateTime) ||
              (startDateTime && !endDateTime) ? (
                <FdButton
                  size="large"
                  style={
                    startDateTime && {
                      backgroundColor: '#C62828',
                      color: '#FFFFFF',
                      borderColor: '#C62828',
                    }
                  }
                  disabled={!hasManagePermission}
                  className={!startDateTime && classes.buttonStart}
                  onClick={() =>
                    !startDateTime
                      ? setShowStartEndCompetition({
                          ...showStartEndCompetition,
                          start: true,
                        })
                      : setShowStartEndCompetition({
                          ...showStartEndCompetition,
                          end: true,
                        })
                  }
                >
                  {!startDateTime ? 'Start Competition' : 'End Competition'}
                </FdButton>
              ) : null}
            </FdSkeleton>
          )
        }
      >
        <FdModal
          size="sm"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#d32f2f',
                  paddingRight: '0.5rem',
                }}
              />
              <span>
                {showStartEndCompetition.start
                  ? 'Start Competition'
                  : 'End Competition'}
              </span>
            </Box>
          }
          description={
            <Box>
              <Typography variant="subtitle1">
                {showStartEndCompetition.start
                  ? 'Are you sure you want to start the competition?'
                  : 'Are you sure you want to end the competition?'}
              </Typography>
              <Box mt={2}>
                <Typography variant="body1" color="secondary">
                  {showStartEndCompetition.start
                    ? `This will start the competition for all ${teamBasedText}`
                    : `This will end the competition for all ${teamBasedText}`}
                </Typography>
              </Box>
            </Box>
          }
          confirm={
            serverTimeLoading || updateLoading
              ? 'Loading...'
              : showStartEndCompetition.start
                ? 'START COMPETITION'
                : 'END COMPETITION'
          }
          dismiss="CANCEL"
          open={showStartEndCompetition.start || showStartEndCompetition.end}
          onDismiss={closeStartEndCompetitionModal}
          onConfirm={() => {
            setExecutionQueue((prevQueue) => [
              ...prevQueue,
              startOrEndCompetition,
            ]);
            refetchServerTime();
          }}
        />
        <FdModal
          size="xs"
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Delete Participant?</span>
            </Box>
          }
          description={
            <Box>
              <Typography variant="subtitle1">
                Are you sure you want to delete the participant?
              </Typography>
              <Box mt={2}>
                <Typography variant="body1" color="secondary">
                  This will prevent the Participant from attempting the
                  competition. The participant will not be notified that they
                  have been deleted.
                </Typography>
              </Box>
            </Box>
          }
          dismiss={
            removeParticipantLoading || removeInvitedUserLoading
              ? 'Loading...'
              : 'DELETE USER'
          }
          confirm="CANCEL"
          open={deleteParticipant}
          onDismiss={() => {
            if (deletedInvitedParticipantId) {
              removeInvitedUserMutation();
              setDeletedInvitedParticipantId('');
              successToastMessage(
                'The Participant has been successfully deleted',
              );
            }
            if (deletedParticipantId) {
              removeParticipantMutation();
              setDeletedParticipantId('');
              successToastMessage(
                'The Participant has been successfully removed',
              );
            }
          }}
          onConfirm={() => {
            setDeleteParticipant(false);
            setDeletedParticipantId('');
            setDeletedInvitedParticipantId('');
            warningToastMessage('No Participant were removed from Competition');
          }}
        />
        <FdTab
          label={[
            {
              label: 'Overview',
              tabRoute: `/competitions/view/${assessmentId}?tabindex=0`,
              index: 0,
              data: (
                <Overview
                  assessmentId={assessmentId}
                  assessmentData={assessmentData}
                  allTaskAttempts={allAttempts}
                  serverTime={serverTime?.getSystemTime}
                  loading={assessmentDataLoading || listTaskAttemptsLoading}
                  onLeaderBoardSelection={(id) => {
                    setSelectionFromLeaderBoard(id);
                    setRouteIndex(4);
                  }}
                />
              ),
            },
            {
              label: 'Details',
              tabRoute: `/competitions/view/${assessmentId}?tabindex=1`,
              index: 1,
              data: (
                <FdSkeleton loading={assessmentDataLoading} height="740px">
                  <FormProvider {...reactHookFormMethods}>
                    <ViewDetails
                      hasManagePermission={hasManagePermission}
                      status={status}
                      assessmentData={assessmentData}
                      showEditButton={showEditButton}
                      editDetails={editDetails}
                      setEditDetails={setEditDetails}
                      isEventGuided={isEventGuided}
                    />
                  </FormProvider>
                </FdSkeleton>
              ),
            },
            {
              label: 'Challenges',
              tabRoute: `/competitions/view/${assessmentId}?tabindex=2`,
              index: 2,
              data: (
                <FdSkeleton loading={assessmentDataLoading} height="740px">
                  <FormProvider {...reactHookFormMethods}>
                    <ViewChallenges
                      status={status}
                      assessmentTasks={assessmentTasks}
                      assessmentData={assessmentData}
                      showEditButton={showEditButton}
                      refetchAssessmentData={refetchAssessmentData}
                    />
                  </FormProvider>
                </FdSkeleton>
              ),
            },
            {
              label: teamBased ? 'Teams' : 'Participants',
              tabRoute: `/competitions/view/${assessmentId}?tabindex=3`,
              index: 3,
              data: teamBased ? (
                <TeamTab
                  orgId={globalSnap?.orgId}
                  assessmentId={assessmentId}
                  status={status}
                  statusColor={statusColor}
                  maxTeamSize={getValues('maxTeamSize')}
                  loading={assessmentDataLoading}
                />
              ) : (
                participants()
              ),
            },
            {
              label: 'Insights',
              tabRoute: `/competitions/view/${assessmentId}?tabindex=4`,
              index: 4,
              data: (
                <Insights
                  assessmentData={assessmentData}
                  scoreboardData={
                    listTaskAttemptsScoreboardData.listScoreboardsByAssessmentId
                      ?.items || []
                  }
                  loading={
                    assessmentDataLoading || listTaskAttemptsScoreboardLoading
                  }
                  timeSpentDataForAssessment={timeSpentDataForAssessment}
                  tasksOpened={tasksOpened}
                  attemptsData={listTaskAttemptsAggregateData}
                  attemptsLoading={
                    listTaskAttemptsAggregateDataLoading ||
                    tasksOpenedLoading ||
                    teamsAssessmentDataLoading
                  }
                  teamsAssessmentData={teamsAssessmentData}
                  usersData={listUsersData}
                  selectionFromLeaderBoard={selectionFromLeaderBoard}
                  refreshViewAssessmentQueries={refreshViewAssessmentQueries}
                  assessmentStatus={status}
                />
              ),
            },
            ...(isEventGuided
              ? []
              : [
                  {
                    label: 'Scoreboard',
                    tabRoute: `/competitions/view/${assessmentId}?tabindex=5`,
                    index: 5,
                    data: (
                      <Scoreboard
                        assessmentData={assessmentData}
                        allAttempts={scoreboardAllAttempts}
                        attemptsTimeline={
                          listTaskAttemptAggregatesData
                            ?.listTaskAttemptAggregatesByStatus?.items || []
                        }
                        isAdmin
                        serverCurrentTime={serverTime?.getSystemTime}
                        loading={
                          assessmentDataLoading ||
                          listTaskAttemptsScoreboardLoading ||
                          listTaskAttemptAggregatesLoading ||
                          listUserScoreboardDataLoading
                        }
                        onLeaderBoardSelection={(id) => {
                          setSelectionFromLeaderBoard(id);
                          setRouteIndex(4);
                        }}
                      />
                    ),
                  },
                  {
                    label: (
                      <Box>
                        Event Tags
                        <FdChip
                          size="small"
                          label="Optional"
                          style={{ marginLeft: 4 }}
                        />
                      </Box>
                    ),
                    tabRoute: `/competitions/view/${assessmentId}?tabindex=6`,
                    index: 6,
                    data: (
                      <FdSkeleton
                        loading={assessmentDataLoading}
                        height="740px"
                      >
                        <ListEventTags
                          teamBased={teamBased}
                          invitedCount={invitedCount}
                          status={status}
                          hasManagePermission={hasManagePermission}
                        />
                      </FdSkeleton>
                    ),
                  },
                ]),
            {
              label: 'Announcements',
              tabRoute: `/competitions/view/${assessmentId}?tabindex=${announcementsTabIndex}`,
              index: announcementsTabIndex,
              data: (
                <AdminAnnouncements
                  assessmentId={assessmentId}
                  isCompetitionEnded={isCompetitionEnded}
                />
              ),
            },
            ...(isEventGuided
              ? []
              : [
                  {
                    label: 'Chat Forum',
                    tabRoute: `/competitions/view/${assessmentId}?tabindex=${chatForumTabIndex}`,
                    index: chatForumTabIndex,
                    data: (
                      <AdminChatForum
                        assessment={{ assessmentId, teamBased, teams }}
                        isCompetitionEnded={isCompetitionEnded}
                      />
                    ),
                  },
                ]),
          ]}
          onTabChange={() => {
            // clear from edit mode if present during tab switch
            if (editDetails || editTasks) {
              setEditDetails(false);
              setEditTasks(false);
              refetchAssessmentData();
            }
          }}
          // default is details, sometimes participant
          index={parseInt(tabindex, 10)}
          custIndex={parseInt(custIndex, 10)}
          setCustIndex={setRouteIndex}
          tabLinkComponent={RouterLink}
        />
      </BasePage>
    </Box>
  );
};

export default ViewAssessment;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImagePreview = ({ imageSrc, onClose }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      position="relative"
      width={48}
      height={48}
      borderRadius="4px"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ cursor: 'pointer' }}
    >
      <img
        src={imageSrc}
        alt="Preview"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />
      {isHovered && (
        <IconButton
          onClick={onClose}
          sx={(theme) => {
            const isDarkMode = theme.palette.mode === 'dark';
            const primaryColor = isDarkMode ? '#fff' : '#000';
            const secondaryColor = isDarkMode ? '#000' : '#fff';

            return {
              borderRadius: '50%',
              position: 'absolute',
              top: -8,
              right: -7,
              width: '20px',
              height: '20px',
              backgroundColor: primaryColor,
              color: secondaryColor,
              border: `1px solid ${secondaryColor}`,
              '&:hover': {
                backgroundColor: primaryColor,
                color: secondaryColor,
                width: '22px',
                height: '22px',
              },
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.3)',
              zIndex: 1,
            };
          }}
        >
          <CloseIcon sx={{ fontSize: '12px' }} />
        </IconButton>
      )}
    </Box>
  );
};

ImagePreview.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImagePreview;

import React, { useState } from 'react';
import { Box, Divider, Grid, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  addWeeks,
  differenceInCalendarWeeks,
  differenceInMinutes,
  hoursToMinutes,
} from 'date-fns';
import {
  FdTypography,
  FdIconWithTooltip,
  FdSkeleton,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import {
  getDateTimeZoneFormatted,
  formatMinutes,
  sortedByDate,
  getDuration,
} from '../../shared/utils/dateUtils';
import {
  listUserScoreboardsByUserId,
  listCourseUsers,
  getSystemTime,
  listUserMetricsByUserId,
} from '../../graphql/queries';
import { listUserAssessmentsLandingPage } from '../../queries/customQueries';
import {
  UserSummary,
  ScoreSummary,
  UserSelfReported,
  AssignedEvents,
} from './index';
import { getCompletedStatus } from '../Insights/utils';
import TimePeriodFilter from '../Participant/SkillsOverview/TimePeriodFilter';
import { SKILLS_PERFORMANCE_TIMELINE_START_DATE } from '../../constants';
import {
  calculatePercentageDifference,
  getArrayByLength,
} from '../../shared/utils/objectUtils';
import PerformanceTriangle from '../Team/Dashboard/PerformanceTriangle';
import { productStyle } from '../../shared/utils/layout';
import { getCourseProgressStatus } from '../../shared/utils/taskUtils';
import {
  getCompetitionStatus,
  getTrainingStatus,
} from '../../shared/utils/getParticipantStatus';
import TrainingSvg from '../Training/TrainingSvg';
import CyberSkillCortex from '../Insights/CyberSkillCortex';
import TrophyRoomCard from '../Trophy-room/TrophyRoomCard';
import CreatedChallenges from '../ChallengeContribution/CreatedChallenges';

const getAverage = (objectArray, columnsToAverage) => {
  const average = _.reduce(
    objectArray,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(average, (value, key) => {
    const length = objectArray?.length ?? 0;
    average[key] = length > 0 ? parseFloat((value / length).toFixed(2)) : 0;
  });

  return average;
};

const UserProfile = ({
  selectedUser,
  accomplishmentData,
  affiliated,
  isSquadManager,
}) => {
  const { userId } = useParams();
  const { orgId } = useSnapshot(globalStore);
  const theme = useTheme();
  const [triangleValueRange, setTriangleValueRange] = useState([0, 100]);
  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: userScoreboardsByUserIdData,
    loading: userScoreboardsByUserIdLoading,
  } = useQueryRecursive(gql(listUserScoreboardsByUserId), {
    variables: {
      userId,
      limit: 1000,
    },
    skip: !userId,
  });

  const {
    data: listUserMetricsByUserIdData,
    loading: listUserMetricsByUserIdLoading,
  } = useQueryRecursive(gql(listUserMetricsByUserId), {
    variables: {
      userId,
      limit: 1000,
    },
    skip: !userId,
  });

  const { data: assessmentsData, loading: assessmentsLoading } =
    useQueryRecursive(gql(listUserAssessmentsLandingPage), {
      variables: {
        userId,
        limit: 1000,
      },
      staleTime: { seconds: 0 },
    });

  const { data: listCourseUsersData, loading: listCourseUsersLoading } =
    useQueryRecursive(gql(listCourseUsers), {
      variables: {
        filter: {
          userId: { eq: userId },
        },
        status: {
          ne: 'REMOVED',
        },
        limit: 1000,
      },
    });

  const timeStamp = (_value) => {
    const {
      status,
      finishedOn,
      startedOn,
      contentType,
      assessment: { startDateTime, endDateTime, hours, minutes },
    } = _value;
    let subHeading;
    switch (status) {
      case 'STARTED': {
        subHeading = endDateTime
          ? `Time Remaining: ${
              contentType === 'assessment'
                ? // Assessment
                  formatMinutes(
                    hoursToMinutes(hours) +
                      minutes -
                      differenceInMinutes(
                        new Date(serverTime?.getSystemTime),
                        new Date(startedOn),
                      ),
                  )
                : // Competition
                  formatMinutes(
                    differenceInMinutes(
                      new Date(endDateTime),
                      new Date(serverTime?.getSystemTime),
                    ),
                  )
            } `
          : 'End TBD by Admin';
        break;
      }
      case 'NOT_COMPLETED':
        subHeading = `Expired: ${getDateTimeZoneFormatted(endDateTime)}`;
        break;
      case 'NOT_STARTED':
        subHeading = !startDateTime
          ? 'Start TBD by Admin'
          : contentType === 'assessment' &&
              getCompletedStatus(status, endDateTime, 'assessment') ===
                'NOT_COMPLETED'
            ? `Expired: ${getDateTimeZoneFormatted(endDateTime)}`
            : `Available: ${getDateTimeZoneFormatted(startDateTime)}`;
        break;
      case 'FINISHED':
        subHeading = `Completed: ${getDateTimeZoneFormatted(finishedOn)}`;
        break;
      case 'ENDED':
        subHeading = `Expired: ${getDateTimeZoneFormatted(endDateTime)}`;
        break;
      default:
        return null;
    }
    return subHeading;
  };

  // all assessmentData for the user except REMOVED
  const listUserAssessmentsData =
    assessmentsData?.listUserAssessmentsByUserId?.items
      ?.filter((ad) => ad?.status !== 'REMOVED')
      ?.map((ad) => ({
        ...ad,
        orgId: ad?.assessment?.orgId,
        eventId: ad?.assessment?.id,
        type: ad?.assessment?.teamBased ? 'Team' : 'Solo',
        title: ad?.assessment?.name,
        unReadMessageCount: 0,
      }));

  const allAssessments =
    listUserAssessmentsData
      ?.filter((ad) => ad?.assessment?.participantEventType === 'ASSESSMENT')
      .map((ad) => ({
        ...ad,
        contentType: 'assessment',
        duration: getDuration(ad?.assessment.hours, ad?.assessment.minutes),
        status:
          ad?.status === 'STARTED'
            ? getCompletedStatus(
                ad?.status,
                ad?.assessment?.endDateTime,
                'assessment',
              )
            : ad?.status,
        icon: (
          <Box style={productStyle('ASSESSMENT', theme)}>
            <AssignmentIcon style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('ASSESSMENT', theme)?.backgroundColor,
        tasksCount: ad?.assessment?.tasks?.items?.length,
      })) || [];

  const coursesData =
    listCourseUsersData?.listCourseUsers?.items
      ?.filter(
        (userCourse) =>
          userCourse.course?.status === 'AVAILABLE' &&
          userCourse.course?.availability,
      )
      .sort(
        (a, b) =>
          new Date(a?.course?.createdAt) - new Date(b?.course?.createdAt),
      )
      .map((userCourse) => ({
        ...userCourse,
        eventId: userCourse?.course?.id,
        contentType: 'course',
        title: userCourse?.course?.name,
        image: userCourse?.course?.image?.key,
        icon: (
          <Box style={productStyle('COURSE', theme)}>
            <WbIncandescentIcon
              style={{
                transform: 'rotateX(180deg)',
                marginBottom: '5px',
                height: '18px',
              }}
            />
          </Box>
        ),
        iconColor: productStyle('COURSE', theme)?.backgroundColor,
        unReadMessageCount: userCourse?.messages?.items?.filter(
          (m) => m.sender.type !== 'PARTICIPANT',
        )?.length,
        courseModulesCount: userCourse?.course?.courseModules?.items?.length,
        courseProgress: getCourseProgressStatus({
          partsProgress: userCourse?.modulePartProgresses?.items,
        })?.courseProgress,
        status: getCourseProgressStatus({
          partsProgress: userCourse?.modulePartProgresses?.items,
        })?.status,
        preComputedStatus: true,
        eventStatus: 'STARTED',
      })) || [];

  const allCompetitions =
    listUserAssessmentsData
      ?.filter((ad) => ad?.assessment?.participantEventType === 'COMPETITION')
      .map((ad) => ({
        ...ad,
        contentType: 'competition',
        participantStatus:
          ['STARTED', 'NOT_STARTED'].includes(ad?.status) &&
          ad?.assessment?.endDateTime
            ? getCompletedStatus(
                ad?.status,
                ad?.assessment?.endDateTime,
                'competition',
              )
            : ad?.status === 'FINISHED'
              ? 'ENDED'
              : ad?.status,
        // status for competition refers to competition status not the participant status
        status: getCompetitionStatus(
          ad?.assessment?.startDateTime,
          ad?.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
        icon: (
          <Box style={productStyle('COMPETITION', theme)}>
            <EmojiEventsIcon style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('COMPETITION', theme)?.backgroundColor,
      })) || [];

  const allTrainings = isSquadManager
    ? listUserAssessmentsData
        ?.filter((ad) => ad?.assessment?.participantEventType === 'TRAINING')
        .map((ad) => ({
          ...ad,
          contentType: 'training',
          preComputedStatus: true,
          status: getTrainingStatus(
            ad?.status,
            ad?.assessment?.startDateTime,
            ad?.assessment?.endDateTime,
            serverTime?.getSystemTime,
          ),
          icon: (
            <Box
              className="flex items-center justify-center w-full h-full"
              style={{
                ...productStyle('TRAINING', theme),
                height: '200px',
                width: '32px',
              }}
            >
              <TrainingSvg />
            </Box>
          ),
          iconColor: productStyle('TRAINING', theme)?.backgroundColor,
          tasksCount: ad?.assessment?.tasks?.items?.length,
        })) || []
    : [];

  // rows sorted by updated date
  const rows = sortedByDate([
    ...allAssessments,
    ...allCompetitions,
    ...coursesData,
    ...allTrainings,
  ]);

  const onTriangleValueRangeChange = (__, newValue) =>
    setTriangleValueRange(newValue);

  const noOfWeeks = differenceInCalendarWeeks(
    new Date(),
    SKILLS_PERFORMANCE_TIMELINE_START_DATE,
  );
  const slice = 100 / (noOfWeeks > 0 ? noOfWeeks : 1);
  const markers = getArrayByLength(Number(noOfWeeks + 1), (__, i) => i + 1);
  const marks =
    markers?.map((m, i) => {
      const last = noOfWeeks + 1 === i + 1;
      return {
        value: last ? 100 : i * slice,
        date: addWeeks(SKILLS_PERFORMANCE_TIMELINE_START_DATE, i),
        first: i === 0,
        last,
      };
    }) || [];

  const userScoreboards =
    userScoreboardsByUserIdData?.listUserScoreboardsByUserId?.items || [];
  const columnsToAverage = ['successScore', 'speed', 'efficiency', 'score'];
  const memberAverage = getAverage(userScoreboards, columnsToAverage);
  const userAverage = _.reduce(
    userScoreboards,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(userAverage, (value, key) => {
    userAverage[key] = parseFloat(
      (value / (userScoreboards?.length ?? 0)).toFixed(2),
    );
  });
  const { lastScore, lastSuccessScore, lastEfficiency, lastSpeed } =
    listUserMetricsByUserIdData?.listUserMetricsByUserId?.items[0] || {};

  userAverage.percentageDifferenceInScore =
    calculatePercentageDifference(lastScore, userAverage.score) || 0;
  userAverage.percentageDifferenceInSuccessScore =
    calculatePercentageDifference(lastSuccessScore, userAverage.successScore) ||
    0;
  userAverage.percentageDifferenceInEfficiency =
    calculatePercentageDifference(lastEfficiency, userAverage.efficiency) || 0;
  userAverage.percentageDifferenceInSpeed =
    calculatePercentageDifference(lastSpeed, userAverage.speed) || 0;

  const performanceData = {
    team: {
      score: Math.round(memberAverage?.score) || 0,
    },
    participant: {
      success: memberAverage?.successScore || 0,
      efficiency: memberAverage?.efficiency || 0,
      speed: memberAverage?.speed || 0,
    },
    teamBased: false,
  };
  const loading =
    assessmentsLoading ||
    listCourseUsersLoading ||
    listUserMetricsByUserIdLoading;

  return (
    <Box className="flex flex-col gap-y-2">
      <UserSummary userData={selectedUser} affiliated={affiliated} />
      <ScoreSummary
        affiliated={affiliated}
        userAverage={userAverage}
        loading={userScoreboardsByUserIdLoading}
      />
      <FdCard variant="outlined">
        <Box className="flex">
          <FdTypography variant="h3">Cyber Skills Cortex</FdTypography>
          <FdIconWithTooltip
            title={
              affiliated
                ? "This user's Cyber Skills Cortex displays the skills they have demonstrated across all events they have participated in platform-wide, including any events from outside your organisation. "
                : "As this user is not affiliated to your organisation, you can only see skills they have demonstrated and attempted within your organisation's events. Any skills this user has demonstrated in events external to your organisation are not displayed."
            }
          />
        </Box>
        <FdTypography
          variant="body2"
          color="secondary"
          style={{ marginTop: '1rem' }}
        >
          The Cyber Skills Cortex displays demonstrated cyber skills as per the
          Dreyfus proficiency levels (1-5). Mapped to one of six FifthDomain
          Professional Specialties, each skill darkens in corresponding squares
          from the centre of the Cortex as one&apos;s demonstration of
          proficiency in that skill increases.
        </FdTypography>
        <Box my={3}>
          <Divider />
        </Box>
        <CyberSkillCortex
          marks={marks}
          affiliated={affiliated}
          selectedUser={selectedUser}
          isSquadManager={isSquadManager}
          loading={loading}
        />
      </FdCard>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FdCard variant="outlined">
            <Box className="flex">
              <FdTypography variant="h3">Performance Triangle</FdTypography>
              <FdIconWithTooltip
                title={`
This user’s Performance Triangle is
${
  affiliated
    ? ' based on their performance in all events they have participated in platform-wide, including any events from outside your '
    : " exclusively based on their performance in your organisation's events. It excludes any of this user's participation in events hosted outside from this "
} 
organisation.
`}
              />
            </Box>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '1rem' }}
            >
              The Performance Triangle visually displays a user’s Success,
              Efficiency, and Speed scores on the platform. The blue triangle
              below shows this user’s Success, Efficiency, and Speed scores,
              whilst the grey triangle shows the average Success, Efficiency,
              and Speed scores of affiliated users in this organisation.
            </FdTypography>
            <Box my={3}>
              <Divider />
            </Box>
            <TimePeriodFilter
              marks={marks}
              step={null}
              valueRange={triangleValueRange}
              onValueChange={onTriangleValueRangeChange}
              description="The slider below enables you to filter your view to a specific time period in the past. The leftmost position represents week 1 of October 2023 (the earliest view available), and the rightmost indicates the current week. Drag the slider to explore your performance metrics from October 2023 to the selected week."
            />
            <Box mt={1} mb={6}>
              <Divider />
            </Box>
            <FdSkeleton loading={userScoreboardsByUserIdLoading} height={448}>
              <PerformanceTriangle performanceData={performanceData} cohort />
            </FdSkeleton>
          </FdCard>
        </Grid>
        <Grid item xs={6}>
          <FdSkeleton loading={loading || serverTimeLoading}>
            <AssignedEvents
              data={rows
                ?.filter((r) => r?.orgId === orgId)
                ?.map((row) => ({
                  ...row,
                  organizer:
                    row?.assessment?.creator?.org?.name ||
                    row?.course?.user?.org?.name,
                  timestamp: row?.assessment ? timeStamp(row) : '-',
                }))}
              externalData={rows?.filter((r) => r?.orgId !== orgId)}
              affiliated={affiliated}
            />
          </FdSkeleton>
        </Grid>
      </Grid>
      {affiliated && <TrophyRoomCard userId={userId} />}
      {affiliated && (
        <>
          <CreatedChallenges userData={selectedUser} />
          <UserSelfReported accomplishmentData={accomplishmentData} />
        </>
      )}
    </Box>
  );
};

UserProfile.propTypes = {
  affiliated: PropTypes.bool,
  selectedUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    alias: PropTypes.string,
  }).isRequired,
  accomplishmentData: PropTypes.shape({
    listaccomplishmentByUserId: PropTypes.shape({
      items: PropTypes.arrayOf({
        id: PropTypes.string,
      }),
    }),
  }),
  isSquadManager: PropTypes.bool.isRequired,
};

UserProfile.defaultProps = {
  affiliated: false,
  accomplishmentData: {},
};
export default UserProfile;

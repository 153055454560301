import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTable } from '@fifthdomain/fe-shared';

const TopFiveTable = ({ columns, actions, data, title, loading }) => {
  // restrict to 5 rows
  const rows = data.slice(0, 5);
  return (
    <Box mb={2} height="390px" width="100%">
      <FdTable
        toolbarSettings={{
          title,
          searchBox: false,
        }}
        disableSelectionOnClick
        rows={rows}
        columns={columns}
        actions={actions}
        visibleSelection
        tablePageSize={5}
        hideFooter
        noRowsMessage="No data to present"
        loading={loading}
        gridId="competitions-top-five-table"
      />
    </Box>
  );
};

TopFiveTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
  ).isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      width: PropTypes.number,
      headerName: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TopFiveTable;

import React, { useState } from 'react';
import _ from 'lodash';
import * as singleSpa from 'single-spa';
import { Box, colors, Card, CardContent, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import {
  FdTypography,
  useQueryRecursive,
  globalStore,
  useSnapshot,
  FdButton,
  FdChip,
  FdSkeleton,
  FdIconWithTooltip,
  FdAlert,
  FdExternalLink,
  SUPPORT_EMAIL,
  WORK_ROLE_OVERLAY_GUIDE,
  Authorization,
} from '@fifthdomain/fe-shared';
import WorkRoleSelection from './WorkRoleSelection';
import WorkRoleFulfilment from './WorkRoleFulfilment';
import { updateUserOrgAffliation } from '../../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import {
  listCustomerWorkRolesByOrgId,
  listCustomerSkillsWorkrolesByOrgId,
} from '../../queries/customQueries';

const WorkRole = ({
  listFdSkills,
  selectedUser,
  assignedWorkRole,
  userOrgAffliation,
  refetch,
  specialtiesDataSorted,
  userId,
  isSquadManager,
}) => {
  const { orgId, permissions } = useSnapshot(globalStore);
  const { grey } = colors;
  const [editWorkRole, setEditWorkRole] = useState(false);
  const [skillDetails, setSkillDetails] = useState(false);
  const hasManageUsersPermission = Authorization.hasPermission(permissions, [
    'MANAGE_USERS',
  ]);
  const canEditWorkRole = isSquadManager || hasManageUsersPermission;

  const requiredSkills = _.map(
    _.groupBy(listFdSkills, 'skillId'), // group by the skillId
    (item) => _.maxBy(item, 'difficulty'), // take the one with the highest value
  );

  const skillFulFilled = requiredSkills?.map((item) => ({
    ...item,
    data:
      _.maxBy(
        specialtiesDataSorted
          ?.find((sk) => sk?.id === item?.skill?.alias)
          ?.data?.filter(
            (i) => i?.yLevelPoints > 0 && i?.yLabelLastSolve !== 'N/A',
          ),
        'x',
      )?.x || 0,
  }));

  const fulfilledSkills =
    skillFulFilled?.filter(
      (item) => item?.data && item?.data >= item?.difficulty,
    ) || [];

  const unfulfilledSkills =
    skillFulFilled?.filter((item) => item?.difficulty > item?.data) || [];

  const data = [
    {
      value: requiredSkills?.length || 'N/A',
      description: 'Required Skills',
    },
    {
      value: requiredSkills?.length
        ? `${fulfilledSkills?.length}/${requiredSkills?.length}`
        : 'N/A',
      description: 'Fulfilled Skills',
    },
    {
      value: requiredSkills?.length
        ? `${unfulfilledSkills?.length}/${requiredSkills?.length}`
        : 'N/A',
      description: 'Unfulfilled Skills',
    },
  ];

  // List Customer work roles by orgId
  const {
    data: listCustomerWorkRolesByOrgIdData,
    loading: listCustomerWorkRolesByOrgIdLoading,
  } = useQueryRecursive(gql(listCustomerWorkRolesByOrgId), {
    variables: {
      orgId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
  });

  const {
    data: listCustomerSkillWorkrolesData,
    loading: listCustomerSkillWorkrolesLoading,
  } = useQueryRecursive(gql(listCustomerSkillsWorkrolesByOrgId), {
    variables: {
      orgId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: !orgId,
  });

  const [updateUserOrgAffliationMutation] = useMutation(
    gql(updateUserOrgAffliation),
  );

  const listWorkRoles =
    listCustomerWorkRolesByOrgIdData?.listCustomerWorkRolesByOrgId?.items
      ?.map((item) => ({
        ...item,
        customerSkills:
          listCustomerSkillWorkrolesData?.listCustomerSkillsWorkrolesByOrgId?.items?.filter(
            (workRole) => workRole?.customerWorkroleId === item?.id,
          ),
      }))
      ?.filter((i) => i?.customerSkills?.length) || [];

  return listWorkRoles?.length > 0 ? (
    <Box my={3}>
      <FdTypography variant="subtitle1">Assigned Work Role</FdTypography>
      <Box mt={0.5} mb={1.5}>
        {canEditWorkRole && (
          <FdTypography variant="captiontext2" color="secondary">
            Click ‘Edit’ to change this user’s assigned work role, or remove
            their assigned work role.
          </FdTypography>
        )}
      </Box>
      <FdSkeleton
        loading={
          listCustomerWorkRolesByOrgIdLoading ||
          listCustomerSkillWorkrolesLoading
        }
      >
        <Box className="flex items-center">
          <FdChip
            label={assignedWorkRole[0]?.name || 'None Assigned'}
            rounded={false}
            style={{ marginRight: 8, backgroundColor: grey[100] }}
          />
          {canEditWorkRole && (
            <FdButton size="small" onClick={() => setEditWorkRole(true)}>
              EDIT
            </FdButton>
          )}
        </Box>
      </FdSkeleton>
      <Box mt={3}>
        <FdTypography variant="subtitle1">
          Work Role Skills Fulfilment
        </FdTypography>
        <Box mt={0.5} mb={1.5}>
          <FdTypography variant="captiontext2" color="secondary">
            View total required skills for this user’s assigned work role, and a
            count of fulfilled and unfulfilled skills. All skills to date are
            shown (this section is unaffected by the Time Period Filter). Only
            skills demonstrated at or above their target proficiency levels are
            considered fulfilled.
          </FdTypography>
        </Box>
        <Box display="flex">
          <Box width="80%">
            <Card variant="outlined">
              <CardContent style={{ padding: '1rem' }}>
                <Box className="flex items-center justify-around w-full">
                  {data?.map((d, idx) => (
                    <>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="center">
                          {d?.value}
                        </Box>
                        <Box display="flex" mt={1}>
                          <FdTypography
                            variant="captiontext2"
                            color="secondary"
                          >
                            {d?.description}
                          </FdTypography>
                        </Box>
                      </Box>
                      {idx < data.length - 1 && (
                        <Divider orientation="vertical" flexItem />
                      )}
                    </>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box className="flex items-center ml-2">
            <FdButton
              variant="secondary"
              size="small"
              onClick={() => setSkillDetails(true)}
            >
              see details
            </FdButton>
          </Box>
        </Box>
      </Box>
      <WorkRoleSelection
        assignedWorkRole={assignedWorkRole}
        user={selectedUser}
        openModal={editWorkRole}
        listWorkRoles={listWorkRoles}
        onDismiss={() => {
          setEditWorkRole(false);
          warningToastMessage('Assigned work role not updated');
        }}
        onConfirm={(_selectedWorkRole) => {
          if (_selectedWorkRole?.length !== 0) {
            updateUserOrgAffliationMutation({
              variables: {
                input: {
                  id: userOrgAffliation?.id,
                  customerWorkroleID: _selectedWorkRole?.id,
                },
              },
              onCompleted: () => {
                setEditWorkRole(false);
                successToastMessage('Success! Assigned work role updated!');
                refetch();
              },
            });
          }
        }}
      />
      {isSquadManager && assignedWorkRole[0]?.name && (
        <Box className="flex gap-x-1 my-6">
          <FdButton
            onClick={() =>
              singleSpa.navigateToUrl(`/competitions/training/create/${userId}`)
            }
          >
            Recommend Training
          </FdButton>
          <FdIconWithTooltip title="Click this to generate a tailored training activity with challenges pre-selected to address this user’s skill deficiencies as per their assigned work role." />
        </Box>
      )}
      <WorkRoleFulfilment
        fulfilledSkills={fulfilledSkills}
        unfulfilledSkills={unfulfilledSkills}
        assignedWorkRole={assignedWorkRole}
        openModal={skillDetails}
        onDismiss={() => {
          setSkillDetails(false);
        }}
      />
    </Box>
  ) : (
    <Box my={3}>
      <FdAlert
        alertTitle="Activate Work Role Skill Measurement"
        variant="info"
        message={
          <Box my={2}>
            <FdTypography variant="body2">
              You now have the ability to measure the skills of an affiliated
              user against work roles!
            </FdTypography>
            <Box my={1}>
              <FdTypography variant="body2">
                Email
                <FdExternalLink href={`mailto:${SUPPORT_EMAIL}`}>
                  {` ${SUPPORT_EMAIL} `}
                </FdExternalLink>
                to request the activation of this feature.
              </FdTypography>
            </Box>
            <FdTypography variant="body2">
              To know more about the Work Role Overlay feature, read this
              <FdExternalLink href={WORK_ROLE_OVERLAY_GUIDE}>
                guide.
              </FdExternalLink>
            </FdTypography>
          </Box>
        }
      />
    </Box>
  );
};

WorkRole.defaultProps = {
  assignedWorkRole: [],
};

WorkRole.propTypes = {
  refetch: PropTypes.bool.isRequired,
  specialtiesDataSorted: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  listFdSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      skillId: PropTypes.string,
    }),
  ).isRequired,
  selectedUser: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    alias: PropTypes.string,
  }).isRequired,
  assignedWorkRole: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  userOrgAffliation: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  isSquadManager: PropTypes.bool.isRequired,
};

export default WorkRole;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdTypography,
  useQueryRecursive,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { listBadgeByUserId } from '../../queries/customQueries';
import { listBadges } from '../../graphql/queries';
import { CHAMPION_LEVELS, TROPHY_TILES } from '../../constants';
import { numberToRoman } from '../../shared/utils/stringUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import TrophyBadge from './images/tiers/TrophyBadge';
import BadgeContainer from './BadgeContainer';
import BlueFillerImage from './images/BlueFillerIAniImage';

const TrophyRoomCardContent = ({ userId }) => {
  const { data: listBadgesData, loading: listBadgesLoading } =
    useQueryRecursive(gql(listBadges), {
      variables: {
        limit: 1000,
      },
    });
  const { data: listUserBadgesData, loading: listUserBadgesLoading } =
    useQueryRecursive(gql(listBadgeByUserId), {
      variables: {
        limit: 1000,
        userId,
      },
    });
  const badges =
    listBadgesData?.listBadges?.items?.map((b) => ({
      ...b,
      badgeType: (b?.specialty?.name || b?.badgeType)?.toLowerCase() || '',
    })) || [];

  const userBadges = listUserBadgesData?.listBadgeByUserId?.items || [];
  const userBadgesUnique = _.uniqBy(userBadges, 'badgeId') || [];

  const badgesForDisplay =
    userBadgesUnique
      ?.sort(sortByDateField('createdAt', 'desc'))
      ?.map((ub) => {
        const badge = badges?.find((b) => b?.id === ub?.badgeId);
        const isChampionType = badge?.badgeType === 'competition_champion';
        return {
          tierCaption: isChampionType
            ? CHAMPION_LEVELS?.find((c) => c?.value === badge?.level)?.name
            : 'Tier',
          tierLabel: isChampionType
            ? `${
                userBadges?.filter((b) => b?.badgeId === ub?.badgeId)?.length ||
                0
              }x`
            : numberToRoman(badge?.level),
          tierValue: badge?.level,
          trophyType: badge?.badgeType,
          caption:
            TROPHY_TILES[
              badge?.specialty?.name || badge?.badgeType?.toUpperCase()
            ]?.name,
        };
      })
      ?.slice(0, 5) || [];

  const loading = listBadgesLoading || listUserBadgesLoading;

  return (
    <FdSkeleton height="225px" loading={loading}>
      {badgesForDisplay?.length === 0 ? (
        <Box className="flex flex-col items-center text-center gap-y-6 mt-8">
          <Box style={{ width: '160px' }}>
            <BlueFillerImage />
          </Box>
          <FdTypography variant="captiontext2">
            You haven&apos;t obtained any trophies yet.
            <br />
            Your trophies will appear here once you obtain them.
          </FdTypography>
        </Box>
      ) : (
        <Box className="flex justify-center gap-x-6">
          {badgesForDisplay?.map((d) => (
            <Box className="flex flex-col items-center gap-y-2 mt-6 mb-2">
              <Box className="flex items-center justify-center gap-x-2">
                <FdTypography
                  variant="captiontext2"
                  color="secondary"
                  style={{ height: '12px' }}
                >
                  {d?.tierCaption}
                </FdTypography>
                <FdTypography variant="subtitle2">{d?.tierLabel}</FdTypography>
              </Box>
              <BadgeContainer>
                <TrophyBadge
                  type={d?.trophyType}
                  tier={d?.tierValue}
                  status="unlocked"
                />
              </BadgeContainer>
              <Box className="text-center w-4/5">
                <FdTypography variant="captiontext1" color="secondary">
                  {d?.caption}
                </FdTypography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </FdSkeleton>
  );
};

TrophyRoomCardContent.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default TrophyRoomCardContent;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import {
  FdTable,
  FdChip as Chip,
  FdSkeleton,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import { getParticipantAssessmentStatusColor } from '../../shared/utils/getStatusColor';
import TaskActivity from './TaskActivity';
import ChallengeInfoCell from '../Assessment/ChallengeInfoCell';
import SpecialtyIcon from './SpecialtyIcon';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';
import ChallengeFinderIcon from './ChallengeFinder/ChallengeFinderIcon';

const TasksTable = ({
  actions,
  rows,
  headerActions,
  onRowClick,
  teamBased,
  activityLoading,
  attemptsLoading,
}) => {
  const theme = useTheme();
  const columns = [
    {
      field: 'image',
      headerName: ' ',
      maxWidth: 50,
      filterable: false,
      valueGetter: (params) => params?.row?.filterItem,
      renderCell: (params) =>
        params?.row?.filterItem && (
          <Box
            className="rounded-full w-7 h-7 flex items-center justify-center"
            style={{
              color: theme?.palette?.autoComplete?.chipColor,
              backgroundColor: theme?.palette?.autoComplete?.chipBackground,
            }}
          >
            <ChallengeFinderIcon className="w-4 h-4 leading-4" />
          </Box>
        ),
    },
    {
      field: 'icon',
      headerName: ' ',
      width: 80,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <SpecialtyIcon specialty={params?.row?.specialty} />
      ),
    },
    {
      field: 'challengeInfo',
      width: 480,
      headerName: 'Challenge Name',
      filterable: false,
      valueGetter: (params) => params?.row?.name,
      renderCell: (params) => <ChallengeInfoCell values={params.row} />,
    },
    { field: 'name', width: 350, headerName: 'Challenge Name' },
    {
      field: 'specialty',
      width: 200,
      headerName: 'Professional Specialty',
      renderCell: (params) => <FdHighlightChip text={params?.value} />,
    },
    {
      field: 'recommendedPoints',
      width: 100,
      headerName: 'Points',
    },
    ...(teamBased
      ? [
          {
            field: 'activity',
            width: 200,
            headerName: 'Activity',
            valueGetter: (params) =>
              params?.row?.activities?.length > 0
                ? params?.row?.activities?.length
                : null,
            renderCell: (params) => {
              return (
                params?.row?.status !== 'Completed' && (
                  <FdSkeleton
                    loading={activityLoading}
                    height="32px"
                    width="100px"
                  >
                    <TaskActivity activities={params?.row?.activities} />
                  </FdSkeleton>
                )
              );
            },
            filterOperators: [
              {
                label: 'Is Empty',
                value: 'isEmpty',
                getApplyFilterFn: () => {
                  return (params) =>
                    params.value === null || params.value === '';
                },
              },
              {
                label: 'Is Not Empty',
                value: 'isNotEmpty',
                getApplyFilterFn: () => {
                  return (params) =>
                    params.value !== null && params.value !== '';
                },
              },
            ],
          },
        ]
      : []),
    {
      field: 'difficulty',
      width: 150,
      headerName: 'Proficiency',
    },
    {
      field: 'skills',
      width: 250,
      headerName: 'Skills',
      valueGetter: (params) =>
        params?.value?.length > 0
          ? [...params?.value].map((t) => t?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        return getCommaSeparatedPlusSuffix(
          params?.row?.skills?.map((skill) => skill?.name),
        );
      },
    },
    {
      field: 'estimatedSolveTime',
      width: 210,
      headerName: 'Est. Time to Solve (min)',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      valueGetter: (params) => params?.row?.status,
      renderCell: (params) => {
        return (
          <FdSkeleton loading={attemptsLoading} height="24px" width="75px">
            <Chip
              color={getParticipantAssessmentStatusColor(params?.row?.status)}
              size="small"
              label={params?.row?.status}
            />
          </FdSkeleton>
        );
      },
    },
    { field: 'attempts', width: 120, headerName: 'Attempts' },
  ];

  return (
    <Box>
      <FdTable
        columnVisibilityModel={{
          name: false,
          difficulty: false,
          estimatedSolveTime: false,
        }}
        rowHeight={60}
        tablePageSize={10}
        toolbarSettings={{
          title: 'Challenges',
          headerActions,
          filterOptions: [],
          filterButton: true,
          searchBox: true,
        }}
        selection={false}
        actions={actions}
        rows={rows}
        columns={columns}
        onRowClick={onRowClick}
        rowClickable
        autoHeight
        gridId="competitions-participant-challenges"
      />
    </Box>
  );
};

TasksTable.defaultProps = {
  actions: [],
  headerActions: [],
  onRowClick: () => {},
};

TasksTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  onRowClick: PropTypes.func,
  teamBased: PropTypes.bool.isRequired,
  activityLoading: PropTypes.bool.isRequired,
  attemptsLoading: PropTypes.bool.isRequired,
};

export default TasksTable;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const IconWithLabel = ({ Icon, color, backgroundColor, label }) => {
  return (
    <Box className="flex items-center mt-0.5">
      <Box
        className="flex items-center justify-center w-8 h-8 rounded-full mr-2"
        style={{ backgroundColor }}
      >
        <Icon
          style={{
            color,
          }}
        />
      </Box>
      <FdTypography variant="subtitle2">{label}</FdTypography>
    </Box>
  );
};

IconWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default IconWithLabel;

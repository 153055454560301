import { uploadData } from 'aws-amplify/storage';
import { amplifyConfig } from '@fifthdomain/fe-shared';
import { getToken } from './session';

const { manageChallengesThreadsUrl, assistantActionsUrl } =
  amplifyConfig.aws_lambda_function_url;

export const getAllMentions = (text) => {
  const regex = /@\[([^\]]+)\]/g;
  const matches = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(text)) !== null) {
    matches.push(`@${match[1]}`);
  }

  return matches;
};

export const fetchAIChatMessages = async ({ userAssessmentId, taskId }) => {
  const token = await getToken();
  const endpoint = `${manageChallengesThreadsUrl}/messages?userAssessmentId=${userAssessmentId}&taskId=${taskId}`;
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(endpoint, config);
  if (!response.ok) {
    console.log(
      `Failed to fetch AI thread messages: ${response.status} ${response.statusText}`,
    );
  }
  return response.json();
};

export const createAIChatThread = async ({
  userId,
  assessmentId,
  userAssessmentId,
  taskId,
}) => {
  const token = await getToken();
  const response = await fetch(`${manageChallengesThreadsUrl}/threads`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      userId,
      assessmentId,
      userAssessmentId,
      taskId,
    }),
  });

  if (!response.ok) {
    console.log(
      `Failed to create AI Chat thread ${response.status} ${response.statusText}`,
    );
  }
  return response.json();
};

export const addAIChatMessage = async ({
  message,
  userAssessmentId,
  taskId,
  images,
  onMessageChunk = () => {},
}) => {
  const token = await getToken();
  const payload = {
    userAssessmentId,
    taskId,
    messages: [
      {
        type: 'TEXT',
        content: message?.text,
      },
      ...(images?.map((image) => ({
        type: 'IMAGE',
        content: image?.url,
      })) || []),
    ],
  };

  const response = await fetch(`${assistantActionsUrl}/messages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to add an AI Chat message ${response.status} ${response.statusText}`,
    );
  }

  // Process the readable stream
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
  const assistantMessage = {
    role: 'assistant',
    content: [{ type: 'text', text: { value: '' } }],
  };

  // read the stream
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { value, done } = await reader.read();
    if (done) break;
    // eslint-disable-next-line no-continue
    if (!value) continue;

    // Split chunks by newline and filter out empty lines
    const chunks = value
      .split('\n')
      .map((chunk) => chunk.trim()) // trim leading and trailing whitespace
      .filter(Boolean); // remove empty or whitespace-only lines

    chunks.forEach((chunk) => {
      try {
        const parsedChunk = JSON.parse(chunk);
        let chunkContent = parsedChunk.chunk;
        const matchPattern = /^\n\n\d+$/;
        if (matchPattern.test(chunkContent)) {
          chunkContent = `\n\n${chunkContent}\n\n`;
        }
        assistantMessage.content[0].text.value += `${chunkContent}`;
        onMessageChunk({ ...assistantMessage });
      } catch (error) {
        console.error('Error parsing AI message chunk:', error, chunk);
      }
    });
  }
};

export const uploadAIChatImageAttachmentToS3 = async (files) => {
  try {
    if (!files || files.length === 0) {
      return [];
    }
    await Promise.all(
      files.map(
        ({ key, file }) =>
          uploadData({
            path: `public/${key}`,
            data: file,
          }).result,
      ),
    );
    const s3Url = `https://${amplifyConfig.aws_user_files_s3_bucket}.s3.${amplifyConfig.aws_user_files_s3_bucket_region}.amazonaws.com/public`;
    const imageUrls = files.map(({ key, file }) => ({
      name: file?.name,
      url: `${s3Url}/${key}`,
    }));

    return imageUrls;
  } catch (error) {
    console.error('Error uploading AI chat attachment files:', error);
    return [];
  }
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, IconButton } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { FdModal, FdTypography } from '@fifthdomain/fe-shared';

const ImageCarousel = ({ open, onClose, imageUrls }) => {
  const [currentIndex, setCurrentIndex] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open !== null) {
      setCurrentIndex(open || 0);
    }
    return () => {
      setCurrentIndex(null);
    };
  }, [imageUrls, open]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = () => {
    if (currentIndex < imageUrls.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <FdModal
      size="xl"
      open={open !== null}
      confirm="Close"
      onConfirm={onClose}
      showDismiss={false}
      className="flex items-center justify-center"
    >
      <Box className="relative w-full h-full md:h-auto p-4 overflow-hidden">
        {currentIndex > 0 && (
          <IconButton
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10"
            onClick={handlePrev}
          >
            <ArrowCircleLeftIcon />
          </IconButton>
        )}
        {currentIndex < imageUrls.length - 1 && (
          <IconButton
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10"
            onClick={handleNext}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        )}
        <Box className="flex flex-col items-center h-full justify-center">
          {loading && <CircularProgress className="absolute" />}
          <img
            src={imageUrls[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className="max-w-full max-h-full object-contain"
            onLoad={() => setLoading(false)}
          />
          {imageUrls?.length > 1 && (
            <Box className="mt-2">
              <FdTypography variant="body2">
                {currentIndex + 1} / {imageUrls?.length}
              </FdTypography>
            </Box>
          )}
        </Box>
      </Box>
    </FdModal>
  );
};

ImageCarousel.propTypes = {
  imageUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageCarousel;

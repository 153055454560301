import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Divider, lighten, useTheme } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import { DonutStacked, BarStacked } from '../../Charts';
import DonutGraphLegend from './DonutGraphLegend';
import BarGraphLegend from './BarGraphLegend';
import LevelTooltip from './LevelTooltip';
import { setToolTipData } from './graphUtils';
import { PROFICIENCY, SPECIALTY_COLOR } from '../../../constants';

const HoverOnTip = () => (
  <Box
    height="62px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    mt={3}
    pb={1}
  >
    <FdTypography variant="captiontext1">
      Hover on the individual segment for challenge details.
    </FdTypography>
  </Box>
);

const ProgressGraphs = ({
  data,
  barGraphLegendData,
  setBarGraphLegendData,
}) => {
  const [showDonutGraphToolTip, setShowDonutGraphTooltip] = useState(false);
  const [donutToolTipData, setDonutToolTipData] = useState(undefined);
  const [showBarGraphToolTip, setShowBarGraphTooltip] = useState(false);
  const [stackedToolTipData, setStackedToolTipData] = useState(undefined);
  const theme = useTheme();

  const barStackedAllSpecialties = [...new Set(data.map((t) => t.specialty))];

  const barStackedKeys = [
    ...new Set(
      barStackedAllSpecialties
        .map((_specialty) =>
          data
            .filter((a) => a.specialty === _specialty)
            .map((t, index) => `id${index}`),
        )
        .flat(),
    ),
  ];

  // sort based on status completed
  const sortStatusMapping = (_status) => _status === 'Completed';
  const barStackedGraphData = barStackedAllSpecialties
    ?.map((_specialty) => {
      const _tasks = data
        .filter((a) => a.specialty === _specialty)
        .sort(
          (a, b) => sortStatusMapping(b.status) - sortStatusMapping(a.status),
        );
      return {
        specialty: _specialty,
        tasks: _tasks
          ?.filter((t) => t.specialty)
          ?.map((t, index) => {
            const specialtyColor = SPECIALTY_COLOR[t.specialty];
            return {
              key: `id${index}`,
              value: t.recommendedPoints,
              color:
                t.status === 'Completed'
                  ? specialtyColor
                  : lighten(specialtyColor, 0.5),
              taskName: t.name,
              points: t.recommendedPoints,
              estimatedTime: t.estimatedSolveTime,
              status: t.status,
              attempts: t.attempts,
            };
          }),
      };
    })
    .map((t) => ({
      specialty: t.specialty,
      ...t.tasks.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.key]: item.value,
            [`${item.key}Color`]: item.color,
            [`${item.key}taskName`]: item.taskName,
            [`${item.key}points`]: item.points,
            [`${item.key}estimatedTime`]: item.estimatedTime,
            [`${item.key}status`]: item.status,
            [`${item.key}attempts`]: item.attempts,
          }),
        {},
      ),
    }))
    .filter((t) => t.specialty);

  const setBarLegendData = (_specialty) => {
    const specialtyTasks =
      data?.filter((t) => t.specialty === _specialty) || [];
    const specialtyTasksCompleted =
      specialtyTasks.filter((t) => t.status === 'Completed') || [];
    setBarGraphLegendData({
      specialty: _specialty,
      solved: specialtyTasksCompleted.length,
      totalTasks: specialtyTasks?.length,
      pointsScored: specialtyTasksCompleted.reduce(
        (acc, t) => Number(t.recommendedPoints) + acc,
        0,
      ),
      totalPoints: specialtyTasks.reduce(
        (acc, t) => Number(t.recommendedPoints) + acc,
        0,
      ),
    });
  };

  const donutGraphLegendData = _(data)
    .groupBy('difficulty')
    .map((items, proficiency) => ({
      label: proficiency,
      solved: items?.filter((i) => i?.status === 'Completed')?.length || 0,
      total: items?.length || 0,
      difficultyInteger: items?.[0].difficultyInteger || 0,
      color: PROFICIENCY[items?.[0].difficulty]?.color || '',
    }))
    .sort((a, b) => a.difficultyInteger - b.difficultyInteger)
    .value();

  const proficiencyRows = _(data)
    .map((pr) => ({
      id: pr?.id,
      difficultyInteger: pr?.difficultyInteger,
      label: pr?.difficulty,
      value: pr?.recommendedPoints || 0,
      color:
        pr?.status === 'Completed'
          ? PROFICIENCY[pr?.difficulty]?.completeColor
          : PROFICIENCY[pr?.difficulty]?.color || '',
      points: pr?.recommendedPoints || 0,
      status: pr?.status,
      specialty: pr?.specialty,
      attempts: pr?.attempts,
      estimatedTime: pr?.estimatedSolveTime,
      taskName: pr?.name,
    }))
    .sort((a, b) => a.difficultyInteger - b.difficultyInteger)
    .value();

  return (
    <Box>
      <Divider style={{ backgroundColor: theme.palette.table.border }} />
      <Box mt={1}>
        <Box className="flex w-full">
          <Box className="flex-1">
            <Box className="flex justify-center">
              <Box mt={1} width="90px">
                <FdTypography variant="subtitle1">
                  Progress by Proficiency
                </FdTypography>
              </Box>
              <Box mr={2} mt={1}>
                <Box height="192px" width="200px">
                  <DonutStacked
                    data={proficiencyRows}
                    colors={proficiencyRows?.map((s) => s.color)}
                    onHover={(_data) => {
                      setShowDonutGraphTooltip(true);
                      setToolTipData(setDonutToolTipData, _data.data);
                    }}
                    onLeave={() => {
                      setShowDonutGraphTooltip(false);
                      setDonutToolTipData(undefined);
                    }}
                  />
                </Box>
              </Box>
              <DonutGraphLegend data={donutGraphLegendData} />
            </Box>
            {showDonutGraphToolTip ? (
              <LevelTooltip data={donutToolTipData} />
            ) : (
              <HoverOnTip />
            )}
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box className="flex-1">
            <Box className="flex justify-center w-full">
              <Box pl={3} mt={1} width="150px" mr={1}>
                <FdTypography variant="subtitle1">
                  Progress by Professional Specialty
                </FdTypography>
              </Box>
              <Box width="390px" height="250px">
                <BarStacked
                  keys={barStackedKeys}
                  indexBy="specialty"
                  data={barStackedGraphData}
                  onHover={(_data) => {
                    setShowBarGraphTooltip(true);
                    const { id } = _data;
                    setToolTipData(setStackedToolTipData, {
                      specialty: _data.data.specialty,
                      taskName: _data.data[`${id}taskName`],
                      points: _data.data[`${id}points`],
                      estimatedTime: _data.data[`${id}estimatedTime`],
                      status: _data.data[`${id}status`],
                      attempts: _data.data[`${id}attempts`],
                    });
                  }}
                  onMouseClick={(_data) => {
                    const _specialty = _data.indexValue;
                    setBarLegendData(_specialty);
                  }}
                  onLeave={() => {
                    setShowBarGraphTooltip(false);
                    setStackedToolTipData(undefined);
                  }}
                />
              </Box>
              <BarGraphLegend data={barGraphLegendData} />
            </Box>
            {showBarGraphToolTip ? (
              <LevelTooltip data={stackedToolTipData} />
            ) : (
              <HoverOnTip />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProgressGraphs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  barGraphLegendData: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  setBarGraphLegendData: PropTypes.func.isRequired,
};

export default ProgressGraphs;

import React, { useState } from 'react';
import * as singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  FdTypography,
  FdButton,
  FdModal,
  FdAlert,
} from '@fifthdomain/fe-shared';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../../shared/utils/toast';
import { removeTeams } from '../../../graphql/mutations';

const TeamDelete = ({ team }) => {
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);

  const [deleteTeamMutation, { loading: deleteTeamLoading }] = useMutation(
    gql(removeTeams),
  );

  const onDeleteTeam = () => {
    // update relevant field
    deleteTeamMutation({
      variables: {
        teams: [team?.id],
      },
      onCompleted: () => {
        successToastMessage('Success! Team deleted!');
        setDeleteTeamModal(false);
        setTimeout(() => {
          singleSpa.navigateToUrl('/landing/teams');
        }, 2000);
      },
      onError: ({ graphQLErrors }) => {
        if (graphQLErrors) {
          errorToastMessage(graphQLErrors[0]?.message);
        }
      },
    });
  };

  return (
    <Box>
      <Box>
        <FdTypography variant="subtitle1">Delete Team</FdTypography>
        <Box className="mt-4">
          <FdAlert
            alertTitle="Attention: Deleting your team cannot be undone."
            message="Before clicking the Delete Team button below, please be aware that deleting a team is irreversible. All team history, data, and associated information will be permanently lost."
            variant="warning"
          />
        </Box>
        <FdButton
          startIcon={<DeleteIcon />}
          style={{
            backgroundColor: 'rgba(198, 40, 40, 1)',
            marginTop: '1rem',
          }}
          onClick={() => setDeleteTeamModal(true)}
        >
          Delete Team
        </FdButton>
      </Box>
      <FdModal
        size="sm"
        title="Delete this team?"
        confirm="proceed with deletion"
        confirmVariant=""
        dismiss="Cancel"
        open={deleteTeamModal}
        onConfirm={onDeleteTeam}
        onDismiss={() => {
          setDeleteTeamModal(false);
          warningToastMessage('Team deletion cancelled');
        }}
        disableConfirm={deleteTeamLoading}
        showConfirmInRed
      >
        <FdTypography variant="body1">
          Are you sure that you want to delete this team?
          <br />
          <br />
          If so, all team history, data, and associated information will be
          permanently lost. You and all team members will no longer be able to
          view this team and participate in events as part of it.
          <br />
          <br />
          Please ensure that this is your intended action, as it cannot be
          undone.
        </FdTypography>
      </FdModal>
    </Box>
  );
};

TeamDelete.propTypes = {
  team: PropTypes.objectOf(PropTypes.shape({ name: PropTypes.string }))
    .isRequired,
};

export default TeamDelete;

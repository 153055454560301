import { useEffect, useRef } from 'react';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

const useSubscription = ({ query, variables, onData, onError }) => {
  const subscriptionRef = useRef(null);

  useEffect(() => {
    const subscription = client.graphql({ query, variables }).subscribe({
      next: (data) => {
        onData?.(data);
      },
      error: (error) => {
        onError?.(error);
        subscriptionRef.current = null;
      },
    });

    subscriptionRef.current = subscription;

    // eslint-disable-next-line consistent-return
    return () => {
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe();
      }
    };
  }, [query, variables, onData, onError]);

  return subscriptionRef.current;
};

export default useSubscription;

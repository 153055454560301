import React from 'react';
import { Box, colors, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { FdTypography, FdModal, FdChip } from '@fifthdomain/fe-shared';
import shortid from 'shortid';
import {
  PROFICIENCY_LEVELS,
  WORK_ROLE_SKILLS_COLOR,
  PROFICIENCY,
} from '../../constants';

const WorkRoleFulfilment = ({
  openModal,
  onDismiss,
  assignedWorkRole,
  fulfilledSkills,
  unfulfilledSkills,
}) => {
  const { grey } = colors;

  const requiredSKills =
    (fulfilledSkills?.length ?? 0) + (unfulfilledSkills?.length ?? 0);

  const viewSkillList = (item) => (
    <li>
      <Box className="flex items-center gap-x-1 my-1">
        <FdTypography variant="body2" fontWeight="bold">
          {`${item?.skill?.name} (${item?.skill?.alias}) `}
        </FdTypography>
        Target Proficiency :
        <FdChip
          size="small"
          label={PROFICIENCY_LEVELS[item?.difficulty]}
          rounded={false}
          style={{
            backgroundColor:
              PROFICIENCY[PROFICIENCY_LEVELS[item?.difficulty]]?.color,
            height: '20px',
          }}
        />
        Demonstrated Proficiency :
        {item?.data > 0 ? (
          <FdChip
            size="small"
            label={PROFICIENCY_LEVELS[item?.data]}
            rounded={false}
            style={{
              backgroundColor:
                PROFICIENCY[PROFICIENCY_LEVELS[item?.data]]?.color,
              height: '20px',
            }}
          />
        ) : (
          <>Not Yet Demonstrated</>
        )}
      </Box>
    </li>
  );

  return (
    <FdModal
      size="lg"
      showClose
      title="Work Role Skills Fulfilment"
      description="Below is a list of skills that this user is required to demonstrate at specific target proficiency levels based on their currently assigned work role."
      open={openModal}
      onDismiss={() => {
        onDismiss();
      }}
      data-cy="open-modal"
    >
      <Box mt={2} display="flex" flexDirection="column" width="100%">
        <FdTypography variant="subtitle1">Assigned Work Role</FdTypography>
        <Box className="flex items-center mt-2 gap-x-3 mb-4">
          <FdChip
            label={assignedWorkRole[0]?.name || 'None Assigned'}
            rounded={false}
            style={{ backgroundColor: grey[100] }}
          />
          <FdTypography variant="body2" color="secondary">
            {` Required Skills: ${requiredSKills}`}
          </FdTypography>
        </Box>
        <Divider />
        <Box mt={2}>
          <Box className="flex gap-x-2">
            <FdTypography variant="subtitle1">
              Fulfilled Skills (Current):
            </FdTypography>
            <FdTypography
              variant="subtitle1"
              style={{ color: WORK_ROLE_SKILLS_COLOR.fulfilledSkills }}
            >
              {`(${fulfilledSkills?.length}/${requiredSKills})`}
            </FdTypography>
          </Box>
          {fulfilledSkills?.length > 0 && (
            <Box mt={0.5}>
              <FdTypography variant="body2" color="secondary">
                This user has demonstrated the following skills at their target
                proficiencies:
              </FdTypography>
              <Box mt={1.5} ml={3}>
                <FdTypography variant="captiontext2" color="secondary">
                  <ul
                    key={shortid.generate()}
                    data-cy="skills"
                    style={{ listStyleType: 'disc' }}
                  >
                    {fulfilledSkills?.map((item) => viewSkillList(item))}
                  </ul>
                </FdTypography>
              </Box>
            </Box>
          )}
        </Box>

        <Box mt={3}>
          <Box className="flex gap-x-2">
            <FdTypography variant="subtitle1">
              Unfulfilled Skills (Current):
            </FdTypography>
            <FdTypography
              variant="subtitle1"
              style={{ color: WORK_ROLE_SKILLS_COLOR.unfulfilledSkills }}
            >
              {`(${unfulfilledSkills?.length}/${requiredSKills})`}
            </FdTypography>
          </Box>
          {unfulfilledSkills?.length > 0 && (
            <Box mt={0.5}>
              <FdTypography variant="body2" color="secondary">
                This user has not yet demonstrated the following skills at their
                target proficiencies:
              </FdTypography>
              <Box mt={1.5} ml={3}>
                <FdTypography variant="captiontext2" color="secondary">
                  <ul
                    key={shortid.generate()}
                    data-cy="skills"
                    style={{ listStyleType: 'disc' }}
                  >
                    {unfulfilledSkills?.map((item) => viewSkillList(item))}
                  </ul>
                </FdTypography>
              </Box>
            </Box>
          )}
        </Box>
        <Box my={3}>
          <Divider />
        </Box>
        <FdTypography variant="subtitle1">
          {`  Current Work Role Coverage: ${
            Math.round(
              ((fulfilledSkills?.length || 0) / requiredSKills) * 100,
            ) || 0
          } % `}
        </FdTypography>
      </Box>
    </FdModal>
  );
};

WorkRoleFulfilment.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fulfilledSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  unfulfilledSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  assignedWorkRole: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default WorkRoleFulfilment;

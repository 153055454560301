import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Message from './Message';
import { scrollToBottomOfContainer } from '../../../../shared/utils/scroll';

const debouncedScrollToBottom = debounce(scrollToBottomOfContainer, 150);

const MessageStream = ({ messages }) => {
  const streamRef = useRef(null);
  const [showScrollTo, setShowScrollTo] = useState(false);
  const reversedMessages = [...messages].reverse();
  const handleScroll = () => {
    const container = streamRef.current;
    if (!container) return;
    const isScrolledToBottom = container.scrollTop >= 0;
    setShowScrollTo(!isScrolledToBottom);
  };

  // Scroll to bottom when messages update
  useEffect(() => {
    if (streamRef.current) {
      debouncedScrollToBottom(streamRef);
    }
    return () => debouncedScrollToBottom.cancel();
  }, [messages]);

  const getBgColor = (theme) =>
    theme?.palette?.type === 'dark' ? '#111010' : 'rgba(0, 0, 0, 0.03)';

  return (
    <>
      <Box
        ref={streamRef}
        className="relative flex flex-col-reverse w-full px-2 mb-1 h-screen"
        sx={{
          overflowY: 'auto',
          position: 'relative',
        }}
        onScroll={handleScroll}
      >
        {reversedMessages.map((message) => (
          <Message key={message?.id} message={message} />
        ))}
      </Box>
      {showScrollTo && (
        <IconButton
          onClick={() => scrollToBottomOfContainer(streamRef)}
          color="primary"
          aria-label="scroll to bottom"
          sx={(theme) => ({
            position: 'absolute',
            color: theme.palette?.primary?.main,
            top: `${(streamRef?.current?.offsetHeight || 0) + 150}px`,
            width: '32px',
            height: '32px',
            backgroundColor: getBgColor(theme),
            border: `2px solid ${theme.palette?.primary?.main}`,
            boxShadow: '0 0 0 1px #000',
            padding: '2px',
            left: '50%',
            transform: 'translateX(-50%)',
            '&:hover': {
              backgroundColor: getBgColor(theme),
            },
            '&:focus': {
              backgroundColor: getBgColor(theme),
            },
          })}
        >
          <ArrowDownwardIcon />
        </IconButton>
      )}
    </>
  );
};

MessageStream.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  liveMessage: PropTypes.shape({
    text: PropTypes.string,
    live: PropTypes.bool,
  }),
};

MessageStream.defaultProps = {
  liveMessage: null,
};

export default MessageStream;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import RadarNivo from '../../Charts/RadarNivo';
import HighlightedLabel from './HighlightedLabel';

const LabelWithTooltip = ({ title, tooltipText, ...props }) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <FdTypography variant="captiontext1" color="secondary">
        {title}
      </FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

LabelWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
};

const LabelValue = ({ label, value }) => (
  <Box className="flex items-center justify-between w-4/5 ml-11 mt-1">
    <FdTypography variant="body2">{label}</FdTypography>
    <FdTypography variant="subtitle1">{value}</FdTypography>
  </Box>
);
LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const PerformanceTriangle = ({ performanceData, cohort }) => {
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';
  const navyBlueColor = 'rgba(29, 57, 110, 1)';
  const { team, participant, teamBased } = performanceData || {};

  const teamOrParticipant = teamBased ? 'Team' : 'Participant';
  const groupLabel = cohort ? 'Your Network' : 'Team';
  const showCohort = Boolean(
    Object.prototype.hasOwnProperty.call(team, 'success'),
  );

  const radarChartData = [
    {
      label: 'Success',
      baseLine: 100,
      ...(showCohort ? { [`${groupLabel} Average`]: team?.success || 0 } : {}),
      'Your Score': participant?.success,
    },
    {
      label: 'Speed',
      baseLine: 100,
      ...(showCohort ? { [`${groupLabel} Average`]: team?.speed || 0 } : {}),
      'Your Score': participant?.speed,
    },
    {
      label: 'Efficiency',
      baseLine: 100,
      ...(showCohort
        ? { [`${groupLabel} Average`]: team?.efficiency || 0 }
        : {}),
      'Your Score': participant?.efficiency,
    },
  ];
  const keys = [
    'baseLine',
    ...(showCohort ? [`${groupLabel} Average`] : []),
    'Your Score',
  ];

  return (
    <Box className="flex">
      <Box mt={1} mb={1} width="100%">
        <Box display="flex">
          <Box width="100%">
            <Box className="flex justify-between mt-1">
              <Box className="flex items-center">
                <HighlightedLabel value={team?.score || 0} />
                <FdTypography variant="captiontext1">
                  FifthDomain Score
                </FdTypography>
                <FdTooltip
                  title={
                    cohort ? (
                      'Your FifthDomain score (0-100) is a combined weighted sum of your Success, Efficiency, and Speed scores, with Success carrying the highest weightage.'
                    ) : (
                      <Box>
                        FifthDomain score (0-100) is a measurement of Success
                        (challenges successfully solved), Efficiency (scoring
                        points with least number of attempts), and Speed
                        (quickness in completing the competition).
                        <br />
                        The most important metric that determines this score is
                        Success, but Speed and Efficiency are also given
                        weightage.
                      </Box>
                    )
                  }
                >
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              </Box>
              <Box className="flex">
                <LabelWithTooltip
                  title="Success"
                  tooltipText={
                    cohort
                      ? 'The Success Score (0-100) measures your ability to capture flags, taking into account points earned for solved challenges relative to overall points available.'
                      : `Success Score (0-100) is measured by the ${teamOrParticipant}'s capability to capture flags. It factors in the points scored for the successfully completed challenges.`
                  }
                  mr={3}
                />
                <LabelWithTooltip
                  title="Efficiency"
                  tooltipText={
                    cohort
                      ? 'The Efficiency Score (0-100) measures your ability to score points in challenges while minimising the number of attempts made.'
                      : `Efficiency Score (0-100) measures the ${teamOrParticipant}'s ability to score points while making the least number of attempts for challenges.`
                  }
                  mr={3}
                />
                <LabelWithTooltip
                  title="Speed"
                  tooltipText={
                    cohort
                      ? 'The Speed Score (0-100) reflects your quickness in completing challenges within the allocated time for an event.'
                      : `Speed Score (0-100) is determined by the ${teamOrParticipant}'s capability to complete the competition in the quickest possible time.`
                  }
                />
              </Box>
            </Box>
            <Box className="flex flex-col">
              <Box height="350px" my={3}>
                <RadarNivo
                  data={radarChartData}
                  keys={keys}
                  indexBy="label"
                  colors={keys?.map(() => 'transparent')}
                  gridLabel={(props) => {
                    // eslint-disable-next-line react/prop-types
                    const { x, y, id } = props;
                    const anchor = x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
                    const lineData = radarChartData?.find(
                      (d) => d.label === id,
                    );
                    return (
                      <g
                        transform={`translate(${
                          x + (id === 'Success' ? 30 : 0)
                        }, ${y})`}
                      >
                        <text
                          alignmentBaseline="middle"
                          textAnchor={id === 'Success' ? 'start' : anchor}
                          fontSize={12}
                          fontFamily="Montserrat, sans-serif"
                          fontWeight={700}
                          opacity={0.7}
                          fill={isDarkTheme ? '#fff' : '#000'}
                        >
                          {id}
                          <tspan fontSize={12} fontWeight={400} x={0} dy={25}>
                            {`Your Score ${lineData['Your Score']}`}
                          </tspan>
                          {showCohort && (
                            <tspan
                              fontSize={12}
                              fontWeight={400}
                              x={0}
                              dy={20}
                              opacity={0.7}
                            >
                              {`${groupLabel} Average ${
                                lineData[`${groupLabel} Average`]
                              }`}
                            </tspan>
                          )}
                        </text>
                      </g>
                    );
                  }}
                  borderColor={(v) =>
                    v.key === `${groupLabel} Average`
                      ? 'rgba(180, 191, 197, 1)'
                      : v.key === 'baseLine'
                        ? 'rgba(227, 230, 236, 1)'
                        : navyBlueColor
                  }
                />
              </Box>
              <Box
                mt={3}
                className="flex items-center gap-x-4 w-full justify-center"
              >
                <Box className="flex items-center">
                  <Box
                    height="6px"
                    width="25px"
                    style={{
                      backgroundColor: navyBlueColor,
                      marginRight: '0.8rem',
                      opacity: 0.8,
                    }}
                  />
                  <FdTypography variant="captiontext2">Your Score</FdTypography>
                </Box>
                {showCohort && (
                  <Box className="flex items-center">
                    <Box
                      height="6px"
                      width="25px"
                      style={{
                        backgroundColor: 'rgba(180, 191, 197, 1)',
                        marginRight: '0.8rem',
                        opacity: 0.8,
                      }}
                    />
                    <FdTypography variant="captiontext2">{`${groupLabel} Average`}</FdTypography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PerformanceTriangle.propTypes = {
  performanceData: PropTypes.shape({
    completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    totalAssessmentPoints: PropTypes.number.isRequired,
    maxDurationInMins: PropTypes.number.isRequired,
    timeSpentDataForAssessment: PropTypes.number.isRequired,
    totalPointsScoredInAssessment: PropTypes.number.isRequired,
    teamBased: PropTypes.bool.isRequired,
    teamId: PropTypes.string,
    participantsFinishedAssessment: PropTypes.arrayOf(PropTypes.shape({}))
      .isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) }),
    selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  contestantSelected: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  cohort: PropTypes.bool,
};

PerformanceTriangle.defaultProps = {
  cohort: false,
};

export default PerformanceTriangle;

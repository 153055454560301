import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography, FdProgress, FdTooltip } from '@fifthdomain/fe-shared';
import TickIcon from './images/TickIcon';
import UnTickIcon from './images/UnTickIcon';
import { numberToRoman } from '../../shared/utils/stringUtils';
import { getArrayByLength } from '../../shared/utils/objectUtils';

const SkillList = ({ total, completed, currentSpecialty }) => (
  <>
    <Box className="flex gap-x-2 my-2">
      <FdTypography variant="captiontext1" style={{ fontWeight: 700 }}>
        Next Skill Target:
      </FdTypography>
      <FdTypography variant="captiontext1">
        {`Demonstrate ${total} x ${currentSpecialty} skills at Proficiency Level ${total} or above`}
      </FdTypography>
    </Box>
    <Box className="flex gap-x-5 mt-4">
      {getArrayByLength(total, (__, i) => i + 1).map((item) => (
        <Box className="flex flex-col items-center gap-y-2">
          {item <= completed ? (
            <Box height="17px">
              <TickIcon />
            </Box>
          ) : (
            <UnTickIcon />
          )}
          <FdTypography variant="captiontext1" color="secondary">
            {`Skill ${item}`}
          </FdTypography>
        </Box>
      ))}
      {completed >= total && (
        <FdTypography
          variant="captiontext1"
          style={{ fontWeight: 700, color: '#28957B' }}
        >
          Skill target reached!
        </FdTypography>
      )}
    </Box>
  </>
);

SkillList.propTypes = {
  total: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
  currentSpecialty: PropTypes.string.isRequired,
};

const getProgressPercentage = (currentPoints, startPoint, endPoint) => {
  if (endPoint <= startPoint) {
    return 0;
  }
  if (currentPoints >= endPoint) {
    return 100;
  }
  return ((currentPoints - startPoint) / (endPoint - startPoint)) * 100;
};

const Progress = ({ showSkillList, tile, isModal }) => {
  const { currentBadgeLevel, pointsScored, specialty, skillsScored } =
    tile || {};
  const {
    start,
    end,
    endDisplay,
    level,
    specialty: currentSpecialty,
    noOfSkillCoverageToAcquireBadge,
  } = currentBadgeLevel || {};
  const targetDiff = endDisplay - pointsScored;
  const progress = getProgressPercentage(pointsScored, start, end);
  const isPointProwess = specialty === 'points';
  const previousLevel = level - 1;
  const previousLevelLabel =
    previousLevel > 0 ? numberToRoman(previousLevel) : '';
  const nextLevelLabel = level <= 5 ? numberToRoman(level) : '';
  const pointsReached = pointsScored >= endDisplay;
  const totalSkillsRequired = noOfSkillCoverageToAcquireBadge || 0;

  return (
    <Box>
      {isModal ? (
        <Box className="flex flex-col mt-6">
          <FdTypography variant="captiontext2" style={{ fontWeight: 700 }}>
            Next Tier to Unlock:
          </FdTypography>
          <Box className="flex items-center justify-start gap-x-2 mt-2">
            <FdTypography variant="subtitle2">
              {`Tier ${numberToRoman(level)}`}
            </FdTypography>
            <FdTypography variant="captiontext2" color="secondary">
              {isPointProwess
                ? `(Earn ${endDisplay} points across any Professional Specialties)`
                : `(Earn ${endDisplay} points in ${currentSpecialty} + Demonstrate ${noOfSkillCoverageToAcquireBadge} x ${currentSpecialty}
              skills at Proficiency Level ${level} or above)`}
            </FdTypography>
          </Box>
          <Box className="flex gap-x-2 items-center mt-6">
            <FdTypography variant="captiontext1">
              Next Point Target:
            </FdTypography>
            <Box className="flex items-center justify-center gap-x-2">
              <FdTypography variant="subtitle2">{endDisplay}</FdTypography>
              {!isPointProwess && (
                <FdTypography variant="captiontext1">{`points in ${currentSpecialty}`}</FdTypography>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="flex gap-x-2 items-center mt-6">
          <FdTypography variant="captiontext1" style={{ fontWeight: 700 }}>
            Next Point Target:
          </FdTypography>
          <FdTypography variant="captiontext1">
            {`${endDisplay} points ${
              !isPointProwess ? `in ${currentSpecialty}` : ''
            }`}
          </FdTypography>
        </Box>
      )}
      <Box className="my-4 w-full">
        <Box className="flex justify-between my-2">
          {previousLevelLabel && isPointProwess && (
            <FdTypography variant="captiontext1" style={{ fontWeight: 700 }}>
              {`Tier ${previousLevelLabel}`}
            </FdTypography>
          )}
          <Box className="flex justify-between items-center gap-x-1">
            <FdTypography variant="captiontext2">
              {isPointProwess ? `${targetDiff} points left until` : ''}
            </FdTypography>
            <FdTypography variant="captiontext1" style={{ fontWeight: 700 }}>
              {isPointProwess && nextLevelLabel ? `Tier ${nextLevelLabel}` : ''}
            </FdTypography>
          </Box>
        </Box>
        <FdTooltip title={`Points Scored: ${pointsScored}`}>
          <span>
            <FdProgress
              type="linear"
              value={progress}
              style={{ width: '100%' }}
            />
          </span>
        </FdTooltip>
        <Box className="flex justify-between my-2">
          <FdTypography
            variant="captiontext1"
            style={{ fontWeight: 700 }}
            color="secondary"
          >
            {start}
          </FdTypography>
          {isPointProwess ? (
            <FdTypography
              variant="captiontext1"
              style={{ fontWeight: 700 }}
              color="secondary"
            >
              {endDisplay}
            </FdTypography>
          ) : (
            <Box className="flex gap-x-1">
              {pointsReached ? (
                <FdTypography
                  variant="captiontext1"
                  style={{ fontWeight: 700, color: '#28957B' }}
                >
                  Point target reached!
                </FdTypography>
              ) : (
                <FdTypography
                  variant="captiontext1"
                  style={{ fontWeight: 400 }}
                  color="secondary"
                >
                  {`${targetDiff} more points to`}
                </FdTypography>
              )}
              <FdTypography
                variant="captiontext1"
                style={{ fontWeight: 700 }}
                color="secondary"
              >
                {endDisplay}
              </FdTypography>
            </Box>
          )}
        </Box>
      </Box>
      {showSkillList && (
        <Box height="77px">
          {totalSkillsRequired > 1 && (
            <SkillList
              total={totalSkillsRequired}
              completed={
                skillsScored?.filter((s) => s?.level >= level).length || 0
              }
              currentSpecialty={currentSpecialty}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

Progress.defaultProps = {
  showSkillList: true,
  isModal: false,
};

Progress.propTypes = {
  showSkillList: PropTypes.bool,
  tile: PropTypes.shape({}).isRequired,
  isModal: PropTypes.bool,
};

export default Progress;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import {
  FdLoadingSpinner,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdButton,
  FdTypography,
  FdIconWithTooltip,
} from '@fifthdomain/fe-shared';
import { listTasksByOrgId } from '../../graphql/queries';
import ChallengeOverview from './ChallengeOverview';
import HighlightedLabel from '../Team/Dashboard/HighlightedLabel';

const CreatedChallenges = ({ userData }) => {
  const globalSnap = useSnapshot(globalStore);
  const [challengeOverview, setChallengeOverview] = useState(false);

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: globalSnap.orgId,
        filter: {
          userId: { eq: userData?.id },
        },
        limit: 1000,
      },
      skip: !globalSnap.orgId,
    },
  );

  if (listTasksLoading) {
    return <FdLoadingSpinner />;
  }

  const createdChallenges =
    listTasksData?.listTasksByOrgId?.items?.filter(
      (t) => t?.type !== 'CONTAINER',
    ) || [];

  return (
    createdChallenges?.length > 0 && (
      <FdCard
        elevation={0}
        heading={
          <Box display="flex" justifyContent="space-between">
            <Box className="flex">
              <FdTypography variant="h3">
                Challenge Contributions for this organisation:
              </FdTypography>
              <Box className="rounded-full ml-3 mt-2">
                <HighlightedLabel value={createdChallenges?.length || 0} />
              </Box>
              <FdIconWithTooltip title="In this section, you will find a list of all the challenges the user has created that are owned by your organisation." />
            </Box>
            <FdButton
              size="small"
              variant="secondary"
              onClick={() => {
                setChallengeOverview(true);
              }}
            >
              View
            </FdButton>
          </Box>
        }
      >
        <ChallengeOverview
          openModal={challengeOverview}
          onDismiss={() => setChallengeOverview(false)}
          userData={userData}
        />
      </FdCard>
    )
  );
};

CreatedChallenges.propTypes = {
  userData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

CreatedChallenges.defaultProps = {
  userData: [],
};

export default CreatedChallenges;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdTable } from '@fifthdomain/fe-shared';
import TaskInfoCell from './TaskInfoCell';

const useStyles = makeStyles()(() => ({
  multiLineCell: {
    lineHeight: '25px !important',
  },
}));

const SummaryTasksTable = ({ data, levelBased }) => {
  const { classes } = useStyles();
  const columns = [
    { field: 'slNo', width: 50, type: 'number', headerName: ' ' },
    {
      field: 'challengeInfo',
      width: 480,
      headerName: 'Challenge Name',
      filterable: false,
      cellClassName: classes.multiLineCell,
      valueGetter: (params) => params.value,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    { field: 'name' },
    { field: 'specialtyName', flex: 1, headerName: 'Professional Specialty' },
    {
      field: 'difficulty',
      headerName: 'Proficiency',
    },
    {
      field: 'estimatedSolveTime',
      type: 'number',
    },
    {
      field: 'recommendedPoints',
      headerName: 'Points',
      width: 100,
      type: 'number',
    },
  ];

  return (
    <Box mt={2} mb={2} width="100%" height={!levelBased ? '305px' : 'auto'}>
      <FdTable
        columnVisibilityModel={{
          name: false,
          difficulty: false,
          estimatedSolveTime: false,
        }}
        rowHeight={60}
        toolbarSettings={{
          hide: true,
        }}
        disableSelectionOnClick
        rows={data}
        columns={columns}
        hideFooter={levelBased}
        autoPageSize={!levelBased}
        autoHeight={levelBased}
        gridId="competitions-summary-tasks-table"
      />
    </Box>
  );
};

SummaryTasksTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,
  levelBased: PropTypes.bool,
};

SummaryTasksTable.defaultProps = {
  levelBased: false,
};

export default SummaryTasksTable;

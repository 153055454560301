import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { format } from 'date-fns';
import { Box } from '@mui/material';
import { FdTypography, FdDelayed } from '@fifthdomain/fe-shared';
import MessageInput from './AIChat/MessageInput';
import MessageStream from './AIChat/MessageStream';
import AIIconImg from '../../../shared/images/ai-icon.png';
import useGetAIChatUserThreads from '../../../hooks/useGetAIChatUserThreads';
import BlueFillerAniImage from '../../Trophy-room/images/BlueFillerIAniImage';
import useGetAIChatAddMessage from '../../../hooks/useGetAIChatAddMessage';
import { uploadAIChatImageAttachmentToS3 } from '../../../shared/utils/chatUtils';
import AILoader from './AIChat/AILoader';
import { AI_ASSISTANT_NAME } from '../../../constants';

const IconAI = () => <img src={AIIconImg} alt="AI" />;

const ChallengeAIChat = ({
  userId,
  assessmentId,
  userAssessmentId,
  taskId,
}) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setChatMessages([]);
  }, [taskId]);

  const { loading: threadsLoading } = useGetAIChatUserThreads({
    userId,
    assessmentId,
    userAssessmentId,
    taskId,
    onCompleted: (data) => {
      setChatMessages(data);
      setLoading(false);
    },
  });

  const onUpdateLiveMessage = (newStreamData) => {
    setChatMessages((prevMessages) => {
      if (prevMessages.length === 0) return prevMessages;
      const updatedMessages = [...prevMessages];
      updatedMessages[0] = {
        ...updatedMessages[0],
        newMessage: newStreamData?.content[0]?.text?.value,
      };
      return updatedMessages;
    });
  };

  const { mutate: addMessageMutation } = useGetAIChatAddMessage({
    onUpdate: onUpdateLiveMessage,
  });

  const onSendMessage = async (message) => {
    setChatMessages((cm) => [
      {
        MessengerIcon: IconAI,
        messengerName: AI_ASSISTANT_NAME,
        newMessage: undefined,
        id: shortid.generate(),
      },
      {
        id: shortid.generate(),
        role: 'user',
        self: true,
        newMessage: message?.text,
        created_at: Math.floor(Date.now() / 1000),
        imagesPreview:
          message?.images?.map((image) => URL.createObjectURL(image)) || [],
      },
      ...cm,
    ]);
    const imagesForUpload =
      message?.images?.map((image) => ({
        file: image,
        key: `challenge-threads-messages/${userId}/${userAssessmentId}-${taskId}-${shortid.generate()}-${image?.name}`,
      })) || [];
    const imageUrls = await uploadAIChatImageAttachmentToS3(imagesForUpload);
    // add message along with images
    addMessageMutation({
      message,
      userAssessmentId,
      taskId,
      images: imageUrls || [],
    });
  };
  const formattedMessages =
    [...chatMessages]?.reverse()?.map((message) => {
      const {
        created_at: createdAt,
        role,
        content,
        newMessage,
        id,
        imagesPreview,
      } = message || {};
      const createdAtDate = createdAt && new Date(createdAt * 1000);
      const self = role === 'user';
      const text =
        content?.find((c) => c?.type === 'text')?.text?.value || newMessage;
      const images =
        content
          ?.filter((c) => c?.type === 'image_url')
          ?.map((c) => c?.image_url?.url) ||
        imagesPreview?.map((url) => url) ||
        [];

      return {
        timeStamp:
          createdAtDate && format(createdAtDate, 'dd MMM yyyy, h:mm a'),
        self,
        MessengerIcon: !self && IconAI,
        messengerName: !self && AI_ASSISTANT_NAME,
        text,
        id,
        images,
      };
    }) || [];

  return (
    <Box className="flex flex-col" sx={{ maxHeight: 'calc(100vh - 270px)' }}>
      {threadsLoading || loading ? (
        <Box className="flex items-center justify-center h-screen w-full">
          <AILoader />
        </Box>
      ) : (
        <>
          {chatMessages?.length > 0 ? (
            <MessageStream messages={formattedMessages} />
          ) : (
            <Box className="flex flex-col items-center justify-center h-screen w-full">
              <Box height="200px" width="200px">
                <BlueFillerAniImage />
              </Box>
              <FdDelayed delay={1000} height="50px">
                <Box className="text-center mt-6">
                  <FdTypography variant="body2" color="secondary">
                    {`Meet ${AI_ASSISTANT_NAME}, your challenge assistant!`}
                    <br />
                    <br />
                    Need help on this challenge?
                    <br />
                    <br />
                    {`Ask ${AI_ASSISTANT_NAME} a question to get started.`}
                  </FdTypography>
                </Box>
              </FdDelayed>
            </Box>
          )}
          <MessageInput
            onSubmitMessage={onSendMessage}
            placeholder={`Ask ${AI_ASSISTANT_NAME} for challenge help`}
            captionText={`Your messages with ${AI_ASSISTANT_NAME} are private. Remember, ${AI_ASSISTANT_NAME}'s responses might not always be perfect.`}
          />
        </>
      )}
    </Box>
  );
};

ChallengeAIChat.propTypes = {
  userId: PropTypes.string.isRequired,
  assessmentId: PropTypes.string.isRequired,
  userAssessmentId: PropTypes.string.isRequired,
  taskId: PropTypes.string.isRequired,
};

export default ChallengeAIChat;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Avatar, Box, CircularProgress } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import AILoader from './AILoader';
import ImageCarousel from './ImageCarousel';

const SelfMessage = ({ text, timeStamp, images }) => {
  const [showImagePreview, setShowImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);

  return (
    <Box className="self-end my-1">
      <Box className="flex gap-x-2 my-2 justify-end">
        {images?.map((image, index) => (
          <Box
            height="120px"
            width="120px"
            className="flex items-center justify-center relative cursor-pointer"
            onClick={() => setShowImagePreview(index)}
          >
            {loading && <CircularProgress className="absolute" />}
            <img
              key={image}
              src={image}
              alt="attachment"
              style={{ height: '120px', width: '120px' }}
              className="rounded bg-cover bg-center"
              onLoad={() => setLoading(false)}
            />
          </Box>
        ))}
      </Box>
      <Box
        className="rounded-xl p-4"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.type === 'dark' ? '#363639' : 'rgba(0, 0, 0, 0.03)',
          maxWidth: '640px',
          borderColor: theme.palette.type === 'dark' && '#27272A',
          borderWidth: '1px',
        })}
      >
        <FdTypography
          variant="body2"
          color="secondary"
          style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        >
          {text}
        </FdTypography>
      </Box>
      <Box className="mb-2 text-right">
        <FdTypography variant="captiontext1" color="secondary">
          {timeStamp}
        </FdTypography>
      </Box>
      <ImageCarousel
        open={showImagePreview}
        onClose={() => setShowImagePreview(null)}
        imageUrls={images}
      />
    </Box>
  );
};

SelfMessage.propTypes = {
  text: PropTypes.string.isRequired,
  timeStamp: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const ExternalMessage = ({ text, messengerName, MessengerIcon }) => (
  <Box my={1}>
    <Box className="flex items-center gap-x-2">
      <Avatar sx={{ width: 28, height: 28 }}>
        <MessengerIcon />
      </Avatar>
      <FdTypography variant="subtitle2">{messengerName}</FdTypography>
    </Box>
    <Box className="w-full my-2 text-wrap">
      <FdTypography variant="body2">
        <Markdown
          key={text}
          // eslint-disable-next-line react/no-children-prop
          children={text}
          components={{
            p: ({ ...props }) => <p {...props} className="pb-1" />,
            ol: ({ ...props }) => <ol {...props} className="list-decimal" />,
            li: ({ ...props }) => <li {...props} className="ml-4 pl-1" />,
            code(props) {
              // eslint-disable-next-line react/prop-types
              const { children, className, ...rest } = props;
              const match = /language-(\w+)/.exec(className || '');
              return match ? (
                <SyntaxHighlighter
                  {...rest}
                  PreTag="div"
                  // eslint-disable-next-line react/no-children-prop
                  children={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  style={dracula}
                />
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              );
            },
          }}
        />
        {!text && (
          <Box mb={1}>
            <AILoader />
          </Box>
        )}
      </FdTypography>
    </Box>
  </Box>
);

ExternalMessage.propTypes = {
  text: PropTypes.string.isRequired,
  messengerName: PropTypes.string.isRequired,
  MessengerIcon: PropTypes.elementType.isRequired,
  live: PropTypes.bool.isRequired,
};

const Message = ({ message }) => {
  const { messengerName, MessengerIcon, images, text, timeStamp, self } =
    message || {};

  return self ? (
    <SelfMessage text={text} timeStamp={timeStamp} images={images} />
  ) : (
    <ExternalMessage
      text={text}
      messengerName={messengerName}
      MessengerIcon={MessengerIcon}
    />
  );
};

Message.propTypes = {
  message: PropTypes.shape({}).isRequired,
};

export default Message;

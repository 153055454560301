import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import { FdTypography, FdCard, FdButton } from '@fifthdomain/fe-shared';
import SectionHeading from './SectionHeading';

const TrophySection = ({
  type,
  section,
  showDivider,
  showTypeChip,
  onView,
  children,
}) => {
  return (
    <Box>
      <FdCard variant="outlined">
        <Box className="flex justify-between mb-4">
          <SectionHeading
            name={section?.name}
            type={type}
            showTypeChip={showTypeChip}
          />
          {showDivider && (
            <FdButton
              variant="secondary"
              onClick={() => onView(section?.specialty)}
            >
              View
            </FdButton>
          )}
        </Box>
        <Box minHeight="36px">
          <FdTypography variant="captiontext1">
            {section?.description}
          </FdTypography>
        </Box>
        {showDivider && (
          <Box my={2}>
            <Divider />
          </Box>
        )}
        <Box mt={3}>{children}</Box>
      </FdCard>
    </Box>
  );
};

TrophySection.defaultProps = {
  showDivider: true,
  showTypeChip: true,
};

TrophySection.propTypes = {
  section: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    verticalTiles: PropTypes.bool,
    specialty: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showDivider: PropTypes.bool,
  showTypeChip: PropTypes.bool,
  onView: PropTypes.func.isRequired,
};

export default TrophySection;

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import {
  useAppTheme,
  setTheme,
  AuthContext,
  AppThemeContext,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { getUserById } from '../graphql/queries';

const AppThemeProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const globalSnap = useSnapshot(globalStore);
  const [appTheme, setAppTheme] = useState(undefined);
  // get user theme
  const { loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
    skip: !user,
    onCompleted: (data) => {
      const userTheme = data?.getUserById?.items[0].theme.toLowerCase();
      // for admins light theme is default and for participants dark theme is default
      const theme =
        globalSnap.userType === 'ADMIN' ? 'light' : userTheme || 'dark';

      setAppTheme(theme);
      setTheme(theme);
    },
  });
  const theme = useAppTheme();

  if (userLoading) return null;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppThemeContext.Provider value={appTheme}>
          {children}
        </AppThemeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppThemeProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { FdCard, FdTypography } from '@fifthdomain/fe-shared';

const ScoreboardVisibility = ({ editMode }) => {
  const { control, setValue } = useFormContext();

  const Wrapper = editMode ? Box : FdCard;

  return (
    <Wrapper heading={!editMode && 'Scoreboard Visibility'} variant="outlined">
      <Box>
        <FdTypography variant="body1" color="secondary">
          Select if the scoreboard should be displayed to participants. This can
          be toggled any time during or after the competition. During the
          competition, the scoreboard will be displayed by default. After the
          competition, the scoreboard will be hidden by default.
        </FdTypography>
        <FdTypography variant="body1" color="secondary">
          If the scoreboard is displayed after a competition, participants will
          only be able to view scores, not challenges or any associated
          competition content.
        </FdTypography>
      </Box>
      <Controller
        control={control}
        name="scoreboardVisibility"
        render={({
          field: { ref, value: fieldValue, ...rest },
          fieldState: { error },
        }) => (
          <Box>
            <Box my={2}>
              <FormGroup>
                <FdTypography variant="subtitle1">
                  Display Scoreboard
                </FdTypography>
                <FormControlLabel
                  control={
                    <Switch
                      id="scoreboardVisibility"
                      checked={fieldValue}
                      value={fieldValue?.value}
                      error={error && error.message}
                      data-cy="scoreboard-visibility"
                      {...rest}
                      onChange={(e) => {
                        setValue('scoreboardVisibility', e.target.checked);
                      }}
                      inputRef={ref}
                    />
                  }
                />
              </FormGroup>
            </Box>
          </Box>
        )}
      />
    </Wrapper>
  );
};

ScoreboardVisibility.propTypes = {
  editMode: PropTypes.bool,
};

ScoreboardVisibility.defaultProps = {
  editMode: false,
};

export default ScoreboardVisibility;

import React from 'react';
import PropTypes from 'prop-types';
import * as singleSpa from 'single-spa';
import { Box, Divider, Card, CardContent, useTheme } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
  FdTypography,
  FdButton,
  FdChip,
  FdCircularProgressBar,
} from '@fifthdomain/fe-shared';
import { getParticipantStatus } from '../../../shared/utils/getParticipantStatus';
import { getParticipantStatusColor } from '../../../shared/utils/getStatusColor';

const EventCard = ({ event }) => {
  const theme = useTheme();
  const { userAssessmentId, name, timeStamp, status, pointsText, pointsAvg } =
    event || {};
  const onEnterClick = () => {
    // navigate to competition start page
    singleSpa.navigateToUrl(
      `/competitions/competition-start/${userAssessmentId}`,
    );
  };

  return (
    <Box mb={2}>
      <Card variant="outlined">
        <CardContent style={{ paddingBottom: '1rem' }}>
          <Box className="flex items-center justify-between" height={45}>
            <Box width="50%" className="flex items-center gap-x-3">
              <Box
                width={32}
                height={57}
                style={{
                  backgroundColor: theme?.fdProColors?.event.competitions,
                }}
                className="rounded-md flex items-center justify-center p-0.8"
              >
                <EmojiEventsIcon style={{ fill: '#000' }} />
              </Box>
              <FdTypography variant="subtitle2">{name}</FdTypography>
            </Box>
            <Divider variant="vertical" />
            <Box className="flex flex-col items-center">
              <Box width={30} height={30}>
                <FdCircularProgressBar value={pointsAvg} />
              </Box>
              <FdTypography
                variant="captiontext1"
                style={{ marginTop: '0.3rem' }}
              >
                {pointsText}
              </FdTypography>
            </Box>
            <Divider variant="vertical" />
            <Box className="flex flex-col justify-around" height={54}>
              <FdTypography variant="captiontext1">{timeStamp}</FdTypography>
              <FdChip
                color={getParticipantStatusColor(status)}
                size="small"
                label={getParticipantStatus(status)}
              />
            </Box>
            <Divider variant="vertical" />
            <FdButton onClick={onEnterClick}>Enter</FdButton>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

EventCard.propTypes = {
  event: PropTypes.shape({}).isRequired,
};

export default EventCard;

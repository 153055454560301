import Level1Completed from './Level1.png';
import Level2Completed from './Level2.png';
import Level3Completed from './Level3.png';
import Level4Completed from './Level4.png';
import Level5Completed from './Level5.png';
import Level6Completed from './Level6.png';
import Level7Completed from './Level7.png';
import Level8Completed from './Level8.png';
import Level9Completed from './Level9.png';
import Level10Completed from './Level10.png';

export {
  Level1Completed,
  Level2Completed,
  Level3Completed,
  Level4Completed,
  Level5Completed,
  Level6Completed,
  Level7Completed,
  Level8Completed,
  Level9Completed,
  Level10Completed,
};

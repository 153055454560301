import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import {
  useQueryRecursive,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import { getSystemTime } from '../graphql/queries';
import appStore from '../store';
import initializeNotificationStore from '../store/initializeNotificationStore';

const AppStoreInitializer = ({ children }) => {
  const globalSnap = useSnapshot(globalStore);
  // initialize notificationStore
  initializeNotificationStore();
  useQueryRecursive(gql(getSystemTime), {
    onCompleted: (_data) => {
      const dateTime = Object.values(_data.getSystemTime).join(''); // to prep result returned by useQueryRecursive as a single string
      appStore.startSystemDateTimer(new Date(dateTime));
    },
    staleTime: { minutes: 0 },
    skip: !globalSnap.userId,
  });

  useEffect(() => {
    // stop sys-date timer on unmount
    return () => {
      appStore.stopSystemDateTimer();
    };
  }, []);

  return <>{children}</>;
};

AppStoreInitializer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppStoreInitializer;

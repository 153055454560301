import React from 'react';
import PropTypes from 'prop-types';
import * as singleSpa from 'single-spa';
import _ from 'lodash';
import { Box, Divider } from '@mui/material';
import { FdTypography, FdCard, FdButton } from '@fifthdomain/fe-shared';
import TrophyRoomCardContent from './TrophyRoomCardContent';

const TrophyRoomCard = ({ userId }) => {
  return (
    <Box>
      <FdCard variant="outlined">
        <Box className="flex justify-between mb-4">
          <FdTypography variant="h3">Trophy Room</FdTypography>
          <FdButton
            onClick={() =>
              singleSpa.navigateToUrl(`/competitions/trophy-room/${userId}`)
            }
          >
            View
          </FdButton>
        </Box>
        <Box minHeight="36px">
          <FdTypography variant="captiontext1">
            A user&apos;s Trophy Room displays the trophies they have obtained
            through various activities on the platform. This user&apos;s five
            most recently obtained trophies are displayed below. Click
            &apos;View&apos; to navigate to this user&apos;s expanded Trophy
            Room.
          </FdTypography>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <TrophyRoomCardContent userId={userId} />
      </FdCard>
    </Box>
  );
};

TrophyRoomCard.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default TrophyRoomCard;

import { useState } from 'react';
import { useQueryClient } from '@fifthdomain/fe-shared';
import { gql, useQuery } from '@apollo/client';
import { listUserThreadsByUserAssessmentId } from '../graphql/queries';
import {
  createAIChatThread,
  fetchAIChatMessages,
} from '../shared/utils/chatUtils';

const useGetAIChatUserThreads = ({
  userId,
  assessmentId,
  userAssessmentId,
  taskId,
  onCompleted,
}) => {
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();

  const { loading: dbThreadsLoading } = useQuery(
    gql(listUserThreadsByUserAssessmentId),
    {
      variables: {
        userAssessmentId,
        taskId: { eq: taskId },
      },
      onCompleted: async (data) => {
        const existingThreadId =
          data?.listUserThreadsByUserAssessmentId?.items?.[0]?.threadId;
        try {
          if (existingThreadId) {
            // fetch messages for the existing thread
            const messageThread = await queryClient.fetchQuery({
              queryKey: ['AIChatMessages', { userAssessmentId, taskId }],
              queryFn: () => fetchAIChatMessages({ userAssessmentId, taskId }),
              staleTime: { seconds: 0 },
            });
            onCompleted?.(messageThread?.messages || []);
          } else {
            // create a new thread
            await createAIChatThread({
              userId,
              assessmentId,
              userAssessmentId,
              taskId,
            });
            // new thread with no messages
            onCompleted?.([]);
          }
        } finally {
          setLoading(false);
        }
      },
    },
  );

  const allLoading = dbThreadsLoading || loading;

  return {
    loading: allLoading,
  };
};

export default useGetAIChatUserThreads;

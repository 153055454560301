import React from 'react';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import getGraphTheme from '../../shared/utils/graphUtil';

const Donut = ({ data, labels, colors }) => {
  const theme = useTheme();
  const graphTheme = getGraphTheme(theme);
  const options = {
    series: data,
    options: {
      plotOptions: {
        donut: {
          expandOnClick: false,
        },
      },
      colors,
      labels,
      legend: {
        show: true,
        position: 'bottom',
      },
      theme: {
        mode: graphTheme,
      },
      chart: {
        background: 'transparent',
        fontFamily: 'Montserrat, Arial, sans-serif',
      },
    },
  };

  return (
    <Box>
      <Chart
        options={options.options}
        series={options.series}
        type="donut"
        width="100%"
        height="350"
      />
    </Box>
  );
};

Donut.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  labels: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
};

Donut.defaultProps = {
  data: [],
  labels: [],
  colors: [],
};

export default Donut;

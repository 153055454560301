import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { PROFICIENCY, SPECIALTY_COLOR } from '../../constants';
import BoxChip from '../Participant/Tasks/BoxChip';

const TaskDrawerHeaderParticipant = ({ activeTask }) => {
  const { difficulty, recommendedPoints, specialty } = activeTask || {};

  return (
    <Box className="flex mt-1 gap-x-2 ml-14">
      <BoxChip bgColor={SPECIALTY_COLOR[specialty]} text={specialty} />
      <BoxChip bgColor={PROFICIENCY[difficulty]?.color} text={difficulty} />
      <BoxChip
        style={{
          background:
            'linear-gradient(285.5deg, #C9DFF0 19.02%, #91BAD8 34.04%, #496174 88.57%)',
        }}
        text={`${recommendedPoints} pts`}
      />
    </Box>
  );
};

TaskDrawerHeaderParticipant.propTypes = {
  activeTask: PropTypes.shape({
    specialty: PropTypes.string,
    type: PropTypes.string,
    solves: PropTypes.number,
    recommendedPoints: PropTypes.number,
    difficulty: PropTypes.string,
  }).isRequired,
};

export default TaskDrawerHeaderParticipant;

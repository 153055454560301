import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box, IconButton, Divider } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdSkeleton,
  FdCard,
  useSnapshot,
  globalStore,
  FdTooltip,
  FdTypography,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import CyberSkillCortex from '../Insights/CyberSkillCortex';
import TeamCompetitions from './Dashboard/TeamCompetitions';
import PerformanceTriangle from './Dashboard/PerformanceTriangle';
import {
  listUserMetricsByOrgId,
  listUserScoreboardsByTeamId,
} from '../../graphql/queries';
import ScoreCardTile from './Dashboard/ScoreCardTile';
import { calculatePercentageDifference } from '../../shared/utils/objectUtils';

const CardHeading = ({ heading, tooltipText, subHeading }) => (
  <Box>
    <Box className="flex">
      <FdTypography variant="h4">{heading}</FdTypography>
      {tooltipText && (
        <FdTooltip title={tooltipText}>
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      )}
    </Box>
    <FdTypography
      variant="captiontext1"
      color="secondary"
      style={{ marginTop: '0.5rem', textAlign: 'justify' }}
    >
      {subHeading}
    </FdTypography>
  </Box>
);

CardHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

const getAverage = (objectArray, columnsToAverage) => {
  const average = _.reduce(
    objectArray,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(average, (value, key) => {
    average[key] = parseFloat((value / objectArray?.length).toFixed(2));
  });

  return average;
};

const TeamDashboard = ({ team, loading }) => {
  const globalSnap = useSnapshot(globalStore);

  const {
    data: userScoreboardsByTeamIdData,
    loading: userScoreboardsByTeamIdLoading,
  } = useQueryRecursive(gql(listUserScoreboardsByTeamId), {
    variables: {
      teamId: team?.id,
      limit: 1000,
    },
  });

  const {
    data: listUserMetricsByOrgIdData,
    loading: listUserMetricsByOrgIdLoading,
  } = useQueryRecursive(gql(listUserMetricsByOrgId), {
    variables: {
      orgId: globalSnap?.orgId,
      limit: 1000,
    },
  });

  const allTeamMemberIds = team?.members?.items?.map((m) => m?.userId);
  const userMetricsData =
    listUserMetricsByOrgIdData?.listUserMetricsByOrgId?.items?.filter((um) =>
      allTeamMemberIds?.includes(um?.userId),
    ) || [];
  const userMetricsMemberData = userMetricsData?.filter(
    (u) => u?.userId === globalSnap?.userId,
  );

  const userScoreboards =
    userScoreboardsByTeamIdData?.listUserScoreboardsByTeamId?.items || [];
  const memberScoreboards = userScoreboards?.filter(
    (u) => u?.userId === globalSnap?.userId,
  );

  const columnsToAverage = ['successScore', 'speed', 'efficiency', 'score'];
  const columnsToAverageLastMonth = [
    'lastSuccessScore',
    'lastSpeed',
    'lastEfficiency',
    'lastScore',
  ];

  const teamAverage = getAverage(userScoreboards, columnsToAverage);
  const memberAverage = getAverage(memberScoreboards, columnsToAverage);

  const teamAverageLastMonth = getAverage(
    userMetricsData,
    columnsToAverageLastMonth,
  );
  const memberAverageLastMonth = getAverage(
    userMetricsMemberData,
    columnsToAverageLastMonth,
  );

  const performanceData = {
    team: {
      score: Math.round(teamAverage?.score) || 0,
      success: teamAverage?.successScore || 0,
      efficiency: teamAverage?.efficiency || 0,
      speed: teamAverage?.speed || 0,
    },
    participant: {
      success: memberAverage?.successScore || 0,
      efficiency: memberAverage?.efficiency || 0,
      speed: memberAverage?.speed || 0,
    },
    teamBased: true,
  };
  const _loading =
    loading || userScoreboardsByTeamIdLoading || listUserMetricsByOrgIdLoading;

  return (
    <FdSkeleton loading={loading} height={620}>
      <Box className="flex gap-x-4 gap-y-4">
        <Box className="w-2/4 flex flex-col gap-y-2">
          <FdCard
            variant="outlined"
            heading={
              <CardHeading
                heading="Cyber Skills Cortex"
                subHeading={
                  <Box className="text-justify">
                    The Cyber Skills Cortex displays demonstrated cyber skills
                    aligned with the Dreyfus model&apos;s five proficiency
                    levels (1-5). Mapped to one of six FifthDomain Professional
                    Specialties, each skill darkens in corresponding squares
                    from the centre of the Cortex as one&apos;s demonstration of
                    proficiency in that skill increases.
                    <br />
                    <br />
                    The Cyber Skills Cortex below shows a current snapshot of
                    your team member&apos;s skills demonstrated across your
                    team&apos;s competitions. The skills of any members who are
                    not currently on your team are not displayed.
                  </Box>
                }
                tooltipText="This version of the Cyber Skills Cortex displays the skills that members within this team have demonstrated in this team's competitions."
              />
            }
          >
            <Box className="mt-2 mb-6">
              <Divider />
            </Box>
            <CyberSkillCortex
              isParticipant
              teamId={team?.id}
              size="small"
              teamBased
              hideToggle
            />
          </FdCard>
          <FdCard
            variant="outlined"
            heading={
              <CardHeading
                heading="Performance Triangle"
                subHeading={
                  <Box className="text-justify">
                    The Performance Triangle displays a participant&apos;s
                    Success, Efficiency, and Speed scores on the platform. The
                    blue triangle below shows your scores within the context of
                    this team, while the grey triangle shows the average scores
                    of all members within this team.
                    <br />
                    <br />
                    The Performance Triangle below shows a current snapshot of
                    your team members&apos; performance data demonstrated across
                    your team&apos;s competitions. The performance data of any
                    members who are not currently on your team is not displayed.
                  </Box>
                }
                tooltipText="This Performance Triangle is based on team members' performance in this team's competitions."
              />
            }
          >
            <PerformanceTriangle performanceData={performanceData} />
          </FdCard>
        </Box>
        <Box className="w-2/4 flex flex-col gap-y-2">
          <Box className="flex gap-x-4 gap-y-4">
            <FdCard
              variant="outlined"
              heading={
                <CardHeading
                  heading="Team Scorecard"
                  subHeading="See your team's average FifthDomain Score, and Success, Efficiency, and Speed Scores. These have been calculated based on each member's participation in this team's competitions."
                />
              }
            >
              <FdSkeleton loading={_loading} height={350}>
                <ScoreCardTile
                  tiles={[
                    {
                      label: 'FifthDomain Score',
                      value: Math.round(teamAverage?.score) || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          teamAverageLastMonth.lastScore,
                          Math.round(teamAverage?.score),
                        ) || 0,
                      toolTipText:
                        "A team's FifthDomain score (0-100) is a combined weighted sum of their Success, Efficiency, and Speed scores, with Success carrying the highest weightage. The displayed FifthDomain Score is exclusively based on your team's activity in team competitions.",
                      valueInChip: true,
                    },
                    {
                      label: 'Success Score',
                      value: teamAverage?.successScore || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          teamAverageLastMonth.lastSuccessScore,
                          Math.round(teamAverage?.successScore),
                        ) || 0,
                      toolTipText:
                        "The Success Score (0-100) measures ability to capture flags, taking into account points earned for solved challenges relative to overall points available. The displayed Success Score is based on team member's activity in this team's competitions.",
                    },
                    {
                      label: 'Efficiency Score',
                      value: teamAverage?.efficiency || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          teamAverageLastMonth.lastEfficiency,
                          Math.round(teamAverage?.efficiency),
                        ) || 0,
                      toolTipText:
                        "The Efficiency Score (0-100) measures ability to score points in challenges while minimising the number of attempts made. The displayed Efficiency Score is based on team member's activity in this team's competitions.",
                    },
                    {
                      label: 'Speed Score',
                      value: teamAverage?.speed || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          teamAverageLastMonth.lastSpeed,
                          Math.round(teamAverage?.speed),
                        ) || 0,
                      toolTipText:
                        "The Speed Score (0-100) reflects quickness in completing challenges within the allocated time for an event. The displayed Speed Score is based on team member's activity in this team's competitions.",
                    },
                  ]}
                />
              </FdSkeleton>
            </FdCard>
            <FdCard
              variant="outlined"
              heading={
                <CardHeading
                  heading="Your Member Scorecard"
                  subHeading="See your FifthDomain Score, and Success, Efficiency, and Speed Scores as a member of this team. These scores have been calculated based on your participation in this team's competitions."
                />
              }
            >
              <FdSkeleton loading={_loading} height={350}>
                <ScoreCardTile
                  tiles={[
                    {
                      label: 'FifthDomain Score',
                      value: Math.round(memberAverage?.score) || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          memberAverageLastMonth.lastScore,
                          Math.round(memberAverage?.score),
                        ) || 0,
                      toolTipText:
                        "Your FifthDomain score (0-100) is a combined weighted sum of your Success, Efficiency, and Speed scores, with Success carrying the highest weightage. The displayed FifthDomain Score is exclusively based on your individual activity in this team's competitions.",
                      valueInChip: true,
                    },
                    {
                      label: 'Success Score',
                      value: memberAverage?.successScore || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          memberAverageLastMonth.lastSuccessScore,
                          Math.round(memberAverage?.successScore),
                        ) || 0,
                      toolTipText:
                        "The Success Score (0-100) measures ability to capture flags, taking into account points earned for solved challenges relative to overall points available. The displayed Success Score is based on your individual activity in this team's competitions. ",
                    },
                    {
                      label: 'Efficiency Score',
                      value: memberAverage?.efficiency || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          memberAverageLastMonth.lastEfficiency,
                          Math.round(memberAverage?.efficiency),
                        ) || 0,
                      toolTipText:
                        "The Efficiency Score (0-100) measures ability to score points in challenges while minimising the number of attempts made. The displayed Efficiency Score is based on your individual activity in this team's competitions.",
                    },
                    {
                      label: 'Speed Score',
                      value: memberAverage?.speed || 0,
                      lastMonthValue:
                        calculatePercentageDifference(
                          memberAverageLastMonth.lastSpeed,
                          Math.round(memberAverage?.speed),
                        ) || 0,
                      toolTipText:
                        "The Speed Score (0-100) reflects quickness in completing challenges within the allocated time for an event. The displayed Speed Score is based on your individual activity in this team's competitions.",
                    },
                  ]}
                />
              </FdSkeleton>
            </FdCard>
          </Box>
          <Box>
            <FdCard
              variant="outlined"
              heading={
                <CardHeading
                  heading="Team Competitions"
                  subHeading="Below is a list of competitions that your team has been enrolled in. Click 'Enter' to participate in competitions that are in progress, and view scoreboards for competitions that have ended."
                />
              }
            >
              <TeamCompetitions team={team} />
            </FdCard>
          </Box>
        </Box>
      </Box>
    </FdSkeleton>
  );
};

TeamDashboard.propTypes = {
  loading: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    key: PropTypes.string,
    members: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    private: PropTypes.bool,
  }).isRequired,
};

export default TeamDashboard;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import { PROFICIENCY, PROFICIENCY_LEVELS } from '../../../constants';
import { getLabelColor } from '../../../shared/utils/layout';

const SkillsDemonstrated = ({
  isSolvesSelected,
  teamBased,
  topSpecialty,
  top3Skills,
  managerMode,
}) => {
  return (
    <Box className="flex  gap-x-5 mt-3 w-full">
      <Box>
        <FdTypography
          variant="captiontext1"
          style={{ fontWeight: 700, marginBottom: '1rem' }}
        >
          {!isSolvesSelected
            ? 'Most Attempted Professional Specialty'
            : managerMode
              ? 'Professional Speciality Match'
              : 'Most Aligned Professional Specialty'}
        </FdTypography>
        {topSpecialty ? (
          <Box>
            <FdTypography variant="captiontext2" color="secondary">
              {teamBased
                ? isSolvesSelected
                  ? 'Your team has demonstrated the highest alignment with the following Professional Specialty:'
                  : 'The following Professional Specialty is currently your team most attempted:'
                : !isSolvesSelected
                  ? 'The following Professional Specialty is currently your most attempted:'
                  : managerMode
                    ? 'Based on your performance, you are best aligned with the following professional speciality:'
                    : 'You currently have most alignment with the following Professional Specialty:'}
            </FdTypography>
            <Box mt={1}>
              <Box className="mb-3">
                <FdTypography variant="captiontext2">
                  <span
                    style={{
                      padding: '4px 8px',
                      borderRadius: '4px',
                      backgroundColor: 'rgba(227, 230, 236, 1)',
                      color: 'rgba(0, 0, 0, 1)',
                      fontWeight: 600,
                    }}
                  >
                    {topSpecialty}
                  </span>
                </FdTypography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <FdTypography variant="captiontext2" color="secondary">
              {isSolvesSelected
                ? 'As you embark on solving challenges during competitions/assessments, the Professional Specialty most closely aligned with your skills, will dynamically update.'
                : 'As you embark on solving challenges during competitions/assessments, most attempted Professional Specialty will dynamically update.'}
            </FdTypography>
          </Box>
        )}
      </Box>
      <Box>
        <FdTypography
          variant="captiontext1"
          style={{ fontWeight: 700, marginBottom: '1rem' }}
        >
          {isSolvesSelected
            ? 'Top Skills Demonstrated'
            : 'Most Attempted Skills'}
        </FdTypography>
        {top3Skills?.length > 0 ? (
          <Box>
            <FdTypography variant="captiontext2" color="secondary">
              {teamBased
                ? isSolvesSelected
                  ? 'Your team has demonstrated strong abilities in the following skills:'
                  : 'The following skills are currently your team most attempted:'
                : isSolvesSelected
                  ? 'You have demonstrated strong abilities in the following skills:'
                  : 'The following skills are currently your most attempted:'}
            </FdTypography>
            <Box mt={1}>
              {top3Skills?.map((ts) => {
                const { yLabelName, x } = ts?.topRow || {};
                const proficiency = PROFICIENCY_LEVELS[x];
                const proficiencyColor = PROFICIENCY[proficiency]?.color;
                return (
                  <Box
                    display="flex"
                    flexDirection={managerMode ? 'row' : 'column'}
                    my={2}
                  >
                    <FdTypography
                      variant="captiontext1"
                      style={{ fontWeight: 600 }}
                    >
                      {yLabelName}
                    </FdTypography>
                    <Box
                      className={`flex ${managerMode ? 'mb-2' : 'my-2'} ${
                        managerMode ? 'ml-2' : 'ml-0'
                      }`}
                    >
                      <FdTypography variant="captiontext2">
                        <span
                          style={{
                            padding: '3px 8px',
                            borderRadius: '4px',
                            backgroundColor: proficiencyColor,
                            color: getLabelColor(proficiencyColor),
                            fontWeight: 600,
                          }}
                        >
                          {proficiency}
                        </span>
                      </FdTypography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Box>
            <FdTypography variant="captiontext2" color="secondary">
              {isSolvesSelected
                ? 'As you engage in solving challenges within competitions/assessments, your top demonstrated skills will dynamically update'
                : 'As you engage in solving challenges within competitions/assessments, your top attempted skills will dynamically update.'}
            </FdTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

SkillsDemonstrated.defaultProps = {
  topSpecialty: undefined,
  teamBased: false,
  managerMode: false,
  top3Skills: [],
};

SkillsDemonstrated.propTypes = {
  isSolvesSelected: PropTypes.bool.isRequired,
  teamBased: PropTypes.bool,
  managerMode: PropTypes.bool,
  topSpecialty: PropTypes.string,
  top3Skills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

export default SkillsDemonstrated;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import _ from 'lodash';
import shortid from 'shortid';
import {
  FdChip,
  FdCard,
  FdButton,
  useQueryRecursive,
  FdSkeleton,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import { Summary, SummaryTitle, TopFiveTable } from '../components/Assessment';
import { Donut } from '../components/Charts';
import {
  listUsersByAssessmentId,
  listInvitedUsersByAssessmentId,
} from '../graphql/queries';
import { listTeamsByEventId } from '../queries/customQueries';
import {
  getDateTimeZoneFormatted,
  sortByDateField,
} from '../shared/utils/dateUtils';
import { getAssessmentStatus, getTotal } from '../shared/utils/taskUtils';
import { getAssessmentStatusColor } from '../shared/utils/getStatusColor';
import NoDataToPresent from '../components/Overview/NoDataToPresent';
import { PROFICIENCY, PROFICIENCY_LEVELS, SPECIALTY_COLOR } from '../constants';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';
import ViewTeam from '../components/Teams/ViewTeam';
import ChallengeAnalyticsTable from '../components/Overview/ChallengeAnalyticsTable';
import { getDifficultyLabel } from '../shared/utils/difficultyMapping';

const Overview = ({
  assessmentId,
  assessmentData,
  allTaskAttempts,
  serverTime,
  loading,
  onLeaderBoardSelection,
}) => {
  const [viewTeam, setViewTeam] = useState(undefined);
  const globalSnap = useSnapshot(globalStore);
  const { data: usersData, loading: usersDataLoading } = useQueryRecursive(
    gql(listUsersByAssessmentId),
    {
      variables: {
        userAssessmentAssessmentId: assessmentId,
        limit: 1000,
      },
    },
  );

  const { data: teamsAssessmentData, loading: teamsAssessmentDataLoading } =
    useQueryRecursive(gql(listTeamsByEventId), {
      variables: {
        eventId: assessmentId,
        limit: 1000,
      },
    });

  const hasManagePermission = Authorization.canManageEvents(
    globalSnap?.permissions,
  );

  const {
    data: listInvitedUsersByAssessmentIdData,
    loading: listInvitedUsersByAssessmentIdLoading,
  } = useQueryRecursive(gql(listInvitedUsersByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
  });

  const {
    teamBased,
    startDateTime,
    endDateTime,
    multiLevel,
    tasks,
    levels,
    teams,
  } = assessmentData?.getAssessment || {
    teamBased: false,
    startDateTime: undefined,
    endDateTime: undefined,
    tasks: { items: [] },
    teams: [],
  };
  const teamIds =
    teams?.items
      ?.filter((t) => t?.status !== 'REMOVED')
      ?.map((t) => t.teamId) || [];
  // team based
  const teamGroups = teamsAssessmentData?.listTeamsByEventId?.items || [];
  const teamGroupsCount = teamGroups.filter(
    (tg) => tg.status !== 'REMOVED',
  ).length;
  const teamsCompleted = teamGroups.filter((tg) =>
    ['STARTED', 'FINISHED'].includes(tg.status),
  ).length;

  const status = getAssessmentStatus(startDateTime, endDateTime, serverTime);
  const statusColor = getAssessmentStatusColor(status);

  const notStarted = status === 'Not Started';
  const participantsCount = usersData?.listUsersByAssessmentId?.items?.length;
  const completedTasks = allTaskAttempts?.filter(
    (a) => a.status === 'COMPLETED',
  );
  const uniqueCompletedTasks = [
    ...new Set(completedTasks?.map((a) => a.task.id)),
  ];

  const getTeamName = (teamId) =>
    teamGroups?.find((tg) => tg.teamId === teamId)?.team?.name || '';

  // get top participants
  const topParticipants = () =>
    _(completedTasks)
      .groupBy('userAssessment.userId')
      .map((items, userId) => ({
        user:
          usersData?.listUsersByAssessmentId?.items?.find(
            (pc) => pc.userId === userId,
          )?.user || [],
        points: items.reduce(
          (p, c) => p + Number(c.task?.recommendedPoints),
          0,
        ),
      }))
      .orderBy(['points'], ['desc'])
      .value();

  // get top teams
  const topTeams = () =>
    _(completedTasks)
      .filter((t) => teamIds.includes(t.teamId))
      .map((ct) => ({ ...ct, teamName: getTeamName(ct.teamId) }))
      .groupBy('teamId')
      .map((items, teamId) => ({
        teamId,
        teamName: items[0].teamName,
        points: items.reduce(
          (p, c) => p + Number(c.task?.recommendedPoints),
          0,
        ),
      }))
      .orderBy(['points'], ['desc'])
      .value();

  // get top solves
  const topSolves = () =>
    _(allTaskAttempts)
      .groupBy('userAssessment.userId')
      .map((items, userId) => {
        return {
          user:
            usersData?.listUsersByAssessmentId?.items?.find(
              (pc) => pc.userId === userId,
            )?.user || [],
          attempts: [
            ...new Map(
              items
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .map((item) => [item.task.id, item]),
            ).values(),
          ].length,
          solved: items.filter((i) => i.status === 'COMPLETED').length,
          status: usersData?.listUsersByAssessmentId?.items?.filter(
            (userData) => userData?.user?.id === userId,
          )[0]?.status,
        };
      })
      .orderBy(['solved'], ['desc'])
      .value();

  // get team top solves
  const topTeamSolves = () =>
    _(allTaskAttempts)
      .filter((t) => teamIds.includes(t.teamId))
      .map((ta) => ({ ...ta, teamName: getTeamName(ta.teamId) }))
      .groupBy('teamId')
      .map((items, teamId) => ({
        teamId,
        teamName: items[0].teamName,
        attempts: [
          ...new Map(
            items
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((item) => [item.task.id, item]),
          ).values(),
        ].length,
        solved: items.filter((i) => i.status === 'COMPLETED').length,
      }))
      .orderBy(['solved'], ['desc'])
      .value();

  // specialty wise attempts
  const attemptsSpecialty = _(
    completedTasks?.filter((c) => c.task?.specialty?.name),
  )
    .groupBy('task.specialty.name')
    .map((items, category) => ({
      name: category,
      data: items.length,
    }))
    .orderBy(['data'], ['desc'])
    .value();

  // difficulty wise attempts
  const attemptsDifficulty = _(completedTasks)
    .map((cp) => ({
      ...cp,
      ...{
        task: {
          ...cp.task,
          difficulty: PROFICIENCY_LEVELS[cp?.task?.difficulty],
        },
      },
    }))
    .groupBy('task.difficulty')
    .map((items, difficulty) => ({
      name: difficulty,
      data: items.length,
      difficultyInteger: PROFICIENCY[difficulty]?.range,
    }))
    .orderBy(['data'], ['desc'])
    .value();

  // e.g % Hard Tasks Solved = (# Hard Tasks Solved) / (Total Tasks Solved)
  const percentageTasksCompleted = attemptsDifficulty
    .map((ad) => ({
      value: `${Math.round((ad.data / completedTasks.length) * 100)}%`,
      description: ad.name,
      difficultyInteger: ad.difficultyInteger,
    }))
    .sort((a, b) => a?.difficultyInteger - b?.difficultyInteger);

  // total points scored
  const totalPoints =
    topParticipants()?.reduce((acc, val) => acc + val.points, 0) || 0;
  const participantsCompleted = topParticipants()?.length || 0;
  // sort based on difficulty presentation
  const difficultyGraphData = attemptsDifficulty.map((ac) => ({
    ...ac,
    graphColor: PROFICIENCY[ac.name]?.completeColor,
  }));
  const manualCompetition =
    (!startDateTime && !endDateTime) || (startDateTime && !endDateTime);

  const participantInvitedCount =
    listInvitedUsersByAssessmentIdData?.listInvitedUsersByAssessmentId?.items?.filter(
      (participant) => !participant.accepted,
    ).length;

  const participantNotRemovedCount =
    usersData?.listUsersByAssessmentId?.items?.filter(
      (userAssessment) => userAssessment.status !== 'REMOVED',
    ).length;

  const invitedCount = participantInvitedCount + participantNotRemovedCount;
  const useCanViewGroup = Authorization.canManageGroups(
    globalSnap?.permissions,
    globalSnap?.orgPricingTier,
  );

  const allTaskAttemptsCount = getTotal(allTaskAttempts, 'attempts');

  const actions = [
    {
      CustomElement: (row) => {
        const { rowData } = row;
        const selectedTeam = teamGroups?.find((t) => t?.teamId === rowData?.id);
        return (
          useCanViewGroup && (
            <FdButton
              variant="tertiary"
              size="small"
              onClick={() =>
                setViewTeam({ ...selectedTeam, name: selectedTeam?.team?.name })
              }
            >
              VIEW
            </FdButton>
          )
        );
      },
    },
  ];
  // get unique tasks count
  const _tasks =
    (multiLevel
      ? levels?.items
          ?.map((l) => ({
            level: l?.levelNumber,
            tasksItems: l?.tasks?.items,
          }))
          ?.map(({ tasksItems, level }) =>
            tasksItems?.map((task) => ({ level, ...task })),
          )
          .flat()
      : tasks?.items) || [];
  const tasksTotal = [...new Set(_tasks?.map((t) => t.id))].length || 0;

  const attemptedData = (_taskId) => {
    const allTaskAttempted = allTaskAttempts?.filter(
      (task) => task?.taskId === _taskId,
    );
    const attemptsBy = allTaskAttempted?.length;
    const completed =
      allTaskAttempted
        ?.filter((a) => a.status === 'COMPLETED')
        .sort(sortByDateField('updatedAt')) || [];

    const firstBloodBy = teamBased ? completed[0]?.team : completed[0]?.user;
    return { attemptsBy, solvesBy: completed?.length, firstBloodBy };
  };

  const challengeAnalyticsRows = _tasks?.map((item) => {
    const { attemptsBy, solvesBy, firstBloodBy } = attemptedData(
      item?.task?.id,
    );
    return {
      id: item?.taskId,
      name: item?.task?.name,
      level: item?.level,
      difficultyLabel: getDifficultyLabel(item?.task?.difficulty),
      points: item?.task?.recommendedPoints,
      specialtyName: item?.task?.specialty?.name,
      solvesBy,
      attemptsBy,
      firstBloodBy,
      status: solvesBy === 0 ? 'Unsolved' : 'Solved',
    };
  });

  const usersLoading =
    !assessmentData ||
    usersDataLoading ||
    teamsAssessmentDataLoading ||
    listInvitedUsersByAssessmentIdLoading;
  const challengesLoading = !assessmentData || loading;

  return (
    <Box>
      <Summary
        loading={!assessmentData}
        data={[
          ...(teamBased
            ? [
                {
                  value: <Box mb={1}>Team</Box>,
                  description: 'Competition Type',
                },
              ]
            : []),
          {
            value: (
              <Box mb={1}>
                <FdChip
                  color={statusColor}
                  size="small"
                  label={
                    !startDateTime && !endDateTime ? 'Not Started' : status
                  }
                />
              </Box>
            ),
            description: 'Competition Status',
          },
          ...(manualCompetition
            ? [
                {
                  value: <Box mb={1}>Manual</Box>,
                  description: 'Date and Time',
                },
              ]
            : [
                {
                  value: (
                    <Box mb={1}>{getDateTimeZoneFormatted(startDateTime)}</Box>
                  ),
                  description: 'Start Date',
                },
                {
                  value: (
                    <Box mb={1}>{getDateTimeZoneFormatted(endDateTime)}</Box>
                  ),
                  description: 'End Date',
                },
                ...(status === 'In Progress'
                  ? [
                      {
                        value: (
                          <Box mb={1}>
                            <FdSystemDateCountdown endDateTime={endDateTime} />
                          </Box>
                        ),
                        description: 'Time remaining',
                      },
                    ]
                  : []),
              ]),
        ]}
        titleVariant="subtitle1"
        subtitleVariant="subtitle2"
      />
      <Box mt={2}>
        <Box className="grid grid-cols-2 gap-4">
          <FdCard
            variant="outlined"
            heading="Challenges completed by Professional Specialty"
          >
            <FdSkeleton loading={challengesLoading} height="404px">
              {notStarted || attemptsSpecialty.length === 0 ? (
                <NoDataToPresent />
              ) : (
                <Donut
                  data={attemptsSpecialty.map((ac) => ac.data)}
                  labels={attemptsSpecialty.map((ac) => ac.name)}
                  colors={attemptsSpecialty.map(
                    (ac) => SPECIALTY_COLOR[ac.name],
                  )}
                />
              )}
              <Summary
                data={
                  attemptsSpecialty.length > 0
                    ? [
                        {
                          value: attemptsSpecialty[0]?.name || '-',
                          description: 'Most Favoured Specialty',
                        },
                        {
                          value:
                            attemptsSpecialty[attemptsSpecialty.length - 1]
                              ?.name || '-',
                          description: 'Least Favoured Specialty',
                        },
                      ]
                    : []
                }
                titleVariant="subtitle1"
                noBorder
              />
            </FdSkeleton>
          </FdCard>
          <FdCard
            variant="outlined"
            heading="Challenges completed by Proficiency"
          >
            <FdSkeleton loading={challengesLoading} height="404px">
              {notStarted || attemptsDifficulty.length === 0 ? (
                <NoDataToPresent />
              ) : (
                <Donut
                  data={difficultyGraphData.map((ac) => ac.data)}
                  labels={difficultyGraphData.map((ac) => ac.name)}
                  colors={difficultyGraphData.map((ac) => ac.graphColor)}
                />
              )}
              <Summary
                data={
                  percentageTasksCompleted.length > 0
                    ? percentageTasksCompleted
                    : []
                }
                justifyContent="center"
                titleVariant="subtitle1"
                noBorder
              />
            </FdSkeleton>
          </FdCard>
        </Box>
      </Box>
      <ViewTeam viewTeam={viewTeam} setViewTeam={setViewTeam} />
      <Box className="grid grid-cols-5 gap-4 mt-2">
        {[
          ...(teamBased
            ? [
                {
                  value: teamGroupsCount,
                  description: 'Teams Invited',
                  loading: usersLoading,
                },
                {
                  value: `${uniqueCompletedTasks?.length}/${tasksTotal}`,
                  description: 'Challenges Solved',
                  loading: challengesLoading,
                },
                {
                  value:
                    teamGroupsCount > 0 && teamsCompleted !== 0
                      ? Math.round(totalPoints / teamsCompleted)
                      : 0,
                  description: 'Average Points',
                  loading: challengesLoading,
                },
                {
                  value:
                    notStarted || teamsCompleted === 0
                      ? '-'
                      : `${Math.round(
                          completedTasks?.length / teamsCompleted,
                        )}/${tasksTotal}`,
                  description: 'Average Solves',
                  loading: challengesLoading,
                },
                {
                  value:
                    notStarted || allTaskAttempts?.length === 0
                      ? '-'
                      : `${Math.round(
                          (completedTasks?.length / allTaskAttemptsCount) * 100,
                        )}%`,
                  description: 'Average Success Rate',
                  loading: challengesLoading,
                },
              ]
            : [
                {
                  value: invitedCount,
                  description: 'Participants Invited',
                  loading: usersLoading,
                },
                {
                  value: `${uniqueCompletedTasks?.length}/${tasksTotal}`,
                  description: 'Challenges Solved',
                  loading: challengesLoading,
                },
                {
                  value:
                    participantsCount > 0 && participantsCompleted !== 0
                      ? Math.round(totalPoints / participantsCompleted)
                      : 0,
                  description: 'Average Points',
                  loading: challengesLoading,
                },
                {
                  value:
                    notStarted || participantsCompleted === 0
                      ? '-'
                      : `${Math.round(
                          completedTasks?.length / participantsCompleted,
                        )}/${tasksTotal}`,
                  description: 'Average Solves',
                  loading: challengesLoading,
                },
                {
                  value:
                    notStarted || allTaskAttempts.length === 0
                      ? '-'
                      : `${Math.round(
                          (completedTasks?.length / allTaskAttempts?.length) *
                            100,
                        )}%`,
                  description: 'Average Success Rate',
                  loading: challengesLoading,
                },
              ]),
        ].map((d) => (
          <FdCard variant="outlined" key={shortid.generate()}>
            <FdSkeleton loading={d.loading} height="68px">
              <SummaryTitle subtitleVariant="subtitle1" data={d} />
            </FdSkeleton>
          </FdCard>
        ))}
      </Box>
      <Box className="grid grid-cols-2 gap-4 mt-2">
        <FdSkeleton loading={challengesLoading} height="390px">
          <TopFiveTable
            loading={challengesLoading}
            actions={teamBased && actions}
            columns={[
              { field: 'rank', width: 100, headerName: 'Rank' },
              {
                field: 'name',
                width: 400,
                headerName: teamBased ? 'Team Name' : 'Name',
              },
              {
                field: 'points',
                width: 150,
                headerName: 'Points',
              },
            ]}
            data={
              teamBased
                ? topTeams()?.map((tp, i) => ({
                    id: tp.teamId || i,
                    name: tp.teamName,
                    rank: i + 1,
                    points: tp.points,
                    status: tp.status,
                  }))
                : topParticipants()?.map((tp, i) => ({
                    id: tp.user.id || i,
                    name: tp.user.name,
                    rank: i + 1,
                    points: tp.points,
                    status: tp.status,
                  }))
            }
            title="Most Points"
          />
        </FdSkeleton>
        <FdSkeleton loading={challengesLoading} height="390px">
          <TopFiveTable
            loading={challengesLoading}
            actions={teamBased && actions}
            columns={[
              { field: 'rank', width: 100, headerName: 'Rank' },
              {
                field: 'name',
                width: 400,
                headerName: teamBased ? 'Team Name' : 'Name',
              },
              {
                field: 'attempted',
                width: 150,
                headerName: 'Attempted',
              },
              {
                field: 'solved',
                width: 100,
                headerName: 'Solved',
              },
            ]}
            data={
              teamBased
                ? topTeamSolves().map((tp, i) => ({
                    id: tp.teamId || i,
                    name: tp.teamName,
                    rank: i + 1,
                    solved: tp.solved,
                    attempted: tp.attempts,
                    status: tp.status,
                  }))
                : topSolves().map((tp, i) => ({
                    id: tp.user.id || i,
                    name: tp.user.name,
                    rank: i + 1,
                    solved: tp.solved,
                    attempted: tp.attempts,
                    status: tp.status,
                  }))
            }
            title="Most Solves"
          />
        </FdSkeleton>
      </Box>
      {hasManagePermission && (
        <FdSkeleton loading={challengesLoading} height="390px">
          <ChallengeAnalyticsTable
            data={challengeAnalyticsRows}
            loading={challengesLoading}
            onLeaderBoardSelection={onLeaderBoardSelection}
            teamBased={teamBased}
            levelBased={multiLevel}
          />
        </FdSkeleton>
      )}
    </Box>
  );
};

Overview.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  assessmentData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allTaskAttempts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  serverTime: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onLeaderBoardSelection: PropTypes.func.isRequired,
};

export default Overview;

import { gql } from '@apollo/client';
import { useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import {
  onCreateChatForum,
  onDeleteChatForum,
  onUpdateChatForum,
} from '../graphql/subscriptions';
import useSubscription from './useSubscription';

const useChatForumSubscription = ({ assessmentId, refreshData }) => {
  const globalSnap = useSnapshot(globalStore);
  const refreshPageQuery = ({ _userId, _assessmentId }) => {
    if (_userId !== globalSnap.userId && _assessmentId === assessmentId) {
      refreshData();
    }
  };

  useSubscription({
    query: gql(onCreateChatForum),
    variables: {
      filter: { assessmentId: { eq: assessmentId } },
    },
    onData: (_result) => {
      const { assessmentId: _assessmentId, userId } =
        _result?.data?.onCreateChatForum || {};
      refreshPageQuery({ _userId: userId, _assessmentId });
    },
  });

  useSubscription({
    query: gql(onUpdateChatForum),
    variables: {
      filter: { assessmentId: { eq: assessmentId } },
    },
    onData: (_result) => {
      const { assessmentId: _assessmentId, userId } =
        _result?.data?.onUpdateChatForum || {};
      refreshPageQuery({ _userId: userId, _assessmentId });
    },
  });

  useSubscription({
    query: gql(onDeleteChatForum),
    variables: {
      filter: { assessmentId: { eq: assessmentId } },
    },
    onData: (_result) => {
      const { assessmentId: _assessmentId, userId } =
        _result?.data?.onDeleteChatForum || {};
      refreshPageQuery({ _userId: userId, _assessmentId });
    },
  });
};

export default useChatForumSubscription;

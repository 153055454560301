import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import {
  BasePage,
  FdLoadingSpinner,
  useQueryRecursive,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import {
  getUserById,
  listaccomplishmentByUserId,
  listSquadMembersByUserId,
} from '../graphql/queries';
import UserProfile from '../components/User/UserProfile';

const AffiliatedUserProfile = () => {
  const { userId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    skip: !userId,
  });
  const selectedUser = userData?.getUserById?.items[0];

  const {
    data: listaccomplishmentByUserIdLoadingData,
    loading: listaccomplishmentByUserIdLoading,
  } = useQueryRecursive(gql(listaccomplishmentByUserId), {
    variables: {
      userId: selectedUser?.id,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !selectedUser?.id,
  });
  const {
    data: listSquadMembersByUserIdData,
    loading: listSquadMembersByUserIdLoading,
  } = useQueryRecursive(gql(listSquadMembersByUserId), {
    variables: {
      userId,
      filter: {
        status: { ne: 'REMOVED' },
      },
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !userId,
  });

  if (
    userLoading ||
    listaccomplishmentByUserIdLoading ||
    listSquadMembersByUserIdLoading
  ) {
    return <FdLoadingSpinner />;
  }
  const isSquadManager =
    listSquadMembersByUserIdData?.listSquadMembersByUserId?.items[0]?.squad?.members?.items?.some(
      (sm) =>
        sm?.userId === globalSnap?.userId &&
        sm?.role === 'MANAGER' &&
        sm?.status === 'ACTIVE',
    ) || false;

  return (
    <BasePage
      breadCrumbs={[{ name: 'Home', url: '/user-management/affiliated-users' }]}
      currentPageBreadcrumbLabel={`Users / Affiliated / View ${selectedUser?.name}`}
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
      data-cy="user-profile-card"
    >
      <UserProfile
        selectedUser={selectedUser}
        accomplishmentData={listaccomplishmentByUserIdLoadingData}
        isSquadManager={isSquadManager}
        affiliated
      />
    </BasePage>
  );
};

export default AffiliatedUserProfile;

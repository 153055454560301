import React from 'react';
import { Box, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import {
  FdTypography,
  FdCard,
  FdTextField,
  FdAutocomplete,
  FdChip,
  FdIconWithTooltip,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import { PROFICIENCY, SPECIALTY_COLOR, STATUS } from '../../../constants';
import { getParticipantAssessmentStatusColor } from '../../../shared/utils/getStatusColor';
import ChallengeFinderIcon from './ChallengeFinderIcon';

const AutoCompleteSelector = ({
  setSelector,
  placeholder,
  options,
  ListItemTextRenderer,
}) => {
  const theme = useTheme();

  return (
    <FdAutocomplete
      label=""
      placeholder={placeholder}
      options={options}
      multiple
      selectAllLabel="Select all"
      optionLabel="id"
      optionValue="id"
      defaultSelected={[]}
      renderTags={(item) => {
        return (
          <Box className="flex items-center gap-x-2">
            <FdTypography
              variant="button_sm"
              color="black"
              style={{
                paddingTop: '4px',
                letterSpacing: '0.05em',
                ...(theme?.palette?.type === 'light' && {
                  fontFamily: 'Raitor',
                }),
              }}
            >
              {placeholder}
            </FdTypography>
            <Box
              className="flex items-center justify-center h-5 rounded-2xl"
              sx={{
                backgroundColor: theme?.palette?.autoComplete?.chipBackground,
                minWidth: '29px',
              }}
            >
              <FdTypography
                variant="subtitle2"
                color={theme?.palette?.autoComplete?.chipColor}
              >
                {item?.length}
              </FdTypography>
            </Box>
          </Box>
        );
      }}
      onChange={(currentSelection) => {
        const currentSelected = currentSelection?.map((i) => i?.id);
        setSelector(currentSelected);
      }}
      sx={{
        '& .MuiInputBase-root': {
          backgroundColor: theme.palette.autoComplete.background,
        },
        '& .MuiOutlinedInput-root': {
          padding: 0,
          paddingLeft: '8px',
        },
        '& .MuiOutlinedInput-input::placeholder': {
          color: 'black',
          fontSize: '12px',
          fontFamily: 'Raitor',
          fontWeight: 400,
          opacity: 1,
          height: '20px',
          letterSpacing: '0.05em',
        },
      }}
      ListItemTextRenderer={ListItemTextRenderer}
    />
  );
};

AutoCompleteSelector.propTypes = {
  setSelector: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.shape({}).isRequired,
  ListItemTextRenderer: PropTypes.func.isRequired,
};

const ChallengeFinder = ({
  setSearchText,
  searchText,
  setProficiency,
  setStatus,
  setSpeciality,
}) => {
  return (
    <FdCard variant="outlined">
      <Box className="flex items-center gap-x-4">
        <Box
          sx={(theme) => ({
            color: theme?.palette?.typography?.primary,
          })}
          className="w-5 opacity-80"
        >
          <ChallengeFinderIcon />
        </Box>
        <Box className="flex items-center">
          <FdTypography variant="subtitle1">Challenge Finder</FdTypography>
          <FdIconWithTooltip title="Use the search bar and dropdown filters to find challenges across levels. The number of matching challenges will appear on the level buttons, and you can select a level to view its challenges." />
        </Box>
      </Box>
      <Box className="flex flex-wrap items-center gap-x-4 gap-y-4">
        <FdTextField
          placeholder="Search challenges across all levels"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value?.toLowerCase())}
          endAdornment={<SearchIcon />}
          fullWidth
          sx={{ width: '350px' }}
        />
        <AutoCompleteSelector
          placeholder="PROFICIENCY"
          setSelector={setProficiency}
          options={Object.entries(PROFICIENCY).map((item) => ({
            id: item[0],
            color: item[1]?.color,
          }))}
          ListItemTextRenderer={({ option }) => (
            <FdHighlightChip text={option?.id} />
          )}
        />
        <AutoCompleteSelector
          placeholder="SPECIALTY"
          setSelector={setSpeciality}
          options={Object.entries(SPECIALTY_COLOR).map(([id, color]) => ({
            id,
            color,
          }))}
          ListItemTextRenderer={({ option }) => (
            <FdHighlightChip text={option?.id} />
          )}
        />
        <AutoCompleteSelector
          placeholder="STATUS"
          setSelector={setStatus}
          options={STATUS?.map((item) => ({
            id: item,
          }))}
          ListItemTextRenderer={({ option }) => {
            return (
              <FdChip
                label={option?.id}
                size="small"
                color={getParticipantAssessmentStatusColor(option?.id)}
              />
            );
          }}
        />
      </Box>
    </FdCard>
  );
};

ChallengeFinder.propTypes = {
  setProficiency: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSpeciality: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default ChallengeFinder;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Card } from '@mui/material';
import { FdTypography, FdIconWithTooltip } from '@fifthdomain/fe-shared';
import HighlightedLabel from './HighlightedLabel';
import PercentageDifferenceIndicator from '../../User/PercentageDifferenceIndicator';

const ScoreCardTile = ({ tiles }) => {
  const dividerCount = tiles?.length - 1;

  return (
    <Card variant="outlined" style={{ borderRadius: 8, padding: 0 }}>
      <Box className="flex flex-col items-center">
        {tiles?.map((t, i) => (
          <>
            <Box my={1.5}>
              <Box className="flex flex-col items-center">
                <Box className="flex items-center gap-x-1">
                  {t?.valueInChip ? (
                    <HighlightedLabel value={t?.value} />
                  ) : (
                    <FdTypography variant="h4" style={{ fontWeight: 500 }}>
                      {t?.value}
                    </FdTypography>
                  )}
                  <PercentageDifferenceIndicator
                    value={t.lastMonthValue}
                    suffix="last month"
                  />
                </Box>
                <Box className="flex items-center mt-1">
                  <FdTypography variant="body2" color="secondary">
                    {t?.label}
                  </FdTypography>
                  <FdIconWithTooltip title={t?.toolTipText} />
                </Box>
              </Box>
            </Box>
            {i < dividerCount && (
              <Box width="100%">
                <Divider />
              </Box>
            )}
          </>
        ))}
      </Box>
    </Card>
  );
};

ScoreCardTile.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ScoreCardTile;

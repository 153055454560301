import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Divider, lighten, Select, MenuItem } from '@mui/material';
import { FdTypography, FdCard } from '@fifthdomain/fe-shared';
import { DonutStacked, BarStacked } from '../Charts';
import DonutGraphLegend from '../Participant/AccordionContents/DonutGraphLegend';
import BarGraphLegend from '../Participant/AccordionContents/BarGraphLegend';
import LevelTooltip from '../Participant/AccordionContents/LevelTooltip';
import { setToolTipData } from '../Participant/AccordionContents/graphUtils';
import {
  PROFICIENCY,
  PROFICIENCY_LEVELS,
  SPECIALTY_COLOR,
} from '../../constants';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';
import { sortObjectArrayByField } from '../../shared/utils/objectUtils';

const HoverOnTip = () => (
  <Box
    height="62px"
    display="flex"
    alignItems="center"
    justifyContent="center"
    mt={3}
    pb={1}
  >
    <FdTypography variant="captiontext1">
      Hover on the individual segment for challenge details.
    </FdTypography>
  </Box>
);

const OVERALL_TEAM = 'overall-team';

const SelectTeamMembers = ({ teamMembers, value, setValue, reset }) => {
  return (
    <Select
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        reset?.();
      }}
      style={{ marginRight: '2rem' }}
      displayEmpty
    >
      <MenuItem value={OVERALL_TEAM}>Overall Team</MenuItem>
      {teamMembers?.map((tm) => (
        <MenuItem key={tm?.userId} value={tm?.userId}>
          {tm?.alias}
        </MenuItem>
      ))}
    </Select>
  );
};
SelectTeamMembers.defaultProps = {
  reset: undefined,
};
SelectTeamMembers.propTypes = {
  teamMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  reset: PropTypes.func,
};

const TeamProgressGraphs = ({
  teamBased,
  attemptsData,
  tasksData,
  selectedUserIds,
  contestantSelected,
  teamGroups,
}) => {
  const [showDonutGraphToolTip, setShowDonutGraphTooltip] = useState(false);
  const [barGraphLegendData, setBarGraphLegendData] = useState(undefined);
  const [donutToolTipData, setDonutToolTipData] = useState(undefined);
  const [showBarGraphToolTip, setShowBarGraphTooltip] = useState(false);
  const [stackedToolTipData, setStackedToolTipData] = useState(undefined);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userIdProficiency, setUserIdProficiency] = useState(OVERALL_TEAM);
  const [userIdSpecialty, setUserIdSpecialty] = useState(OVERALL_TEAM);

  useEffect(() => {
    const members = teamBased
      ? teamGroups
          ?.find((t) => t?.teamId === contestantSelected?.id)
          ?.team?.members?.items?.filter((m) => m?.userId !== 'UNDEFINED')
          ?.map((tm) => ({
            userId: tm?.userId,
            alias: tm?.User?.alias,
            selected: false,
          }))
          ?.sort(sortObjectArrayByField('alias'))
      : [];
    setTeamMembers(members);
  }, [teamBased, teamGroups, contestantSelected]);

  const getData = (_userIds) => {
    const data =
      tasksData?.items?.map((i) => {
        const allTaskAttempts = attemptsData?.filter(
          (ta) => ta.taskId === i.taskId && _userIds?.includes(ta?.userId),
        );
        const attempts =
          allTaskAttempts?.reduce((acc, at) => acc + at?.attempts, 0) || 0;
        const completed = allTaskAttempts?.some(
          (at) => at?.status === 'COMPLETED',
        );
        let status = attempts > 0 ? 'Attempted' : 'Incomplete';
        if (completed) {
          status = 'Completed';
        }

        return {
          ...i.task,
          id: i.id,
          levelId: i.levelId,
          status,
          attempts,
          difficultyInteger: i.task?.difficulty,
          difficulty:
            i.task?.difficulty > 5
              ? getDifficultyLevel(i.task?.difficulty)
              : PROFICIENCY_LEVELS[i.task?.difficulty],
          specialty: i.task?.specialty?.name,
          creator: i.task?.org?.name,
          modulePartId: i.modulePartId,
          modulePart: i.modulePart,
          taskAssessmentId: i?.id,
          labId: i.task?.labId,
        };
      }) || [];
    return data;
  };
  const allData = getData(selectedUserIds);

  const barStackedData = getData(
    teamBased
      ? userIdSpecialty === OVERALL_TEAM
        ? selectedUserIds
        : [userIdSpecialty]
      : selectedUserIds,
  );
  const barStackedAllSpecialties = [
    ...new Set(allData.map((t) => t.specialty)),
  ];

  const barStackedKeys = [
    ...new Set(
      barStackedAllSpecialties
        .map((_specialty) =>
          allData
            .filter((a) => a.specialty === _specialty)
            .map((t, index) => `id${index}`),
        )
        .flat(),
    ),
  ];

  // sort based on status completed
  const sortStatusMapping = (_status) => _status === 'Completed';
  const barStackedGraphData = barStackedAllSpecialties
    ?.map((_specialty) => {
      const _tasks = barStackedData
        .filter((a) => a.specialty === _specialty)
        .sort(
          (a, b) => sortStatusMapping(b.status) - sortStatusMapping(a.status),
        );
      return {
        specialty: _specialty,
        tasks: _tasks
          ?.filter((t) => t.specialty)
          ?.map((t, index) => {
            const specialtyColor = SPECIALTY_COLOR[t.specialty];
            return {
              key: `id${index}`,
              value: t.recommendedPoints,
              color:
                t.status === 'Completed'
                  ? specialtyColor
                  : lighten(specialtyColor, 0.5),
              taskName: t.name,
              points: t.recommendedPoints,
              estimatedTime: t.estimatedSolveTime,
              status: t.status,
              attempts: t.attempts,
            };
          }),
      };
    })
    .map((t) => ({
      specialty: t.specialty,
      ...t.tasks.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.key]: item.value,
            [`${item.key}Color`]: item.color,
            [`${item.key}taskName`]: item.taskName,
            [`${item.key}points`]: item.points,
            [`${item.key}estimatedTime`]: item.estimatedTime,
            [`${item.key}status`]: item.status,
            [`${item.key}attempts`]: item.attempts,
          }),
        {},
      ),
    }))
    .filter((t) => t.specialty);

  const setBarLegendData = (_specialty) => {
    const specialtyTasks =
      barStackedData?.filter((t) => t.specialty === _specialty) || [];
    const specialtyTasksCompleted =
      specialtyTasks.filter((t) => t.status === 'Completed') || [];
    setBarGraphLegendData({
      specialty: _specialty,
      solved: specialtyTasksCompleted.length,
      totalTasks: specialtyTasks?.length,
      pointsScored: specialtyTasksCompleted.reduce(
        (acc, t) => Number(t.recommendedPoints) + acc,
        0,
      ),
      totalPoints: specialtyTasks.reduce(
        (acc, t) => Number(t.recommendedPoints) + acc,
        0,
      ),
    });
  };

  const donutData = getData(
    teamBased
      ? userIdProficiency === OVERALL_TEAM
        ? selectedUserIds
        : [userIdProficiency]
      : selectedUserIds,
  );

  const donutGraphLegendData = _(donutData)
    .groupBy('difficulty')
    .map((items, proficiency) => ({
      label: proficiency,
      solved: items?.filter((i) => i?.status === 'Completed')?.length || 0,
      total: items?.length || 0,
      difficultyInteger: items?.[0].difficultyInteger || 0,
      color: PROFICIENCY[items?.[0].difficulty]?.completeColor || '',
    }))
    .sort((a, b) => a.difficultyInteger - b.difficultyInteger)
    .value();

  const proficiencyRows = _(donutData)
    .map((pr) => ({
      id: pr?.id,
      difficultyInteger: pr?.difficultyInteger,
      label: pr?.difficulty,
      value: pr?.recommendedPoints || 0,
      color:
        pr?.status === 'Completed'
          ? PROFICIENCY[pr?.difficulty]?.completeColor
          : PROFICIENCY[pr?.difficulty]?.color || '',
      points: pr?.recommendedPoints || 0,
      status: pr?.status,
      specialty: pr?.specialty,
      attempts: pr?.attempts,
      estimatedTime: pr?.estimatedSolveTime,
      taskName: pr?.name,
    }))
    .sort((a, b) => a.difficultyInteger - b.difficultyInteger)
    .value();

  return (
    <FdCard variant="outlined">
      <Box className="flex">
        <Box width="50%">
          <Box className="flex justify-between">
            <FdTypography variant="subtitle1">
              Progress by Proficiency
            </FdTypography>
            {teamBased && (
              <SelectTeamMembers
                teamMembers={teamMembers}
                value={userIdProficiency}
                setValue={setUserIdProficiency}
              />
            )}
          </Box>
          <Box mt={1}>
            <Box className="flex justify-center" mb={6}>
              <Box className="mr-10">
                <Box height="220px" width="220px">
                  <DonutStacked
                    data={proficiencyRows}
                    colors={proficiencyRows?.map((s) => s.color)}
                    onHover={(_data) => {
                      setShowDonutGraphTooltip(true);
                      setToolTipData(setDonutToolTipData, _data.data);
                    }}
                    onLeave={() => {
                      setShowDonutGraphTooltip(false);
                      setDonutToolTipData(undefined);
                    }}
                  />
                </Box>
              </Box>
              <DonutGraphLegend data={donutGraphLegendData} />
            </Box>
            {showDonutGraphToolTip ? (
              <LevelTooltip data={donutToolTipData} />
            ) : (
              <HoverOnTip />
            )}
          </Box>
        </Box>
        <Box width="1px">
          <Divider orientation="vertical" />
        </Box>
        <Box width="40%">
          <Box pl={2}>
            <Box className="flex justify-between">
              <FdTypography variant="subtitle1">
                Progress by Professional Specialty
              </FdTypography>
              {teamBased && (
                <SelectTeamMembers
                  teamMembers={teamMembers}
                  value={userIdSpecialty}
                  setValue={setUserIdSpecialty}
                  reset={() => setBarLegendData(undefined)}
                />
              )}
            </Box>
            <Box className="flex justify-center">
              <Box width="390px" height="250px">
                <BarStacked
                  keys={barStackedKeys}
                  indexBy="specialty"
                  data={barStackedGraphData}
                  onHover={(_data) => {
                    setShowBarGraphTooltip(true);
                    const { id } = _data;
                    setToolTipData(setStackedToolTipData, {
                      specialty: _data.data.specialty,
                      taskName: _data.data[`${id}taskName`],
                      points: _data.data[`${id}points`],
                      estimatedTime: _data.data[`${id}estimatedTime`],
                      status: _data.data[`${id}status`],
                      attempts: _data.data[`${id}attempts`],
                    });
                  }}
                  onMouseClick={(_data) => {
                    const _specialty = _data.indexValue;
                    setBarLegendData(_specialty);
                  }}
                  onLeave={() => {
                    setShowBarGraphTooltip(false);
                    setStackedToolTipData(undefined);
                  }}
                />
              </Box>
              <BarGraphLegend data={barGraphLegendData} />
            </Box>
            {showBarGraphToolTip ? (
              <LevelTooltip data={stackedToolTipData} />
            ) : (
              <HoverOnTip />
            )}
          </Box>
        </Box>
      </Box>
    </FdCard>
  );
};

TeamProgressGraphs.propTypes = {
  teamBased: PropTypes.bool.isRequired,
  attemptsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasksData: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  contestantSelected: PropTypes.shape({ id: PropTypes.string }).isRequired,
  teamGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TeamProgressGraphs;

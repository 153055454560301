import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, FormGroup, FormControlLabel, Switch } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdCard,
  FdButton,
  FdTypography,
  FdModal,
  FdChip,
  FdAlert,
} from '@fifthdomain/fe-shared';

const Jumpbox = ({ editMode }) => {
  const { control, setValue, getValues } = useFormContext();
  const Wrapper = editMode ? Box : FdCard;
  const [openVPN, setOpenVPN] = React.useState(false);
  const [enableVPN, setEnableVPN] = React.useState(
    getValues('enableVPN') ? getValues('enableVPN') : false,
  );

  return (
    <Wrapper
      heading={!editMode && 'Advanced Networking Features'}
      variant="outlined"
    >
      <FdTypography variant="subtitle1">
        Enable VPN configuration for participants
      </FdTypography>
      {!enableVPN ? (
        <Box mt={1}>
          <FdButton
            size="medium"
            variant="secondary"
            onClick={() => {
              setOpenVPN(true);
            }}
          >
            ENABLE VPN CONFIGURATION
          </FdButton>
        </Box>
      ) : (
        <Box mt={1}>
          <FdChip color="default" size="medium" label="VPN Enabled" />
        </Box>
      )}
      <Box>
        <Box mt={1}>
          <FdTypography color="secondary" variant="body2">
            When enabled, participants will have access to a VPN configuration
            file, allowing them to connect securely to your competition
            environment.
          </FdTypography>
          <FdTypography style={{ color: 'red' }} variant="body2">
            Note: VPN configuration can’t be disabled once enabled.
          </FdTypography>
        </Box>
      </Box>
      <Controller
        control={control}
        name="jumpbox"
        render={({
          field: { ref, value: fieldValue, ...rest },
          fieldState: { error },
        }) => (
          <Box>
            <Box my={2}>
              <FormGroup>
                <FdTypography variant="subtitle1">
                  Enable Jump Box for participants
                </FdTypography>
                <FormControlLabel
                  control={
                    <Switch
                      id="jumpbox"
                      checked={fieldValue}
                      value={fieldValue?.value}
                      error={error && error.message}
                      data-cy="jumpbox"
                      {...rest}
                      onChange={(e) => {
                        setValue('jumpbox', e.target.checked);
                      }}
                      inputRef={ref}
                    />
                  }
                />
              </FormGroup>
              <Box>
                <FdTypography color="secondary" variant="body2">
                  When enabled, participants can connect to a Jump Box which
                  allows them to access competitions resources securely.
                </FdTypography>
                <FdAlert
                  style={{ marginTop: '4px' }}
                  variant="info"
                  message={
                    <FdTypography variant="body2">
                      Jump Box enablement settings cannot be edited once the
                      competition has started. If enabled, participants will
                      have access to a Jump Box for the entire duration of the
                      event.
                    </FdTypography>
                  }
                />
              </Box>
            </Box>
          </Box>
        )}
      />
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Enable VPN configuration for participants?</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you certain you want to enable VPN configuration for
              participants?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                Please be aware that this action is irreversible, and once
                enabled, VPN configuration cannot be disabled.
              </FdTypography>
            </Box>
          </Box>
        }
        confirm="ENABLE"
        dismiss="CANCEL"
        open={openVPN}
        onDismiss={() => {
          setOpenVPN(false);
        }}
        onConfirm={() => {
          setOpenVPN(false);
          setValue('enableVPN', true);
          setEnableVPN(true);
        }}
      />
    </Wrapper>
  );
};

Jumpbox.propTypes = {
  editMode: PropTypes.bool,
};

Jumpbox.defaultProps = {
  editMode: false,
};

export default Jumpbox;

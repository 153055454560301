import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const globalSnap = useSnapshot(globalStore);

  if (!globalSnap.orgProducts) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'PARTICIPANT') {
          return <Redirect to="/landing/landing-homepage" />;
        }
        if (user && ['ADMIN', 'MANAGER'].includes(type)) {
          if (!globalSnap.orgProducts?.includes('Competitions'))
            singleSpa.navigateToUrl('/landing');
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ProtectedRoute;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Divider } from '@mui/material';
import { FdTypography, FdAccordion } from '@fifthdomain/fe-shared';
import SummaryTitle from './SummaryTitle';
import { formatMinutes } from '../../shared/utils/dateUtils';
import DonutWithLegends from '../Charts/DonutWithLegends';
import { PROFICIENCY, SPECIALTY_COLOR } from '../../constants';

const AssessmentOverview = ({ allTasks, tasks }) => {
  const taskIds = tasks?.map((t) => t.taskId);
  const tasksSelected = allTasks?.filter((a) => taskIds.includes(a.id)) || [];
  const Tile = ({ value, description }) => (
    <Box p={2}>
      <SummaryTitle
        data={{ value, description }}
        titleVariant="subtitle2"
        subtitleVariant="h4"
        justifyContent="center"
        subtitleColor="primary"
      />
    </Box>
  );
  Tile.propTypes = {
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };
  const findTotal = (field) =>
    tasksSelected?.reduce((acc, ts) => acc + ts?.[field], 0);

  const tilesFirstRow = [
    {
      value: 'Total Challenges',
      description: String(tasksSelected?.length),
    },
    {
      value: 'Total Est. Time to Solve',
      description: formatMinutes(findTotal('estimatedSolveTime'), true),
    },
    {
      value: 'Total Points',
      description: String(findTotal('recommendedPoints')),
    },
  ];
  const firstRowDividers = tilesFirstRow?.length - 1;
  const getTypeCount = (_type) =>
    tasksSelected?.filter((t) => t.type === _type)?.length || 0;
  const tilesSecondRow = [
    {
      value: 'Lab Based Challenges',
      description: String(getTypeCount('LAB')),
    },
  ];
  const secondRowDividers = tilesSecondRow?.length - 1;

  const specialtyRows = _(tasksSelected?.filter((ts) => ts.specialtyId))
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      id: specialtyId,
      label: items[0]?.specialty?.name,
      value: items?.length || 0,
      color: SPECIALTY_COLOR[items[0]?.specialty?.name] || '',
    }))
    .value();

  const proficiencyRows = _(
    tasksSelected?.map((ts) => ({
      ...ts,
      proficiency: ts.difficultyLabel,
    })),
  )
    .sort((a, b) => a.difficulty - b.difficulty)
    .groupBy('proficiency')
    .map((items, proficiency) => ({
      id: proficiency,
      label: proficiency,
      value: items?.length || 0,
      color: PROFICIENCY[proficiency]?.color || '',
    }))
    .value();

  return (
    <Box>
      <FdAccordion
        summary={() => (
          <Box>
            <FdTypography variant="h4">Competition Overview</FdTypography>
            <Box mt={1}>
              <FdTypography variant="body1" color="secondary">
                Expand to get a glimpse of the diversity of challenges based on
                Proficiency, Specialty and type.
              </FdTypography>
            </Box>
          </Box>
        )}
        content={() => (
          <Box>
            <Divider />
            <Box display="flex">
              <Box
                display="flex"
                justifyContent="center"
                width="40%"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="space-evenly">
                  {tilesFirstRow?.map((t, idx) => (
                    <>
                      <Tile value={t.value} description={t.description} />
                      {idx < firstRowDividers && (
                        <Divider orientation="vertical" />
                      )}
                    </>
                  ))}
                </Box>
                <Box my={2}>
                  <Divider variant="middle" />
                </Box>
                <Box display="flex" justifyContent="space-evenly">
                  {tilesSecondRow?.map((t, idx) => (
                    <>
                      <Tile value={t.value} description={t.description} />
                      {idx < secondRowDividers && (
                        <Divider orientation="vertical" />
                      )}
                    </>
                  ))}
                </Box>
              </Box>
              <Box my={3}>
                <Divider orientation="vertical" />
              </Box>
              <Box width="60%" display="flex">
                <Box width="50%" ml={2}>
                  <DonutWithLegends
                    title="Professional Specialty composition"
                    valueSegment="specialty"
                    data={specialtyRows}
                    colors={specialtyRows?.map((s) => s.color)}
                    totalTasks={String(tasksSelected?.length)}
                  />
                </Box>
                <Box my={1}>
                  <Divider orientation="vertical" />
                </Box>
                <Box width="50%" ml={2}>
                  <DonutWithLegends
                    title="Proficiency composition"
                    valueSegment="proficiency"
                    data={proficiencyRows}
                    colors={proficiencyRows?.map((s) => s.color)}
                    totalTasks={String(tasksSelected?.length)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

AssessmentOverview.propTypes = {
  allTasks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default AssessmentOverview;
